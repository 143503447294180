
import React from "react";
import { TextField, Autocomplete, MenuItem } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import PropTypes from 'prop-types';
import { at } from 'lodash';
import { useField , useFormikContext, Field } from 'formik';

import {
  InputLabel,
  FormControl,
  FormHelperText
} from '@material-ui/core';

export default function MultiSelectField(props) {

  const { setFieldValue } = useFormikContext();
  const { label, data, ...rest } = props;
  const [field, meta] = useField(props);
  const { value: selectedValue } = field;
  const [touched, error] = at(meta, 'touched', 'error');
  const isError = touched && error && true;

  function _renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }


  return (
  <FormControl {...rest} error={isError}>

    <Autocomplete
      sx={{ m: 1, width: 500 }}
      multiple
      autoHighlight
      options={data}
      getOptionLabel={(option) => option.label}
      disableCloseOnSelect
      onChange={
        (event, value) => setFieldValue(field.name, JSON.stringify(value, null, 2))
      }

      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={label}

        />
      )}
      renderOption={(props, option, { selected }) => (
            <MenuItem
                {...props}
                key={option}
                value={option}
                sx={{ justifyContent: "space-between" }}
            >

            {option.label}

          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />

{_renderHelperText()}

</FormControl>

  );
}

MultiSelectField.defaultProps = {
  data: []
};

MultiSelectField.propTypes = {
  data: PropTypes.array.isRequired
};