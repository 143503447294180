
import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import {UploaderField} from '../../../components/formfields/UploaderField';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';

//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";
import CourseService  from "../../../services/CourseService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const courseGroups = [
    {
    code: 'CLMFOUFOU',
    label: 'Computer Literacy & MS Office Specialty'
    },
    {
    code: 'JT',
    label: 'Java Technologies'
    },
    {
    code: '.NETT',
    label: '.NET Technologies'
    },
    {
      code: 'PYTT',
      label: 'Python Technologies'
    },
    {
     code: 'MOADT',
     label: 'Mobile App Development Technologies'
    },
    {
      code: 'JST',
      label: 'Javascript Technologies'
    },
    {
       code: 'DSMLAIT',
       label: 'Data Science, ML & AI Technologies'
    },
    {
    code: 'DBT',
    label: 'Database Technologies'
    }
];


const CourseCreationSchema = Yup.object().shape({
   code: Yup.string()
     .min(3, 'Too Short!')
     .max(70, 'Too Long!')
     .required('Required'),
     name: Yup.string()
       .min(4, 'Too Short!')
       .max(70, 'Too Long!')
       .required('Required'),
    description: Yup.string()
         .min(10, 'Too Short!')
         .max(70, 'Too Long!')
         .required('Required'),
       courseGroupCode: Yup.string()
        .required(`Course Group Required`),
 });

export default function CourseCreationForm() {

  return (

        <Container  component="main" >
            <ToastContainer />
         <div>
              <Typography  component="h1" variant="h5">
                 Course Creation
              </Typography>

            <Formik

                initialValues={{
                code: '',
                name: '',
                description: '',
                courseGroupCode: '',
                deletedFlag: ''
                }}

                validationSchema={CourseCreationSchema}

                 onSubmit={(values, { setSubmitting }) => {
                                   setTimeout(() => {

                                      alert(JSON.stringify(values, null, 2));

                                      let courseRequestRequest = {};
                                      courseRequestRequest.code = values.code;
                                      courseRequestRequest.name = values.name;
                                      courseRequestRequest.description = values.description;
                                      courseRequestRequest.courseGroupCode = values.courseGroupCode;
                                      courseRequestRequest.deletedFlag = values.deletedFlag;

                                        courseRequestRequest.classList = JSON.stringify(values, null, 2);

                                        CourseService.saveCourse(courseRequestRequest).then(
                                              response => {
                                               // courseList = response.data;
                                               toast.success('Course saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                console.log("Course saved successfully ...: "+ JSON.stringify(response));
                                              },
                                          error => {
                                           toast.error('Error while saving Course items(s)', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                            console.log("Error saving Course [error.toString()] : "+ error.toString());
                                          }
                                         );
                                     setSubmitting(false);
                                   }, 400);
                                 }}



          render={({ values }) => (

               <Form>
                    <Grid container spacing={3}>
                           <Grid item xs={12} sm={6}>
                             <InputField name="code" label="Course Code" fullWidth />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <InputField name="name" label="Course Name" fullWidth />
                           </Grid>
                            <Grid item xs={12} sm={6}>
                               <InputField name="description" label="Course Description" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                   name="courseGroupCode"
                                   label="Course Group"
                                   data={courseGroups}
                                   fullWidth
                                 />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                  <CheckboxField name="deletedFlag" label="Deleted Flag" fullWidth />
                             </Grid>

                             <br />

                            <Grid item xs={12} sm={6}>
                                <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Cancel
                                </Button>

                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Reset
                                </Button>

                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Save
                                </Button>
                            </Grid>
                    </Grid>
               </Form>
                )}
             />

         </div>
        </Container>
);

}

