import React, { Component } from "react";
//import {useState, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
//import TutorialDataService from "../services/tutorial.service";
//import CollectionService from "../services/collection/CollectionService";
//import CommonService from "../services/collection/CommonService";
//import CollectionService from "../../services/collection/CollectionService";
//import CommonService from "../../services/collection/CommonService";

import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import UploadImages from "./image-upload.component";
//import DigitalTypes from "./DigitalTypes";
//import UploadImages from "../../components/image-upload.component";
//import DigitalTypes from "../../components/DigitalTypes";

import axios from 'axios';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import { v4 as uuidv4 } from 'uuid';

import { v4  } from 'uuid';
import BlogService from '../services/BlogService';

//import FileUpload from "./FileUpload.component";


class NewPost extends Component {

	constructor(props) {
		super(props);
        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeTag = this.onChangeTag.bind(this);
        this.onChangeNewTag = this.onChangeNewTag.bind(this);
        this.rteChange = this.rteChange.bind(this);
        this.newBlog = this.newBlog.bind(this);
        this.selectFile = this.selectFile.bind(this);
        this.saveBlog = this.saveBlog.bind(this);

        this.state = {
          id: null,
          title: "",
          content: "",
          tag: "",
          newTag: "",
          published: false,
          submitted: false,
          filePath: null,
          currentFile: undefined,
          assetTags: [],
          blogCategories : [],
          categoryId : null,
          collectionTypeCd: null,
          blogCategory: "",
          selectedFile: null,
         loaded:0,
         fields: {},
         errors: {}

        };


		this.modules = {
			toolbar: [
		      [{ 'header': [1,2,3,4,5,6,false] }, { 'font': [] }],
		      [{ 'size': ['small', false, 'large', 'huge'] }],
		      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
		      [{'list': 'ordered'}, {'list': 'bullet'},{ 'indent': '-1' }, { 'indent': '+1' }],
              ['link', 'image', 'video'],
		      [{ 'align': [] }],
		      [{ 'color': [] }, { 'background': [] }],
		      ['clean']
		    ]
		};

		this.formats = [
		    'header',
		    'font',
		    'size',
		    'bold',
		    'italic',
		    'underline',
		    "strike",
            "blockquote",
		    'list',
		    'bullet',
		    'align',
		    'indent',
		    'color',
		    'background',
		    "link",
            "image"
	  	];
	}

	rteChange = (content, delta, source, editor) => {
        this.setState({
              content: editor.getHTML()
            });
		console.log(editor.getHTML()); // rich text
		console.log(editor.getText()); // plain text
		console.log(editor.getLength()); // number of characters
	}

 // for validation
  handleValidation() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;

        if(!this.state.title) {
          formIsValid = false;
          errors["title"] = "Title cannot be empty.";
        }

        // description field
        if(!this.state.content ) {
          formIsValid = false;
          errors["content"] = "Description cannot be empty.";
        }

       //  if ((this.state.collectionType != "TEX") && (this.state.selectedFile == null)) {
        if((this.state.selectedFile == null)) {
              formIsValid = false;
              errors["uploadFile"] = "You must select a file for upload.";
       }

      if (((!this.state.tag) || this.state.tag == 'Select Tag') && (!this.state.newTag)) {
        formIsValid = false;
        errors["tagOrnewTag"] = "Please select tag or enter a new tag.";
      }

        this.setState({ errors: errors });
        return formIsValid;
  }

    onChangeTitle(e) {
        this.setState({
          title: e.target.value
        });
        console.log('onChangeTitle - this.state.title: '+ this.state.title);
      }

      onChangeTag(e) {
        this.setState({
          tag: e.target.value
        });
        console.log('onChangeTag - this.state.tag: '+ this.state.tag);
      }

     onChangeNewTag(e) {
          this.setState({
            newTag: e.target.value
          });
         console.log('onChangeNewTag - this.state.newTag: '+ this.state.newTag);
   }


  componentDidMount() {

                    BlogService.getCategories().then(
                      response => {
                        this.setState({
                          assetTags: response.data
                        });
                      },
                      error => {
                       /* this.setState({
                          content:
                            (error.response &&
                              error.response.data &&
                              error.response.data.message) ||
                            error.message ||
                            error.toString()
                        });*/
                        console.log('add blog - error.toString(): '+  error.toString());
                      }
                    );
                    console.log('add blog - this.state.assetTags :'+this.state.assetTags);

         }


     selectFile(event) {
        this.setState({
          currentFile: event.target.files[0],
          previewImage: URL.createObjectURL(event.target.files[0]),
          progress: 0,
          message: ""
        });
      }


saveBlog() {

         //let selectedFileType = (this.state.selectedFile == null)? "" : (this.state.selectedFile[0].type);

        let selectedFileType = (this.state.selectedFile == null)? "" : (this.state.selectedFile[0].type);

        var data = {
          categoryId : this.state.categoryId,
          title: this.state.title,
          collectionTypeCd: selectedFileType,
          tag: this.state.newTag,
          content: this.state.content,
          filePath: this.state.filePath
        };

     console.log('saveBlog() JSON.stringify(data): ' +JSON.stringify(data));
     console.log('save data filePath :'+data.filePath);
     console.log('add resource ssss - data :'+data);
     console.log('add resource - this.state.selectedFile :'+this.state.selectedFile);

        BlogService.saveBlog(data)
          .then(response => {
            this.setState({
              id: response.data.id,
              title: response.data.title,
              collectionTypeCd: response.data.collectionType,
              tag: response.data.tag,
              content: response.data.content,
              published: response.data.published,
              submitted: true
            });
            console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
          });
      }


      /*
      // for uploading with Java backend
      saveBlog() {
        var data = {
          title: this.state.title,
          collectionTypeCd: this.state.collectionTypeCd,
          tag: this.state.tag,
          description: this.state.description,
          filePath: this.state.filePath
        };
     console.log('add resource ssss - data :'+data);
     console.log('add resource - this.state.currentFile :'+this.state.currentFile);

        BlogService.saveBlog(data, this.state.currentFile)
          .then(response => {
            this.setState({
              id: response.data.id,
              title: response.data.title,
              collectionTypeCd: response.data.collectionTypeCd,
              tag: response.data.tag,
              description: response.data.description,
              published: response.data.published,
              submitted: true
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          });
      }*/

      newBlog() {
        this.setState({
          id: null,
          title: "",
          collectionTypeCd: "",
          content: "",
          tag: "",
          currentFile : null,
          submitted: false
        });
 }


    // upload functions for server.js
    checkMimeType=(event)=> {
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
       const types = ['image/png', 'image/jpeg', 'image/gif']
       // const types = ['image/png', 'image/jpeg', 'image/gif']
       // 'image/*','video/*', 'audio/*', 'text/*', 'application/*'
        // loop access array
        for(var x = 0; x<files.length; x++) {

        console.log("File Type: " + files[x].type);

         // compare file type find doesn't matach
             if (types.every(type => files[x].type !== type)) {
             // create error message and assign to container
             err[x] = files[x].type+' is not a supported format\n';
           }
         };
         for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
             // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
       return true;
   }

  maxSelectFile=(event)=> {
        let files = event.target.files
            if (files.length > 3) {
               const msg = 'Only 3 images can be uploaded at a time'
               event.target.value = null
               toast.warn(msg)
               return false;
          }
        return true;
     }

     checkFileSize=(event)=> {
          let files = event.target.files
          let size = 6000000
          let err = [];
          for(var x = 0; x<files.length; x++) {
          if (files[x].size > size) {
           err[x] = files[x].type+'is too large, please pick a smaller file\n';
         }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
          // discard selected file
         toast.error(err[z])
         event.target.value = null
        }
        return true;
}

   // for when file is selected - new implementation
    onChangeHandler = event=> {
          var files = event.target.files
        //  if(this.maxSelectFile(event) && this.checkMimeType(event) &&    this.checkFileSize(event)){
//        if(this.maxSelectFile(event)  &&    this.checkFileSize(event)){
          // if return true allow to setState
             this.setState({
             selectedFile: files,
             loaded: 0
          })
//        }
    }

     showMessage = (msg) => {
        toast(msg)
      };

// for upload and data saving =====================================
onClickHandler = () => {

        if (!this.handleValidation()) {
               console.log("form is not valid");
              return;
          }

        let selectedFileType = (this.state.selectedFile == null)? "" : (this.state.selectedFile[0].type);
        ///selectedFile, collectionType/ "TEX";,
        console.log('add resource - onClickHandler - selectedFileType :'+ selectedFileType);

        //const types = ['image/png', 'image/jpeg', 'image/gif']
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // 'image/*','video/*', 'audio/*', 'text/*', 'application'

        if(selectedFileType.startsWith('image/')) {
         selectedFileType = "PHO";
        }
        else if(selectedFileType.startsWith('video/')) {
          selectedFileType = "VID";
        }
        else if(selectedFileType.startsWith('audio/')) {
           selectedFileType = "AUD";
        }
        else  {
            selectedFileType = "TEX";
        }

        console.log('Blog onClickHandler() - selectedFileType :'+ selectedFileType);

        var data = {
          title: this.state.title,
          collectionTypeCd: selectedFileType,
          tag: ((this.state.tag == "")? this.state.newTag : this.state.tag),
          content: this.state.content,
          filePath: this.state.filePath
        };

     console.log('Blog onClickHandler() JSON.stringify(data): ' +JSON.stringify(data));
     console.log('save data filePath :'+data.filePath);
     console.log('add onClickHandler ssss - data :'+data);
     console.log('add onClickHandler - this.state.selectedFile[0] :'+this.state.selectedFile[0]);

        BlogService.saveBlog(data, this.state.selectedFile[0])
          .then(response => {
            this.setState({
              id: response.data.id,
              title: response.data.title,
              collectionTypeCd: response.data.collectionType,
              tag: response.data.tag,
              content: response.data.content,
              published: response.data.published,
              submitted: true
            });
            toast.success('🦄 Blog saved successfully', {
             position: "top-center",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
             });
            console.log('Blog onClickHandler() JSON.stringify(response.data): ' +JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
          });

 }

//============================================

    resizeImage = (settings) => {
//var resizeImage = function (settings) {
    var file = settings.file;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement('canvas');
    var dataURItoBlob = function (dataURI) {
        var bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
            atob(dataURI.split(',')[1]) :
            unescape(dataURI.split(',')[1]);
        var mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var max = bytes.length;
        var ia = new Uint8Array(max);
        for (var i = 0; i < max; i++)
            ia[i] = bytes.charCodeAt(i);
        return new Blob([ia], { type: mime });
    };
    var resize = function () {
        var width = image.width;
        var height = image.height;
        if (width > height) {
            if (width > maxSize) {
                height *= maxSize / width;
                width = maxSize;
            }
        } else {
            if (height > maxSize) {
                width *= maxSize / height;
                height = maxSize;
            }
        }
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(image, 0, 0, width, height);
        var dataUrl = canvas.toDataURL('image/jpeg');
        return dataURItoBlob(dataUrl);
    };
    return new Promise(function (ok, no) {
        if (!file.type.match(/image.*/)) {
            no(new Error("Not an image"));
            return;
        }
        reader.onload = function (readerEvent) {
            image.onload = function () { return ok(resize()); };
            image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    });
};
//=============================

	render() {

     let selectedFileType = (this.state.selectedFile == null)? "" : (this.state.selectedFile[0].type);

	return (

	  <div className="container">

              <div className="fieldset-wrapper">
                <fieldset>
                  <legend>Blog Post Detail</legend>

                   <p class="field field-text">
                      <label for="title">Title:</label>
                      <input id="title"
                             name="title"
                             required
                             value={this.state.title}
                             onChange={this.onChangeTitle}
                             size="100" type="text" />
                  </p>
                  <div style={{ color: "red" }}>{this.state.errors["title"]}</div>

                  <p/>
                       <div>Title value is : {this.state.title}.</div>
                  <p/>

                  <p class="field field-text">
                    <label for="comment-text">Content:</label>
                    <ReactQuill
                          theme="snow"
                          modules={this.modules}
                          formats={this.formats}
                          onChange={this.rteChange}
                          value={this.state.content || ''}
                          placeholder="Type in full text for publication..."
                          style={{
                                  height: '200px',
                              }}
                      />
                  </p>
                   <div style={{ color: "red" }}>{this.state.errors["content"]}</div>

                 <br />
                 <br />
                 <p class="field field-text">

                    <div>
                       <span className="addContentTagDisplay"><label for="tag">Tag:</label></span>
                       <span className="addContentTagDisplay">
                            <select id="tag" name="tag" onChange={this.onChangeTag}>
                                <option value={this.state.tag}>Select Tag</option>
                                 {this.state.assetTags.map(tag => (
                                   <option key={tag.title} value={tag.title}>
                                     {tag.title}
                                   </option>
                                 ))}
                              </select>
                       </span>
                        <span className="addContentTagDisplay" >
                         Tag
                        </span>
                         <span className="addContentTagDisplay" >
                                        <input
                                        id="newTag"
                                        name="newTag"
                                        required
                                        value={this.state.newTag}
                                        onChange={this.onChangeNewTag}
                                        aria-describedby="comment-url-help"
                                        />
                         </span>
                    </div>
                 </p>
                 <div style={{ color: "red" }}>{this.state.errors["tagOrnewTag"]}</div>

                <p/>
                    <div>Title is : {this.state.title}.</div>
                <p/>
                <p/>
                    <div>Selected Tag is : {this.state.tag}.</div>
                <p/>
                <p/>
                 <div>New Tag value is : {this.state.newTag}.</div>
                <p/>

                 <p/>
                    <div>File Type is : {selectedFileType}</div>
                 <p/>
                  <p/>
                      <div>Current File  is : {this.state.currentFile}</div>
                  <p/>

        {
                 /*
                  <p class="field field-text"  className="col-xs-4 col-xs-offset-2">
                              <label for="comment-url" >Attachment:</label>
                              <label className="btn btn-default p-0">
                                <input type="file"  style = {{  marginLeft : 15 }} accept="image/*,video/*, audio/* text/*, application/*"
                                onChange={this.selectFile} />
                              </label>
                   </p>
                   */
        }
                  <p class="field field-text"  className="col-xs-4 col-xs-offset-2">
                        <label for="comment-url" >Attachment:</label>
                        <input type="file" class="form-control" single onChange={this.onChangeHandler}/>
                        <div class="form-group">
                            <ToastContainer />
                            <Progress max="100" color="success" value={this.state.loaded} >{Math.round(this.state.loaded,2) }%</Progress>
                        </div>
                   </p>

                   <div style={{ color: "red" }}>{this.state.errors["uploadFile"]}</div>

                </fieldset>



              </div>
              <div className="field-submit">
                <p>
                    <span>
                        <button id="cancelButton" name="cancelButton" className="submit-span"  type="submit">Cancel</button>
                    </span>
                    <span>
                        <button id="resetButton" name="resetButton" onClick={this.newBlog}  className="submit-span"  type="submit">Reset</button>
                    </span>
                    <span>
                        <button id="saveButton"  name="saveButton"  className="submit-span"type="submit"   onClick={this.onClickHandler}>Post</button>
                    </span>
                </p>
              </div>

	      </div>
	    );
	}

}

export default NewPost;

