import React, { useState } from 'react';
import UserDetail from './UserDetail';
import UserContact from './UserContact';
import Confirmation from './Confirmation';
import Success from './Success';
import UserSchema from './UserSchema';
import UserFormModel from './UserFormModel';
import UserFormFormitInitValues from './UserFormFormitInitValues';
import AuthService from "../../../../services/auth.service";

import { Formik, Form, Field } from 'formik';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import useStyles from '../styles';

import StudentService from "../../../../services/StudentService";
import { register } from "../../../../actions/auth";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const steps = ['Account Detail', 'Contact Info', 'Confirmation','Submission'];
const { formId, formField } = UserFormModel;

function _renderStepContent(step, values) {
    switch (step) {
        case 0:
          return <UserDetail formField={formField} />;
        case 1:
          return <UserContact formField={formField} />;
        case 2:
          return <Confirmation values={ values } />;
        default:
          return <div />;
    }
}


export default function Signup(props)  {

        const classes = useStyles();
        const [activeStep, setActiveStep] = useState(0);
        const currentValidationSchema = UserSchema[activeStep];
        const isLastStep = activeStep === steps.length - 1;
        const [values, setValues] = useState("");

        function _sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }

          async function _submitForm(values, actions) {
          await _sleep(1000);
          values.userRoleCode = "ROLE_USER";
          console.log(JSON.stringify(values, null, 2));
          alert('JSON.stringify(values, null, 2): '+JSON.stringify(values, null, 2));

          let role = new Set();
          role.add('ROLE_USER');

         let userData = {};
         userData.userName = values.userName;
         userData.firstName = values.firstName;
         userData.lastName = values.lastName;
         userData.email = values.email;
         userData.phoneNumber = values.phoneNumber;
         userData.password = values.password;
         userData.role = role;
         userData.realm = 'REALM_BYTES_STEROID';

          alert("userData before calling service :" +JSON.stringify(userData) );
          //AuthService.register(values.userName, values.firstName, values.lastName, values.email, values.phoneNumber, values.password, 'REALM_BYTES_STEROID')
           AuthService.register(userData)
              .then(response => {
                        toast.success('Your registration was successful', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                      console.log("response data after user creation: "+  JSON.stringify(response.data));
                   })
                   .catch(e => {
                            toast.error('Error: Registration Failed ...', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                     console.log(e);
                   });
          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);

        }

        function _handleSubmit(values, actions) {
          if (isLastStep) {
            _submitForm(values, actions);
          } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          }

            setValues(values);
            console.log("values in _handleSubmit function: "+ JSON.stringify(values, null, 2));
        }

        function _handlePrevious() {
          setActiveStep(activeStep - 1);
        }


 return (

     <Container  component="main" >
         <ToastContainer />
       <Typography component="h4" variant="h5" align="center">
             Registration
       </Typography>
       <Stepper activeStep={activeStep} className={classes.stepper}>
         {steps.map(label => (
           <Step key={label}>
             <StepLabel>{label}</StepLabel>
           </Step>
         ))}
       </Stepper>

       <React.Fragment>
                {activeStep === steps.length ? (
                   <div>Submission Message (End)</div>
                 ) : (
                   <Formik
                        initialValues={UserFormFormitInitValues}
                        onSubmit={_handleSubmit}
                        validationSchema={currentValidationSchema}
                     >
                     {({ isSubmitting }) => (
                       <Form >
                         {_renderStepContent(activeStep, values)}

                         <div className={classes.buttons}>
                           {activeStep !== 0 && (
                             <Button onClick={_handlePrevious} className={classes.button}>
                               Back
                             </Button>
                           )}
                           <div className={classes.wrapper}>
                             <Button
                               disabled={isSubmitting}
                               type="submit"
                               variant="contained"
                               color="primary"
                               className={classes.button}
                             >
                               {isLastStep ? 'Save' : 'Next'}
                             </Button>
                             {isSubmitting && (
                               <CircularProgress
                                 size={24}
                                 className={classes.buttonProgress}
                               />
                             )}
                           </div>
                         </div>
                       </Form>
                     )}

                   </Formik>
                 )}
      </React.Fragment>
     </Container>
   );

}