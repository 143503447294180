import React, { Component } from "react";
import { Redirect, Link  } from 'react-router-dom';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { connect } from "react-redux";
import { login } from "../actions/auth";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import {ErrorOutline} from "@material-ui/icons";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import AuthService from "../services/auth.service";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Resetpasswordemail extends Component {

  constructor(props) {
  
    super(props);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.submitEmailForVerification = this.submitEmailForVerification.bind(this);

    this.state = {
      email: "",
      result: null
    };

    // call that gets us here
    // contextPath + "/user/change/password?token=" + token
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  showMessage = (msg) => {
        toast(msg)
   };

  submitEmailForVerification(e) {
    e.preventDefault();
    this.form.validateAll();

    AuthService.sendVerificationEmail(this.state.email).then(
                  response => {
                    this.setState({
                      result: response.data
                    });
                    //this.showMessage('An email has been sent with instructions to reset your password.')
                   // toast.info('An email has been sent with instructions to reset your password.',{position: toast.POSITION.TOP_CENTER}, {autoClose:3000});
                    toast.info('🦄 An email has been sent with instructions to reset your password.', {
                                                                                                              position: "top-center",
                                                                                                              autoClose: 5000,
                                                                                                              hideProgressBar: false,
                                                                                                              closeOnClick: true,
                                                                                                              pauseOnHover: true,
                                                                                                              draggable: true,
                                                                                                              progress: undefined,
                                                                                                              });
                  },
                  error => {
                  console.log('submitEmailForVerification - error.toString(): '+  error.toString());
                     //toast.error('Error: unable to send verification email.',{position: toast.POSITION.TOP_CENTER}, {autoClose:3000});
                         toast.error('🦄 Error: unable to send verification email.', {
                                                                                                                                   position: "top-center",
                                                                                                                                   autoClose: 5000,
                                                                                                                                   hideProgressBar: false,
                                                                                                                                   closeOnClick: true,
                                                                                                                                   pauseOnHover: true,
                                                                                                                                   draggable: true,
                                                                                                                                   progress: undefined,
                                                                                                                                   });
                  }
                );

  }

  render() {


    return (
      <div className="col-md-12">
        <div className="formContainerDiv">

        <fieldset>
               <legend>User email</legend>
          <img
            src="user_common_avatar.png"
            alt="profile-img"
            className="profile-img-card"
          />


        <Form
            onSubmit={this.submitEmailForVerification}
            ref={(c) => {
              this.form = c;
            }}
          >

        <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                    className="form-control"
                    id="email"
                    name="email"
                    value={this.state.email}
                    onChange={this.onChangeEmail}
                 />
                <br/>

               <div>
                    <button type="submit">Submit</button>
               </div>
         </div>

        <div className="field-submit">
            <Link to={"./resetPassword"}>
                    Password Reset
            </Link>
        </div>
         </Form>

         </fieldset>

        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {
}

export default connect(mapStateToProps)(Resetpasswordemail);
