
import React from "react";
import { render } from "react-dom";
import Styles from "./Styles";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import FileField from "./FileField";
// https://codesandbox.io/s/react-final-form-wizard-example-forked-kt8hn?file=/index.js
import Select from "react-select";
import Calendar from "../../common/Calendar.component"; // alternative to date picker
import states from "../../common/states";
import countries from "../../common/countries";
//import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, isValid, toDate } from "date-fns";
//import TutorService from "../../services/tutoring/TutorService";
//import TutoringUserService from "../../services/tutoring/TutoringUserService";
import StudentService from "../../services/StudentService";
import axios from "axios";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
       // await sleep(300);
       // console.log("JSON.stringify(values: "+ JSON.stringify(values));
       // window.alert(JSON.stringify(values, 0, 2));
       // window.alert(JSON.stringify(values));

        let studentRegistrationRequest = {};
        studentRegistrationRequest.userName = values.userName; // T
        studentRegistrationRequest.firstName = values.firstName;
        studentRegistrationRequest.middleName = values.middleName;
        studentRegistrationRequest.lastName = values.lastName;
        studentRegistrationRequest.password = values.password; //"passwordConfirmation": "test",
        studentRegistrationRequest.dateOfBirth = values.dateOfBirth; // Error? conversion
        //studentRegistrationRequest.resumeFilePath = selectedFile; //values.resumeFilePath[0].path || null; // TODO


        if(null != values.photoFile) {
          studentRegistrationRequest.profilePhotoFilePath = values.photoFile[0]?.path || null; //
          studentRegistrationRequest.photoFile = values.photoFile[0];
        }
        else {
           studentRegistrationRequest.photoFilePath =  null;
           studentRegistrationRequest.photoFile = null;
        }

        if(null != values.resumeFile) {
          studentRegistrationRequest.resumeFilePath = values.resumeFile[0]?.path || null; //
          studentRegistrationRequest.resumeFile = values.resumeFile[0];
        }
        else {
            studentRegistrationRequest.resumeFilePath =  null;
            studentRegistrationRequest.resumeFile = null;
        }

        if(null != values.paymentFile) {
          studentRegistrationRequest.paymentFileReceiptPath = values.paymentFile[0]?.path || null; //
          studentRegistrationRequest.paymentFile = values.paymentFile[0];
        }
        else {
           studentRegistrationRequest.paymentFileReceiptPath =  null;
           studentRegistrationRequest.paymentFile = null;
        }
         studentRegistrationRequest.files = [studentRegistrationRequest.photoFile,
                    studentRegistrationRequest.resumeFile, studentRegistrationRequest.paymentFile];

        studentRegistrationRequest.email = values.email;
        studentRegistrationRequest.phoneNo = values.phoneNo;
        studentRegistrationRequest.address1 = values.address1;
        studentRegistrationRequest.address2 = values.address2;
        studentRegistrationRequest.city = values.city;
        studentRegistrationRequest.stateInCountry = values.stateInCountry;
        studentRegistrationRequest.country = values.country;
        //studentRegistrationRequest.profileNote = values.notes;
        studentRegistrationRequest.resumeCoverNote = values.coverNote;
        studentRegistrationRequest.employmentStatusCd = values.employmentStatusCd;

        // new
        studentRegistrationRequest.certifications = values.certifications;
        //studentRegistrationRequest.educationalLevelCd =  JSON.stringify(values.educationalLevelCd) || null;
        studentRegistrationRequest.educationalLevelCd =  values.educationalLevelCd;
        studentRegistrationRequest.coursesInterestJson = values.coursesInterestJson;

        // student specific
        //studentRegistrationRequest.educationalLevel = JSON.stringify(values.educationalLevel) || null; ;
        //console.log("studentRegistrationRequest.photoPath:"+ studentRegistrationRequest.photoPath);
         console.log("values.resumeFile[0]:"+ values.resumeFile[0]);

     console.log("studentRegistrationRequest.files >:"+ studentRegistrationRequest.files); //
       // console.log("values.resumeFilePath[0]:"+ values.resumeFilePath[0]);
       // console.log("values.resumeFilePath[0].path:"+ values.resumeFilePath[0].path);

       //console.log("values.photo[0]:"+ values.photo[0]);

       // console.log("studentRegistrationRequest.resumeFilePath:"+ studentRegistrationRequest.resumeFilePath);

      console.log("studentRegistrationRequest.files >> :"+ studentRegistrationRequest.files);

        studentRegistrationRequest.resumeText = values.resumeText;
        studentRegistrationRequest.notes = values.notes;
        studentRegistrationRequest.languagesSpoken = JSON.stringify(values.languagesSpoken);
        studentRegistrationRequest.learningHoursCount = 0;
         studentRegistrationRequest.realm = 'REALM_BS';
        //studentRegistrationRequest.courses = {};

        const studentAvailabilityTimes = {
        "mondayMorning":values.mondayMorning || false ,
        "mondayAfternoon":values.mondayAfternoon || false ,
        "mondayEvening":values.mondayEvening || false ,
        "mondayNight":values.mondayNight || false ,
        "tuesdayMorning":values.tuesdayMorning || false ,
        "tuesdayAfternoon":values.tuesdayAfternoon || false ,
        "tuesdayEvening":values.tuesdayEvening || false ,
        "tuesdayNight":values.tuesdayNight || false ,
        "wednesdayMorning":values.wednesdayMorning || false ,
        "wednesdayAfternoon":values.wednesdayAfternoon || false ,
        "wednesdayEvening":values.wednesdayEvening || false ,
        "wednesdayNight":values.wednesdayNight || false ,
        "thursdayMorning":values.thursdayMorning || false ,
        "thursdayAfternoon":values.thursdayAfternoon || false ,
        "thursdayEvening":values.thursdayEvening || false ,
        "thursdayNight":values.thursdayNight || false ,
        "fridayMorning":values.fridayMorning || false ,
        "fridayAfternoon":values.fridayAfternoon || false ,
        "fridayEvening":values.fridayEvening || false ,
        "fridayNight":values.fridayNight || false ,
        "saturdayMorning":values.saturdayMorning || false ,
        "saturdayAfternoon":values.saturdayAfternoon || false ,
        "saturdayEvening":values.saturdayEvening || false ,
        "saturdayNight":values.saturdayNight || false ,
        "sundayMorning":values.sundayMorning || false ,
        "sundayAfternoon":values.sundayAfternoon || false ,
        "sundayEvening":values.sundayEvening || false ,
        "sundayNight":values.sundayNight || false
        };
        // window.alert(JSON.stringify(tutorAvailabilityTimes));
        //  console.log("JSON.stringify(tutorAvailabilityTimes: "+ JSON.stringify(tutorAvailabilityTimes));
        //tutorToSave.availability = tutorAvailabilityTimes;
        // put escape slashes to avoid bad request - 400 error
        studentRegistrationRequest.preferredClassScheduleJson = JSON.stringify(studentAvailabilityTimes);

        registerStudentUser(studentRegistrationRequest);
        // TODO - phone, city, state, actual files upload

};


function registerStudentUser(studentRegistrationRequest) {
     console.log("studentRegistrationRequest.resumeFilePath[0]:"+ studentRegistrationRequest.resumeFilePath);
     console.log("JSON.stringify(registerStudentUser in registerStudentUser(studentRegistrationRequest) function: "+ JSON.stringify(studentRegistrationRequest));

  StudentService.createStudentUser(studentRegistrationRequest, studentRegistrationRequest.files)
      .then(response => {
         console.log("response data after create: "+  JSON.stringify(response.data));
      })
      .catch(e => {
        console.log(e);
      });


  }



const Error = ({ name }) => (
  <Field
    name={name}
    subscription={{ touched: true, error: true }}
    render={({ meta: { touched, error } }) =>
      touched && error ? <span>{error}</span> : null
    }
  />
);

    ///*
    const RenderDatePicker = ({ input: { onChange, value }, ...rest }) => (
      <DatePicker selected={value} onChange={date => onChange(date)} {...rest} />
    );
    //*/

    const ReactSelectAdapter = ({ input, ...rest }) => (
      <Select {...input} {...rest} searchable />
    );



//// File upload =================================
   // On file select (from the pop up)
   /*
    const onFileChange  = (event) => {
      // Update the state
      this.setState({ selectedFile: event.target.files[0] });
    };
    */

    // On file upload (click the upload button)
   /*const  onFileUpload = () => {
      // Create an object of formData
      const formData = new FormData();

      // Update the formData object
      formData.append(
        "myFile",
        this.state.selectedFile,
        this.state.selectedFile.name
      );

      // Details of the uploaded file
      console.log('this.state.selectedFile'+ this.state.selectedFile);

      // Request made to the backend api
      // Send formData object
      axios.post("api/uploadfile", formData);
    };*/

//============================================

const required = (value) => (value ? undefined : "Required");

const StudentSignup = () => (
  <Styles>
   <div className = "register-title"> Student Registration </div>
    <Wizard
      initialValues={{ employed: true }}
      onSubmit={onSubmit}
       >
      <Wizard.Page>
      <div className = "register-wrapper">
        <fieldset>
        <legend>Bio Information</legend>
        <div className = "register-left-div">
         <p className="field field-text">
              <label for="firstName">First Name</label>
              <Field
                name="firstName"
                component="input"
                type="text"
                validate={required}
              />
              <Error name="firstName" />
        </p>
        <p className="field field-text">
              <label for="middleName">Middle Name</label>
              <Field
                name="middleName"
                component="input"
                type="text"
                validate={required}
              />
        </p>
        <p className="field field-text">
              <label for="lastName">Last Name</label>
              <Field
                name="lastName"
                component="input"
                type="text"
                validate={required}
              />
              <Error name="lastName" />
        </p>
        <p className="field field-text">
              <label for="userName">User Name</label>
              <Field
                name="userName"
                component="input"
                type="text"
                validate={required}
              />
              <Error name="userName" />
        </p>
        <p className="field field-text">
            <label for="userName">Gender</label>
            <Field name="gender" component="select" >
                <option value="unknown">Not specified</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
            </Field>
             <Error name="gender" />
        </p>
     </div>

    <div className="register-right-div">
        <p className="field field-text">
          <label for="password">Password</label>
          <Field
            name="password"
            component="input"
            type="text"
            validate={required}
          />
        </p>
        <p className="field field-text">
          <label for="passwordConfirmation">Password Confirmation</label>
          <Field
            name="passwordConfirmation"
            component="input"
            type="text"
            validate={required}
          />
        </p>

        <p className="field field-text">
          <label for="photoFile">Photo</label>
          <FileField
            name="photoFile"
            component="input"
            type="text"
          />
        </p>

        <div>
          <label for="dateOfBirth">Date of birth</label>
          <Field
            name="dateOfBirth"
            component={RenderDatePicker}
            validate={required}
            locale="de" dateFormat="P"
          />
          <Error name="dateOfBirth" />
        </div>

       </div>



      </fieldset>
     </div>

      </Wizard.Page>

      <Wizard.Page
              //validate={(values) => {
              //  const errors = {};
               /* if (!values.email) {
                  errors.email = "Required";
                }
                if (!values.favoriteColor) {
                  errors.favoriteColor = "Required";
                }
                return errors;
              }}*/
            >

         <div className = "register-wrapper">
          <fieldset>
              <legend>Contact Information</legend>
               <div className = "register-left-div">

               <p className="field field-text">
                <label>Email</label>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  placeholder="Email"
                />
                <Error name="email" />
              </p>
              <p className="field field-text">
                <label>Phone No.</label>
                <Field
                  name="phoneNo"
                  component="input"
                  type="phone"
                  placeholder="Phone No."
                />
                <Error name="phoneNo" />
              </p>
              <p className="field field-text">
                <label>Address Line 1</label>
                <Field
                  name="address1"
                  component="input"
                  type="address1"
                  placeholder="Address Line 1"
                />
                <Error name="address1" />
              </p>
              <p className="field field-text">
                <label>Address Line 2</label>
                <Field
                  name="address2"
                  component="input"
                  type="address2"
                  placeholder="Address Line 2"
                />
                <Error name="address2" />
              </p>

              </div>
              <div className = "register-right-div">

              <p className="field field-text">
                <label>City</label>
                <Field
                  name="city"
                  component="input"
                  type="city"
                  placeholder="City"
                />
                <Error name="city" />
              </p>
              <p className="field field-text">
              <label>State</label>
                <Field
                    name="stateInCountry"
                    placeholder="State or province"
                    component="input"
                 />
                <Error name="stateInCountry" />
              </p>
              <p className="field field-text">
                <label>Country</label>
                <Field
                    name="country"
                    placeholder="Select a country"
                    component={ReactSelectAdapter}
                    options={countries}
                />
                <Error name="country" />
              </p>
          </div>
          </fieldset>
          </div>


        </Wizard.Page>

      <Wizard.Page
       /* validate={(values) => {
          const errors = {};
          if (!values.toppings) {
            errors.toppings = "Required";
          } else if (values.toppings.length < 2) {
            errors.toppings = "Choose more";
          }
          return errors;
        }}*/
      >

    <div className = "register-wrapper">
    <fieldset>
    <legend>Education & Certification</legend>
     <div className = "register-left-div">

     <p className="field field-text">
        <label>Certifications</label>
        <Field
          name="certifications"
          placeholder="Enter your certifications"
          component="textarea"
        />
      <Error name="certifications" />
    </p>
    </div>

    <div className = "register-right-div">
     <p className="field field-text">
      <label>Educational Level</label>
      <Field name="educationalLevelCd" component="select" multiple>
        <option value="KINDERGATHEN">🐷 Kindergathen</option>
        <option value="ELEMENTARY">🍄 Elementary</option>
        <option value="HIGHSCHOOL">🧀 High School</option>
        <option value="TERTIARY">🐓 Tertiary</option>
      </Field>
      <Error name="educationalLevelCd" />
    </p>

    <p className="field field-text">
              <label>Employment Status</label>
              <Field name="employmentStatusCd" component="select">
                <option value="UNEMPLOYED">🐷 UnEmployed</option>
                <option value="EMPLOYEDPARTTIME">🍄 Employed Part-time</option>
                <option value="EMPLOYED">🧀 Employed</option>
              </Field>
              <Error name="employmentStatusCd" />
    </p>
    </div>
    </fieldset>
    </div>

      </Wizard.Page>

      <Wizard.Page>

        <div className = "register-wrapper">
        <fieldset>
          <legend>Courses of interest</legend>
           <div className = "register-left-div">

         <p className="field field-text">
            <label for="coursesInterestJson">Courses Of Interest</label>
            <Field name="coursesInterestJson" component="select" multiple>
              <option value="JAVA">🐷Java</option>
              <option value=".NET">🍄 .NET</option>
              <option value="DATASCI">🧀 Data Science</option>
              <option value="DB">🐓Databases</option>
              <option value="WEBDESIGN">🍍Web Design</option>
            </Field>
            <Error name="coursesInterestJson" />
          </p>
         </div>
        </fieldset>
        </div>

      </Wizard.Page>


      <Wizard.Page>
        <div className = "register-wrapper">
            <fieldset>
                <legend>Resume & background</legend>
                 <div className = "register-left-div">
                        <p className="field field-text">
                          <label for="resumeFile">Resume</label>
                          <FileField name="resumeFile" />
                        </p>
                 </div>
            </fieldset>
         </div>
     </Wizard.Page>

   <Wizard.Page
         validate={(values) => {
           const errors = {};
           if (!values.coverNote) {
             errors.coverNote = "Required";
           }
           return errors;
         }}
       >
     <div className = "register-wrapper">
         <fieldset>
             <legend>Resume & background</legend>
              <div className = "register-left-div">
                     <p className="field field-text">
                       <label for="coverNote">Short note about you</label>
                       <Field name="coverNote" component="textarea" placeholder="Resume cover note" />
                       <Error name="coverNote" />
                     </p>
              </div>
         </fieldset>
      </div>
  </Wizard.Page>

  <Wizard.Page>
        <div className = "register-wrapper">
        <fieldset>
          <legend>Availability</legend>

          <label>Available Times</label>
            <div>(Morning: 8:00 AM - 11:30 AM GMT)</div>
            <div>(Afternoon: 12:00 PM - 3:30 PM GMT)</div>
            <div>(Evening: 4:00 PM - 7:30 PM GMT)</div>
            <div>(Night: 8:00 PM - 11:30 PM GMT)</div>
        <div>
            <table name="tutorAvailabilityTimes">
              <tr>
                <td></td>
                <th scope="col">Morning</th>
                <th scope="col">Afternoon</th>
                <th scope="col">Evening</th>
                <th scope="col">Night</th>
              </tr>
              <tr>
                <th scope="row">Monday</th>
                <td><Field name="mondayMorning" component="input" type="checkbox" /></td>
                <td><Field name="mondayAfternoon" component="input" type="checkbox" /></td>
                <td><Field name="mondayEvening" component="input" type="checkbox" /></td>
                <td><Field name="mondayNight" component="input" type="checkbox" /></td>
              </tr>
              <tr>
                <th scope="row">Tuesday</th>
                <td><Field name="tuesdayMorning" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayAfternoon" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayEvening" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayNight" component="input" type="checkbox" /></td>
              </tr>
            <tr>
              <th scope="row">Wednesday</th>
              <td><Field name="wednesdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Thursday</th>
              <td><Field name="thursdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="thursdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="thursdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="thursdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Friday</th>
              <td><Field name="fridayMorning" component="input" type="checkbox" /></td>
              <td><Field name="fridayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="fridayEvening" component="input" type="checkbox" /></td>
              <td><Field name="fridayNight" component="input" type="checkbox" /></td>
            </tr>
             <tr>
              <th scope="row">Saturday</th>
              <td><Field name="saturdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="saturdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="saturdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="saturdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Sunday</th>
              <td><Field name="sundayMorning" component="input" type="checkbox" /></td>
              <td><Field name="sundayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="sundayEvening" component="input" type="checkbox" /></td>
              <td><Field name="sundayNight" component="input" type="checkbox" /></td>
            </tr>
            </table>
        </div>

        </fieldset>
       </div>

      </Wizard.Page>

      <Wizard.Page>
          <div className = "register-wrapper">
              <fieldset>
                  <legend>Payment</legend>
                   <div className = "register-left-div">
                          <p className="field field-text">
                            <label for="paymentFile">Payment Receipt</label>
                            <FileField name="paymentFile"  type="text" />
                          </p>
                          <p className="field field-text">
                            <label>Payment Information</label>
                            <Field name="paymentChannels" component="textarea" placeholder="paymentChannels" />
                            <Error name="paymentChannels" />
                          </p>

                   </div>
              </fieldset>
           </div>
      </Wizard.Page>

    </Wizard>
  </Styles>

);

export default StudentSignup;
