
import React from "react";
import "./modal.css";


class Modal2 extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false
    }
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }
  render() {

const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };

  const display = {
    display: 'block',
    marginTop: '25%',
    marginLeft: '15%',
    marginRight: '15%',
    zIndex: '1',
    width: '40%',
    height: '40%',

  };
  const hide = {
      display: 'none',
      marginTop: '25%',
      marginLeft: '15%',
      marginRight: '15%',
      zIndex: '1',
      width: '40%',
      height: '40%'
  };

    /*<div className="modal" style={this.state.toggle ? display : hide}>
    <div className="modal-content">
    <h4>Modal Header</h4>
    </div>
    <div className="modal-footer">
    <a className="btn-flat" onClick={this.toggle}>Agree</a>
    </div>*/

    var modal = [];
    modal.push(

      <div class="modal-content">
        <div class="modal-header">
          <span class="close">&times;</span>
          <h2>Modal Header</h2>
        </div>
        <div class="modal-body">
          <p>Thank you</p>

        </div>
        <div class="modal-footer">
          <h3>Modal Footer</h3>
        </div>
      </div>
    );

    return (
      <div>
        <a className="btn" onClick={this.toggle}>{this.state.toggle ? 'Close modal' : 'Open modal'}</a>
        {modal}
        <a href="http://www.automationfuel.com/reactjs-modal/">Read the tutorial for this ReactJS modal</a>
      </div>
    );
  }
}
export default Modal2;