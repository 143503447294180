
import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import MultiSelectField from '../../../components/formfields/MultiSelectField';
import BSCommonService  from "../../../services/BSCommonService";
import ClassService  from "../../../services/ClassService";
import StudentService from '../../../services/StudentService';

//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const courseGroups = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];


const ClassCreationSchema = Yup.object().shape({

   code: Yup.string()
     .min(3, 'Too Short!')
     .max(70, 'Too Long!')
     .required('Required'),
  trainingClassName: Yup.string()
       .min(6, 'Too Short!')
       .max(150, 'Too Long!')
       .required('Required'),
    description: Yup.string()
         .min(10, 'Too Short!')
         .max(150, 'Too Long!')
         .required('Required'),
       offerCode: Yup.string()
        .required(`Training Offer Required`),

        //scheduledStartDate: Yup.date()
        //.required(`Scheduled start date is required`),
        duration: Yup
           .number("Must be a number type") // Validates for numerical value
           .positive("Must be a positive value") // Validates against negative values
           .required("Please enter a duration.") // Sets it as a compulsory field
           .min(0, "Duration must be greater than or equal to 0!"),
 });

export default function ClassCreationForm() {


          const [trainingOfferMap, setTrainingOfferMap] = useState([]);
          const [offerValuesAndLabels, setOfferValuesAndLabels] = useState([]);

           useEffect(() => {
                getTrainingOfferMap();
           }, [])

           useEffect(() => {
                getTrainingOfferLabels();
            }, [])



            const getTrainingOfferLabels = async () => {
                         StudentService.getTrainingOfferLabels().then(
                       response => {
                         setOfferValuesAndLabels(response.data);
                       },
                       error => {
                         console.log("error.toString(): "+ error.toString());
                       }
                     );
                };

          const getTrainingOfferMap = async () => {
                   StudentService.getTrainingOfferMap().then(
                     response => {
                                console.log("TrainingOfferMap Fetched: "+ JSON.stringify(response.data));
                       setTrainingOfferMap(response.data);
                     },
                     error => {
                       console.log("error.toString(): "+ error.toString());
                     }
               );
          };

        const [courses, setCourses] = useState('');

             useEffect(() => {
                loadCourses();
               }, []);

        const loadCourses = async () => {
                 BSCommonService.getAllCourses().then(
               response => {
                 setCourses(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };


  return (

        <Container  component="main" >
            <ToastContainer />
         <div>
              <Typography  component="h1" variant="h5">
                 Training Class Creation
              </Typography>
            <Formik

               initialValues={{
                      code: '',
                      trainingClassName: '',
                      description: '',
                      offerCode: '',
                      duration: '',
                      scheduledStartDate: ''
                    }}

                     validationSchema={ClassCreationSchema}

                     onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {

                           alert(JSON.stringify(values, null, 2));

                           let classRequestRequest = {};
                           classRequestRequest.code = values.code;
                           classRequestRequest.name = values.trainingClassName;
                           classRequestRequest.description = values.description;
                           classRequestRequest.duration = values.duration;
                           classRequestRequest.scheduledStartDate = values.scheduledStartDate;
                           classRequestRequest.offerCode = values.offerCode;

                             ClassService.saveClass(classRequestRequest).then(
                                   response => {
                                     toast.success('Training class saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Training class saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                  toast.error('Error while saving Training class', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                 console.log("Error Training class [error.toString()] : "+ error.toString());
                               }
                              );

                          setSubmitting(false);
                        }, 400);
                      }}

              >
               <Form>
                    <Grid container spacing={.2}>
                           <Grid item xs={4} sm={4}>
                             <InputField name="code" label="Class Code" />
                           </Grid>
                           <Grid item xs={4} sm={4}>
                              <InputField name="trainingClassName" label="Class Name" />
                           </Grid>
                            <Grid item xs={4} sm={4}>
                               <InputField name="description" label="Description"  />
                            </Grid>
                            <br />


                            <Grid item xs={4} sm={4}>
                                <InputField name="duration" label="Duration(In Weeks)" />
                            </Grid>
                            <Grid item xs={4} sm={4}>

                                 <DatePickerField
                                   name="scheduledStartDate"
                                   label="Scheduled Start Date"
                                   margin="normal"
                                   disablePast
                                   inputFormat="yyyy-MM-dd"
                                 />
                             </Grid>

                             <Grid item xs={4} sm={4}>
                               <SelectField
                                   name="offerCode"
                                   label="Training Offer"
                                   data={offerValuesAndLabels}
                               />
                            </Grid>

                             <Grid item xs={4} sm={4}>
                                <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Cancel
                                </Button>

                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Reset
                                </Button>

                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Save
                                </Button>
                             </Grid>
                    </Grid>

               </Form>

            </Formik>
         </div>
        </Container>
);

}

