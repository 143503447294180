import React from 'react'
import { Typography } from '@material-ui/core';


const Success = () => {
  return (
    <React.Fragment>
          <Typography variant="h5" gutterBottom>
            Thank you for your registration.
          </Typography>
          <Typography variant="subtitle1">
            Your registration was successful.
          </Typography>
        </React.Fragment>
  )
}

export default Success