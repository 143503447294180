
import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import MultiSelectField from '../../../components/formfields/MultiSelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";
import ClassService  from "../../../services/ClassService";
import FacultyService  from "../../../services/FacultyService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";

const courses = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];

        /*-----------------------------------

        Faculty - class Assignment

        - Faculties
        - classes
        - start date

        Assign



        Student - class Assignment

        - Students
        - Classes
        - Payment Receipt
        - Payment Receipt Confirmed
        Enroll

        Student Enrollment

        - Classes
        - Payment Receipt

        - Email would be sent notifying of you of class assignment after confirmation of payment

        */

const ClassFacultyEngSchema = Yup.object().shape({
    classCode: Yup.string()
    .required(`Class Required`),
    coursePlannedStartDate: Yup.date()
    .required(`Planned start date is required`),
    facultyId: Yup
    .number("Must be a number type") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("Faculty is required.") // Sets it as a compulsory field
 });

export default function FacultyClassAssignmentForm() {

 const [classList, setClassList] = useState([]);
 const [facultyList, setFacultyList] = useState([]);

   const [value, setValue] = React.useState('');
   const [error, setError] = React.useState(false);
   const [helperText, setHelperText] = React.useState('');

             useEffect(() => {
                loadClasses();
               }, []);

            const loadClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched: "+ JSON.stringify(response.data));
                 setClassList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };

             useEffect(() => {
                        loadFaculties();
                }, []);

                    const loadFaculties = async () => {
                         FacultyService.getAllFaculties().then(
                       response => {
                                  console.log("setFacultyList Fetched: "+ JSON.stringify(response.data));
                         setFacultyList(response.data);
                       },
                       error => {
                         console.log("error.toString(): "+ error.toString());
                       }
                     );
                };

          const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
           // setValue((event.target as HTMLInputElement).value);
            setValue(event.target.value);
            setHelperText(' ');
            setError(false);
          };

  return (

        <Container  component="main" >
            <ToastContainer />
         <div>
              <Typography  component="h1" variant="h5">
                 Faculty - Course Assignment
              </Typography>
            <Formik

              initialValues={{
                             classCode: '',
                             facultyId: '',
                             coursePlannedStartDate: '',
                             isPrimaryEngagement : '0'
                           }}

                  onSubmit={(values, { setSubmitting }) => {
                                                      setTimeout(() => {

                                 alert(JSON.stringify(values, null, 2));
                                 let classFacultyEngRequest = {};
                                 classFacultyEngRequest.coursePlannedStartDate = values.coursePlannedStartDate;
                                 classFacultyEngRequest.classCode = values.classCode;
                                 classFacultyEngRequest.facultyId = values.facultyId;
                                 classFacultyEngRequest.isPrimaryEngagement = values.isPrimaryEngagement;

                                   alert('classFacultyEngRequest :'+JSON.stringify(classFacultyEngRequest, null, 2));

                                 FacultyService.saveFacultyClassEngagement(classFacultyEngRequest).then(
                                         response => {
                                           toast.success('Faculty-class engagement saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                           console.log("Faculty-class engagement  saved successfully ...: "+ JSON.stringify(response));
                                         },
                                     error => {
                                      toast.error('Error while saving Faculty-class engagement', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     }
                                    );

                            setSubmitting(false);
                          }, 400);
                        }}

                 validationSchema={ClassFacultyEngSchema}
              >
               <Form>
                    <Grid container spacing={1}>

                               <Grid item xs={6} sm={6}>
                                    <SelectField
                                      name="classCode"
                                      label="Select Class"
                                      data={classList}
                                      fullWidth
                                    />

                               </Grid>
                                <Grid item xs={6} sm={6}>
                                      <SelectField
                                      name="facultyId"
                                      label="Select Faculty"
                                      data={facultyList}
                                      fullWidth
                                      />
                                </Grid>

                                <Grid item xs={6} sm={6}>
                                    <div id="my-radio-group">
                                         Is This Primary Faculty For This Class?
                                    </div>
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <div>
                                            <label>
                                              <Field type="radio" name="isPrimaryEngagement" value="true" />
                                               Yes
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                              <Field type="radio" name="isPrimaryEngagement" value="false" />
                                               No
                                            </label>
                                        </div>
                                    </div>
                              </Grid>


                               <Grid item xs={6} sm={6}>

                                     <DatePickerField
                                       name="coursePlannedStartDate"
                                       label="Planned Start Date"
                                       margin="normal"
                                       disablePast
                                       inputFormat="yyyy-MM-dd"
                                     />
                                 </Grid>

                    </Grid>

                    <div>
                    < br/>
                      <Grid container spacing={.2}>
                        <Grid item xs={4} sm={4}>
                            <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Cancel
                            </Button>
                         </Grid>
                         <Grid item xs={4} sm={4}>
                            <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={4} sm={4}>
                            <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Assign
                            </Button>
                        </Grid>

                      </Grid>
                     < br/>
                    </div>
               </Form>
            </Formik>
         </div>
        </Container>
);

}

