import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import ClassService  from "../../services/ClassService";
import ProgramService  from "../../services/ProgramService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
import { register } from "../../actions/auth";

class CreateTrainingClass extends Component {

  constructor(props) {
    super(props);

    this.state = {
        id : "",
       successful: false,
       fields: {},
       errors: {},
       programs : [],
       programCode : "",
       proAssociatedOptionValue : "",
    };

  }

handleValidation() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    //alert('2...this.state.fields: '+JSON.stringify(this.state.fields));
    //courseGroupCode
    // alert('fields["courseGroupCode"]: '+ fields["courseGroupCode"]);

     if (!fields["programCode"] || (fields["programCode"] === "Select Program")) {
       formIsValid = false;
      errors["programCode"] = "You must select Program";
     }


    // course code
     if (!fields["code"] || fields["code"].length < 3 ) {
        formIsValid = false;
         errors["code"] = 'Class code must not be empty or less than three characters';
      }
      //  alert('4...:'+formIsValid);

    // course name
     if (!fields["name"] || fields["name"].length < 3 ) {
        formIsValid = false;
         errors["name"] = 'Class name must not be empty or less than three characters';
      }
     // alert('5...:'+ formIsValid);

    // course description
     if (!fields["description"] || fields["description"].length < 6 ) {
        formIsValid = false;
         errors["description"] = 'Class description must not be empty or less than six characters';
      }
      //alert('6...:'+formIsValid);

     // course duration
      if (!fields["duration"] ) {
         formIsValid = false;
          errors["duration"] = 'Class duration must not be empty';
     }
      //alert('7...:'+ formIsValid);

    this.setState({ errors: errors });
      //alert('8...:'+formIsValid);
     //alert('formIsValid...:'+ formIsValid);
    return formIsValid;
  }

  handleChange(field, e) {

   console.log('handleChange(field, e) - this.state : entry');

      let fields = this.state.fields;
      if('checkbox' === e.target.type) {
            fields[field] =  e.target.checked;
            //alert('event.target.type:'+ e.target.type);
            //alert('e.target.checked:'+ e.target.checked);
        }
        else {
            fields[field] = e.target.value;
        }
     this.setState({ fields });
      console.log('handleChange(field, e) - this.state : final');
     //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
  }

saveClass(e) {

        e.preventDefault();

        if (!this.handleValidation()) {
            console.log('saveClass invoked; invalid form ...');
             return;
        }

        console.log('saveClass invoked ...');

        let data = {
          code: this.state.fields["code"],
          name: this.state.fields["name"],
          description: this.state.fields["description"],
          programCode: this.state.fields["programCode"],
          duration: this.state.fields["duration"],
          featured: this.state.fields["featured"]
        };

         //alert('data: '+ JSON.stringify(data));
         //return;

           console.log('saveClass() JSON.stringify(data): ' + JSON.stringify(data));
          // console.log('saveCourse(field, e) - this.state :'+ JSON.stringify(this.state));

        ClassService.saveClass(data)
          .then(response => {
            //this.setState({
            //  successful: true
            //});
           toast.success('🦄 Class saved successfully', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });

              console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
             this.setState({
                  successful: false,
                });

               toast.error('🦄 Error while saving course ...'+ e.message, {
                                 position: "top-center",
                                 autoClose: 5000,
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                                 });
          });
}

 componentDidMount() {

        ProgramService.getAllPrograms().then(
                  response => {
                    this.setState({
                      programs: response.data
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
                console.log('loaded programs - this.state.programs :'+this.state.programs);
      }

    onChangePrograms(e) {
            this.setState({
              programCode: e.target.value
            });
            console.log('onChangePrograms - this.state.programCode: '+ this.state.programCode);
   }

 onChangeAssociatedWithProgram(e) {
            this.setState({
              proAssociatedOptionValue: e.target.value
            });
            console.log('proAssociatedOptionValue - this.state.proAssociatedOptionValue: '+ this.state.proAssociatedOptionValue);
   }

  render() {

    const { message } = this.props;

    return (
      <div className="col-md-12">

        <div className="formContainerDiv">

         <fieldset>
                 <legend>Create Training Class</legend>
          <Form
            onSubmit={this.handleCreate}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>

                <div className="form-group">
                              <label htmlFor="code">Class Code</label>
                              <Input
                                type="text"
                                className="form-control"
                                name="code"
                                 onChange={this.handleChange.bind(this, "code")}
                                 value={this.state.fields["code"]}
                              />
                </div>
                <div className="form-group">
                  <label htmlFor="name">Training Class Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                     onChange={this.handleChange.bind(this, "name")}
                     value={this.state.fields["name"]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Class Description</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="description"
                     onChange={this.handleChange.bind(this, "description")}
                     value={this.state.fields["description"]}
                  />
                </div>
                <div className="form-group">
                <label htmlFor="associatedWithProgram">Is this class associated with a Training Program?</label>
                   <div>
                       <span style={{margin: "10px"}} className="submit-span">
                        <input
                          type="radio"
                          name="proAssociatedOption"
                          value="yes"
                          id="proAssociatedOption"
                          checked={this.state.proAssociatedOptionValue === "yes"}
                          onChange={this.onChangeAssociatedWithProgram.bind(this)}
                        />
                        <label htmlFor="yes">Yes</label>
                       </span>

                        <span className="submit-span">
                        </span>
                        <span className="submit-span">
                        </span>

                       <span style={{margin: "10px"}} className="submit-span">
                        <input
                          type="radio"
                          name="proAssociatedOption"
                          value="no"
                          id="proAssociatedOption"
                          checked={this.state.proAssociatedOptionValue === "no"}
                          onChange={this.onChangeAssociatedWithProgram.bind(this)}
                        />
                        <label htmlFor="no">No</label>
                     </span>
                    </div>
                </div>

            <p>
                Select proAssociatedOption Value: <strong>{this.state.proAssociatedOptionValue}</strong>
            </p>
            <p>
                Select Program Value: <strong>{this.state.programCode}</strong>
            </p>
            <p>
                Select Program Value 2: <strong>{this.state.fields["programCode"]}</strong>
            </p>


                <div className="form-group">
                    <label htmlFor="programCode">Program</label>
                    <span className = "span-criteria" >
                      <select id="programCode" name="programCode"
                      onChange={this.handleChange.bind(this, "programCode")} disabled={this.state.proAssociatedOptionValue === "no"} >
                             <option value="Select Program">Select Program</option>
                              {this.state.programs.map(prog => (
                                    <option key={prog.code} value={prog.code}>
                                      {prog.name}
                                    </option>
                                  ))}
                       </select>
                    </span>
                </div>

             <div className="form-group">
                  <label htmlFor="duration">Duration</label>
                  <Input
                    type="duration"
                    className="form-control"
                    name="duration"
                     onChange={this.handleChange.bind(this, "duration")}
                     value={this.state.fields["duration"]}
                  />
                </div>

                <div class="form-group">
                    <ToastContainer />
                </div>

                <div className="field-submit">
                    <span style={{margin: "10px"}} className="submit-span">
                        <button onClick={this.saveClass.bind(this)} >Create Class</button>
                   </span>

                   <span style={{margin: "10px"}} className="submit-span">
                             <button>Cancel</button>
                    </span>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(CreateTrainingClass);
