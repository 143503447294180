import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import useStyles from './styles';
import formInitialValues from './formInitialValues';
//import fileuploadFormModel from '../../../components/formfields/fileupload/fileuploadFormModel'; // file upload

import BasicInfo from './BasicInfo';
import ContactInfo from './ContactInfo';
import EducationInfo from './EducationInfo';
import CourseOfInterest from './CourseOfInterest';
import ClassSchedulePreference from './ClassSchedulePreference';
import PaymentInfo from './PaymentInfo';
import Confirmation from './Confirmation';
import SubmissionMessage from './SubmissionMessage';

import RegistrationFormValidationForStudentSchema from './RegistrationFormValidationForStudentSchema';
import RegistrationFormModel from './RegistrationFormModel';
import RegistrationFormFormitInitValues from './RegistrationFormFormitInitValues';
import Debug from '../../../common/Debug';
import FormitLogger from '../../../common/FormitLogger';
import StudentService from "../../../services/StudentService";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const steps = ['Student BioData', 'Contact Info', 'Education',
'Course Of Interest', 'Class Schedule Preference',  'Confirmation', 'Submission Message'];

const { formId, formField } = RegistrationFormModel;

//const { formId, formField } =  profileformModel;
//const { formId, formField } = formModel;
// https://codesandbox.io/s/react-multi-step-file-upload-with-preview-formik-9g6n4?file=/src/components/UploadForm/FormComponent.jsx:228-244

function _renderStepContent(step, values) {

  switch (step) {

    case 0:
      return   <BasicInfo formField={formField} />;
    case 1:
        return <ContactInfo formField={formField} />;
    case 2:
      return    <EducationInfo formField={formField} />;
    case 3:
        return  <CourseOfInterest formField={formField} />;
    case 4:
        return  <ClassSchedulePreference formField={formField} />;
    case 5:
         return <Confirmation values={ values } />;;
    case 6:
         return  <SubmissionMessage />;
    default:
      return     <div>Not Found</div>;
  }
}

export default function StudentRegistrationForm() {

      const classes = useStyles();

      const [activeStep, setActiveStep] = useState(0);
      const currentValidationSchema = RegistrationFormValidationForStudentSchema[activeStep];
      const isLastStep = activeStep === steps.length - 1;

      const [values, setValues] = useState("");

      function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      async function _submitForm(values, actions) {
        await _sleep(500);


        console.log('Std form  UserCat: ' +values.userCat) ;

            //alert('JSON.stringify(values, null, 2): '+ JSON.stringify(values, null, 2));
           // alert('JSON.stringify(values.photoFile.file, null, 2): '+ values.photoFile[0]);
           //console.log('values.var date = new Date(str) : '+ new Date(values.dateOfBirth) );

            /*
            console.log('values.photoFile.name: '+ values.photoFile.name);
            console.log('values.photoFile.type: '+ values.photoFile.type);
            console.log('values.photoFile.size: '+ values.photoFile.size);*/

            /*
            console.log("photoFile Props:" + {
            filename: values.photoFile.name,
            type: values.photoFile.type,
            size: `${values.photoFile.size} bytes`
            });

          */

               let studentRegistrationRequest = {};
                studentRegistrationRequest.userName = values.userName; // T
                studentRegistrationRequest.firstName = values.firstName;
                studentRegistrationRequest.middleName = values.middleName;
                studentRegistrationRequest.lastName = values.lastName;
                studentRegistrationRequest.password = values.password; //"passwordConfirmation": "test",
                studentRegistrationRequest.dateOfBirth = new Date(values.dateOfBirth); // Error? conversion
                //studentRegistrationRequest.resumeFilePath = selectedFile; //values.resumeFilePath[0].path || null; // TODO

               // if(null != values.photoFile) {
                 if(null != values.photoFile) {
                  // studentRegistrationRequest.profilePhotoFilePath = values.photoFile[0]?.path || null; // TODO
                  // studentRegistrationRequest.photoFile = values.photoFile[0];
                  studentRegistrationRequest.photoFile = values.photoFile[0];
                  studentRegistrationRequest.photoFilePath = values.photoFile[0].name;
                }
                else {
                   studentRegistrationRequest.photoFilePath =  null;
                   studentRegistrationRequest.photoFile = null;
                }

               // if(null != values.resumeFile) {
                  if(null != values.resumeFile) {
                  //studentRegistrationRequest.resumeFilePath = values.resumeFile[0]?.path || null; //
                  //studentRegistrationRequest.resumeFile = values.resumeFile[0]; // TODO
                   studentRegistrationRequest.resumeFile = values.resumeFile[0]; // TODO
                   studentRegistrationRequest.resumeFilePath =  values.resumeFile[0].name;
                }
                else {
                    studentRegistrationRequest.resumeFilePath =  null;
                    studentRegistrationRequest.resumeFile = null;
                }

               // if(null != values.paymentFile) {
               if(null != values.paymentFile) {
                  //studentRegistrationRequest.paymentFileReceiptPath = values.paymentFile[0]?.path || null; //
                  //studentRegistrationRequest.paymentFile = values.paymentFile[0];  // TODO
                   studentRegistrationRequest.paymentFile = values.paymentFile[0];  // TODO
                  // studentRegistrationRequest.paymentFileReceiptPath =  values.paymentFile[0].name;
                }
                else {
                   studentRegistrationRequest.paymentFileReceiptPath =  null;
                   studentRegistrationRequest.paymentFile = null;

                }
                // set files field
                 studentRegistrationRequest.files = [studentRegistrationRequest.photoFile,
                            studentRegistrationRequest.resumeFile, studentRegistrationRequest.paymentFile];

                studentRegistrationRequest.email = values.email;
                studentRegistrationRequest.phoneNo = values.phoneNo;
                studentRegistrationRequest.address1 = values.address1;
                studentRegistrationRequest.address2 = values.address2;
                studentRegistrationRequest.gender = values.gender;
                studentRegistrationRequest.city = values.city;
                studentRegistrationRequest.stateInCountry = values.stateInCountry;
                studentRegistrationRequest.country = values.country;
                //studentRegistrationRequest.profileNote = values.notes;
                studentRegistrationRequest.resumeCoverNote = values.coverNote;
                studentRegistrationRequest.employmentStatusCd = values.employmentStatusCd;
                // new
                studentRegistrationRequest.certifications = values.certifications;
                //studentRegistrationRequest.educationalLevelCd =  JSON.stringify(values.educationalLevelCd) || null;
                studentRegistrationRequest.educationalLevelCd =  values.educationalLevelCd;
                studentRegistrationRequest.coursesInterestJson = values.coursesInterestJson;

                // student specific
                // studentRegistrationRequest.educationalLevel = JSON.stringify(values.educationalLevel) || null; ;
                // console.log("studentRegistrationRequest.photoPath:"+ studentRegistrationRequest.photoPath);
               //  console.log("values.resumeFile[0]:"+ values.resumeFile[0]);

               console.log("studentRegistrationRequest.files >:"+ studentRegistrationRequest.files); //
               // console.log("values.resumeFilePath[0]:"+ values.resumeFilePath[0]);
               // console.log("values.resumeFilePath[0].path:"+ values.resumeFilePath[0].path);

               //console.log("values.photo[0]:"+ values.photo[0]);
               // console.log("studentRegistrationRequest.resumeFilePath:"+ studentRegistrationRequest.resumeFilePath);

                console.log("studentRegistrationRequest.files >> :"+ studentRegistrationRequest.files);

                studentRegistrationRequest.resumeText = values.resumeText;
                studentRegistrationRequest.notes = values.notes;
                studentRegistrationRequest.languagesSpoken = JSON.stringify(values.languagesSpoken);
                studentRegistrationRequest.learningHoursCount = 0;
                studentRegistrationRequest.realm = 'REALM_BS';
                studentRegistrationRequest.userRoleCode = 'ROLE_STUDENT';
                //studentRegistrationRequest.courses = {};

                const studentAvailabilityTimes = {
                "mondayMorning" : values.mondayMorning,
                "mondayAfternoon" : values.mondayAfternoon ,
                "mondayEvening" : values.mondayEvening,
                "mondayNight" : values.mondayNight,
                "tuesdayMorning" : values.tuesdayMorning,
                "tuesdayAfternoon" : values.tuesdayAfternoon,
                "tuesdayEvening" : values.tuesdayEvening ,
                "tuesdayNight" : values.tuesdayNight,
                "wednesdayMorning" : values.wednesdayMorning,
                "wednesdayAfternoon" : values.wednesdayAfternoon,
                "wednesdayEvening" : values.wednesdayEvening,
                "wednesdayNight" : values.wednesdayNight ,
                "thursdayMorning" : values.thursdayMorning ,
                "thursdayAfternoon" : values.thursdayAfternoon,
                "thursdayEvening" : values.thursdayEvening,
                "thursdayNight" : values.thursdayNight,
                "fridayMorning" : values.fridayMorning,
                "fridayAfternoon" : values.fridayAfternoon,
                "fridayEvening" : values.fridayEvening,
                "fridayNight" : values.fridayNight,
                "saturdayMorning" : values.saturdayMorning,
                "saturdayAfternoon" : values.saturdayAfternoon,
                "saturdayEvening" : values.saturdayEvening,
                "saturdayNight" : values.saturdayNight,
                "sundayMorning" : values.sundayMorning,
                "sundayAfternoon" : values.sundayAfternoon,
                "sundayEvening" : values.sundayEvening,
                "sundayNight" : values.sundayNight
                };

               // studentRegistrationRequest.
                // window.alert(JSON.stringify(tutorAvailabilityTimes));
                //  console.log("JSON.stringify(tutorAvailabilityTimes: "+ JSON.stringify(tutorAvailabilityTimes));
                //tutorToSave.availability = tutorAvailabilityTimes;
                // put escape slashes to avoid bad request - 400 error

                studentRegistrationRequest.preferredClassScheduleJson = JSON.stringify(studentAvailabilityTimes);
                alert("studentRegistrationRequest: "+ JSON.stringify(studentRegistrationRequest, null, 2));

                registerStudentUser(studentRegistrationRequest);
                // TODO - phone, city, state, actual files uploa


        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);

      }

      function _handleSubmit(values, actions) {
           // values.dateOfBirth = values.dateOfBirth.split("T")[0];
            if (isLastStep) {
              _submitForm(values, actions);
            } else {
              setActiveStep(activeStep + 1);
              actions.setTouched({});
              actions.setSubmitting(false);
            }

          setValues(values);
          console.log("values in _handleSubmit function: "+ JSON.stringify(values, null, 2));
          // console.log("dateOfBirth :"+ (values.dateOfBirth.split("T")[0])) ;
      }

      function _handlePrevious() {
        setActiveStep(activeStep - 1);
      }


    function registerStudentUser(studentRegistrationRequest) {
     console.log("studentRegistrationRequest.resumeFilePath[0]:"+ studentRegistrationRequest.resumeFilePath);
     console.log("JSON.stringify(registerStudentUser in registerStudentUser(studentRegistrationRequest) function: "+ JSON.stringify(studentRegistrationRequest));

  StudentService.saveStudentUser(studentRegistrationRequest, studentRegistrationRequest.files)
      .then(response => {
         toast.success('Your registration was successful', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
         console.log("response data after create: "+  JSON.stringify(response.data));
      })
      .catch(e => {
         toast.error('Error: Registration Failed ...', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
        console.log(e);
      });


  }
   const debug = true;

  return (

    <Container  component="main" >
             <ToastContainer />
      <Typography component="h4" variant="h5" align="center">
           Student Registration
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
               {activeStep === steps.length ? (
                  <div>Submission Message (End)</div>
                ) : (
                  <Formik
                        initialValues={RegistrationFormFormitInitValues}
                        onSubmit={_handleSubmit}
                        validationSchema={currentValidationSchema}
                         debug={debug}
                    >
                    {({ isSubmitting }) => (
                      <Form id={formId}>

                        <FormitLogger />

                        {_renderStepContent(activeStep, values)}

                        <div className={classes.buttons}>
                          {activeStep !== 0 && (
                            <Button onClick={_handlePrevious} className={classes.button}>
                              Back
                            </Button>
                          )}
                          <div className={classes.wrapper}>
                            <Button
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                            >
                              {isLastStep ? 'Save' : 'Next'}
                            </Button>
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>

                        </div>
                      </Form>
                    )}


                  </Formik>
                )}
     </React.Fragment>
    </Container>
  );

}