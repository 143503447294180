import React from 'react';
import { at } from 'lodash';
import { TextField } from '@material-ui/core';
import { Box, Rating, Typography } from "@mui/material";
import { useField, useFormikContext, Field } from 'formik';

export default function RatingField({ ...props }) {
  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }


  return (

        <Rating
             name="text-feedback"
                 precision={0.5}
                 {...field}
                 {...props}
                 onChange={(event) => {
                     setFieldValue(field.name, event.currentTarget.value)
                 }}
                 defaultValue={1} size="large"
        />

  );
}
