import React from "react";
import { render } from "react-dom";
import Styles from "./Styles";
import { Field } from "react-final-form";
import Wizard from "./Wizard";
import FileField from "./FileField";
//import TutorService from "../../services/tutoring/TutorService";
//import TutoringUserService from "../../services/tutoring/TutoringUserService";
import axios from "axios";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "../../common/Calendar.component"; // alternative to date picker
import states from "../../common/states";
import countries from "../../common/countries";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import FacultyService from "../../services/FacultyService";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const onSubmit = async (values) => {
       // await sleep(300);
       // console.log("JSON.stringify(values: "+ JSON.stringify(values));
       // window.alert(JSON.stringify(values, 0, 2));
       // window.alert(JSON.stringify(values));

        let tutorRegistrationRequest = {};
        tutorRegistrationRequest.userName = values.userName; // T
        tutorRegistrationRequest.firstName = values.firstName;
        tutorRegistrationRequest.middleName = values.middleName;
        tutorRegistrationRequest.lastName = values.lastName;
        tutorRegistrationRequest.password = values.password; //"passwordConfirmation": "test",
        //tutorRegistrationRequest.dateOfBirth = values.dateOfBirth; // Error? convertion
        tutorRegistrationRequest.gender = values.gender;

        tutorRegistrationRequest.email = values.email; //
        tutorRegistrationRequest.phoneNo = values.phoneNo;
        tutorRegistrationRequest.address1 = values.address1;//
        tutorRegistrationRequest.address2 = values.address2;//
        //tutorRegistrationRequest.isFaculty = true;
        tutorRegistrationRequest.city = values.city; //
        tutorRegistrationRequest.stateInCountry = values.stateInCountry ; //
        tutorRegistrationRequest.country = values.country; //
        tutorRegistrationRequest.resumeCoverNote = values.resumeCoverNote;
        tutorRegistrationRequest.introText = values.introText;
        tutorRegistrationRequest.profileText = values.profileText;
        tutorRegistrationRequest.coursesInterestJson =  JSON.stringify(values.coursesInterestJson) || null;
        tutorRegistrationRequest.certifications =  JSON.stringify(values.certifications) || null; //educationalLevelCd
        tutorRegistrationRequest.educationalLevelCd =  values.educationalLevelCd;
        tutorRegistrationRequest.employmentStatusCd = values.employmentStatusCd;
        tutorRegistrationRequest.realm = 'REALM_BS';

        if(null != values.photoFile) {
          tutorRegistrationRequest.profilePhotoFilePath = values.photoFile[0]?.path || null; //
          tutorRegistrationRequest.photoFile = values.photoFile[0];
        }
        else {
           tutorRegistrationRequest.profilePhotoFilePath =  null;
           tutorRegistrationRequest.photoFile = null;
        }

        if(null != values.resumeFile) {
          tutorRegistrationRequest.resumeFilePath = values.resumeFile[0]?.path || null; //
          tutorRegistrationRequest.resumeFile = values.resumeFile[0];
        }
        else {
            tutorRegistrationRequest.resumeFilePath =  null;
            tutorRegistrationRequest.resumeFile = null;
        }

        tutorRegistrationRequest.files = [tutorRegistrationRequest.photoFile, tutorRegistrationRequest.resumeFile];


        const tutorAvailabilityTimes = {
        "mondayMorning":values.mondayMorning || false ,
        "mondayAfternoon":values.mondayAfternoon || false ,
        "mondayEvening":values.mondayEvening || false ,
        "mondayNight":values.mondayNight || false ,
        "tuesdayMorning":values.tuesdayMorning || false ,
        "tuesdayAfternoon":values.tuesdayAfternoon || false ,
        "tuesdayEvening":values.tuesdayEvening || false ,
        "tuesdayNight":values.tuesdayNight || false ,
        "wednesdayMorning":values.wednesdayMorning || false ,
        "wednesdayAfternoon":values.wednesdayAfternoon || false ,
        "wednesdayEvening":values.wednesdayEvening || false ,
        "wednesdayNight":values.wednesdayNight || false ,
        "thursdayMorning":values.thursdayMorning || false ,
        "thursdayAfternoon":values.thursdayAfternoon || false ,
        "thursdayEvening":values.thursdayEvening || false ,
        "thursdayNight":values.thursdayNight || false ,
        "fridayMorning":values.fridayMorning || false ,
        "fridayAfternoon":values.fridayAfternoon || false ,
        "fridayEvening":values.fridayEvening || false ,
        "fridayNight":values.fridayNight || false ,
        "saturdayMorning":values.saturdayMorning || false ,
        "saturdayAfternoon":values.saturdayAfternoon || false ,
        "saturdayEvening":values.saturdayEvening || false ,
        "saturdayNight":values.saturdayNight || false ,
        "sundayMorning":values.sundayMorning || false ,
        "sundayAfternoon":values.sundayAfternoon || false ,
        "sundayEvening":values.sundayEvening || false ,
        "sundayNight":values.sundayNight || false
        };

        tutorRegistrationRequest.preferredClassScheduleJson = JSON.stringify(tutorAvailabilityTimes);

        registerFacultyUser(tutorRegistrationRequest);

};


    function registerFacultyUser(tutorRegistrationRequest) {
      console.log("JSON.stringify(tutorRegistrationRequest in registerTutorUser(tutorRegistrationRequest) function: "+ JSON.stringify(tutorRegistrationRequest));
      FacultyService.createFacultyUser(tutorRegistrationRequest, tutorRegistrationRequest.files)
          .then(response => {
             console.log("response data after createFacultyUser: "+  JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
          });
      }


    const Error = ({ name }) => (
      <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
          touched && error ? <span>{error}</span> : null
        }
      />
    );

    const RenderDatePicker = ({ input: { onChange, value }, ...rest }) => (
      <DatePicker selected={value} onChange={date => onChange(date)} {...rest} />
    );

    const ReactSelectAdapter = ({ input, ...rest }) => (
      <Select {...input} {...rest} searchable />
    );



    const required = (value) => (value ? undefined : "Required");
  const FacultySignup = () => (
    <Styles>
        <a
            href="https://final-form.org/react"
            target="_blank"
            rel="noopener noreferrer"
        >
        </a>
    <div className ="register-title">Faculty Registration </div>
    <Wizard
        initialValues={{  }}
        onSubmit={onSubmit}
    >

 <Wizard.Page>
        <div className = "register-wrapper">
        <fieldset>
          <legend>Bio Information</legend>
          <div className = "register-left-div">
            <p className="field field-text">
              <label for="firstName">First Name</label>
              <Field
                name="firstName"
                component="input"
                type="text"
                placeholder="First Name"
                validate={required}
              />
              <Error name="firstName" />
            </p>
            <p className="field field-text">
              <label for="middleName">Middle Name</label>
              <Field
                name="middleName"
                component="input"
                type="text"
                placeholder="Middle Name"
                validate={required}
              />
            </p>
            <p className="field field-text">
              <label for="lastName">Last Name</label>
              <Field
                name="lastName"
                component="input"
                type="text"
                placeholder="Last Name"
                validate={required}
              />
              <Error name="lastName" />
            </p>
             <p className="field field-text">
                  <label for="userName">User Name</label>
                  <Field
                    name="userName"
                    component="input"
                    type="text"
                    placeholder="User Name"
                    validate={required}
                  />
                  <Error name="userName" />
            </p>
            <p className="field field-text">
                <label for="gender">Gender</label>
                <Field name="gender" component="select" >
                    <option value="unknown">Not specified</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </Field>
                 <Error name="gender" />
            </p>
          </div>

        <div className = "register-right-div">
        <p class="field field-text">
          <label for="password">Password</label>
          <Field
            name="password"
            component="input"
            type="text"
            validate={required}
          />
        </p>
        <p className="field field-text">
          <label>Password Confirmation</label>
          <Field
            name="passwordConfirmation"
            component="input"
            type="text"
            validate={required}
          />
        </p>
        <p className="field field-text">
          <label for="dateOfBirth">Date of Birth</label>
          <Field
            name="dateOfBirth"
            component={RenderDatePicker}
            validate={required}
          />
        </p>

         <p className="field field-text">
              <label for="profileText">Profile Text</label>
              <Field name="profileText"  component="textarea" placeholder="Profile text"  />
         </p>
         <p className="field field-text">
                  <label for="photoFile">Profile Photo</label>
                  <FileField name="photoFile" />
         </p>
          <p className="field field-text">
               <label for="introText">Intro Text</label>
               <Field name="introText" component="textarea" placeholder="Introductory text" />
               <Error name="introText" />
          </p>

        </div>
        </fieldset>
        </div>
  </Wizard.Page>


 <Wizard.Page
        validate={(values) => {
          const errors = {};
          if (!values.email) {
            errors.email = "Required";
          }
          return errors;
        }}
      >

  <div className = "register-wrapper">
    <fieldset>
        <legend>Contact Information</legend>

       <div className = "register-left-div">
        <p className="field field-text">
          <label for="email">Email</label>
          <Field
            name="email"
            component="input"
            type="email"
            placeholder="Email"
          />
          <Error name="email" />
        </p>
        <p className="field field-text">
          <label for="phoneNo">Phone No.</label>
          <Field
            name="phoneNo"
            component="input"
            type="phone"
            placeholder="Phone No."
          />
          <Error name="phoneNo" />
        </p>
        <p className="field field-text">
          <label for="address1">Address Line 1</label>
          <Field
            name="address1"
            component="input"
            type="address1"
            placeholder="Address Line 1"
          />
          <Error name="address1" />
        </p>
       <p className="field field-text">
          <label for="address2">Address Line 2</label>
          <Field
            name="address2"
            component="input"
            type="address2"
            placeholder="Address Line 2"
          />
          <Error name="address2" />
        </p>
      </div>

     <div className = "register-right-div">
        <p className="field field-text">
          <label for="city">City</label>
          <Field
            name="city"
            component="input"
            type="city"
            placeholder="City"
          />
          <Error name="city" />
        </p>
        <p className="field field-text">
          <label for="stateInCountry">State</label>
          <Field name="stateInCountry"
            component="input"
            placeholder="State or province"
          />
          <Error name="stateInCountry" />
        </p>

        <p className="field field-text">
          <label for="country">Country</label>
          <Field
            name="country"
            placeholder="Select a country"
            component={ReactSelectAdapter}
            options={countries}
          />
          <Error name="country" />
        </p>
       </div>
      </fieldset>
     </div>
     </Wizard.Page>

  <Wizard.Page
       /* validate={(values) => {
          const errors = {};
          if (!values.toppings) {
            errors.toppings = "Required";
          } else if (values.toppings.length < 2) {
            errors.toppings = "Choose more";
          }
          return errors;
        }}*/
      >


<div className = "register-wrapper">
    <fieldset>
        <legend>Education & Certifications</legend>
         <div className = "register-left-div">

       <p className="field field-text">
          <label for="certifications">Certifications</label>
          <Field name="certifications" component="select" multiple>
            <option value="Hausa">🐷 Hausa</option>
            <option value="Yoruba">🍄 Yoruba</option>
            <option value="Edo">🧀 Edo</option>
            <option value="French">🐓French</option>
            <option value="English">🍍 English</option>
          </Field>
          <Error name="certifications" />
        </p>
       </div>

    <div className = "register-right-div">
       <p className="field field-text">
                 <label>Educational Level</label>
                 <Field name="educationalLevelCd" component="select" multiple>
                   <option value="KINDERGATHEN">🐷 Kindergathen</option>
                   <option value="ELEMENTARY">🍄 Elementary</option>
                   <option value="HIGHSCHOOL">🧀 High School</option>
                   <option value="TERTIARY">🐓 Tertiary</option>
                 </Field>
                 <Error name="educationalLevelCd" />
        </p>
        <p className="field field-text">
                 <label>Employment Status</label>
                 <Field name="employmentStatusCd" component="select">
                   <option value="UNEMPLOYED">🐷 UnEmployed</option>
                   <option value="EMPLOYEDPARTTIME">🍄 Employed Part-time</option>
                   <option value="EMPLOYED">🧀 Employed</option>
                 </Field>
                 <Error name="employmentStatusCd" />
        </p>
    </div>
      </fieldset>
    </div>

    </Wizard.Page>

    <Wizard.Page>

    <div className = "register-wrapper">
        <fieldset>
            <legend>Course you are interested in teaching</legend>
             <div className = "register-left-div">

           <p className="field field-text">
              <label for="coursesInterestJson">Course Of Interest</label>
              <Field name="coursesInterestJson" component="select" multiple>
                <option value="Java">🐷Java</option>
                <option value=".NET">🍄 .NET</option>
                <option value="DATASCIENCE">🧀 Data Science</option>
                <option value="DB">🐓Databases</option>
                <option value="WEBDesign">🍍Web Design</option>
              </Field>
              <Error name="coursesInterestJson" />
            </p>
           </div>
          </fieldset>
   </div>

    </Wizard.Page>

   <Wizard.Page>

     <div className = "register-wrapper">
        <fieldset>
            <legend>Resume & background</legend>
             <div className = "register-left-div">
                <p className="field field-text">
                     <label for="resumeFile">Resume</label>
                     <FileField name="resumeFile" />
                </p>
                 <p className="field field-text">
                      <label>Resume Cover Note</label>
                      <Field name="resumeCoverNote" component="textarea" placeholder="Resume Cover Note" />
                      <Error name="resumeCoverNote" />
                 </p>
              </div>
          </fieldset>
     </div>
    </Wizard.Page>

      <Wizard.Page>
        <div className = "register-wrapper">
        <fieldset>
            <legend>Availability</legend>

        <label>Available Times</label>
            <div>(Morning: 8:00 AM - 11:30 AM GMT)</div>
            <div>(Afternoon: 12:00 PM - 3:30 PM GMT)</div>
            <div>(Evening: 4:00 PM - 7:30 PM GMT)</div>
            <div>(Night: 8:00 PM - 11:30 PM GMT)</div>
        <div>
            <table name="tutorAvailabilityTimes">
              <tr>
                <td></td>
                <th scope="col">Morning</th>
                <th scope="col">Afternoon</th>
                <th scope="col">Evening</th>
                <th scope="col">Night</th>
              </tr>
              <tr>
                <th scope="row">Monday</th>
                <td><Field name="mondayMorning" component="input" type="checkbox" /></td>
                <td><Field name="mondayAfternoon" component="input" type="checkbox" /></td>
                <td><Field name="mondayEvening" component="input" type="checkbox" /></td>
                <td><Field name="mondayNight" component="input" type="checkbox" /></td>
              </tr>
              <tr>
                <th scope="row">Tuesday</th>
                <td><Field name="tuesdayMorning" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayAfternoon" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayEvening" component="input" type="checkbox" /></td>
                <td><Field name="tuesdayNight" component="input" type="checkbox" /></td>
              </tr>
            <tr>
              <th scope="row">Wednesday</th>
              <td><Field name="wednesdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="wednesdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Thursday</th>
              <td><Field name="thursdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="thursdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="thursdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="thursdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Friday</th>
              <td><Field name="fridayMorning" component="input" type="checkbox" /></td>
              <td><Field name="fridayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="fridayEvening" component="input" type="checkbox" /></td>
              <td><Field name="fridayNight" component="input" type="checkbox" /></td>
            </tr>
             <tr>
              <th scope="row">Saturday</th>
              <td><Field name="saturdayMorning" component="input" type="checkbox" /></td>
              <td><Field name="saturdayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="saturdayEvening" component="input" type="checkbox" /></td>
              <td><Field name="saturdayNight" component="input" type="checkbox" /></td>
            </tr>
            <tr>
              <th scope="row">Sunday</th>
              <td><Field name="sundayMorning" component="input" type="checkbox" /></td>
              <td><Field name="sundayAfternoon" component="input" type="checkbox" /></td>
              <td><Field name="sundayEvening" component="input" type="checkbox" /></td>
              <td><Field name="sundayNight" component="input" type="checkbox" /></td>
            </tr>
            </table>
        </div>


        </fieldset>
        </div>

      </Wizard.Page>

    </Wizard>
  </Styles>
);

export default FacultySignup;