import React from 'react'
import { Container, Grid, List, ListItem, ListItemText, Button,FormControl,FormLabel} from '@material-ui/core'
import CheckboxField from '../../../components/formfields/CheckboxField';
import useStyles from './styles';
//import FormLabel from "@mui/material/FormLabel";
//import FormControlLabel from "@mui/material/FormControlLabel";

const Confirmation = ({ values }) => {

  console.log("values:" +values);
  //(alert("values:" +JSON.stringify(values));
  const { employmentNumber, firstName, middleName, lastName, userName, password, gender
  ,dateOfBirth,photoFile, resumeFile,email,phoneNo,address1,address2
  ,city,stateInCountry,country,certifications,educationalLevelCd,employmentStatusCd
  ,coursesInterestJson, mondayMorning,mondayAfternoon,mondayEvening,mondayNight
  ,tuesdayMorning,tuesdayAfternoon,tuesdayEvening,tuesdayNight
  ,wednesdayMorning,wednesdayAfternoon,wednesdayEvening,wednesdayNight
  ,thursdayMorning,thursdayAfternoon,thursdayEvening,thursdayNight
  ,fridayMorning,fridayAfternoon,fridayEvening,fridayNight
  ,saturdayMorning,saturdayAfternoon,saturdayEvening,saturdayNight
  ,sundayMorning,sundayAfternoon,sundayEvening,sundayNight} = values;

  return (
    <Container  component="main" maxWidth="xs">
      <div>
        <div>
            <strong>Confirmation</strong>
        </div>
        <br />
        <FormControl component="fieldset">
             <div>
                <FormLabel >
                    Employment number : {employmentNumber}
                </FormLabel>
              </div>
             <p />
             <div>
                <FormLabel >
                    User Name : {userName}
                </FormLabel>
              </div>
               <p />
               <div>
                  <FormLabel >
                      First Name : {firstName}
                  </FormLabel>
                </div>
                 <p />
                 <div>
                    <FormLabel >
                        Last Name : {lastName}
                    </FormLabel>
                  </div>
                   <p />
                   <div>
                      <FormLabel >
                          Password : {password}
                      </FormLabel>
                    </div>
                     <p />
                    <div>
                        <FormLabel >
                            Gender : {gender}
                        </FormLabel>
                      </div>
                       <p />
                        <div>
                            <FormLabel >
                               Date Of Birth : {new Date(dateOfBirth).toLocaleDateString()}
                            </FormLabel>
                         </div>
                       <p />
                       <div>
                          <FormLabel >
                             Profile Photo File : {photoFile[0].name}
                          </FormLabel>
                        </div>
                            <p />
                           <div>
                              <FormLabel >
                                 Resume File : {resumeFile[0].name}/
                              </FormLabel>
                            </div>
                            <p />
                             <div>
                                <FormLabel >
                                   Email : {email}/
                                </FormLabel>
                              </div>
                             <p />
                             <div>
                                <FormLabel >
                                   Phone No : {phoneNo}/
                                </FormLabel>
                              </div>
                               <p />
                            <div>
                               <FormLabel >
                                  Address 1 : {address1}/
                               </FormLabel>
                             </div>
                               <p />
                             <div>
                                <FormLabel >
                                   Address 2 : {address2}/
                                </FormLabel>
                              </div>
                                <p />
                              <div>
                                 <FormLabel >
                                    City : {city}/
                                 </FormLabel>
                               </div>
                               <p />
                                <div>
                                    <FormLabel >
                                      Country : {country}/
                                    </FormLabel>
                                </div>
                                <p />
                              <div>
                                  <FormLabel >
                                    Certifications : {certifications}/
                                  </FormLabel>
                              </div>
                              <p />
                               <div>
                                   <FormLabel >
                                     Educational Level : {educationalLevelCd}/
                                   </FormLabel>
                               </div>
                              <p />
                                <div>
                                    <FormLabel >
                                      Employment Status : {employmentStatusCd}/
                                    </FormLabel>
                                </div>
                              <p />
                             <div>
                                 <FormLabel >
                                   Courses Of Interest : {coursesInterestJson}/
                                 </FormLabel>
                             </div>
                              <p />
                             <div>
                                    <strong>Class Schedule Preferences</strong>
                             </div>

                              <p />
                             <div>
                                <Grid item xl={3} >
                                             <div>(Morning: 8:00 AM - 11:30 AM GMT)</div>
                                             <div>(Afternoon: 12:00 PM - 3:30 PM GMT)</div>
                                             <div>(Evening: 4:00 PM - 7:30 PM GMT)</div>
                                             <div>(Night: 8:00 PM - 11:30 PM GMT)</div>
                                             <br />

                                               <div>
                                                 <table name="availabilityTimes">
                                                   <tr>
                                                     <td></td>
                                                     <th scope="col">Morning|</th>
                                                     <th scope="col"></th>
                                                     <th scope="col">Afternoon|</th>
                                                      <th scope="col"></th>
                                                     <th scope="col">Evening|</th>
                                                     <th scope="col"></th>
                                                     <th scope="col">Night</th>
                                                   </tr>
                                                   <tr>

                                                     <th scope="row">Monday</th>

                                                     <td>{(true === mondayMorning? "Yes": "No")}</td>
                                                     <th scope="col"></th>
                                                     <td>{(true === mondayAfternoon? "Yes": "No")}</td>
                                                     <th scope="col"></th>
                                                     <td>{(true === mondayEvening? "Yes": "No")}</td>
                                                      <th scope="col"></th>
                                                     <td>{(true === mondayNight? "Yes": "No")}</td>
                                                   </tr>
                                                   <tr>
                                                     <th scope="row">Tuesday</th>
                                                     <td> {(true === tuesdayMorning? "Yes": "No")} </td>
                                                      <th scope="col"></th>
                                                     <td> {(true === tuesdayAfternoon? "Yes": "No")}</td>
                                                      <th scope="col"></th>
                                                     <td> {(true === tuesdayEvening? "Yes": "No")}</td>
                                                      <th scope="col"></th>
                                                     <td> {(true === tuesdayNight? "Yes": "No")}</td>
                                                   </tr>
                                                 <tr>
                                                   <th scope="row">Wednesday</th>
                                                   <td>{(true === wednesdayMorning? "Yes": "No")} </td>
                                                    <th scope="col"></th>
                                                   <td>{(true === wednesdayAfternoon? "Yes": "No")} </td>
                                                    <th scope="col"></th>
                                                   <td>{(true === wednesdayEvening? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === wednesdayNight? "Yes": "No")}</td>
                                                 </tr>
                                                 <tr>
                                                   <th scope="row">Thursday</th>
                                                   <td>{(true === thursdayMorning? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === thursdayAfternoon? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === thursdayEvening? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === thursdayNight? "Yes": "No")} </td>
                                                 </tr>
                                                 <tr>
                                                   <th scope="row">Friday</th>
                                                   <td>{(true === fridayMorning? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === fridayAfternoon? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === fridayEvening? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === fridayNight? "Yes": "No")}</td>
                                                 </tr>
                                                  <tr>
                                                   <th scope="row">Saturday</th>
                                                   <td>{(true === saturdayMorning? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === saturdayAfternoon? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === saturdayEvening? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === saturdayNight? "Yes": "No")}</td>
                                                 </tr>
                                                 <tr>
                                                   <th scope="row">Sunday</th>
                                                   <td>{(true === sundayMorning? "Yes": "No")}</td>
                                                   <th scope="col"></th>
                                                   <td>{(true === sundayAfternoon? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                    <td>{(true === sundayEvening? "Yes": "No")}</td>
                                                    <th scope="col"></th>
                                                   <td>{(true === sundayNight? "Yes": "No")}</td>
                                                 </tr>
                                                 </table>
                                             </div>
                                         </Grid>
                             </div>
          </FormControl >



      </div>
    </Container>
  )
}

export default Confirmation