
//import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useLocation, useHistory, useParams } from "react-router-dom";

//import { useLocation, useParams } from "react-router-dom";

//import { useNavigate } from 'react-router-dom'; // TODO - UPDATE TO THIS
//import { useHistory } from "react-router-dom";
//import { useLocation } from "react-router-dom";

export const withRouter = (Component) => {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
   // let navigate = useNavigate();
     let history = useLocation();
    let params = useParams();
    return <Component {...props} router={{ location, history, params }} />;
  }
  //   return <Component {...props} router={{ location, navigate, params }} />;
  return ComponentWithRouterProp;
};
