
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import useStyles from './styles';
//import { InputField, CheckboxField, SelectField } from '../../../components/formfields';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import { UploaderField }from '../../../components/formfields/UploaderField';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import MultiSelectField from '../../../components/formfields/MultiSelectField';



const genders = [
  {
    code: 'M',
    label: 'Male'
  },
  {
    code: 'F',
    label: 'Female'
  },
  {
    code: 'U',
    label: 'Unknown'
  }
];

export default function BasicInfo(props) {

       const classes = useStyles();

       const {
         formField: {
           firstName,
           middleName,
           lastName,
           userName,
           password,
           passwordConfirmation,
           gender,
           dateOfBirth,
           photoFile
         }
       } = props;


  return (

        <Container  component="main" >
         <div>
          <Typography variant="h6" className={classes.typo} >
             Bio Information
          </Typography>
                <form  >
                <Grid container spacing={3}>

                       <Grid item xs={12} sm={6}>
                         <InputField name="firstName" label="First Name" fullWidth />
                       </Grid>
                       <Grid item xs={12} sm={6}>
                          <InputField name="middleName" label="Middle Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <InputField name="lastName" label="Last Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name="userName" label="User Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField  type="password" name="password" label="Password" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField  type="password" name="passwordConfirmation" label="Password Confirmation" fullWidth />
                         </Grid>
                        <Grid item xs={12} sm={6}>
                           <SelectField
                                       name="gender"
                                       label="Gender"
                                       data={genders}
                                       fullWidth
                                     />
                        </Grid>
                        <Grid item xs={12} sm={6}>

                             <DatePickerField
                               name="dateOfBirth"
                               label="Date Of Birth"
                               margin="normal"
                               disableFuture
                               inputFormat="yyyy-MM-dd"
                               fullWidth
                             />

                         </Grid>

                          <Grid item xs={12} sm={6}>
                             Profile Image
                            <UploaderField name="photoFile" type="file" value={undefined}  />
                          </Grid>
                   </Grid>
                 </form>
            </div>
          </Container>
);

}
