import React, { Component } from "react";
import { Redirect } from 'react-router-dom';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";

import { connect } from "react-redux";
import { login } from "../actions/auth";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import {ErrorOutline} from "@material-ui/icons";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";
import AuthService from "../services/auth.service";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

class Resetpassword extends Component {

  constructor(props) {

    super(props);
    this.savePassWord = this.savePassWord.bind(this);
    this.onChangeToken = this.onChangeToken.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeMatchPassword = this.onChangeMatchPassword.bind(this);

    this.state = {
      newPassword: "",
      matchPassword: "",
      token: null,
      result: null,
    };

  }

componentDidMount() {
// http://localhost:3000/resetPassword?token=00c439fc-accc-4d8a-90b2-26b420c6b802
//path: ?token=00c439fc-accc-4d8a-90b2-26b420c6b802
//  path token :00c439fc-accc-4d8a-90b2-26b420c6b802

        let path = window.location.search
        console.log('path: '+path);
        let token = '';
        let pathMatch = path.match("(token=)(\\S*?)(&|$)")
        if (pathMatch) {
          token = pathMatch[2];
          this.setState({
                token: token
              });
        }
          console.log('path token :'+ token);

    }

  onChangeMatchPassword(e) {
    this.setState({
      matchPassword: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      newPassword: e.target.value,
    });
  }

  onChangeToken(e) {
    this.setState({
      token: e.target.value,
    });
  }

  showMessage = (msg) => {
        toast(msg)
      };

  savePassWord(e) {

    console.log('this.state.token :'+ this.state.token);
    console.log('this.state.newPassword :'+ this.state.newPassword);


    e.preventDefault();
    this.form.validateAll();

    let data = {
              token: this.state.token,
              newPassword: this.state.newPassword
          };

     console.log("savePassword data - JSON.stringify(data): "+ JSON.stringify(data));

    // TODO : confirm before saving
    // 'Are you sure you want to change the passord?' . [Cancel] [Confirm]

    AuthService.savePassword(data)
                .then(response => {
                        this.setState({
                          result: response.data
                        });
                        //toast.success('Your password has been reset. Please log in to access Huge Collection.',{position: toast.POSITION.TOP_CENTER}, {autoClose:3000});
                        toast.success('🦄 Your password has been reset. Please log in to access Huge Collection.', {
                                                            position: "top-center",
                                                            autoClose: 5000,
                                                            hideProgressBar: false,
                                                            closeOnClick: true,
                                                            pauseOnHover: true,
                                                            draggable: true,
                                                            progress: undefined,
                                                            });

                        }).catch(err => {
                                console.log('savePassword - error.toString(): '+  err.toString());
                                //toast.error('Error: unable to reset password.',{position: toast.POSITION.TOP_CENTER}, {autoClose:3000});
                                toast.error('🦄 Error: unable to reset password.', {
                                                          position: "top-center",
                                                          autoClose: 5000,
                                                          hideProgressBar: false,
                                                          closeOnClick: true,
                                                          pauseOnHover: true,
                                                          draggable: true,
                                                          progress: undefined,
                                                          });
                                });


  }

  render() {


    return (
      <div className="col-md-12">
        <div className="formContainerDiv">

        <fieldset>
                  <legend>Reset password</legend>

          <img
            src="user_common_avatar.png"
            alt="profile-img"
            className="profile-img-card"
          />

        <Form
           onSubmit={this.savePassWord}
             ref={(c) => {
               this.form = c;
             }}
           >

        <div>

            <div className="form-group">
                <label  htmlFor="newPassword">Password</label>
                <input
                className="form-control"
                id="newPassword"
                name="newPassword"
                type="newPassword"
                value={this.state.newPassword}
                onChange={this.onChangePassword}
                 />

                <label htmlFor="matchPassword">Confirm password</label>
                <input
                className="form-control"
                id="matchPassword"
                name="matchPassword"
                type="password"
                value={this.state.matchPassword}
                onChange={this.onChangeMatchPassword}
                />

                <label  htmlFor="token" >Token</label>
                <input
                className="form-control"
                id="token"
                 name="token"
                 value={this.state.token}
                 onChange={this.onChangeToken}
                  />

                <div id="globalError">error</div>
                <button type="submit">Submit</button>
            </div>
         </div>

        </Form>

         </fieldset>

        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {

  const { isLoggedIn } = state.auth;
  const { message } = state.message;
 // const { isTimedOut } = state.isTimedOut; TODO
  return {
    isLoggedIn,
    message
  };
}

export default connect(mapStateToProps)(Resetpassword);
