import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class StudentService {

// https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios

saveCourse(data) {

    console.log('Called StudentService.saveCourse() ...');

    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/course/save', data, config);
 }

saveSyllabus(data) {

    console.log('Called StudentService.saveSyllabus() ...');
    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/syllabus/save', data, config);
 }


saveStudentUser(data, files) {

            let formData = new FormData();

            formData.append("userName", data.userName );
            formData.append("firstName",data.firstName );
            formData.append("middleName",data.middleName);
            formData.append("lastName",data.lastName );
            formData.append("password",data.password );

            // alert('data.dateOfBirth: '+ data.dateOfBirth); // data.dateOfBirth: Mon Feb 27 2023 00:00:00 GMT-0600 (Central Standard Time)
            // formData.append("dob",data.dateOfBirth ); // causes issue in formData - TODO
            formData.append("dob",data.dateOfBirth )
            //formData.append("dob","Feb 27 2023")
            formData.append("email",data.email );
            formData.append("phoneNo",data.phoneNo );
            formData.append("address1",data.address1 );
            formData.append("address2",data.address2 );
            formData.append("city",data.city );
            formData.append("stateInCountry",data.stateInCountry );
            formData.append("country",data.country );
            formData.append("profileNote",data.profileNote );
            formData.append("educationalLevel",data.educationalLevel );
            formData.append("resumeText",data.resumeText );
            formData.append("notes",data.notes );
            formData.append("languagesSpoken",data.languagesSpoken );
            formData.append("learningHoursCount",data.learningHoursCount );
            formData.append("availability",data.availability );
            formData.append("realm",data.realm );
             // new
            formData.append("coursesInterestJson",data.coursesInterestJson);
            formData.append("educationalLevelCd",data.educationalLevelCd);
            formData.append("certifications",data.certifications);
            formData.append("employmentStatusCd",data.employmentStatusCd );
            formData.append("resumeCoverNote",data.resumeCoverNote );
            formData.append("preferredClassScheduleJson",data.preferredClassScheduleJson );

            // formData.append("gender", data.gender ); // Deal with this; just like dob
            // formData.append("files", files);
            // formData.append("file", files[0]);

             //formData.append("JSON.stringify(files)", JSON.stringify(files));
            formData.append("profilePhotoFilePath", data.profilePhotoFilePath);
            formData.append("resumeFilePath", data.resumeFilePath);
            formData.append("paymentFileReceiptPath", data.paymentFileReceiptPath);

            if(null != files[0] && null != files[0].name) {
                formData.append("photoFile", files[0]);
            }
            if(null != files[1] && null != files[1].name) {
                 formData.append("resumeFile", files[1]);
            }
            if(null != files[2] && null != files[2].name) {
                 formData.append("paymentFile", files[2]);
            }
            // if list of files - handle same way also if in backend
            //for(let i = 0; i< e.target.files.length; i++) {
            //            formData.append('file', e.target.files[i])
            //   }
            console.log('StudentService.saveStudentUser - JSON.stringify(data):'+ JSON.stringify(data));
            console.log('StudentService.saveStudentUser - files:'+files);
            console.log('StudentService.saveStudentUser - data.resumeFile:'+data.resumeFile);
            console.log('StudentService.saveStudentUser - data.photoFile:'+data.photoFile);
            console.log('StudentService.saveStudentUser - data.paymentFile:'+data.paymentFile);
            console.log('StudentService.saveStudentUser - JSON.stringify(formData):'+JSON.stringify(formData));

            // get user token
            /*
               let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
                console.log('user token: '+ JSON.stringify(user));
                let token = JSON.stringify(user);
                if(user && user.accessToken) {
                    token = user.accessToken;
                 }
             */

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                }
            }

            return http.post("/bs/student/save", formData, config);
  }

/*
@Deprecated
*/

createStudentUser(data, files) {

            let formData = new FormData();

            formData.append("userName", data.userName );
            formData.append("firstName",data.firstName );
            formData.append("middleName",data.middleName);
            formData.append("lastName",data.lastName );
            formData.append("password",data.password );

            // alert('data.dateOfBirth: '+ data.dateOfBirth); // data.dateOfBirth: Mon Feb 27 2023 00:00:00 GMT-0600 (Central Standard Time)
            // formData.append("dob",data.dateOfBirth ); // causes issue in formData - TODO

            formData.append("dob","Feb 27 2023")// TODO
            formData.append("email",data.email );
            formData.append("phoneNo",data.phoneNo );
            formData.append("address1",data.address1 );
            formData.append("address2",data.address2 );
            formData.append("city",data.city );
            formData.append("stateInCountry",data.stateInCountry );
            formData.append("country",data.country );
            formData.append("profileNote",data.profileNote );
            formData.append("educationalLevel",data.educationalLevel );
            formData.append("resumeText",data.resumeText );
            formData.append("notes",data.notes );
            formData.append("languagesSpoken",data.languagesSpoken );
            formData.append("learningHoursCount",data.learningHoursCount );
            formData.append("availability",data.availability );
            formData.append("realm",data.realm );
             // new
            formData.append("coursesInterestJson",data.coursesInterestJson);
            formData.append("educationalLevelCd",data.educationalLevelCd);
            formData.append("certifications",data.certifications);
            formData.append("employmentStatusCd",data.employmentStatusCd );
            formData.append("resumeCoverNote",data.resumeCoverNote );
            formData.append("preferredClassScheduleJson",data.preferredClassScheduleJson );

            // formData.append("gender", data.gender ); // Deal with this; just like dob
            // formData.append("files", files);
            // formData.append("file", files[0]);

             //formData.append("JSON.stringify(files)", JSON.stringify(files));
            formData.append("profilePhotoFilePath", data.profilePhotoFilePath);
            formData.append("resumeFilePath", data.resumeFilePath);
            formData.append("paymentFileReceiptPath", data.paymentFileReceiptPath);

            formData.append("photoFile", files[0]);
            formData.append("resumeFile", files[1]);
            formData.append("paymentFile", files[2]);

            // if list of files - handle same way also if in backend
            //for(let i = 0; i< e.target.files.length; i++) {
            //            formData.append('file', e.target.files[i])
            //   }
            console.log('TraininingService.saveStudentUser - JSON.stringify(data):'+ JSON.stringify(data));
            console.log('TraininingService.saveStudentUser - files:'+files);
            console.log('TraininingService.saveStudentUser - data.resumeFile:'+data.resumeFile);
            console.log('TraininingService.saveStudentUser - data.photoFile:'+data.photoFile);
            console.log('TraininingService.saveStudentUser - data.paymentFile:'+data.paymentFile);

            console.log('saveStudentUser - JSON.stringify(formData):'+JSON.stringify(formData));

            // get user token
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            let token = JSON.stringify(user);
            if(user && user.accessToken) {
                token = user.accessToken;
             }

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                'Authorization' : `Bearer ${token}`
                }
            }

            return http.post("/bs/student/create", formData, config);
  }


// using this
saveStudentSingleCourseEnrollment(data) {

               console.log('Called StudentService.saveStudentSingleCourseEnrollment() ...');

               let formData = new FormData();

               // formData.append("enrollmentUserId", data.enrollmentUserId ); // TODO
               // formData.append("studentId",data.studentId );

                formData.append("courseCode",data.courseCode);
                formData.append("courseCode",data.courseCode);
                formData.append("courseCode",data.courseCode);
                formData.append("studentEmail",data.studentEmail);
                formData.append("registrationAmt",data.registrationAmt);
                formData.append("paymentReceiptFileName",data.paymentReceiptFileName);
                formData.append("paymentReceiptFile", data.paymentReceiptFile);


                // get user token
                // TODO - do this in util
                let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
                console.log('user : '+ JSON.stringify(user));
                let token;
                if(user && user.accessToken) {
                    token = user.accessToken;
                }

                // headers
                const config = {
                    headers: {
                     'content-type': 'multipart/form-data',
                     'Authorization' : `Bearer ${token}`
                    }
                }
            return http.post('/bs/student/student-sinlge-course-enrollment/save', formData, config);
 }

 saveStudentClassAssignment(data) {

        console.log('Called StudentService.saveStudentClassAssignment() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post('/bs/student/student-class-engagement/save', data, config);
 }

saveStudentEnrollment(data, file) {

        let formData = new FormData();

        formData.append("enrollmentUserId", data.enrollmentUserId );
        formData.append("studentId",data.studentId );
        formData.append("trainingCourseId",data.trainingCourseId);
        formData.append("certificateFilePath",data.certificateFilePath );
       // formData.append("certificateFile",files[0] );
        formData.append("enrollemntTimeStamp",data.enrollemntTimeStamp);
        formData.append("courseCompletionTimestamp", data.courseCompletionTimestamp);
        formData.append("enrollmentApprovalTimeStamp",data.enrollmentApprovalTimeStamp);
        formData.append("courseCompletionStatusCd",data.courseCompletionStatusCd);
        formData.append("paymentReceiptFile", file);
        formData.append("certificateFile", null);
        formData.append("paymentVerificationStatusCd",data.paymentVerificationStatusCd);
        formData.append("enrollmentApprovalStatusCd",data.enrollmentApprovalStatusCd);

        formData.append("courseCode",data.courseCode);
        formData.append("registrationAmt",data.registrationAmt);
        formData.append("paymentReceiptFilePath",data.paymentReceiptFilePath);
        formData.append("file", file);

       // get user token
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
        let token = JSON.stringify(user);
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        const config = {
            headers: {
            'content-type': 'multipart/form-data',
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post("/bs/student/student-course-enrollment", formData, config);

}

// saveTrainingOffer
saveTrainingOffer(data) {

       let formData = new FormData();

        formData.append("id", data.id );
        formData.append("code", data.code );
        formData.append("name", data.name);
        formData.append("offerTypeCode",data.offerTypeCode );
        formData.append("mktDescription",data.mktDescription);
        formData.append("resourcePath", data.resourcePath);
        formData.append("courseListJson",data.courseListJson);
        formData.append("courseGroupCode",data.courseGroupCode);
        formData.append("price", data.price);
        formData.append("imageFile", data.imageFile);
        formData.append("imagePath", data.imagePath);
        formData.append("startDate", data.startDate);
        formData.append("endDate", data.endDate);
        formData.append("deletedFlag",data.deletedFlag);

        console.log('saveTrainingOffer data before save ZZZyyy: '+JSON.stringify(data, null, 2));

        console.log('saveTrainingOffer formData before ZZZyyy save: '+JSON.stringify(formData, null, 2));

       // get user token
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
        let token = JSON.stringify(user);
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        const config = {
            headers: {
            'content-type': 'multipart/form-data',
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post("/bs/trainingoffer/save", formData, config);
}

// Get by package type
getTrainingOfferMap() {

        // get user token
           let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            let token = JSON.stringify(user);
            if(user && user.accessToken) {
            token = user.accessToken;
            }

        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post("/bs/training/training-offer-map", null, config)
}

getTrainingOfferLabels() {
    return http.get('/bs/training/training-offer/all-labels');
 }


// Get status - inprogress, Enrolled, Applied, completed
getStudentClassEnrollmentMap() {
           // get user token
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            let token = JSON.stringify(user);
            if(user && user.accessToken) {
            token = user.accessToken;
            }

        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post("/bs/student/student-class-enrollment-map", null, config)
       //return http.post("/bs/training/training-offer-map", null, config)
}

getTrainingOffersMap(params) {

        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        let token = JSON.stringify(user);

        if(user && user.accessToken) {
            token = user.accessToken;
        }

        const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
        }

        // https://axios-http.com/docs/post_example
        // https://www.baeldung.com/spring-request-param
        // https://stackoverflow.com/questions/53501185/how-to-post-query-parameters-with-axios
        // https://stackoverflow.com/questions/44617825/passing-headers-with-axios-post-request
        return http.post('/bs/student/training-offers-map', params, config)
}




// TODO - implement the backend
getAllStudents() {

     console.log('Called StudentService.getAllStudents() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.get('/bs/student/all-students');
        //return http.get('/bs/student/all-students', config);
 }


 onChangeCode(e) {
    this.setState({
      code: e.target.value,
    });
  }

onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

onChangeCourseGroupCode(e) {
    this.setState({
      courseGroupCode: e.target.value,
    });
  }


onChangeFeatured(e) {
    this.setState({
      featured: e.target.value,
    });
  }



/*    getAll() {
        return http.get('/collection/asset/all');
    }

    fetchAssetsByType(collectionTypeCd) {
        return http.get('/collection/asset/type/'+collectionTypeCd);
    }


   getPageableAll(params) {
           return http.get("/collection/asset/pageable/all", { params });
   }

    fetchAssetsByTag(tag) {
        return http.get('/collection/asset/tag/${tag}');
    }

    fetchAssetsBySearchString(searchString) {
        return http.get('/collection/asset/search/${searchString}');
    }

    fetchAssetsBySearchSpecification(searchSpecification) {

            return http.post("/collection/asset/specificationFetch", searchSpecification, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                });
        }

        */


 }

export default new StudentService();