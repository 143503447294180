import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'
import Box from '@mui/material/Box';

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import InputTextAreaField from '../../../components/formfields/InputTextAreaField';
import styles from './evaluation.module.css'

import EvaluationService from '../../../services/EvaluationService';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Formik, Form, Field, FieldArray, ErrorMessage  } from 'formik';
import FormitLogger  from "../../../common/FormitLogger";
import jeanLogo from'./static/jeans3.jpg'

const examTypes = [
  {
    code: 'T',
    label: 'Test'
  },
  {
    code: 'E',
    label: 'Examination'
  }
];


const questionTypes = [
  {
    code: 'TF',
    label: 'True or False'
  },
  {
    code: 'MC',
    label: 'Multiple Choice'
  },
   {
      code: 'FB',
      label: 'Fill In Blank/Exact'
    }
    ,
    {
       code: 'DD',
       label: 'Drag And Drop Matching'
    }
    ,
    {
      code: 'ES',
      label: 'Essay'
    },
    {
        code: 'MT',
        label: 'Matching Question'
    }
    ,
    {
        code: 'SE',
        label: 'Rank/Sequence'
    } ,
     {
        code: 'HS',
        label: 'Hot Spot'
    }
];


const booleanOptions = [
  {
    code: 'T',
    label: 'True'
  },
  {
    code: 'F',
    label: 'False'
  }
];

const multiChoiceAnswerOptionCount = [
      {
        code: '3',
        label: 'Three'
      },
    {
    code: '4',
    label: 'Four'
    }
    ,
    {
    code: '5',
    label: 'Five'
    }
];


/*

// Quesion

    private Long id;
    private  Integer sn; // keep a counter per exam
    private Integer questionNo;
    private String questionType;
    private String examCode;
    private String  questionAnswerOptionsStr;
    private String  correctAnswerStr;
    private Boolean allowsMultipleAnswers;
    private Integer maxAnswersAllowed;
    private Integer score;
    private String content;
    private String questionText;

// Answer

    private Long id;
    private Long userId;
    private Integer sn;
    private Integer order;
    private Integer answerScore;
    //private  Integer answerTakeAnswerSn; // keep a counter per exam
    private String questionText;  // json of questionText, answerOptions []
    private String questionType;
    private String examCode;
    private String  questionAnswerOptionsStr; // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
    private String  correctAnswerStr;  // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
    private Long examId;
    private Long questionId;

// ExamTake

    private Long id;
    private Long userId;
    //private User user;
    private User user;
    private Long examId;
    private String examCode;
    private  Integer questionSn; // keep a counter per exam
    private  Integer answerSn; // keep a counter per exa
    private String  userAnswerOptionsStr;
    private String  correctAnswerStr;
    private String  userExamTakeContent
    private String responses;
    private Integer questionOrder;
    private String statusCode;
    private Double userScore;
    private Double percentCorrect;
    private Boolean passedOrFailed;
    private Integer attempts;
    private Integer attemptsAllowed;
    private Boolean published;
    private Date dateTimeTaken;
    private Date startedDateTime;
    private Date endedDateTime;
    private String remark;

*/




const ExamSetupNew = () =>    {

            const [flow, setFlow] = React.useState('');
            const [error, setError] = React.useState(false);
            const [helperText, setHelperText] = React.useState('');

            let questionsList = [];
            let answersList = [];

            const handleFlowRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            // setValue((event.target as HTMLInputElement).value);
            setFlow(event.target.value);
            setHelperText(' ');
            setError(false);
            };

    //================
/*  const [exam, setExam] = React.useState( {
                                             "examCodeForSearch": null,
                                             "examCode": "",
                                             "examDescription": "",
                                             "classCode": null,
                                             "type": "",
                                             "score": "",
                                             "timeLimitSec": "" ,
                                             "attemptsAllowed": "",
                                             "startsDate": "",
                                             "endsDate": ""
                                           });*/
/*  const [questionsList, setQuestionsList] = useState([{
                                                               questionSN: "",
                                                               questionText : "",

                                                               answerAlphabetALabel: "A",
                                                               answerAText : "" ,
                                                               isACorrectChoice : "false" ,

                                                               answerAlphabetBLabel: "B",
                                                               answerBText : "" ,
                                                               isBCorrectChoice : "false" ,

                                                               answerAlphabetCLabel: "C",
                                                               answerCText : "" ,
                                                               isCCorrectChoice : "false" ,

                                                               answerAlphabetDLabel: "D",
                                                               answerDText : "" ,
                                                               isDCorrectChoice : "false" ,

                                                               answerAlphabetELabel: "E",
                                                               answerEText : "" ,
                                                               isECorrectChoice : "false"
                                                             }]);*/

    function lookupExam(e, examCode) {

           e.preventDefault();
           console.log('lookupExam called ... ');

          // alert('examCode: '+examCode)
           let examSetupRequest = {
                               "exam" : {
                                    "examCode": examCode
                               }
                          }

        // alert('examSetupRequest : '+JSON.stringify(examSetupRequest, null, 2));
        // examCodeForSearch
         console.log('examSetupRequest called ..: JSON.stringify(examSetupRequest) - from UI : ' +JSON.stringify(examSetupRequest));

         EvaluationService.lookupExamSetup( examSetupRequest )
                  .then(response => {

                    // alert('response.data : '+JSON.stringify(response.data, null, 2));
                    // exam, questionAndAnswerList
                    //   setQuestionsList(response.data.questionAndAnswerList);

                    // setQuestionsList(questionsList => ([...questionsList, ...response.data.questionAndAnswerList]));

                    // alert('questionsList : '+JSON.stringify(questionsList, null, 2));

                    // setExam(exam => ([...exam, ...response.data.exam]));

                   toast.success('🦄 Exam setup fetched successfully', {
                             position: "top-center",
                             autoClose: 5000,
                             hideProgressBar: false,
                             closeOnClick: true,
                             pauseOnHover: true,
                             draggable: true,
                             progress: undefined,
                             });
                      console.log('Exam setup fetched response: JSON.stringify(response.data): ' +JSON.stringify(response.data));
                  })
                  .catch(e => {
                    console.log(e);
                    toast.error('🦄 Error while fetching Exam setup ...'+ e.message, {
                                         position: "top-center",
                                         autoClose: 5000,
                                         hideProgressBar: false,
                                         closeOnClick: true,
                                         pauseOnHover: true,
                                         draggable: true,
                                         progress: undefined,
                                         });
                  });

                // alert('questionsList 2 : '+JSON.stringify(questionsList, null, 2));
    }

// Varying question type question rendering
function renderTrueOrFalseQuestionSetup() {

 return (
       <Grid item xs={12} sm={12}>

             <Grid item xs={12} sm={12}>
                 <InputTextAreaField
                    label="True or False Question Text"
                      name="trueOrFalseQtnText"
                      fullWidth
                    />
            </Grid>

           <Grid item xs={12} sm={12}>
                <RadioGroup
                  aria-labelledby="demo-error-radios"
                  name="trueOrFalseQtnGrp"
                  value={flow}
                  onChange={handleFlowRadioChange}>

                 {
                /*<FormControlLabel value="True" control={<Radio />} label="True" />
                <FormControlLabel value="False" control={<Radio />} label="False" />*/

                 }
                </RadioGroup>
             </Grid>
             <Grid item xs={12} sm={6}>
                <SelectField
                    name="correctTrueOrFalseAnswer"
                    label="Correct True Or False Answer"
                    data={booleanOptions}
                    fullWidth
                  />
            </Grid>

        <br />
        <br />

        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
                <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Remove
                </Button>
                <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Reset
                </Button>
        </Grid>

        </Grid>
  );

}

function renderMultiChoiceQuestionSetup(values) {

 return (
    <>
        <Grid item xs={6} sm={6}>
                    <div className={styles.tableDisplay}>
                         <div className={styles.tableRowDisplay}>
                              <span className={styles.tableCellDisplay} >

                                  <InputField
                                  label="Question Text"
                                    name="multiChoiceQuestionText"
                                    className={styles.inPutBox}
                                  />
                              </span>

                            <span className={styles.tableCellDisplay} >
                            </span>
                            <span className={styles.tableCellDisplay} >
                            </span>
                         </div>
                     </div>
                    <div>
                        <Grid item item xs={6} sm={6}>
                             <SelectField
                                name="multiChoiceAnswerOptionCount"
                                label="MultiChoice Answer Option Count"
                                data={multiChoiceAnswerOptionCount}
                                fullWidth
                              />

                        </Grid>
                    </div>

                    <div className={styles.tableDisplay}>
                        <div className={styles.tableRowDisplay}>
                            <span className={styles.tableCellDisplay} >
                                Answer Options
                            </span>
                            <span className={styles.tableCellDisplay} >
                            </span>
                           <span className={styles.tableCellDisplay} >
                           </span>

                            <span className={styles.tableCellDisplay}  >
                                <label >Is correct option ?</label>
                            </span>

                       </div>
                    </div>

                    <div>
                        { values.multiChoiceAnswerOptionCount === "3" ? renderThreeMultiChoice():  null }
                        { values.multiChoiceAnswerOptionCount === "4" ? renderFourMultiChoice():  null }
                        { values.multiChoiceAnswerOptionCount === "5" ? renderFiveMultiChoice():  null }
                    </div>

        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
                <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Remove
                </Button>
                <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Reset
                </Button>
        </Grid>
       </>
  );

}

function renderThreeMultiChoice() {


  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>
                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                      <label  name="answerAlphabetBLabel" > (B) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                          name="answerBText"
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                          name="isBCorrectChoice"
                                      />
                                </div>
                  </div>


                 <div className={styles.tableRowDisplay}>
                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

             </div>
      </>

  );

}

function renderFourMultiChoice() {

  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>
                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                      <label  name="answerAlphabetBLabel" > (B) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                          name="answerBText"
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                          name="isBCorrectChoice"
                                      />
                                </div>
                  </div>


                 <div className={styles.tableRowDisplay}>
                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

                <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                    <label  name="answerAlphabetDLabel" > (D) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                             name="answerDText"
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                 </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                            name="isDCorrectChoice"
                                      />
                                </div>
                </div>

             </div>
      </>

  );
}


function renderFiveMultiChoice() {

  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>
                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                      <label  name="answerAlphabetBLabel" > (B) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                          name="answerBText"
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                          name="isBCorrectChoice"
                                      />
                                </div>
                  </div>


                 <div className={styles.tableRowDisplay}>
                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

                <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                    <label  name="answerAlphabetDLabel" > (D) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                             name="answerDText"
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                 </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                            name="isDCorrectChoice"
                                      />
                                </div>
                </div>

                 <div className={styles.tableRowDisplay}>
                               <div className={styles.tableCellDisplay} >
                                     <label name="answerAlphabetELabel" > (E) </label>
                               </div>

                              <div className={styles.tableCellDisplay} >
                                   <InputField
                                         name="answerEText"
                                        className={styles.inPutBox}
                                   />
                              </div>
                              <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                              </div>
                              <div className={styles.tableCellDisplay} >
                                   <CheckboxField
                                         name="isECorrectChoice"
                                    />
                              </div>
                 </div>

             </div>
      </>

  );

}

function renderFillBlankExactMatchQuestionSetup() {

 return (
    <>
          <Grid>
             </Grid >
             <Grid item xs={12} sm={12}>
                 <InputField
                      name="fillInBlankQuestionText"
                      label="Fill In Blank Question Text"
                      fullWidth
                    />
            </Grid>
             <Grid item xs={12} sm={12}>
                 <InputField
                      name="fillInBlankQuestionAnswer"
                      label="Fill In Blank Question Possible Answer"
                      fullWidth
                    />
            </Grid>

            <br />
            <br />

        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
                <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Remove
                </Button>
                <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Reset
                </Button>
        </Grid>
       </>
  );

}

function renderMatchingQuestionSetup() {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <div>
                 <h6> Question type not supported. Please, choose another question type. </h6 >
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
            <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                Add
            </Button>
            <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
               Remove
            </Button>
            <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
               Reset
            </Button>
        </Grid>
       </>
  );

}



function renderSequenceQuestionSetup() {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <div>
                  <h6> Question type not supported. Please, choose another question type. </h6 >
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
                <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Remove
                </Button>
                <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Reset
                </Button>
        </Grid>
       </>
  );

}

function renderHotSpotQuestionSetup() {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <div>
                <h6>  Question type not supported. Please, choose another question type. </h6>
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
                <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Remove
                </Button>
                <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                   Reset
                </Button>
        </Grid>
       </>
  );

}

function renderEssayQuestionSetup() {
 console.log('Called renderEssayQuestionSetup...');

 return (
    <>

             <Grid item xs={12} sm={12}>
                 <InputTextAreaField
                      name="essayQuestionText"
                      label="Essay Question Text"
                      fullWidth
                    />
            </Grid>

             <Grid item xs={12} sm={12}>
                 <InputTextAreaField
                      name="essayQuestionPossibleAnswer"
                      label="Essay Question Possible Answer"
                      fullWidth
                    />
            </Grid>

            <br />
            <br />

            <Grid item xs={12} sm={12}>
                    <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                        Add
                    </Button>
                    <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                       Remove
                    </Button>
                    <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                       Reset
                    </Button>
            </Grid>

       </>
  );

}



 function renderDragAndDropMatchingQuestionSetup() {

  console.log('Called renderDragAndDropMatchingQuestionSetup...');

/*
        // UI : https://knowledgebase.xjtlu.edu.cn/article/drag-and-drop-matching-31.html
        // Drag & Drop

        https://www.w3schools.com/html/html5_draganddrop.asp //
        https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer //
        https://codepen.io/noyiri/pen/zeoRwB //

        https://www.digitalocean.com/community/tutorials/js-drag-and-drop-vanilla-js //



        https://www.npmjs.com/package/react-draggable
        https://www.freakyjolly.com/draggable-react-components-using-react-draggable-example-application/#:%7E:text=Adding%20a%20drag%20feature%20to%20a%20component%20is,component%20to%20make%20them%20draggable%20on%20the%20page.
        https://blog.logrocket.com/drag-and-drop-react-dnd/
    */

  return (
     <>

              <Grid item xs={12} sm={12}>

                  <p>Drag the image back and forth between the two div elements.</p>

                  <div id="ddDiv1" className={styles.ddDiv}  onDrop={drop} onDragOver={allowDrop}>
                    <img src={jeanLogo} draggable="true" onDragStart={drag} id="drag1" width="88" height="31" />
                  </div>

                  <div id="ddDiv2"   className={styles.ddDiv}  onDrop={drop} onDragOver={allowDrop} ></div>
             </Grid>

             <br />
             <br />

             <Grid item xs={12} sm={6}>
                     <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                         Add
                     </Button>
                     <Button  id="removeButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                        Remove
                     </Button>
                     <Button id="rstButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                        Reset
                     </Button>
             </Grid>

        </>
   );

 }

function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}

// End Varying question type question rendering

function renderNewExamBaseInfo() {

 return (
    <div>
        <Grid container spacing={2}>
           <Grid item sm={12}>
                <label>Exam Meta Data</label>
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examCode" label="Exam Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examDescription" label="Exam Description" fullWidth />
            </Grid>
            <Grid item sm={3}>
                 <InputField name="classCode" label="Class Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                     <SelectField
                        name="type"
                        label="Type"
                        data={examTypes}
                        fullWidth
                      />

            </Grid>
            <Grid item sm={3}>
                  <InputField name="score" label="Maximum Score" fullWidth />
             </Grid>
            <Grid item sm={3}>
                    <InputField name="timeLimitSec" label="Time Limit(Min)" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="attemptsAllowed" label="Attempts Allowed" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="startsDate" label="Start Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="endsDate" label="End Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
          </Grid>

    </div>
    );

}


function renderExamSearchView(values) {

     return (
        <div>
             <Grid container>
                <Grid item sm={12}>
                      <label>Search Term</label>
                </Grid>
                <Grid item sm={12}>
                        <InputField name="examCodeForSearch" label="Exam Code" />
                </Grid>


                    <Grid item xs={12} sm={6}>
                            <Button id="lookupExamButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined"

                            onClick={(e) => {
                                  lookupExam(e, values.examCodeForSearch);
                               }}
                            >
                                Lookup Exam Setup
                            </Button>

                    </Grid>

                <br />
                <br />
              </Grid>

        </div>
        );

}

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <div>

            <Typography  component="h3"  gutterBottom variant="h3" component="div">
              <Grid item sm={12}>
                   Examination Setup xxx
              </Grid>
            </Typography>

                <Formik

                       initialValues={{
                                       questionTypes: questionTypes,
                                       questionType: "",
                                       // Question
                                        questionId: "",
                                        sn: "",
                                        questionNo: "",
                                        examCode: "",
                                        questionAnswerOptionsStr: "",
                                        correctAnswerStr: "",
                                        allowsMultipleAnswers: "",
                                        maxAnswersAllowed: "",
                                        score: "",
                                        questionText: "",
                                       // Answer
                                        order: "",
                                        sn: "",
                                        answerScore: "",
                                        examCode: "",
                                        questionAnswerOptionsStr: "",
                                        correctAnswerStr: "",
                                        allowsMultipleAnswers: "",
                                        maxAnswersAllowed: "",
                                        score: "",
                                        questionText: "",
                                       // ExamTake
                                       examTakeId: "",
                                       answerSn: "",
                                       userExamTakeContent: "",
                                       statusCode: "",
                                       userScore: "",
                                       percentCorrect: "",
                                       passedOrFailed: "",
                                       attempts: "",
                                       attemptsAllowed: "",
                                       published: "",
                                       dateTimeTaken: "",
                                       startedDateTime: "",
                                       endedDateTime: "",
                                       remark: ""
                                      }}

                        enableReinitialize={true}

                        onSubmit={(values, { setSubmitting }) => {

                              setTimeout(() => {
                                     alert(JSON.stringify(values, null, 2));
                                     let questionAndAnswerList = [];

                                let question = {};
                                let answer = {};

                                if(values.questionType ===  "TF") {
                                     // build True or False Qestion and Answer Request
                                     // questionText, questionNo, questionType,

                                    // trueOrFalseQtnText
                                    // trueOrFalseQtnGrp
                                    // correctTrueOrFalseAnswer
                                     let qtn = {
                                         "questionType" : values.questionType,
                                         "questionId" : values.questionId,
                                         "sn" : values.questionNo,
                                         "questionNo" : values.questionNo,
                                         "examCode" : values.examCode,

                                         "questionText" : values.trueOrFalseQtnText,
                                         "questionAnswerOptionsStr" : values.trueOrFalseQtnGrp,
                                         "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                         "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                         "questionType" : values.questionType,
                                         "sn" : values.questionNo,
                                         "examCode" :  values.examCode,
                                         "questionAnswerOptionsStr" : values.trueOrFalseQtnGrp,
                                         "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                         "allowsMultipleAnswers" : "",
                                         "maxAnswersAllowed" : "",
                                         "score" :  values.score,
                                         "questionText" : values.trueOrFalseQtnText,
                                         "answerScore" : values.questionScore
                                    }
                                    answer = ans;
                                 }

                                if(values.questionType ===  "MC") {

                                // { values.multiChoiceAnswerOptionCount === "3" ? renderThreeMultiChoice():  null }
                                // { values.multiChoiceAnswerOptionCount === "4" ? renderFourMultiChoice():  null }
                                // { values.multiChoiceAnswerOptionCount === "5" ? renderFiveMultiChoice():  null }


                                let qtn = {
                                 "questionType" : values.questionType,
                                 "questionId" : values.questionId,
                                 "sn" : values.questionNo,
                                 "questionNo" : values.questionNo,
                                 "examCode" : values.examCode,
                                // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                 "questionAnswerOptionsStr" : "",
                                 "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                  "questionScore" : values.questionScore,
                                 "questionText" : values.multiChoiceQuestionText,
                                  "multiChoiceQuestionTexts" : { "answerAText" : values.answerAText, "answerBText" : values.answerBText, "answerCText" : values.answerCText,  "answerDText" : values.answerDText, "answerEText" : values.answerEText } ,
                                  "multiChoiceAnswerTexts" : {"isACorrectChoice" : values.isACorrectChoice, "isBCorrectChoice" : values.isBCorrectChoice, "isCCorrectChoice" : values.isCCorrectChoice,  "isDCorrectChoice" : values.isDCorrectChoice, "isECorrectChoice" : values.isECorrectChoice },
                                  "multiChoiceAnswerOptionCount" : values.multiChoiceAnswerOptionCount,
                                }


                                question = qtn;

                                let ans = {
                                 "questionType" : values.questionType,
                                 "sn" : values.questionNo,
                                 "examCode" :  values.examCode,
                                 "questionAnswerOptionsStr" : "",
                                 "correctAnswerStr" : "",
                                 "allowsMultipleAnswers" : "",
                                 "maxAnswersAllowed" : "",
                                 "answerScore" : values.questionScore,
                                 "questionText" : values.multiChoiceQuestionText,
                                  "multiChoiceQuestionTexts" : { "answerAText" : values.answerAText, "answerBText" : values.answerBText, "answerCText" : values.answerCText,  "answerDText" : values.answerDText, "answerEText" : values.answerEText }  ,
                                  "multiChoiceAnswerTexts" : {"isACorrectChoice" : values.isACorrectChoice, "isBCorrectChoice" : values.isBCorrectChoice, "isCCorrectChoice" : values.isCCorrectChoice,  "isDCorrectChoice" : values.isDCorrectChoice, "isECorrectChoice" : values.isECorrectChoice },
                                 "multiChoiceAnswerOptionCount" : values.multiChoiceAnswerOptionCount
                                }
                                answer = ans;

                                }
                                if(values.questionType ===  "FB") {
                                   // renderFillBlankExactMatchQuestionSetup():
                                   // fillInBlankQuestionText
                                   // fillInBlankQuestionAnswer

                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,

                                    "questionText" : values.fillInBlankQuestionText,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.fillInBlankQuestionAnswer,
                                    "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.fillInBlankQuestionAnswer,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "answerScore" : values.questionScore,
                                    "questionText" : values.fillInBlankQuestionText,
                                    }
                                    answer = ans;
                                }
                                if(values.questionType ===  "MT") {
                                   // renderMatchingQuestionSetup()
                                   // No Support
                                }
                                if(values.questionType ===  "SE") {
                                    // renderSequenceQuestionSetup()
                                    // No Support
                                }
                                if(values.questionType ===  "DD") {

                                   // renderDragAndDropMatchingQuestionSetup()
                                   // ddDiv1
                                   // ddDiv2
                                   // img: drag1

                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,

                                    "questionText" : values.ddDiv1,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.ddDiv2,
                                     "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.ddDiv2,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "score" :  values.score,
                                    "questionText" : values.ddDiv1,
                                    "answerScore" : values.questionScore
                                    }
                                    answer = ans;
                                }
                                if(values.questionType ===  "HS") {
                                   // renderHotSpotQuestionSetup():
                                   // No Support
                                }
                                if(values.questionType ===  "ES") {
                                   // renderEssayQuestionSetup()
                                   // essayQuestionText
                                   // essayQuestionPossibleAnswer
                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,
                                    "questionText" : values.essayQuestionText,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.essayQuestionPossibleAnswer,
                                     "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.essayQuestionPossibleAnswer,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "answerScore" : values.questionScore,
                                    "questionText" : values.essayQuestionText,
                                    }
                                    answer = ans;

                                }

                                // question-answer structure as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                // let question = {

                                /*     private Long id;
                                     private  Integer sn; // keep a counter per exam
                                     private Integer questionNo;
                                     private String questionType;
                                     private String examCode;
                                     private String  questionAnswerOptionsStr;
                                     private String  correctAnswerStr;
                                     private Boolean allowsMultipleAnswers;
                                     private Integer maxAnswersAllowed;
                                     private Integer score;
                                     private String content;
                                     private String questionText*/
                               //  }


                                    //let answer = {
                                      /*  private Long id;
                                        private Long userId;
                                        private Integer sn;
                                        private Integer order;
                                        private Integer answerScore;
                                        //private  Integer answerTakeAnswerSn; // keep a counter per exam
                                        private String questionText;  // json of questionText, answerOptions []
                                        private String questionType;
                                        private String examCode;
                                        private String  questionAnswerOptionsStr; // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                        private String  correctAnswerStr;  // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                        private Long examId;
                                        private Long questionId;*/
                                  //}


                               let examSetupRequest = {
                                       "exam" : {
                                            "examCodeForSearch": values.examCodeForSearch,
                                            "examCode": values.examCode,
                                            "examDescription": values.examDescription,
                                            "classCode": values.classCode,
                                            "type": values.type,
                                            "score": values.score,
                                            "timeLimitSec": values.timeLimitSec,
                                            "attemptsAllowed": values.attemptsAllowed,
                                            "startsDate": values.startsDate,
                                            "endsDate": values.endsDate
                                       },
                                       "question" : question,
                                       "answer" : answer,
                               }

                                alert('ZZZ examSetupRequest ZZZ : '+ JSON.stringify(examSetupRequest, null, 2));

                                 EvaluationService.saveExamSetup(examSetupRequest).then(
                                       response => {
                                        // courseList = response.data;
                                         toast.success('Exam Setup saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                         console.log("Exam Setup saved successfully ...: "+ JSON.stringify(response));
                                       },
                                   error => {
                                    toast.error('Error while saving Exam Setup', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error saving Exam Setup [error.toString()] : "+ error.toString());
                                   }
                                  );

                              setSubmitting(false);
                            }, 400);
                          }}



               render={({ values }) => (

                 <Form>

                 <Box sx={{width: 600, maxWidth: '100%',}}>
                    <Grid container spacing={3}>
                      <FormitLogger />

                        <FormControl sx={{ m: 3 }} error={error} variant="standard">
                          <FormLabel id="demo-error-radios">How do you want to proceed?</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="flowgroup"
                            value={flow}
                            onChange={handleFlowRadioChange}>

                            <FormControlLabel value="SearchExam" control={<Radio />} label="Lookup Existing Exam" />
                            <FormControlLabel value="NewExam" control={<Radio />} label="Setup New Exam" />

                          </RadioGroup>
                          <FormHelperText>{helperText}</FormHelperText>


                        </FormControl>

                        <Grid item xs={12} sm={12}>
                                       <hr style={{color: "gray",backgroundColor: "gray",height: "3px", width: "100%"}} />
                         </Grid>

                        <div>
                            { flow === "SearchExam" ? renderExamSearchView(values):  null }
                        </div>
                        <div>
                            { flow === "NewExam" ? renderNewExamBaseInfo():  null }
                        </div>
                        <Grid item xs={12} sm={12}>
                            <hr style={{color: "gray",backgroundColor: "gray",height: "3px", width: "100%"}} />
                        </Grid>


                        <Grid item xs={12} sm={6}>
                             <SelectField
                                   name="questionType"
                                   label="Question Type"
                                   data={questionTypes}
                                   fullWidth
                                 />
                        </Grid>

                        <Grid item xs={12} sm={12}>

                                {
                                 values.questionType ===  "TF" ? renderTrueOrFalseQuestionSetup():  null
                                }

                                {
                                    values.questionType ===  "MC" ? renderMultiChoiceQuestionSetup(values):  null
                                }

                                {
                                    values.questionType ===  "FB" ? renderFillBlankExactMatchQuestionSetup():  null
                                }

                                {
                                    values.questionType ===  "MT" ? renderMatchingQuestionSetup():  null
                                }

                                {
                                    values.questionType ===  "SE" ? renderSequenceQuestionSetup():  null
                                }

                                {
                                    values.questionType ===  "DD" ? renderDragAndDropMatchingQuestionSetup():  null
                                }

                                {
                                    values.questionType ===  "HS" ? renderHotSpotQuestionSetup():  null
                                }
                                {
                                    values.questionType ===  "ES" ? renderEssayQuestionSetup():  null
                                }

                        </Grid>

                        <Grid item xs={12} sm={12}>
                                <Button id="submitButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Save Exam Setup
                                </Button>
                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                                   Save Exam Take
                                </Button>
                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                                    Generate Result
                                </Button>
                        </Grid>
                            <br />
                            <br />
                        </Grid>
                     </Box>

                  </Form>

                   )}

                 />

              </div>
    </Container>
  )
}

export default ExamSetupNew

