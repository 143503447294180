
import React, { useState, useEffect} from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

 import { Formik, Field, Form, FieldArray, ErrorMessage } from 'formik';
 import * as Yup from 'yup';

import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../../services/CourseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom'; // TODO - UPDATE TO THIS
import { useHistory } from "react-router-dom";


//https://fkhadra.github.io/react-toastify/api/toast
// https://fkhadra.github.io/react-toastify/introduction

const toastOptions = {
    //onOpen: props => console.log(props.foo),
    //onClose: props => console.log(props.foo),
    autoClose: 500,
    closeButton: null,
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
    transition: null,
    progress: 0.2
    // and so on ...
};


export default function CourseSyllabusCreationForm() {

    const history = useHistory();
    const [isLoading, SetLoading] = useState(false);
    const [courseList, setCourseList] = useState([]);

    useEffect(() => {
      loadCourseList();
     }, []);

    const loadCourseList = async () => {
           CourseService.getAllCourses().then(
             response => {
               setCourseList(response.data);
              // courseList = response.data;
               console.log("courseList: "+ JSON.stringify(response.data));
             },
         error => {
           console.log("error.toString(): "+ error.toString());
         }
        );
    };

/*
     validationSchema={Yup.object().shape({
                       friends: Yup.array()
                         .of(
                           Yup.object().shape({
                             name: Yup.string().min(4, 'too short').required('Required'), // these constraints take precedence
                             salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                           })
                         )
                         .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
                         .min(3, 'Minimum of 3 friends'),
                     })}
                */

  return (

        <Container  component="main" >
         <ToastContainer />
         <div>

               <Typography  component="h1" variant="h5">
                Course Syllabus Creation
               </Typography>

                <Formik

                initialValues={{
                                classList: [
                                                    {
                                                    title: '',
                                                    description: ''
                                                    }
                                             ]
                                             }}

                   validationSchema={Yup.object().shape({
                                  classList: Yup.array()
                                    .of(
                                         Yup.object().shape({
                                           title: Yup.string().min(4, 'Title text is too short').required('Title text is Required'), // these constraints take precedence
                                           description: Yup.string().min(4, 'Description text too short').required('Descritpion text is Required')
                                         })
                                       )
                                    .required('Syllabus items required') // these constraints are shown if and only if inner constraints are satisfied
                                    //.contains('Enter your syllabus item here and add/remove as many as needed before saving', 'A syllabus item is not valid')
                                    .min(1, 'Minimum of 1 Syllabus item'),
                                    courseCode: Yup.string()
                                    .required(`Course is required`)

                                })}



                   onSubmit={(values, { setSubmitting }) => {
                     setTimeout(() => {

                        alert(JSON.stringify(values, null, 2));
                        //if(values.)
                        let syllabusRequestRequest = {};
                        syllabusRequestRequest.courseCode = values.courseCode;
                        syllabusRequestRequest.classList = JSON.stringify(values, null, 2);

                          CourseService.saveSyllabus(syllabusRequestRequest).then(
                                response => {
                                 // courseList = response.data;
                                 toast.success('Syllabus item(s)  saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                  console.log("Syllabus saved successfully ...: "+ JSON.stringify(response));
                                },
                            error => {
                             toast.error('Error while saving Syllabus items(s)', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                              console.log("Error saving Syllabus [error.toString()] : "+ error.toString());
                            }
                           );

                       setSubmitting(false);
                     }, 400);
                   }}

        render={({ values }) => (
             <Form>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <SelectField
                       name="courseCode"
                       label="Select A Course"
                       data={courseList}
                       fullWidth
                     />
                    <ErrorMessage name="courseCode" />
                </Grid>
             </Grid>
             <br />

               <FieldArray
                 name="classList"
                 render={arrayHelpers => (

                   <Grid container spacing={3}>
                       {values.classList && values.classList.length > 0 ? (
                          values.classList.map((item, index) => (
                         <Grid container spacing={3} key={index}>
                            {
                            /* <Field name={`classList.${index}`} />*/
                            }
                            <Grid item xs={4} sm={4}>
                                  Title
                                <Field
                                   name={`classList[${index}].title`}
                                   className="form-control"
                                 />
                                 <ErrorMessage name={`classList[${index}].title`}/>
                             </Grid>

                            <Grid item xs={4} sm={4}>
                                   Description
                                <Field
                                name={`classList[${index}].description`}
                                className="form-control"
                                />
                                <ErrorMessage name={`classList[${index}].description`}/>
                            </Grid>

                         <Grid item xs={4} sm={4}>
                               <button
                                 type="button"
                                 onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                               >
                                 -
                               </button>


                               <button
                                 type="button"
                                 onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                               >
                                 +
                               </button>

                          </Grid>
                         </Grid>
                       ))
                     ) : (
                       <button type="button" onClick={() => arrayHelpers.push("")}>
                         {/* show this when user has removed all friends from the list */}
                         Add a syllabus item
                       </button>
                     )}

                   </Grid>
                 )}
               />


               <br />
               <br />
                   <Grid>
                    <button type="submit">Submit</button>
                   </Grid>
               <br />
               <br />
            </Form>
           )}
  />

 </div>
</Container>
);

}

