
import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import useStyles from './styles';
//import { InputField, CheckboxField, SelectField } from '../../../components/formfields';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import { UploaderField }from '../../../components/formfields/UploaderField';




const education_levels = [
  {
    code: 'KINDERGATHEN',
    label: 'Kindergathen'
  },
  {
    code: 'ELEMENTARY',
    label: 'Elementary'
  },
  {
    code: 'HIGHSCHOOL',
    label: 'Elementary'
  },
   {
      code: 'TERTIARY',
      label: 'Tertiary'
    }
];

const employment_statuses = [
  {
    code: 'UNEMPLOYED',
    label: 'Unemployed'
  },
  {
    code: 'EMPLOYEDPARTTIME',
    label: 'Employed Part-time'
  },
  {
    code: 'EMPLOYED',
    label: 'Employed'
  }
];

export default function EducationInfo(props) {

            const classes = useStyles();

           const {
             formField: {
                certifications,
                educationalLevelCd,
                employmentStatusCd
             }
           } = props;

  return (

        <Container  component="main" >
         <div>
          <Typography  variant="h6" className={classes.typo} >
             Education & Certification
          </Typography>
           <fieldset>
                <legend></legend>
                <form>
                <Grid container spacing={3}>

                       <Grid item xs={12} sm={6}>
                         <InputField name="certifications" label="Enter your certifications" fullWidth />
                       </Grid>

                        <Grid item xs={12} sm={6}>
                           <SelectField
                                       name="educationalLevelCd"
                                       label="Educational Level"
                                       data={education_levels}
                                       fullWidth
                                     />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           Resume File
                           <UploaderField name="resumeFile" type="file" value={undefined}/>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SelectField
                                      name="employmentStatusCd"
                                      label="Employment Status"
                                      data={employment_statuses}
                                      fullWidth
                                    />
                        </Grid>

                   </Grid>
                 </form>
                </fieldset>
            </div>
          </Container>
);

}

