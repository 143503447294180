
import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

import { Formik, Form } from 'formik';
import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const courseGroups = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];

export default function ProgramCreationForm() {

function _handleNext(values, actions) {

      }

  return (

        <Container  component="main" >
         <div>
              <Typography  component="h1" variant="h5">
                 Program Creation
              </Typography>
            <Formik
              initialValues={formInitialValues}
              onSubmit={_handleNext}
              >
               <form>
                    <Grid container spacing={3}>
                           <Grid item xs={12} sm={6}>
                             <InputField name="code" label="Program Code" fullWidth />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <InputField name="programName" label="Program Name" fullWidth />
                           </Grid>
                            <Grid item xs={12} sm={6}>
                               <InputField name="description" label="Description" fullWidth />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField name="duration" label="Duration" fullWidth />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                   <ToastContainer />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Cancel
                                </Button>

                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Reset
                                </Button>

                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Save
                                </Button>
                            </Grid>
                    </Grid>
               </form>
            </Formik>
         </div>
        </Container>
);

}

