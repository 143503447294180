
import './blog.css';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useTable } from "react-table";
import { Link } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../components/formfields/InputField';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import MultiSelectField from '../components/formfields/MultiSelectField';
import DatePickerField from '../components/formfields/DatePickerField';
import Pagination from "@material-ui/lab/Pagination";
import Box from "@mui/material/Box";

import { DataGrid } from '@mui/x-data-grid';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import PublicationMgt from "./PublicationMgt";

import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";
import ClassService  from "../services/ClassService";
import FacultyService  from "../services/FacultyService";
import PublicationService  from "../services/PublicationService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";

const courses = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];

const ClassFacultyEngSchema = Yup.object().shape({
    classCode: Yup.string()
    .required(`Class Required`),
    coursePlannedStartDate: Yup.date()
    .required(`Planned start date is required`),
    facultyId: Yup
    .number("Must be a number type") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("Faculty is required.") // Sets it as a compulsory field
 });


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff"
  },
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    }
  },
  blogsContainer: {
    paddingTop: theme.spacing(3)
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3)
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 240
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between"
  },
  author: {
    display: "flex"
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default function PersonalResourceHome() {

           const classes = useStyles();
           const [classList, setClassList] = useState([]);
           const [facultyList, setFacultyList] = useState([]);

           const [personalPublicationResList, setPersonalPublicationResList] = useState([]);

            const personalPublicationResRef = useRef();
            personalPublicationResRef.current = personalPublicationResList;

           const [personalPubResTitleSearchStr, setPersonalPubResTitleSearchStr] = React.useState('');
           const [page, setPage] = React.useState(1);
           const [pageSize, setPageSize] = React.useState(0);
           const [count, setCount] = React.useState(0);

           const [currentIndex, setCurrentIndex] = React.useState(0);
           const [currentBlog, setCurrentBlog] = React.useState({});


           const [value, setValue] = React.useState('');
           const [error, setError] = React.useState(false);
           const [helperText, setHelperText] = React.useState('');

             useEffect(() => {
                loadClasses();
               }, []);

            const loadClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched: "+ JSON.stringify(response.data));
                 setClassList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };


       useEffect(() => {
                loadFaculties();
        }, []);

        useEffect(() => {
                  retrieveAllPersonalResource();
         }, []);


      const loadFaculties = async () => {
             FacultyService.getAllFaculties().then(
           response => {
                      console.log("setFacultyList Fetched: "+ JSON.stringify(response.data));
             setFacultyList(response.data);
           },
           error => {
             console.log("error.toString(): "+ error.toString());
           }
         );
    };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   // setValue((event.target as HTMLInputElement).value);
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

 function getRequestParams(searchTitle, page, pageSize) {
    let params = {};
    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }
    return params;
  }

 const handlePageChange = (event, value) => {
        setPage(value);
    };

 function retrieveAllPersonalResource() {
        const params = getRequestParams(personalPubResTitleSearchStr, page, pageSize);
        PublicationService.getAllPersonalResource(params)
          .then((response) => {
             const { personalPublicationResList, totalPages } = response.data;
             setPersonalPublicationResList(personalPublicationResList);
             setCount(totalPages);
             console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
 }



let pageSizes = [3, 6, 9];

let topPersonalPublication = personalPublicationResList[0];

// https://github.com/bezkoder/react-table-pagination-server-side/blob/master/src/components/TutorialsList.js
// https://github.com/bezkoder/react-table-pagination-server-side

// table rendering
const columns = useMemo(
    () => [

     {
        Header: "Id",
        accessor: "id",
        maxWidth: 10,
     },
      {
        Header: "Title",
        accessor: "title",
        minWidth: 800,
        wdth: 800,
      },

      {
        Header: "Actions",
        accessor: "actions",
        maxWidth: 200,
        Cell: (props) => {

          const rowIdx = props.row.id;
          const id = personalPublicationResRef.current[rowIdx].id;

          return (
            <div>

               <span>
                    <PublicationMgt publicationType="PRP" resourceId={id} />
               </span>
            </div>
          );
        },
      },

    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data: personalPublicationResList,
  });


  return (

        <Container  component="main" >
                <ToastContainer />

                <Box className={classes.hero}>
                   <Box>Personal Publication</Box>
                </Box>

                <br />
                 <div>
                    <Formik

                      initialValues={{
                                     classCode: '',
                                     facultyId: '',
                                     coursePlannedStartDate: '',
                                     isPrimaryEngagement : '0'
                                   }}

                          onSubmit={(values, { setSubmitting }) => {
                                                              setTimeout(() => {

                                         alert(JSON.stringify(values, null, 2));
                                         let classFacultyEngRequest = {};
                                         classFacultyEngRequest.coursePlannedStartDate = values.coursePlannedStartDate;
                                         classFacultyEngRequest.classCode = values.classCode;
                                         classFacultyEngRequest.facultyId = values.facultyId;
                                         classFacultyEngRequest.isPrimaryEngagement = values.isPrimaryEngagement;

                                           alert('classFacultyEngRequest :'+JSON.stringify(classFacultyEngRequest, null, 2));

                                         FacultyService.saveFacultyClassEngagement(classFacultyEngRequest).then(
                                                 response => {
                                                   toast.success('Faculty-class engagement saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                   console.log("Faculty-class engagement  saved successfully ...: "+ JSON.stringify(response));
                                                 },
                                             error => {
                                              toast.error('Error while saving Faculty-class engagement', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                             }
                                            );

                                    setSubmitting(false);
                                  }, 400);
                                }}

                                validationSchema={ClassFacultyEngSchema}
                      >

                      <Form>

                                     <div>

                                             <div className="row" id="upperContentDiv">

                                                     <Grid container spacing={.2}>
                                                            <Grid item xs={8} sm={8}>
                                                                <InputField
                                                                name="searchTerm"
                                                                label="Enter search term"
                                                                fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4}>
                                                               <input type="submit" value="Search" />
                                                            </Grid>
                                                     </Grid>

                                                <div className="card" id="topContentDiv">

                                                        <ul className="list-group">
                                                                {
                                                                    topPersonalPublication &&

                                                                    <li>
                                                                      {topPersonalPublication.title}
                                                                        <br />
                                                                      {topPersonalPublication.content}
                                                                    </li>

                                                                  }
                                                        </ul>
                                                </div>

                                                <div className="rightcolumn" id="topRightSubMenu">

                                                        <div >
                                                          <h3>Categories</h3>
                                                          <div className="fakeimg">Image</div>
                                                          <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
                                                        </div>

                                                        <div >
                                                          <h3>Popular Post</h3>
                                                          <div className="fakeimg">Image</div><br />
                                                          <div className="fakeimg">Image</div><br />
                                                          <div className="fakeimg">Image</div>
                                                        </div>

                                                        <div >
                                                          <h3>Follow Me</h3>
                                                          <p>Some text..</p>
                                                        </div>
                                                    </div>
                                              </div>



<div className="col-md-12 list">
    <table
      className="table table-striped table-bordered"
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
</div>



                                           <br />


                                            <Box
                                               sx={{
                                                       margin: "auto",
                                                       width: "fit-content",
                                                       alignItems: "center",
                                                   }}
                                                   >


                                               <Typography fontSize={32} align="center">

                                                    {"Items Per Page: "}
                                                    <select value={pageSize}>
                                                       {pageSizes.map((size) => (
                                                        <option key={size} value={size}>
                                                          {size}
                                                        </option>
                                                      ))}
                                                    </select>

                                                    <Pagination
                                                          className="my-3"
                                                         count={count}
                                                         page={page}
                                                         siblingCount={1}
                                                         boundaryCount={1}
                                                         variant="outlined"
                                                         shape="rounded"
                                                         onChange={handlePageChange}
                                                      />

                                                    <br />

                                                   Page: {page}
                                               </Typography>

                                               <div className="mt-3">

                                                </div>
                                            </Box>


                                            <br />

                                 </div>
                                 <br />

                           </Form>
                    </Formik>

                 </div>
        </Container>
);

}

