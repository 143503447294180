import logo from './logo.svg';
import './App.css';
import "./My214.css";
import Test from './components/test.component';


//import "bootstrap/dist/css/bootstrap.min.css";
//import "react-modal-video/scss/modal-video.scss";

//import "./App.css";
//import "./My214.css";
import { history } from  "./helpers/history";
// import AuthVerify from "./common/auth-verify";
import EventBus from "./common/EventBus";

import React, { Component } from "react";
//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Router, Switch, Route, Link } from "react-router-dom";

import Layout from "./layout/Layout";
import Register from "./components/register.component";
import Login from "./components/login.component";
import Profile from "./components/Profile.component";
import Resetpasswordemail from "./components/resetpasswordemail.component";

import CreateCourse from "./admin/components/CreateCourse.component";
import CourseCreationForm from "./admin/components/dashboard/CourseCreationForm";
import CourseSyllabusCreationForm from "./admin/components/dashboard/CourseSyllabusCreationForm";
import ProgramCreationForm from "./admin/components/dashboard/ProgramCreationForm";
import ClassCreationForm from "./admin/components/dashboard/ClassCreationForm";
import FacultyClassAssignmentForm from "./admin/components/dashboard/FacultyClassAssignmentForm";
import StudentClassAssignment from "./admin/components/dashboard/StudentClassAssignment";

import CreateCourseSyllabi from "./admin/components/CreateCourseSyllabi.component";
import CreateProgram from "./admin/components/CreateProgram.component";
import CreateTrainingClass from "./admin/components/CreateTrainingClass.component";
import FacultyTrainingAssignment from "./admin/components/FacultyTrainingAssignment.component";

import StudentCourseEnrollment from "./admin/components/StudentCourseEnrollment.component";
import StudentProgramEnrollment from "./admin/components/StudentProgramEnrollment.component";
import FacultyCourseAssignment from "./admin/components/FacultyCourseAssignment.component"; //
import FacultyProgramAssignment from "./admin/components/FacultyProgramAssignment.component";
import FacultySignup from "./components/training/FacultySignup.component";
import StudentSignup from "./components/training/StudentSignup.component";
import TrainingReview from "./components/training/TrainingReview.component";
import FacultyReview from "./components/training/FacultyReview.component";
import ClassReview from "./components/training/ClassReview.component";
import Review from "./components/training/Review.component";
import ClassesDashboard from "./components/training/ClassesDashboard.component";
import SoftwareDevDashboard from "./components/development/SoftwareDevDashboard.component";
import RollCall from "./admin/components/RollCall.component";
import ClassRollCallLanding from "./admin/components/ClassRollCallLanding";
import ClassRollCall from "./admin/components/ClassRollCall";

import RecordAttendance from "./admin/components/RecordAttendance.component";
import RecordedAttendanceMgt from "./admin/components/RecordedAttendanceMgt.component";
import ExamsAdminDashboard from "./admin/components/exams/ExamsAdminDashboard.component";
import ExamSetupForm from "./admin/components/exams/ExamSetupForm";
import ExamSetup from "./admin/components/exams/ExamSetup";

import CustomerForm from "./admin/components/stepperform/CustomerForm";
import Signup from "./components/training/user/ordinaryuser/Signup";
import FormDialog from "./admin/components/FormDialog";

import CheckoutPage from './admin/components/checkoutpagestepper/CheckoutPage';

import MaterialLayout from './admin/components/checkoutpagestepper/MaterialLayout';
import StudentRegistrationForm from './components/training/user/StudentRegistrationForm';
import FacultyRegistrationForm from './components/training/user/FacultyRegistrationForm';
import RegistrationStartPage from './components/training/user/RegistrationStartPage';
import StudentEnrollment from './components/training/StudentEnrollment';

import Grade from "./admin/components/Grade";
import RecordRollCallTest from "./admin/components/RecordRollCallTest";

import ContactUs from "./components/ContactUs.component";
import BlogHome from "./blog/BlogHome";
import PersonalResourceHome from "./blog/PersonalResourceHome";
import PersonalResourceNotesHome from "./blog/PersonalResourceNotesHome";
import TeachingResourceHome from "./blog/TeachingResourceHome";
import PublicResourceHome from "./blog/PublicResourceHome";

import Home from "./Home";
import SignIn from "./components/training/user/SignIn";

import BlogPage from './blog/BlogPage';
import NewPost from './blog/components/new-post.component';

import PublicationCreation from './blog/PublicationCreation';

import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
//import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
//import { Redirect } from 'react-router';
import { connect } from "react-redux";

 function App(props) {

            const [isAuthenticated, setIsAuthenticated] = useState(
                 () => JSON.parse(localStorage.getItem('user_byte_steroid')) || false
            );

            const [user, setUser] = useState({});

            const setAuth = (value) => {
                setIsAuthenticated(value);
                console.log('setIsAuthenticated value: '+value);
            };

            useEffect(()=> {
               // localStorage.setItem("user_byte_steroid", JSON.stringify(isAuthenticated));
                  localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
            }, [isAuthenticated]);

          useEffect(()=> {
                          checkLoginStatus();
               }, [isAuthenticated]);



            function isLoggedIn()
            {
                console.log("isAuthenticated ...: value: "+ JSON.stringify(isAuthenticated));
                if(isAuthenticated) {
                 return true;
                }else {
                return false;
                }
            }

       //=================New ==================================
            function checkLoginStatus() {

                    let user = JSON.stringify(localStorage.getItem('user_byte_steroid')) ;

                    if(null != user && null != user.username) {
                          setIsAuthenticated(true);
                          setUser(user);
                    } {
                         setIsAuthenticated(false);
                         setUser({});
                    }
                    console.log('checkLoginStatus() user: '+user);
              }

             function  handleLogout() {
               setIsAuthenticated(false);
               setUser({});
              }

            function  handleLogin(user) {
                 setIsAuthenticated(true);
                 setUser(user);
              }

       // ======================================================

  return (

    <div>

       <Router history={history}>
            <Layout>

                  <Route exact path="/studentRegistrationForm" component={() => <StudentRegistrationForm userCat="STU"/>} />

                  <Route exact path="/facultyRegistrationForm" render={(props) => <FacultyRegistrationForm userCat="FAC" {...props}/>}  />
                  <Route exact path="/signUpStepper" component={Signup} />

                  <Route exact path="/regStart" component={RegistrationStartPage} />
                  <Route exact path="/customerForm" component={CustomerForm} />
                  <Route exact path="/signin" component={SignIn} />




                  <Route exact path="/resetPasswordemail" component={Resetpasswordemail} />

                  <Route exact path="/studentEnrollment" component={StudentEnrollment} />
                  <Route exact path="/checkoutPage" component={CheckoutPage} />
                  <Route exact path="/courseCreationForm" component={CourseCreationForm} />
                  <Route exact path="/courseSyllabusCreationForm" component={CourseSyllabusCreationForm} />
                  <Route exact path="/programCreationForm" component={ProgramCreationForm} />
                  <Route exact path="/classCreationForm" component={ClassCreationForm} />


                  <Route exact path="/blogPage" component={BlogPage} />
                  <Route exact path="/newBlogPost" component={NewPost} />
                  <Route exact path="/blogHome" component={BlogHome} />
                  <Route exact path="/publicationCreation" component={PublicationCreation} />
                  <Route exact path="/personalResourceHome" component={PersonalResourceHome} />
                  <Route exact path="/personalResourceNotesHome" component={PersonalResourceNotesHome} />
                  <Route exact path="/teachingResourceHome" component={TeachingResourceHome} />
                  <Route exact path="/publicResourceHome" component={PublicResourceHome} />

                  <Route exact path="/" component={Home} />
                  <Route exact path="/login" component={Login} />
                  <Route exact path="/register" component={Register} />


                     <Route
                                exact
                                path={"/profile"}
                                render={props => (
                                  <Profile
                                    {...props}
                                        handleLogin={handleLogin}
                                        handleLogout={handleLogout}
                                        loggedInStatus={isAuthenticated}
                                  />
                                )}
                              />


                  <Route exact path="/classesDashboard" component={ClassesDashboard} />
                  <Route exact path="/softwareDevDashboard" component={SoftwareDevDashboard} />
                  <Route exact path="/studentClassAssignment" component={StudentClassAssignment} />
                  <Route exact path="/classRollCallLanding" component={ClassRollCall} />


                   <Route exact path="/studentCourseEnrollment" render={() => (
                                                       isLoggedIn() ? (
                                                         <StudentCourseEnrollment />
                                                       ) : (
                                                         <SignIn />
                                                       )
                     )}/>

                  <Route exact path="/createCourse" render={() => (
                                     isLoggedIn() ? (
                                       <CreateCourse />
                                     ) : (
                                       <SignIn />
                                     )
                    )}/>

                    <Route exact path="/createCourseSyllabi" render={() => (
                                  isLoggedIn() ? (
                                    <CreateCourseSyllabi />
                                  ) : (
                                    <SignIn />
                                  )
                    )}/>

                  <Route exact path="/createProgram" render={() => (
                                                    isLoggedIn() ? (
                                                      <CreateProgram />
                                                    ) : (
                                                      <SignIn />
                                                    )
                   )}/>
                   <Route exact path="/createTrainingClass" render={() => (
                                          isLoggedIn() ? (
                                            <CreateTrainingClass />
                                          ) : (
                                            <SignIn />
                                          )
                   )}/>

                 <Route exact path="/facultySignup" render={() => (
                                                           isLoggedIn() ? (
                                                             <FacultySignup />
                                                           ) : (
                                                             <SignIn />
                                                           )
                                    )}/>

                  <Route exact path="/studentCourseEnrollment" render={() => (
                                    isLoggedIn() ? (
                                      <StudentCourseEnrollment />
                                    ) : (
                                      <SignIn />
                                    )
                  )}/>

                <Route exact path="/studentProgramEnrollment" render={() => (
                                 isLoggedIn() ? (
                                   <StudentProgramEnrollment />
                                 ) : (
                                   <SignIn />
                                 )
                )}/>
                 <Route exact path="/facultyTrainingAssignment" render={() => (
                                                  isLoggedIn() ? (
                                                    <FacultyTrainingAssignment />
                                                  ) : (
                                                    <SignIn />
                                                  )
                    )}/>

                <Route exact path="/facultyClassAssignment" render={() => (
                                               isLoggedIn() ? (
                                                 <FacultyClassAssignmentForm />
                                               ) : (
                                                 <SignIn />
                                               )
                 )}/>

                  <Route exact path="/classRollCall" render={() => (
                         isLoggedIn() ? (
                           <ClassRollCall />
                         ) : (
                           <SignIn />
                         )
                  )}/>

                 <Route exact path="/rollCall" render={() => (
                                                                isLoggedIn() ? (
                                                                  <RollCall />
                                                                ) : (
                                                                  <SignIn />
                                                                )
                 )}/>
                 <Route exact path="/recordAttendance" render={() => (
                                                                isLoggedIn() ? (
                                                                  <RecordAttendance />
                                                                ) : (
                                                                  <SignIn />
                                                                )
                 )}/>

                 <Route exact path="/recordedAttendanceMgt" render={() => (
                                                                 isLoggedIn() ? (
                                                                   <RecordedAttendanceMgt />
                                                                 ) : (
                                                                   <SignIn />
                                                                 )
                  )}/>


                 <Route exact path="/grade" component={Grade} />
                 <Route exact path="/rollCallTest" component={RecordRollCallTest} />
                 <Route exact path="/facultyProgramAssignment" render={() => (
                                          isLoggedIn() ? (
                                            <FacultyProgramAssignment />
                                          ) : (
                                            <SignIn />
                                          )
                  )}/>

                 <Route exact path="/examsAdminDashboard" render={() => (
                                                           isLoggedIn() ? (
                                                             <ExamsAdminDashboard />
                                                           ) : (
                                                             <SignIn />
                                                           )
                 )}/>

                 <Route exact path="/studentTrainingEnrollment" component={StudentSignup} />
                 <Route exact path="/trainingReview" render={() => (
                                                            isLoggedIn() ? (
                                                              <ClassReview />
                                                            ) : (
                                                              <SignIn />
                                                            )
                  )}/>

                <Route exact path="/facultyReview" render={() => (
                                                 isLoggedIn() ? (
                                                   <FacultyReview />
                                                 ) : (
                                                   <SignIn />
                                                 )
                )}/>
                <Route exact path="/review" render={() => (
                                                  isLoggedIn() ? (
                                                    <Review />
                                                  ) : (
                                                    <SignIn />
                                                  )
                 )}/>

                 <Route exact path="/getInContactWIthUs" component={ContactUs} />
                 <Route exact path="/examSetupForm" component={ExamSetupForm} />

            </Layout>
       </Router>
    </div>
  );
}

export default connect()(App);