import React, { Component } from "react";
import { Redirect, Link } from 'react-router-dom';
//  import { Link } from 'react-router-dom'

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";

import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from "@material-ui/core/Paper";
import {ErrorOutline} from "@material-ui/icons";
import red from "@material-ui/core/colors/red";
import grey from "@material-ui/core/colors/grey";

import Resetpassword from "./resetpassword.component";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


class Login extends Component {

  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.onChangeUsername = this.onChangeUsername.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);


    this.state = {
      username: "",
      password: "",
      loading: false,
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value,
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleLogin(e) {

    e.preventDefault();
    this.setState({
      loading: true,
    });

    this.form.validateAll();

  //alert('props in login - 1 submit :'+JSON.stringify(this.props, null, 2));

    const { dispatch, history } = this.props;

    //  alert('props in login - 2 submit :'+JSON.stringify(this.props, null, 2));

    if (this.checkBtn.context._errors.length === 0) {
      dispatch(login(this.state.username, this.state.password))
        .then(() => {
          history.push("/profile");
          window.location.reload();
        })
        .catch(() => {
          this.setState({
            loading: false
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }


  render() {

    const { isLoggedIn, message , isTimedOut} = this.props;
    console.log('isLoggedIn in Login Page:'+ isLoggedIn);
    console.log('isTimedOut in Login Page:'+ isTimedOut);

    /*if (isLoggedIn) {
       return <Redirect to="/profile" />;
    }
*/
    return (
      <div className="col-md-12">

        <div className="formContainerDiv">

         <fieldset>
                <legend>Log on</legend>
              <img
                src="user_common_avatar.png"
                alt="profile-img"
                className="profile-img-card"
              />

          <Form
            onSubmit={this.handleLogin}
            ref={(c) => {
              this.form = c;
            }}
          >
            <div className="form-group">
              <label htmlFor="username">User Name</label>
              <Input
                type="text"
                className="form-control"
                name="username"
                value={this.state.username}
                onChange={this.onChangeUsername}
                validations={[required]}
              />
            </div>

            <div className="form-group">
              <label htmlFor="password">Password</label>
              <Input
                type="password"
                className="form-control"
                name="password"
                value={this.state.password}
                onChange={this.onChangePassword}
                validations={[required]}
              />
            </div>

            <div className="field-submit">
              <button

                className="btn btn-primary btn-block"
                disabled={this.state.loading}
              >
                {this.state.loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span className="submit-span">Login</span>
              </button>


            </div>

          <div className="field-submit">
                <Link to={"./resetPasswordemail"}>
                        Password Reset
                </Link>
          </div>


            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

             {!isLoggedIn && (
              <>
                <Typography className="title">Session Timed Out</Typography>
                <Paper elevation={0} className="timeoutPaper">
                  <ErrorOutline className="icon"/>
                  <div className="message">
                    <Typography>
                        <span className="placeholderText">
                          Your current session has timed out.
                        </span>
                      <div className="placeholderText">
                        Please log in again to continue.
                      </div>
                    </Typography>
                  </div>
                </Paper>
              </>
            )}
        </fieldset>

        </div>

      </div>
    );
  }
}

function mapStateToProps(state) {

  const { isLoggedIn } = state.auth;
  const { message } = state.message;
 // const { isTimedOut } = state.isTimedOut; TODO
  return {
    isLoggedIn,
    message
  };
}

export default connect()(Login);
//export default connect(mapStateToProps)(Login);
