import React, { useState, useEffect } from 'react';
//import BlogService from '../services/BlogService';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Card, Row, Col, Container } from "react-bootstrap";
import FormDialog from "../../admin/components/FormDialog";
import StudentSingleCourseEnrollment from "./StudentSingleCourseEnrollment";
import StudentEnrollment from "./StudentEnrollment";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import StudentService from '../../services/StudentService';

const MyEnrolledAndActiveCoursesDashboard = (props) => {


/*

 let clientId = "DfFCvW2ViuP8uyRPl1_yCobGqMXUF0m9qlX27WtWVMU";
 let query ="computing";
 let url = "https://api.unsplash.com/search/photos/?client_id=" +
           clientId +
           "&query=" +
            query


const singleTrainingOfferList = props.singleTrainingOfferList;
// setTrainingOfferList(props.trainingOfferLis);

     const [classEnrollmentMap, setClassEnrollmentMap] = useState([]);

     useEffect(() => {
        getStudentClassEnrollmentMap();
     }, [])


   const getStudentClassEnrollmentMap = async () => {
           StudentService.getStudentClassEnrollmentMap().then(
             response => {
                        console.log("StudentClassEnrollmentMap Fetched: "+ JSON.stringify(response.data));
               setClassEnrollmentMap(response.data);
             },
             error => {
               console.log("error.toString(): "+ error.toString());
             }
       );
  };


    const [playerData, setPlayerData] = useState([]);
    useEffect(() => {

        const fetchData = async () => {
            //const response = await fetch('https://nba-players.herokuapp.com/players-stats')
            //const response = await fetch(url)
            //const nbaData = await response.json()
            //setPlayerData(nbaData.slice(0, 15))

            const response = await fetch(url)
            const nbaData = await response.json()

            console.log("data: "+ JSON.stringify(nbaData));

            setPlayerData(nbaData);
        }
        fetchData()
    }, [])

let courseCode = "PRGINTROJAVA";
let courseName = "Introduction to programming in java";
let classCode = "BATCH 204";
let courseCost = 20000;
let classCost = 20000;
let testImg = 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6';
*/

 let clientId = "DfFCvW2ViuP8uyRPl1_yCobGqMXUF0m9qlX27WtWVMU";
 let query ="computing";
 let url = "https://api.unsplash.com/search/photos/?client_id=" +
           clientId +
           "&query=" +
            query

    const enrolledTrainingOfferList = props.enrolledTrainingOfferList;
   // console.log("packageTrainingOfferList props.PACKAGE: "+ JSON.stringify(enrolledTrainingOfferList));

let classCode = "BATCH 204";
let classCost = 20000;
let testImg = 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6';


  return (

      <Container >

                <br />
                <br />


                <div>
                                    <ImageList cols={4} gap={3}
                                                          sx={{
                                                            gridTemplateColumns:
                                                              'repeat(auto-fill, minmax(250px, 1fr))!important',
                                                          }}

                                                          >

                                            {
                                            (null != enrolledTrainingOfferList ) &&
                                                enrolledTrainingOfferList.map((item) => (


                                                         <div>
                                                                 <ImageListItem key={item.img}>
                                                                       <img
                                                                         srcSet={process.env.PUBLIC_URL + `/images/offers/${item.imagePath}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                                                         src={process.env.PUBLIC_URL + `/images/offers/${item.imagePath}?w=248&fit=crop&auto=format`}
                                                                         alt={item.title}
                                                                         loading="lazy"
                                                                       />
                                                                       <ImageListItemBar
                                                                         title={item.title}
                                                                         subtitle={<span>by: {item.author}</span>}
                                                                         position="below"
                                                                       />
                                                                 </ImageListItem>


                                                    <div>
                                                       <bold> {item.name} </bold>
                                                       <br />
                                                    </div>
                                                    <div>
                                                       {item.mktDescription}
                                                       <br />
                                                    </div>
                                                    <div>
                                                                <ul>
                                                                  Course Content:
                                                                  {
                                                                         ((item.courseListJson) && (item.courseListJson.length > 0)) &&
                                                                         JSON.parse(item.courseListJson).map(courseContent => (
                                                                                                     <li key={courseContent.code}> {courseContent.label} </li>
                                                                                                ))
                                                                  }
                                                                </ul>
                                                       <br />
                                                    </div>
                                                    <div>
                                                        Duration: {item.duration}
                                                       <br />
                                                    </div>
                                                    <div>
                                                        Price: {item.price}
                                                       <br />
                                                    </div>

                                                    <br />

                                                    </div>

                                               ))
                                            }
                                  </ImageList>

                                   <div>
                                        <a target="_blank"  href={""}>
                                           * Syllabus
                                         </a>
                                     </div>
                                     <div >
                                       <a target="_blank"  href={""}>
                                          * Attendance Report
                                        </a>
                                     </div>
                                     <div >
                                       <a target="_blank"  href={""}>
                                          * Course Materials
                                        </a>
                                     </div>
                            </div>


    </Container>
  );

};

export default MyEnrolledAndActiveCoursesDashboard;