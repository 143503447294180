import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import ProgramService  from "../../services/ProgramService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
import { register } from "../../actions/auth";

class CreateProgram extends Component {

  constructor(props) {
    super(props);

    this.state = {
            id : "",
           successful: false,
           fields: {},
           errors: {}
        };
  }

//let courseGroupSelectInput = React.createRef();

 handleValidation() {

     let fields = this.state.fields;
     let errors = {};
     let formIsValid = true;
     //alert('2...this.state.fields: '+JSON.stringify(this.state.fields));

     //courseGroupCode
    // alert('fields["courseGroupCode"]: '+ fields["courseGroupCode"]);

    // if (!fields["programCode"] || (fields["programCode"] === "Select Course Group")) {
    //    formIsValid = false;
    //   errors["programCode"] = "You must selct Program";
    // }


     // course code
      if (!fields["code"] || fields["code"].length < 3 ) {
         formIsValid = false;
          errors["code"] = 'Class code must not be empty or less than three characters';
       }
       //  alert('4...:'+formIsValid);

     // course name
      if (!fields["name"] || fields["name"].length < 3 ) {
         formIsValid = false;
          errors["name"] = 'Class name must not be empty or less than three characters';
       }
      // alert('5...:'+ formIsValid);

     // course description
      if (!fields["description"] || fields["description"].length < 6 ) {
         formIsValid = false;
          errors["description"] = 'Class description must not be empty or less than six characters';
       }
       //alert('6...:'+formIsValid);

      // course duration
       if (!fields["duration"] ) {
          formIsValid = false;
           errors["duration"] = 'Class duration must not be empty';
      }
       //alert('7...:'+ formIsValid);

     this.setState({ errors: errors });
       //alert('8...:'+formIsValid);
      //alert('formIsValid...:'+ formIsValid);
     return formIsValid;
   }

   handleChange(field, e) {

    console.log('handleChange(field, e) - this.state : entry');

       let fields = this.state.fields;
       if('checkbox' === e.target.type) {
             fields[field] =  e.target.checked;
             //alert('event.target.type:'+ e.target.type);
             //alert('e.target.checked:'+ e.target.checked);
         }
         else {
             fields[field] = e.target.value;
         }
      this.setState({ fields });
       console.log('handleChange(field, e) - this.state : final');
      //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
   }

 saveProgram(e) {

         e.preventDefault();

         if (!this.handleValidation()) {
             console.log('saveProgram invoked; invalid form ...');
              return;
         }

         console.log('saveProgram invoked ...');

         let data = {
           code: this.state.fields["code"],
           name: this.state.fields["name"],
           description: this.state.fields["description"],
           duration: this.state.fields["duration"],
           featured: this.state.fields["featured"]
         };

          // alert('data: '+ JSON.stringify(data));
          // return;
          // console.log('saveProgram() JSON.stringify(data): ' + data);
          // console.log('saveCourse(field, e) - this.state :'+ JSON.stringify(this.state));

         ProgramService.saveProgram(data)
           .then(response => {
             //this.setState({
             //  successful: true
             //});
            toast.success('🦄 Program saved successfully', {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      });

               console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
           })
           .catch(e => {
             console.log(e);
              this.setState({
                   successful: false,
                 });

                toast.error('🦄 Error while saving program ...'+ e.message, {
                                  position: "top-center",
                                  autoClose: 5000,
                                  hideProgressBar: false,
                                  closeOnClick: true,
                                  pauseOnHover: true,
                                  draggable: true,
                                  progress: undefined,
                                  });
           });
 }


  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">




        <div className="formContainerDiv">

         <fieldset>
                 <legend>Create Training Program</legend>


          <Form
            onSubmit={this.handleCreate}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>

              <div className="form-group">
                            <label htmlFor="code">Program Code</label>
                            <Input
                              type="text"
                              className="form-control"
                              name="code"
                              onChange={this.handleChange.bind(this, "code")}
                              value={this.state.fields["code"]}
                            />
              </div>
                <div className="form-group">
                  <label htmlFor="name">Program Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="name"
                    onChange={this.handleChange.bind(this, "name")}
                    value={this.state.fields["name"]}
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="description">Description</label>
                  <Input
                    type="text"
                    className="form-control"
                    onChange={this.handleChange.bind(this, "description")}
                    value={this.state.fields["description"]}
                  />
                </div>
                 <div className="form-group">
                      <label htmlFor="duration">Duration</label>
                      <Input
                        type="duration"
                        className="form-control"
                        name="duration"
                        onChange={this.handleChange.bind(this, "duration")}
                        value={this.state.fields["duration"]}
                      />
                </div>

                <div class="form-group">
                    <ToastContainer />
                </div>

                <div className="field-submit">
                    <span style={{margin: "10px"}} className="submit-span">
                        <button onClick={this.saveProgram.bind(this)} >Create Program</button>
                   </span>

                   <span style={{margin: "10px"}} className="submit-span">
                             <button>Cancel</button>
                    </span>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(CreateProgram);
