import http from "../../http-common";
import axios from "axios";

class BlogService {

saveBlog(data, file) {

    /*console.log('blog data:'+data);

    let user = JSON.parse(localStorage.getItem('user'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);

    if(user && user.accessToken) {
        token = user.accessToken;
      }

   console.log('Bearer ${token}: '+ `Bearer ${token}` );
   console.log('Bearer ${token} 2: '+ "Bearer " +token);

    return http.post("/collection/blog/save", data, {
          headers: {
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${token}`
          }
        });*/


        console.log('data:'+data);
            console.log('file:'+file);

            let formData = new FormData();

            formData.append("tag", data.tag);
            formData.append("title", data.title);
            formData.append("content", data.content);
            if(data.collectionTypeCd) {
                 formData.append("collectionTypeCd", data.collectionTypeCd);
            }

            formData.append("file", file);

            // if list of files - handle same way also if in backend
            //for(let i = 0; i< e.target.files.length; i++) {
            //            formData.append('file', e.target.files[i])
            //   }
            console.log('data.collectionTypeCd - formData:'+data.collectionTypeCd);
            console.log('formData:'+formData);

            // get user token
            let user = JSON.parse(localStorage.getItem('user'));
            console.log('user token: '+ JSON.stringify(user));
            let token = JSON.stringify(user);
            if(user && user.accessToken) {
                token = user.accessToken;
             }

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                'Authorization' : `Bearer ${token}`
                }
            }

             // works
            return http.post("/collection/blog/save", formData, config);

  }

     getCategories = async () =>  {
            return http.get('/collection/blog/categories/all');
     }


   getAllBlogs = async () =>  {
            return http.get('/collection/blog/all');
   }

  getRecentBlogs = async () =>  {
            return http.get('/collection/blog/recent');
   }

    getFeaturedBlogs = async () =>  {
       return http.get('/collection/blog/featured');
     }


    fetchBlogsByCategory(blogCategoryCd) {
        return http.get('/collection/blog/category/'+blogCategoryCd);
    }


    fetchBlogsByTag(tag) {
        return http.get('/collection/blog/tag/${tag}');
    }

    fetchBlogsBySearchString(searchString) {
    return http.get('/collection/blog/search/${searchString}');
    }

    fetchBlogsBySearchSpecification(searchSpecification) {
    return http.post("/collection/blog/specificationFetch", searchSpecification, {
          headers: {
            "Content-Type": "application/json"
          }
        });
    }


   getPageableAll(params) {
           return http.get("/collection/blog/pageable/all", { params });
   }


 }

export default new BlogService();
