
import React, { useState, useEffect} from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import DatePickerField from '../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

 import { Formik, Field, Form, FieldArray, useFormikContext,ErrorMessage } from 'formik';
 import * as Yup from 'yup';

import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";
import StudentService  from "../../services/StudentService";
import ClassService  from "../../services/ClassService";

import FormitLogger  from "../../common/FormitLogger";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom'; // TODO - UPDATE TO THIS
import { useHistory } from "react-router-dom";


// https://fkhadra.github.io/react-toastify/api/toast
// https://fkhadra.github.io/react-toastify/introduction

const toastOptions = {
    //onOpen: props => console.log(props.foo),
    //onClose: props => console.log(props.foo),
    autoClose: 500,
    closeButton: null,
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
    transition: null,
    progress: 0.2
    // and so on ...
};


export default function ClassRollCallLanding() {


    const history = useHistory();
   // const navigate = useNavigate();

    const [isLoading, SetLoading] = useState(false);
    const [classSelectItemsList, setClassSelectItemsList] = useState([]);
    const [classList, setClassList] = useState({
                                               courseCode : "JAVA131",
                                               effectiveDate : "02-22-2023",
                                               classList: [
                                                     {
                                                     id: "1",
                                                     studentId: "1",
                                                     firstName: 'Johny - for my update',
                                                     middleName: 'Dandy Johny - for my update',
                                                     lastName: 'Igwe Johny - for my update',
                                                     isPresent: false
                                                     }
                                               ]
                                               }
                                             );


        useEffect(() => {
           fetchClasses();
         }, []);

       /*  const loadClassList = async () => {
                   StudentService.getAllStudents().then(
                     response => {
                     //setClassList(esponse.data);
                     console.log("xx classList xx : "+ JSON.stringify(response.data));
                     },
                 error => {
                   console.log("error.toString(): "+ error);
                 }
                );
            };
*/
        const fetchClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched => : "+ JSON.stringify(response.data));
                 setClassSelectItemsList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };

/*
     validationSchema={Yup.object().shape({
                       friends: Yup.array()
                         .of(
                           Yup.object().shape({
                             name: Yup.string().min(4, 'too short').required('Required'), // these constraints take precedence
                             salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                           })
                         )
                         .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
                         .min(3, 'Minimum of 3 friends'),
                     })}
                */


    // https://stackoverflow.com/questions/42173786/react-router-pass-data-when-navigating-programmatically
    // https://stackoverflow.com/questions/71284510/how-to-pass-props-get-persist-using-history-push

    function goToRecordAttendance1() {
       //history.push("/classRollCallSaveNew", state: classList);

       /*  let rollCallRequest = {};
         rollCallRequest.classCode = values.classCode;
         rollCallRequest.effectiveDate = values.effectiveDate;

           alert('rollCallRequest:'+ JSON.stringify(rollCallRequest, null, 2));

         ClassService.fetchRollCall(rollCallRequest).then(
                    response => {
                      setClassList(response.data);
                      console.log("classList: "+ JSON.stringify(response.data));
                    },
                error => {
                  console.log("error.toString(): "+ error.toString());
                }
               );*/

      /* history.push({
                  pathname: '/classRollCall',
                  state: classList
              });
       */
        // navigate('/classRollCallSaveNew', {state: classList})
    }

  return (

        <Container  component="main" >
         <ToastContainer />
         <div>

               <Typography  component="h1" variant="h5">
                Class RollCall
               </Typography>

                <Formik

                        initialValues={{
                        classCode : "JAVA131",
                        effectiveDate : "02-22-2023",
                        classList: [
                                        {
                                        id: "1",
                                        studentId: "1",
                                        firstName: 'John',
                                        middleName: 'Dandy',
                                        lastName: 'Igwe',
                                        isPresent: false
                                        }
                                     ]
                                     }}

                        validationSchema={Yup.object().shape({

                        })}



                   onSubmit={(values, { setSubmitting }) => {

                     /*setTimeout(() => {
                        alert(JSON.stringify(values, null, 2));
                        //if(values.)
                        let syllabusRequestRequest = {};
                        syllabusRequestRequest.courseCode = values.courseCode;
                        syllabusRequestRequest.classList = JSON.stringify(values, null, 2);

                       setSubmitting(false);
                     }, 400);*/

                    setTimeout(() => {
                        let rollCallRequest = {};
                        rollCallRequest.classCode = values.classCode;
                        rollCallRequest.effectiveDate = new Date(values.effectiveDate);

                       // alert('<< rollCallRequest >>:'+ JSON.stringify(rollCallRequest, null, 2));

                        ClassService.fetchRollCall(rollCallRequest).then(
                            response => {
                              setClassList(response.data);
                               console.log("ClassService.fetchRollCall ... - classList: "+ JSON.stringify(response.data));
                               console.log("classList ... - classList: "+ JSON.stringify(classList));

                               //use this once the backend is ready with the data structure
                                history.push({
                                        pathname: '/classRollCall',
                                         state: response.data
                                      });
                            },
                        error => {
                          console.log("error.toString(): "+ error.toString());
                        }
                        );

                setSubmitting(false);
             }, 400);
           }
          }


        render={({ values }) => (

             <Form>

              <FormitLogger />

              <Grid container spacing={3}>
                <Grid item xs={6} sm={3}>
                    <SelectField
                       name="classCode"
                       label="Select A Class"
                       data={classSelectItemsList}
                       fullWidth
                     />
                    <ErrorMessage name="classCode" />
                </Grid>
                <Grid item xs={6} sm={3}>

                     <DatePickerField
                        name="effectiveDate"
                        label="Effective Date"
                        margin="normal"
                        disableFuture
                        inputFormat="yyyy-MM-dd"
                        fullWidth
                      />

                    <ErrorMessage name="effectiveDate" />


                </Grid>
             </Grid>
             <br />






               <br />
               <br />
                <div className="container">
                            <span style={{margin: "10px"}}>
                               <button type="submit" >Fetch Class List</button>
                           </span>
               </div>

                <br />
                <br />

            </Form>
           )}
  />

 </div>
</Container>
);

}

