import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
} from "./types";

import AuthService from "../services/auth.service";

export const register = (username,firstName,lastName, email, password, realm) => (dispatch) => {

/*
    Actions
    An action is a plain JavaScript object that has a type field. You can think of an action as an event that describes something that happened in the application.

    Reducers
    A reducer is a function that receives the current state and an action object, decides how to update the state if necessary,
    and returns the new state: (state, action) => newState. You can think of a reducer as an event listener which handles events based on
    the received action (event) type.

    Store
    The current Redux application state lives in an object called the store .
    The store is created by passing in a reducer, and has a method called getState that returns the current state value

    Dispatch
    The Redux store has a method called dispatch. The only way to update the state is to call store.dispatch() and pass in an action object.
    The store will run its reducer function and save the new state value inside, and we can call getState()
    You can think of dispatching actions as "triggering an event" in the application. Something happened, and we want the store to know about it. Reducers act like event listeners, and when they hear an action they are interested in, they update the state in response.

Dispatching

https://react-redux.js.org/using-react-redux/connect-mapdispatch

Connect: Dispatching Actions with mapDispatchToProps
As the second argument passed in to connect, mapDispatchToProps is used for dispatching actions to the store.

dispatch is a function of the Redux store. You call store.dispatch to dispatch an action. This is the only way to trigger a state change.

With React Redux, your components never access the store directly - connect does it for you. React Redux gives you two ways to let components dispatch actions:

By default, a connected component receives props.dispatch and can dispatch actions itself.
connect can accept an argument called mapDispatchToProps, which lets you create functions that dispatch when called, and pass those functions as props to your component.
The mapDispatchToProps functions are normally referred to as mapDispatch for short, but the actual variable name used can be whatever you want.

Approaches for Dispatching
Default: dispatch as a Prop
If you don't specify the second argument to connect(), your component will receive dispatch by default. For example:

connect()(MyComponent)
// which is equivalent with
connect(null, null)(MyComponent)

// or
//connect(mapStateToProps ,no second argument )(MyComponent)

//Once you have connected your component in this way, your component receives props.dispatch. You may use it to dispatch actions to
// the store.


*/

  return AuthService.register(username, firstName, lastName, email, password, realm).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (username, password) => (dispatch) => {

  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};


export const authenticateUser = (username, password) => (dispatch) => {

  return AuthService.authenticateUser(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: LOGOUT,
  });
};
