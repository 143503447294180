import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class ProgramService {

// https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios

saveProgram(data) {

    console.log('Called ProgramService.saveProgram() ...');

    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/training-program/save', data, config);
 }


getAllPrograms() {

     console.log('Called ProgramService.getAllPrograms() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.get('/bs/training-program/all-programs', config);
 }


  getProgramByCode(code) {
       let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
         console.log('user token: '+ JSON.stringify(user));
        // let token = JSON.stringify(user);
        let token;
         if(user && user.accessToken) {
             token = user.accessToken;
          }

         // headers
         const config = {
             headers: {
             'Authorization' : `Bearer ${token}`
             }
         }
        return http.get('/bs/training-program/'+code, config);
}

}

export default new ProgramService();