
import React from "react";
import "./modal.css";


class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      toggle: false
    }
  }

  toggle(event) {
    this.setState((prevState) => ({
      toggle: !prevState.toggle
    }));
  }
  render() {

const mystyle = {
      color: "white",
      backgroundColor: "DodgerBlue",
      padding: "10px",
      fontFamily: "Arial"
    };

  const display = {
    display: 'block',
    margin: '25%',
    zIndex: '1',
    width: '40%',
    height: '40%',

/*      padding: '25%',
      textAlign: 'center',
      backGround: '#1abc9c',
      color: 'white',
      fontSize: '30px',
      width: '40%',
      height: '40%',*/

  };
  const hide = {
      display: 'none',
     margin: '25%',
      zIndex: '1',
      width: '40%',
      height: '40%'

/*        padding: '25%',
        textAlign: 'center',
        backGround: '#1abc9c',
        color: 'white',
        fontSize: '30px',
        width: '40%',
        height: '40%',*/
  };




    var modal = [];
    modal.push(
      <div className="modal" style={this.state.toggle ? display : hide}>
          <div className="modal-content">
             <span className="tag-remove">&times;</span>
             <h6>Huge Collection - Message</h6>
          </div>
          <div className="modal-body">
             <p>Record saved successfully</p>
             <p>Thank you</p>
          </div>
          <div className="modal-footer">
            <h6>Modal Footer</h6>
            <a className="btn-flat" onClick={this.toggle}> Ok </a>

                <button className="yes">okay</button>
                <button className="no">Unsubscribe</button>
          </div>
      </div>
    );
    return (
      <div>
        <a className="btn" onClick={this.toggle}>{this.state.toggle ? 'Close modal' : 'OPEN modal'}</a>
        {modal}
      </div>
    );


  }
}
export default Modal;