import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import styles from './evaluation.module.css'

import EvaluationService from '../../../services/EvaluationService';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Formik, Form, Field, FieldArray, ErrorMessage  } from 'formik';
import FormitLogger  from "../../../common/FormitLogger";
import ExamTimer from "./ExamTimer.component";
import StopWatch from "./examtimer/StopWatch";


const examTypes = [
  {
    code: 'T',
    label: 'Test'
  },
  {
    code: 'E',
    label: 'Examination'
  }
];





const ExamTake = () =>    {

            const [flow, setFlow] = React.useState('');

            const [error, setError] = React.useState(false);
            const [helperText, setHelperText] = React.useState('');

          const handleFlowRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
           // setValue((event.target as HTMLInputElement).value);
            setFlow(event.target.value);
            setHelperText(' ');
            setError(false);
          };

    //================

 /*    const [exam, setExam] = useState({
                                       "examId" : "",
                                       "examCodeForSearch": null,
                                       "examCode": "",
                                       "examDescription": "",
                                       "classCode": null,
                                       "type": "",
                                       "score": "",
                                       "timeLimitSec": "" ,
                                       "attemptsAllowed": "",
                                       "startsDate": "",
                                       "endsDate": ""
                                     });*/

 const [exam, setExam] = React.useState( {
                                             "examId" : null,
                                             "examCodeForSearch": null,
                                             "examCode": "",
                                             "examDescription": "",
                                             "classCode": null,
                                             "type": "",
                                             "score": "",
                                             "timeLimitSec": "" ,
                                             "attemptsAllowed": "",
                                             "startsDate": "",
                                             "endsDate": ""
                                           });
  const [questionsList, setQuestionsList] = useState([{
                                                               questionId: "",
                                                               questionSN: "",
                                                               questionText : "",

                                                               answerAlphabetALabel: "A",
                                                               answerAText : "" ,
                                                               isACorrectChoice : "false" ,

                                                               answerAlphabetBLabel: "B",
                                                               answerBText : "" ,
                                                               isBCorrectChoice : "false" ,

                                                               answerAlphabetCLabel: "C",
                                                               answerCText : "" ,
                                                               isCCorrectChoice : "false" ,

                                                               answerAlphabetDLabel: "D",
                                                               answerDText : "" ,
                                                               isDCorrectChoice : "false" ,

                                                               answerAlphabetELabel: "E",
                                                               answerEText : "" ,
                                                               isECorrectChoice : "false"
                                                             }]);

    function lookupExam(examCode) {

          // e.preventDefault();

           console.log('lookupExam called ... ');

           alert('examCode: '+examCode)
           let examSetupRequest = {
                               "exam" : {
                                    "examCode": examCode
                               }
                          }

         alert('examSetupRequest : '+JSON.stringify(examSetupRequest, null, 2));
        // examCodeForSearch
         console.log('examSetupRequest called ..: JSON.stringify(examSetupRequest) - from UI : ' +JSON.stringify(examSetupRequest));

         EvaluationService.lookupExamSetup( examSetupRequest )
                  .then(response => {

                      let respData = response.data;
                      alert('response.data : '+JSON.stringify(respData, null, 2));
                      setQuestionsList(questionsList => ([...questionsList, ...respData.questionAndAnswerList]));

                     setExam(prevExam => ({
                            ...prevExam,
                            ...respData.exam
                        }
                    ))


                    // alert('questionsList : '+JSON.stringify(questionsList, null, 2));
                    alert('exam : '+JSON.stringify(exam, null, 2));
                    // setExam(exam => ([...exam, ...response.data.exam]));

                   toast.success('🦄 Exam setup fetched successfully', {
                             position: "top-center",
                             autoClose: 5000,
                             hideProgressBar: false,
                             closeOnClick: true,
                             pauseOnHover: true,
                             draggable: true,
                             progress: undefined,
                             });
                      console.log('Exam setup fetched response: JSON.stringify(response.data): ' +JSON.stringify(response.data));
                  })
                  .catch(e => {
                    console.log(e);
                    toast.error('🦄 Error while fetching Exam setup ...'+ e.message, {
                                         position: "top-center",
                                         autoClose: 5000,
                                         hideProgressBar: false,
                                         closeOnClick: true,
                                         pauseOnHover: true,
                                         draggable: true,
                                         progress: undefined,
                                         });
                  });

        alert('questionsList 2 : '+JSON.stringify(questionsList, null, 2));
    }


function renderNewExamBaseInfo() {

 return (
    <div>
    <Grid container spacing={2}>
           <Grid item sm={12}>
                <label>Exam Meta Data</label>
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examCode" label="Exam Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examDescription" label="Exam Description" fullWidth />
            </Grid>
            <Grid item sm={3}>
                 <InputField name="classCode" label="Class Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                  <SelectField
                        name="type"
                        label="Type"
                        data={examTypes}
                        fullWidth
                      />
            </Grid>
            <Grid item sm={3}>
                  <InputField name="score" label="Maximum Score" fullWidth />
             </Grid>
            <Grid item sm={3}>
                    <InputField name="timeLimitSec" label="Time Limit(Min)" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="attemptsAllowed" label="Attempts Allowed" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="startsDate" label="Start Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="endsDate" label="End Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
          </Grid>

    </div>
    );

}


function renderExamSearchView(values) {

     return (
        <div>
             <Grid container>
                <Grid item sm={12}>
                      <label>Search Term</label>
                </Grid>
                <Grid item sm={12}>
                        <InputField name="examCodeForSearch" label="Exam Code" />
                </Grid>


                    <Grid item xs={12} sm={6}>
                            <Button id="lookupExamButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined"

                            onClick={(e) => {
                                  lookupExam(e, values.examCodeForSearch);
                               }}
                            >
                                Lookup Exam Setup
                            </Button>

                    </Grid>

                <br />
                <br />
              </Grid>

        </div>
        );

}

// call  { generateQuestionComponent() }
function generateQuestionComponent(values) {

  return (

    <div>

         <FieldArray
                               name="questionsList"

                               render={arrayHelpers => (

                                       <div>

                                            <div>
                                            {
                                             values.questionsList && values.questionsList.length > 0 &&
                                             values.questionsList.map((element, index) => (

                                             <div className={styles.tableDisplay}  key={index} >
                                                    <div className={styles.tableDisplay}>
                                                        <div className={styles.tableRowDisplay}>
                                                           <div className={styles.tableCellDisplay} >
                                                                <label>Question No</label>
                                                                 <span style={{margin: "3px"}}></span>
                                                                <label  name={`questionsList[${index}].questionSN`}  value={ index + 1}  >{index + 1}</label>
                                                                <div> {element.questionSN = index + 1}</div>
                                                                <label  name={`questionsList[${index}].questionId`}   ></label>
                                                            </div>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                        </div>
                                                     </div>

                                                    <div className={styles.tableDisplay}>
                                                         <div className={styles.tableRowDisplay}>
                                                             <span className={styles.tableCellDisplay} >
                                                                Question Text
                                                             </span>
                                                              <span className={styles.tableCellDisplay} >

                                                                  <InputField
                                                                    name={`questionsList[${index}].questionText`}
                                                                    className={styles.inPutBox}
                                                                  />
                                                              </span>

                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                         </div>
                                                     </div>

                                                    <div className={styles.tableDisplay}>
                                                        <div className={styles.tableRowDisplay}>
                                                            <span className={styles.tableCellDisplay} >
                                                                Answer Options
                                                            </span>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                           <span className={styles.tableCellDisplay} >
                                                           </span>

                                                            <span className={styles.tableCellDisplay}  >
                                                                <label >Is correct option ?</label>
                                                            </span>

                                                       </div>
                                                    </div>


                                                     <div className={styles.tableDisplay}>

                                                       <div className={styles.tableRowDisplay}>
                                                            <div className={styles.tableCellDisplay} >
                                                                <label  name={`questionsList[${index}].answerAlphabetALabel`}  > (A) </label>
                                                            </div>

                                                           <div className={styles.tableCellDisplay} >
                                                                <InputField
                                                                    name={`questionsList[${index}].answerAText`}
                                                                     className={styles.inPutBox}
                                                                />
                                                           </div>
                                                           <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                          </div>
                                                           <div className={styles.tableCellDisplay} >
                                                                <CheckboxField
                                                                    name={`questionsList[${index}].isACorrectChoice`}
                                                                    defaultChecked={false}
                                                                 />
                                                           </div>
                                                      </div>

                                                      <div className={styles.tableRowDisplay}>
                                                                     <div className={styles.tableCellDisplay} >
                                                                          <label  name={`questionsList[${index}].answerAlphabetBLabel`} > (B) </label>
                                                                     </div>

                                                                    <div className={styles.tableCellDisplay} >
                                                                         <InputField
                                                                              name={`questionsList[${index}].answerBText`}
                                                                              className={styles.inPutBox}
                                                                         />
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} >
                                                                         <CheckboxField
                                                                              name={`questionsList[${index}].isBCorrectChoice`}
                                                                               defaultChecked={false}
                                                                          />
                                                                    </div>
                                                      </div>


                                                     <div className={styles.tableRowDisplay}>
                                                               <div className={styles.tableCellDisplay} >
                                                                   <label  name={`questionsList[${index}].answerAlphabetCLabel`}  > (C) </label>
                                                               </div>

                                                              <div className={styles.tableCellDisplay} >
                                                                   <InputField
                                                                        name={`questionsList[${index}].answerCText`}
                                                                        className={styles.inPutBox}
                                                                   />
                                                              </div>
                                                              <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                               </div>
                                                              <div className={styles.tableCellDisplay} >
                                                                   <CheckboxField
                                                                        name={`questionsList[${index}].isCCorrectChoice`}
                                                                        defaultChecked={false}
                                                                    />
                                                              </div>
                                                    </div>

                                                    <div className={styles.tableRowDisplay}>
                                                                     <div className={styles.tableCellDisplay} >
                                                                        <label  name={`questionsList[${index}].answerAlphabetDLabel`} > (D) </label>
                                                                     </div>

                                                                    <div className={styles.tableCellDisplay} >
                                                                         <InputField
                                                                                 name={`questionsList[${index}].answerDText`}
                                                                              className={styles.inPutBox}
                                                                         />
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                     </div>
                                                                    <div className={styles.tableCellDisplay} >
                                                                         <CheckboxField
                                                                                name={`questionsList[${index}].isDCorrectChoice`}
                                                                               defaultChecked={false}
                                                                          />
                                                                    </div>
                                                    </div>

                                                     <div className={styles.tableRowDisplay}>
                                                                   <div className={styles.tableCellDisplay} >
                                                                         <label name={`questionsList[${index}].answerAlphabetELabel`} > (E) </label>
                                                                   </div>

                                                                  <div className={styles.tableCellDisplay} >
                                                                       <InputField
                                                                             name={`questionsList[${index}].answerEText`}
                                                                            className={styles.inPutBox}
                                                                       />
                                                                  </div>
                                                                  <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                  </div>
                                                                  <div className={styles.tableCellDisplay} >
                                                                       <CheckboxField
                                                                             name={`questionsList[${index}].isECorrectChoice`}
                                                                             defaultChecked={false}
                                                                        />
                                                                  </div>
                                                     </div>

                                                 </div>

                                           <Grid>

                                              <button
                                                type="button"
                                                onClick={() => arrayHelpers.remove(index)}
                                               >
                                                -
                                              </button>



                                              <button
                                                type="button"
                                            onClick={() => arrayHelpers.insert(index, {
                                                    questionId: "",
                                                    questionSN: "",
                                                    questionText : "",

                                                    answerAlphabetALabel: "A",
                                                    answerAText : "" ,
                                                    isACorrectChoice : "false" ,

                                                    answerAlphabetBLabel: "B",
                                                    answerBText : "" ,
                                                    isBCorrectChoice : "false" ,

                                                    answerAlphabetCLabel: "C",
                                                    answerCText : "" ,
                                                    isCCorrectChoice : "false" ,

                                                    answerAlphabetDLabel: "D",
                                                    answerDText : "" ,
                                                    isDCorrectChoice : "false" ,

                                                    answerAlphabetELabel: "E",
                                                    answerEText : "" ,
                                                    isECorrectChoice : "false"
                                          })}
                                              >
                                                +
                                              </button>

                                        </Grid>

                                         </div>


                                         )
                                         )}



                                </div>


                            </div>

                              )

                              }

                         />


               </div>

    );
  }


  return (
    <Container  component="main" maxWidth="xl">
      <ToastContainer />
      <div>

            <Typography  component="h3"  gutterBottom variant="h3" component="div">
              <Grid item sm={12}>
                  Desmond Examination & Evaluation System(D-EES)
              </Grid>
            </Typography>

            <Typography  component="h3"  gutterBottom variant="h3" component="div">
                          <Grid item sm={12}>
                               <StopWatch />
                          </Grid>
            </Typography>

       <Formik

                       initialValues={{
                                       questionsList: questionsList
                                      }}

                        enableReinitialize={true}

                          onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {

                               alert('values: DDDD'+JSON.stringify(values, null, 2));

                               let questionAndAnswerList = [];

                               values.questionsList.forEach(function (item, index) {
                                      console.log(item, index);

                                      let qtnAndAns = {
                                         "questionId": item.questionId,
                                        "questionSN": item.questionSN,
                                        "questionText": item.questionText,

                                        "answerAlphabetALabel": item.answerAlphabetALabel,
                                        "answerAText": item.answerAText,
                                        "isACorrectChoice": item.isACorrectChoice,

                                        "answerAlphabetBLabel": item.answerAlphabetBLabel,
                                        "answerBText": item.answerBText,
                                        "isBCorrectChoice": item.isBCorrectChoice,

                                        "answerAlphabetCLabel": item.answerClphabetCLabel,
                                        "answerCText": item.answerCText,
                                        "isCCorrectChoice": item.isCCorrectChoice,

                                        "answerAlphabetDLabel": item.answerAlphabetDLabel,
                                        "answerDText": item.answerDText,
                                        "isDCorrectChoice": item.isDCorrectChoice,

                                        "answerAlphabetELabel": item.answerAlphabetELabel,
                                        "answerEText": item.answerEText,
                                        "isECorrectChoice": item.isECorrectChoice
                                  }
                                  questionAndAnswerList.push(qtnAndAns);

                               });

                               let examSetupRequest = {
                                       "exam" : {
                                            "examId" : exam.examId,
                                            "examCodeForSearch": values.examCodeForSearch,
                                            "examCode": exam.examCode,
                                            "examDescription": values.examDescription,
                                            "classCode": values.classCode,
                                            "type": values.type,
                                            "score": values.score,
                                            "timeLimitSec": values.timeLimitSec,
                                            "attemptsAllowed": values.attemptsAllowed,
                                            "startsDate": values.startsDate,
                                            "endsDate": values.endsDate
                                       },
                                       "questionAndAnswerList" : questionAndAnswerList

                               }

                                 alert('examSetupRequest T: '+ JSON.stringify(examSetupRequest, null, 2));
                                 alert("examId: "+exam.examId);

                                 EvaluationService.saveExamTake(examSetupRequest).then(
                                       response => {
                                         toast.success('Exam Take saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                         console.log("Exam Take saved successfully ...: "+ JSON.stringify(response));
                                       },
                                   error => {
                                     toast.error('Error while saving Exam Take', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error saving Exam Take [error.toString()] : "+ error.toString());
                                   }
                                  );


                              setSubmitting(false);
                            }, 400);
                          }}



       render={({ values }) => (

         <Form>

              <FormitLogger />

                <FormControl sx={{ m: 3 }} error={error} variant="standard">
                  <FormLabel id="demo-error-radios">How do you want to proceed?</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-error-radios"
                    name="flowgroup"
                    value={flow}
                    onChange={handleFlowRadioChange}>

                    <FormControlLabel value="SearchExam" control={<Radio />} label="Lookup Existing Exam" />

                  </RadioGroup>
                  <FormHelperText>{helperText}</FormHelperText>


                </FormControl>

                <div>
                    <hr style={{color: "gray",backgroundColor: "gray",height: "5px"}} />
                </div>

                <div>
                    { flow === "SearchExam" ? renderExamSearchView(values):  null }
                </div>

                <br />
                <br />
                <br />
                <br />

                <div>
                    <hr style={{color: "gray",backgroundColor: "gray",height: "5px"}} />
                    <label>Questions</label>
                </div>

                {   generateQuestionComponent(values) }



            <Grid item xs={12} sm={6}>
                    <Button id="submitButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                        Submit Exam
                    </Button>

                    <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                        Generate Result
                    </Button>
            </Grid>
            <br />
            <br />

          </Form>
           )}

         />

      </div>
    </Container>
  )
}

export default ExamTake

