import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
import { register } from "../../actions/auth";
import Pagination from "@material-ui/lab/Pagination";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik } from 'formik';

import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import DatePickerField from '../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};


class RecordedAttendanceMgt extends Component {

  constructor(props) {
     super(props);
     this.handleCreate = this.handleCreate.bind(this);
     this.setCoursePlannedStartDate = this.setCoursePlannedStartDate.bind(this);
     this.handlePageChange = this.handlePageChange.bind(this);
     this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      coursePlannedStartDate: null,
      studentsRollCall : {},

      successful: false,
      collections: [],
      page: 1,
      count: 0,
      pageSize: 10,
    };
    this.pageSizes = [10, 20, 30];

  }


componentDidMount() {


    let stdsRollCallData = {

                   "rollCall" : {

                            "attendanceDate" : "05/03/2023",
                            "classId" : "203",
                            "classList" :  [

                                                {
                                                    "SN" : "1",
                                                    "studentId" : "576868693334",
                                                    "firstName" : "Edet",
                                                    "middleName" : "Okon",
                                                    "lastName" : "Obong",
                                                    "isPresent" : "true"
                                                },

                                                {
                                                    "SN" : "2",
                                                    "studentId" : "89868693311",
                                                    "firstName" : "John",
                                                    "middleName" : "Peter",
                                                    "lastName" : "Ruffai",
                                                    "isPresent" : "true"
                                                },
                                                {
                                                    "SN" : "3",
                                                    "studentId" : "576868693334",
                                                    "firstName" : "Ike",
                                                    "middleName" : "Ugwu",
                                                    "lastName" : "Ohia",
                                                    "isPresent" : "false"
                                                }

                                          ]
                    }

                }

               this.setState({
                  studentsRollCall: stdsRollCallData
                },() => {
                     // ensure state data stetting happens when data read from file has fully loaded
                     this.loadRollCallData(stdsRollCallData);
                 });

}



loadRollCallData(data) {

            this.setState({
                  studentsRollCall: data,
                },() => {

               });
}



setCoursePlannedStartDate(date)  {
        this.setState({
                coursePlannedStartDate: date
              });
 }

handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveCollections();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveCollections();
      }
    );
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.courseName, this.state.courseDescription, this.state.courseGroup, this.state.duration, this.state.deletedFlag, 'REALM_BYTES_STEROID')
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

handleNext(values, actions) {

}


  render() {

    const { message } = this.props;
    console.log('this.state.studentsRollCall: => ' +JSON.stringify(this.state.studentsRollCall));

     const {
              collectionTypeCd,
              collections,
              currentAsset,
              currentIndex,
              page,
              count,
              pageSize,
            } = this.state;



    return (
          <Container  component="main" >
                <div>
                  <Typography  component="h1" variant="h5">
                     Manage Rollcall
                  </Typography>

                    <Formik
                      initialValues={formInitialValues}
                      onSubmit={this.handleNext} >


                                <Form
                                    onSubmit={this.handleCreate}
                                    ref={(c) => {
                                      this.form = c;
                                    }}
                                  >


                                <div className="form-group">
                                  <label htmlFor="coursePlannedStartDate">Rollcall Date</label>
                                  <span style={{margin: "10px"}}>
                                          <DatePicker
                                            locale="es"
                                            selected={this.state.coursePlannedStartDate}
                                            showTimeSelect
                                            dateFormat="Pp"
                                            showIcon
                                           onChange={(coursePlannedStartDate) => this.setCoursePlannedStartDate(coursePlannedStartDate)}
                                          />
                                  </span>

                                </div>

                                <div>
                                    <span style={{margin: "10px"}}>
                                        Class Id:
                                    </span>

                                    <span style={{margin: "10px"}}>
                                        {null == this.state.studentsRollCall.rollCal? "" :  this.state.studentsRollCall.rollCall.classId}
                                    </span>
                                </div>


                                <div style={{marginLeft : "35%"}}>
                                        {"Items per Page: "}
                                        <select onChange={this.handlePageSizeChange} value={pageSize}>
                                          {this.pageSizes.map((size) => (
                                            <option key={size} value={size}>
                                              {size}
                                            </option>
                                          ))}
                                        </select>
                                        <p />
                                        <Pagination
                                          className="my-3"
                                          count={count}
                                          page={page}
                                          siblingCount={1}
                                          boundaryCount={1}
                                          variant="outlined"
                                          shape="rounded"
                                          onChange={this.handlePageChange}
                                        />
                                </div>

                                 <div class="form-group">
                                      <ToastContainer />
                                </div>

                                <div>

                                { (null != this.state.studentsRollCall.rollCall && this.state.studentsRollCall.rollCall.classList) &&

                                    <table  style={{width: "100%"}}  >
                                     <thead>
                                       <tr>
                                        <th>SN</th>
                                        <th>Student Id</th>
                                        <th>First Name</th>
                                        <th>Middle Name</th>
                                        <th>Last Name</th>
                                        <th>Is Present?</th>
                                       </tr>
                                     </thead>

                                     <tbody>

                                            { this.state.studentsRollCall.rollCall.classList.map((val, key) => {
                                              return (
                                                <tr key={key}>
                                                   <td>{val.SN}</td>
                                                   <td>{val.studentId}</td>
                                                   <td>{val.firstName}</td>
                                                    <td>{val.middleName}</td>
                                                    <td>{val.lastName}</td>
                                                    <td><input type="checkbox"
                                                                   defaultChecked={(val.isPresent === "true")? true : false} /> </td>

                                                    <td><button>Edit</button></td>
                                                    <td><Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">Edit</Button></td>

                                                </tr>
                                              )
                                            })}


                                     </tbody>

                                   </table>

                                }


                                </div>

                                 <p/>

                                 <div className="field-submit">
                                        <span style={{margin: "10px"}} className="submit-span">
                                            <button>Save</button>
                                       </span>

                                       <span style={{margin: "10px"}} className="submit-span">
                                                 <button>Cancel</button>
                                        </span>
                                  </div>

                            <Grid item xs={12} sm={6}>
                              <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                   Cancel
                              </Button>

                              <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                  Reset
                              </Button>

                              <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                   Save
                              </Button>
                            </Grid>

                              </Form>

                            </Formik>
                        </div>
               </Container>
    );

  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(RecordedAttendanceMgt);
