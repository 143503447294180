
import './blog.css';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../components/formfields/InputField';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import MultiSelectField from '../components/formfields/MultiSelectField';
import DatePickerField from '../components/formfields/DatePickerField';
import Pagination from "@material-ui/lab/Pagination";
import Box from "@mui/material/Box";

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import PublicationMgt from "./PublicationMgt";

import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";
import ClassService  from "../services/ClassService";
import FacultyService  from "../services/FacultyService";
import PublicationService  from "../services/PublicationService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";

const courses = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];

        /*-----------------------------------

        Faculty - class Assignment

        - Faculties
        - classes
        - start date

        Assign



        Student - class Assignment

        - Students
        - Classes
        - Payment Receipt
        - Payment Receipt Confirmed
        Enroll

        Student Enrollment

        - Classes
        - Payment Receipt

        - Email would be sent notifying of you of class assignment after confirmation of payment

        */

const ClassFacultyEngSchema = Yup.object().shape({
    classCode: Yup.string()
    .required(`Class Required`),
    coursePlannedStartDate: Yup.date()
    .required(`Planned start date is required`),
    facultyId: Yup
    .number("Must be a number type") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("Faculty is required.") // Sets it as a compulsory field
 });


const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff"
  },
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    }
  },
  blogsContainer: {
    paddingTop: theme.spacing(3)
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3)
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 240
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between"
  },
  author: {
    display: "flex"
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));

export default function BlogHome() {

           const classes = useStyles();
           const [classList, setClassList] = useState([]);
           const [facultyList, setFacultyList] = useState([]);

           const [blogList, setBlogList] = useState([]);
           const [blogTitleSearchStr, setBlogTitleSearchStr] = React.useState('');
           const [page, setPage] = React.useState(1);
           const [pageSize, setPageSize] = React.useState(0);
           const [count, setCount] = React.useState(0);

           const [currentIndex, setCurrentIndex] = React.useState(0);
           const [currentBlog, setCurrentBlog] = React.useState({});

           const [value, setValue] = React.useState('');
           const [error, setError] = React.useState(false);
           const [helperText, setHelperText] = React.useState('');

             useEffect(() => {
                loadClasses();
               }, []);

            const loadClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched: "+ JSON.stringify(response.data));
                 setClassList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };


       useEffect(() => {
                loadFaculties();
        }, []);

        useEffect(() => {
                  retrieveBlogs();
         }, []);


      const loadFaculties = async () => {
             FacultyService.getAllFaculties().then(
           response => {
                      console.log("setFacultyList Fetched: "+ JSON.stringify(response.data));
             setFacultyList(response.data);
           },
           error => {
             console.log("error.toString(): "+ error.toString());
           }
         );
    };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   // setValue((event.target as HTMLInputElement).value);
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };

 function getRequestParams(searchTitle, page, pageSize) {
    let params = {};
    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }
    return params;
  }

 const handlePageChange = (event, value) => {
        setPage(value);
    };

 function retrieveBlogs() {
        const params = getRequestParams(blogTitleSearchStr, page, pageSize);
        PublicationService.getAllBlogs(params)
          .then((response) => {
             const { blogList, totalPages } = response.data;
             setBlogList(blogList);
             setCount(totalPages);
             console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          });
 }

  function setActiveBlog(blog, index) {
        try {
             console.log('current blog title : '+blog.title);
            setCurrentBlog(blog);
            setCurrentIndex(index);
        }
        catch( e) {
        console.log('blog error catch: '+ e);
        }
  }

let pageSizes = [3, 6, 9];
let topBlog = blogList[0];

  return (

        <Container  component="main" >
                <ToastContainer />

                <Box className={classes.hero}>
                   <Box>Uprise Computing Blog</Box>
                </Box>

                <br />
                 <div>
                    <Formik

                      initialValues={{
                                     classCode: '',
                                     facultyId: '',
                                     coursePlannedStartDate: '',
                                     isPrimaryEngagement : '0'
                                   }}

                          onSubmit={(values, { setSubmitting }) => {
                                                              setTimeout(() => {

                                         alert(JSON.stringify(values, null, 2));
                                         let classFacultyEngRequest = {};
                                         classFacultyEngRequest.coursePlannedStartDate = values.coursePlannedStartDate;
                                         classFacultyEngRequest.classCode = values.classCode;
                                         classFacultyEngRequest.facultyId = values.facultyId;
                                         classFacultyEngRequest.isPrimaryEngagement = values.isPrimaryEngagement;

                                           alert('classFacultyEngRequest :'+JSON.stringify(classFacultyEngRequest, null, 2));

                                         FacultyService.saveFacultyClassEngagement(classFacultyEngRequest).then(
                                                 response => {
                                                   toast.success('Faculty-class engagement saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                   console.log("Faculty-class engagement  saved successfully ...: "+ JSON.stringify(response));
                                                 },
                                             error => {
                                              toast.error('Error while saving Faculty-class engagement', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                             }
                                            );

                                    setSubmitting(false);
                                  }, 400);
                                }}

                         validationSchema={ClassFacultyEngSchema}
                      >

                      <Form>

                                     <div>

                                             <div className="row" id="upperContentDiv">

                                                     <Grid container spacing={.2}>
                                                            <Grid item xs={8} sm={8}>
                                                                <InputField
                                                                name="searchTerm"
                                                                label="Enter search term"
                                                                fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4} sm={4}>
                                                               <input type="submit" value="Search" />
                                                            </Grid>
                                                     </Grid>

                                                <div className="card" id="topContentDiv">

                                                        <ul className="list-group">
                                                                {
                                                                  topBlog &&

                                                                    <li>
                                                                      {topBlog.title}
                                                                        <br />
                                                                      {topBlog.content}
                                                                    </li>

                                                                  }
                                                        </ul>
                                                </div>

                                                <div className="rightcolumn" id="topRightSubMenu">

                                                        <div >
                                                          <h3>Categories</h3>
                                                          <div className="fakeimg">Image</div>
                                                          <p>Some text about me in culpa qui officia deserunt mollit anim..</p>
                                                        </div>

                                                        <div >
                                                          <h3>Popular Post</h3>
                                                          <div className="fakeimg">Image</div><br />
                                                          <div className="fakeimg">Image</div><br />
                                                          <div className="fakeimg">Image</div>
                                                        </div>

                                                        <div >
                                                          <h3>Follow Me</h3>
                                                          <p>Some text..</p>
                                                        </div>
                                                    </div>
                                              </div>


                      {
                        blogList &&
                          blogList.map((blog, index) => (

                                            <Grid container spacing={3} id="lowerContentDiv">
                                             <Grid item xs={12} sm={6} md={4}>
                                               <Card className={classes.card}>
                                                 <CardActionArea>
                                                   <CardMedia
                                                     className={classes.media}
                                                     image="https://images.pexels.com/photos/2004161/pexels-photo-2004161.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                                     title="Contemplative Reptile"
                                                   />
                                                   <CardContent>
                                                     <Typography gutterBottom variant="h5" component="h2">
                                                       React useContext
                                                     </Typography>
                                                     <Typography variant="body2" color="textSecondary" component="p">
                                                       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                       across all continents except Antarctica

                                                        {blog.title}

                                                     </Typography>
                                                   </CardContent>
                                                 </CardActionArea>
                                                 <CardActions className={classes.cardActions}>
                                                   <Box className={classes.author}>
                                                     <Avatar src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                                                     <Box ml={2}>
                                                       <Typography variant="subtitle2" component="p">
                                                         Guy Clemons

                                                          {blog.content}

                                                          <Link to={"/bs/resource/blogs/" + blog.id} className="badge badge-warning" >
                                                             Edit
                                                           </Link>

                                                       </Typography>
                                                       <Typography variant="subtitle2" color="textSecondary" component="p">
                                                         May 14, 2020

                                                         <PublicationMgt publicationType="BLG" resourceId={blog.id} />


                                                       </Typography>
                                                     </Box>
                                                   </Box>
                                                   <Box>
                                                     <BookmarkBorderIcon />
                                                   </Box>
                                                 </CardActions>
                                               </Card>
                                             </Grid>

                                             <Grid item xs={12} sm={6} md={4}>
                                               <Card className={classes.card}>
                                                 <CardActionArea>
                                                   <CardMedia
                                                     className={classes.media}
                                                     image="https://images.pexels.com/photos/34600/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                                     title="Contemplative Reptile"
                                                   />
                                                   <CardContent>
                                                     <Typography gutterBottom variant="h5" component="h2">
                                                       React Router
                                                     </Typography>
                                                     <Typography variant="body2" color="textSecondary" component="p">
                                                       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                       across all continents except Antarctica
                                                     </Typography>
                                                   </CardContent>
                                                 </CardActionArea>
                                                 <CardActions className={classes.cardActions}>
                                                   <Box className={classes.author}>
                                                     <Avatar src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2734&q=80" />
                                                     <Box ml={2}>
                                                       <Typography variant="subtitle2" component="p">
                                                         Guy Clemons
                                                       </Typography>
                                                       <Typography variant="subtitle2" color="textSecondary" component="p">
                                                           May 14, 2020

                                                           <PublicationMgt resourceId={blog.id} />

                                                       </Typography>
                                                     </Box>
                                                   </Box>
                                                   <Box>
                                                     <BookmarkBorderIcon />
                                                   </Box>
                                                 </CardActions>
                                               </Card>
                                             </Grid>
                                             <Grid item xs={12} sm={6} md={4}>
                                               <Card className={classes.card}>
                                                 <CardActionArea>
                                                   <CardMedia
                                                     className={classes.media}
                                                     image="https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                                     title="Contemplative Reptile"
                                                   />
                                                   <CardContent>
                                                     <Typography gutterBottom variant="h5" component="h2">
                                                       React useContext
                                                     </Typography>
                                                     <Typography variant="body2" color="textSecondary" component="p">
                                                       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                       across all continents except Antarctica
                                                     </Typography>
                                                   </CardContent>
                                                 </CardActionArea>
                                                 <CardActions className={classes.cardActions}>
                                                   <Box className={classes.author}>
                                                     <Avatar src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" />
                                                     <Box ml={2}>
                                                       <Typography variant="subtitle2" component="p">
                                                         Guy Clemons
                                                       </Typography>
                                                       <Typography variant="subtitle2" color="textSecondary" component="p">
                                                         May 14, 2020

                                                           <PublicationMgt resourceId={blog.id} />

                                                       </Typography>
                                                     </Box>
                                                   </Box>
                                                   <Box>
                                                     <BookmarkBorderIcon />
                                                   </Box>
                                                 </CardActions>
                                               </Card>
                                             </Grid>

                                             <Grid item xs={12} sm={6} md={4}>
                                               <Card className={classes.card}>
                                                 <CardActionArea>
                                                   <CardMedia
                                                     className={classes.media}
                                                     image="https://images.pexels.com/photos/325111/pexels-photo-325111.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                                     title="Contemplative Reptile"
                                                   />
                                                   <CardContent>
                                                     <Typography gutterBottom variant="h5" component="h2">
                                                       React useContext
                                                     </Typography>
                                                     <Typography variant="body2" color="textSecondary" component="p">
                                                       Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                                                       across all continents except Antarctica
                                                     </Typography>
                                                   </CardContent>
                                                 </CardActionArea>
                                                 <CardActions className={classes.cardActions}>
                                                   <Box className={classes.author}>
                                                     <Avatar src="https://images.unsplash.com/photo-1584999734482-0361aecad844?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1100&q=80" />
                                                     <Box ml={2}>
                                                       <Typography variant="subtitle2" component="p">
                                                         Guy Clemons
                                                       </Typography>
                                                       <Typography variant="subtitle2" color="textSecondary" component="p">
                                                         May 14, 2020
                                                       </Typography>
                                                     </Box>
                                                   </Box>
                                                   <Box>
                                                     <BookmarkBorderIcon />
                                                   </Box>
                                                 </CardActions>
                                               </Card>
                                             </Grid>

                                            </Grid>
                        ))}



                                            <br />


                                            <Box
                                               sx={{
                                                       margin: "auto",
                                                       width: "fit-content",
                                                       alignItems: "center",
                                                   }}
                                                   >


                                               <Typography fontSize={32} align="center">

                                                    {"Items Per Page: "}
                                                    <select value={pageSize}>
                                                       {pageSizes.map((size) => (
                                                        <option key={size} value={size}>
                                                          {size}
                                                        </option>
                                                      ))}
                                                    </select>

                                                    <br />

                                                   Page: {page}
                                               </Typography>

                                                 <Pagination
                                                         count={10}
                                                         page={page}
                                                         siblingCount={1}
                                                         boundaryCount={1}
                                                         variant="outlined"
                                                         shape="rounded"
                                                         onChange={handlePageChange}
                                                      />
                                            </Box>


                                            <br />

                                 </div>
                                 <br />

                           </Form>
                    </Formik>

                 </div>
        </Container>
);

}

