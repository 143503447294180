

import http from "../http-common";
import axios from "axios";

class BSCommonService {


getAllCourseGroups() {
                return http.get('/bs/course-groups/all');
     }

getAllCourses() {
                return http.get('/bs/courses/all');
     }


getAllEmployment() {
                return http.get('/bs/employment/all');
     }

getAllTags() {

}



saveContactUs(data) {

          const config = {
                  headers: {
                  }
              }

      return http.post('/bs/messaging/contactusmessage/save', data);
}


}

export default new BSCommonService();
