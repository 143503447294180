import React, { useState } from 'react';
import ExamSetup from './ExamSetup';
import ExamSetupQuestions from './ExamSetupQuestions';
import ExamSetupConfirmation from './ExamSetupConfirmation';
import Success from './Success';
import UserSchema from './UserSchema';
import UserFormModel from './UserFormModel';
import UserFormFormitInitValues from './UserFormFormitInitValues';
//import AuthService from "../../../../services/auth.service";

import { Formik, Form, Field } from 'formik';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import useStyles from './styles';

//import StudentService from "../../../../services/StudentService";
//import { register } from "../../../../actions/auth";

const steps = ['Exam Base Info', 'Exam Questions', 'Exam Setup Confirmation', 'Submission'];
const { formId, formField } = UserFormModel;

function _renderStepContent(step, values) {
    switch (step) {
        case 0:
          return <ExamSetup formField={formField} />;
        case 1:
          return <ExamSetupQuestions formField={formField} />;
        case 2:
          return <ExamSetupConfirmation values={ values } />;
        case 3:
            return <Success />;
        default:
          return <div>Not Found</div>;
    }
}


export default function ExamSetupForm(props)  {

        const classes = useStyles();
        const [activeStep, setActiveStep] = useState(0);
        const currentValidationSchema = UserSchema[activeStep];
        const isLastStep = activeStep === steps.length - 1;

        const [values, setValues] = useState("");

        function _sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }

          async function _submitForm(values, actions) {
          await _sleep(1000);
          values.userRoleCode = "ROLE_USER";
          console.log(JSON.stringify(values, null, 2));

          /* AuthService.register(values.userName, values.firstName, values.lastName, values.email, values.password, 'REALM_BYTES_STEROID')
              .then(response => {
                      console.log("response data after user creation: "+  JSON.stringify(response.data));
                   })
                   .catch(e => {
                     console.log(e);
                   });
*/
          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);

        }

        function _handleSubmit(values, actions) {
          if (isLastStep) {
            _submitForm(values, actions);
          } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          }

            setValues(values);
            console.log("values in _handleSubmit function: "+ JSON.stringify(values, null, 2));
        }

        function _handlePrevious() {
          setActiveStep(activeStep - 1);
        }


 return (

     <Container  component="main" >

       <Typography component="h4" variant="h5" align="center">
             Examination Setup
       </Typography>
       <Stepper activeStep={activeStep} className={classes.stepper}>
         {steps.map(label => (
           <Step key={label}>
             <StepLabel>{label}</StepLabel>
           </Step>
         ))}
       </Stepper>

       <React.Fragment>
                {activeStep === steps.length ? (
                   <div>Submission Message (End)</div>
                 ) : (
                   <Formik
                        initialValues={UserFormFormitInitValues}
                        onSubmit={_handleSubmit}
                        validationSchema={currentValidationSchema}
                     >
                     {({ isSubmitting }) => (
                       <Form >
                         {_renderStepContent(activeStep, values)}

                         <div className={classes.buttons}>
                           {activeStep !== 0 && (
                             <Button onClick={_handlePrevious} className={classes.button}>
                               Back
                             </Button>
                           )}
                           <div className={classes.wrapper}>
                             <Button
                               disabled={isSubmitting}
                               type="submit"
                               variant="contained"
                               color="primary"
                               className={classes.button}
                             >
                               {isLastStep ? 'Save' : 'Next'}
                             </Button>
                             {isSubmitting && (
                               <CircularProgress
                                 size={24}
                                 className={classes.buttonProgress}
                               />
                             )}
                           </div>
                         </div>
                       </Form>
                     )}

                   </Formik>
                 )}
      </React.Fragment>
     </Container>
   );

}