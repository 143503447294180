import React, { useState } from "react";
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container, Typography, Grid,
  CircularProgress,
  Box
} from "@material-ui/core";

import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import MultiSelectField from '../../components/formfields/MultiSelectField';
import DatePickerField from '../../components/formfields/DatePickerField';
import { UploaderField }from '../../components/formfields/UploaderField';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import StudentService  from "../../services/StudentService";

import FormitLogger from '../../common/FormitLogger';
const styles = {};

const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT;

function StudentEnrollment(props) {

  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen() {
    setSubmitionCompleted(false);
    setOpen(true);
  }

  return (
    <React.Fragment>

      <ToastContainer />


      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Enroll In Class
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle>
                        <Box display="flex" alignItems="center">
                             <Box flexGrow={1} >Enrollment VVVHN</Box>
                              <Box>
                                  <IconButton onClick={handleClose}>
                                        <CloseIcon />
                                  </IconButton>
                              </Box>
                         </Box>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>Student Class Enrollment</DialogContentText>
              <Formik

                initialValues={{
                    classCode: props.classCode,
                    className : "",
                    registrationAmt: props.classCost,
                    paymentReceiptFile : "",
                    studentEmail: ""
                }}

                onSubmit={(values, { setSubmitting }) => {
                setSubmitting(false);

                alert(JSON.stringify(values, null, 2));

                let studentEnrollRequest = {};
                studentEnrollRequest.classCode = values.classCode;
                //studentEnrollRequest.className = values.className;
                studentEnrollRequest.registrationAmt = values.registrationAmt;
                //studentEnrollRequest.studentEmail = values.studentEmail;

                let paymentReceiptFile = null;
                let paymentReceiptFileName = null;

                if(null != values.paymentReceiptFile) {
                    paymentReceiptFile = values.paymentReceiptFile[0];
                    studentEnrollRequest.paymentReceiptFile = values.paymentReceiptFile[0];
                    studentEnrollRequest.paymentReceiptFileName = values.paymentReceiptFile[0].name;
                }

                alert('studentEnrollRequest: '+ JSON.stringify(studentEnrollRequest, null, 2));
                alert('paymentReceiptFile: '+paymentReceiptFile);

                StudentService.saveStudentClassEnrollment(studentEnrollRequest).then(
                     response => {
                       toast.success('Student-class Enrollment saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                       console.log("Student-class Enrollment  saved successfully ...: "+ JSON.stringify(response));
                     },
                 error => {
                  toast.error('Error while saving Student-class Enrollment', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                 }
                );
                setSubmitionCompleted(true);

                }}

                validationSchema={Yup.object().shape({
                  studentEmail: Yup.string()
                    .email()
                    .required("Required"),
                    classCode: Yup.string().required("Required"),
                     registrationAmt: Yup.string().required("Required")
                })}

              >
                {props => {
                  const {
                    values,
                    touched,
                    errors,
                    dirty,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset
                  } = props;
                  return (


                    <Form onSubmit={handleSubmit}>
                      <FormitLogger />

                      <Grid container spacing={3}>
                         <Grid item xs={6} sm={6}>
                           <InputField name="classCode" label="Class Code" fullWidth />
                         </Grid>
                         <Grid item xs={6} sm={6}>
                           <InputField name="className" label="Class Name" fullWidth />
                         </Grid>
                          <Grid item xs={6} sm={6}>
                            <InputField name="registrationAmt" label="Registration Amount" fullWidth />
                          </Grid>
                         <Grid item xs={6} sm={6}>
                           <InputField name="studentEmail" label="Student Contact Email" fullWidth />
                         </Grid>

                         <Grid item xs={6} sm={6}>
                           <UploaderField name="paymentReceiptFile"  label="Payment Receipt File" type="file" value={undefined}  />
                         </Grid>


                        </Grid>

                        <div>
                            <Grid item xs={6} sm={6}>
                              <DialogActions>
                                <Button
                                  type="button"
                                  className="outline"
                                  onClick={handleReset}
                                  disabled={!dirty || isSubmitting}
                                >
                                  Reset
                                </Button>

                                <Button
                                  type="button"
                                  className="outline"
                                  onClick={handleClose}
                                  disabled={!dirty || isSubmitting}
                                >
                                  Close
                                </Button>


                                <Button type="submit" disabled={isSubmitting}>
                                  Submit
                                </Button>
                              </DialogActions>
                            </Grid>
                        </div>

                        </Form>
                      );
                    }}
              </Formik>
            </DialogContent>
          </React.Fragment>
        )}
        {
      /*        isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" className="outline" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )*/

        }
      </Dialog>
    </React.Fragment>
  );
}

export default withStyles(styles)(StudentEnrollment);
