import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class FacultyService {



saveFacultyUser(data, files) {

            let formData = new FormData();

            formData.append("userName", data.userName );
            formData.append("firstName",data.firstName );
            formData.append("middleName",data.middleName);
            formData.append("lastName",data.lastName );
            formData.append("password",data.password );

            // alert('data.dateOfBirth: '+ data.dateOfBirth); // data.dateOfBirth: Mon Feb 27 2023 00:00:00 GMT-0600 (Central Standard Time)
            // formData.append("dob",data.dateOfBirth ); // causes issue in formData - TODO

            formData.append("dob", data.dateOfBirth )
            formData.append("email", data.email);
            formData.append("phoneNo",data.phoneNo );
            formData.append("address1",data.address1 );
            formData.append("address2",data.address2 );
            formData.append("city",data.city );
            formData.append("stateInCountry",data.stateInCountry );
            formData.append("country",data.country );
            formData.append("profileNote",data.profileNote );
            formData.append("educationalLevel",data.educationalLevel );
            formData.append("resumeText",data.resumeText );
            formData.append("notes",data.notes );
            formData.append("languagesSpoken",data.languagesSpoken );
            formData.append("learningHoursCount",data.learningHoursCount );
            formData.append("availability",data.availability );
            formData.append("realm",data.realm );
             // new
            formData.append("coursesInterestJson",data.coursesInterestJson);
            formData.append("educationalLevelCd",data.educationalLevelCd);
            formData.append("certifications",data.certifications);
            formData.append("employmentStatusCd",data.employmentStatusCd );
            formData.append("resumeCoverNote",data.resumeCoverNote );
            formData.append("preferredClassScheduleJson",data.preferredClassScheduleJson );

            // formData.append("gender", data.gender ); // Deal with this; just like dob
            // formData.append("files", files);
            // formData.append("file", files[0]);

             //formData.append("JSON.stringify(files)", JSON.stringify(files));
            formData.append("profilePhotoFilePath", data.profilePhotoFilePath);
            formData.append("resumeFilePath", data.resumeFilePath);
            formData.append("paymentFileReceiptPath", data.paymentFileReceiptPath);

            if(null != files[0] && null != files[0].name) {
                formData.append("photoFile", files[0]);
            }
            if(null != files[1] && null != files[1].name) {
                 formData.append("resumeFile", files[1]);
            }
            if(null != files[2] && null != files[2].name) {
                 formData.append("paymentFile", files[2]);
            }
            // if list of files - handle same way also if in backend
            //for(let i = 0; i< e.target.files.length; i++) {
            //            formData.append('file', e.target.files[i])
            //   }
            console.log('StudentService.saveStudentUser - JSON.stringify(data):'+ JSON.stringify(data));
            console.log('StudentService.saveStudentUser - files:'+files);
            console.log('StudentService.saveStudentUser - data.resumeFile:'+data.resumeFile);
            console.log('StudentService.saveStudentUser - data.photoFile:'+data.photoFile);
            console.log('StudentService.saveStudentUser - data.paymentFile:'+data.paymentFile);
            console.log('StudentService.saveStudentUser - JSON.stringify(formData):'+JSON.stringify(formData));

            // get user token
            /*
               let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
                console.log('user token: '+ JSON.stringify(user));
                let token = JSON.stringify(user);
                if(user && user.accessToken) {
                    token = user.accessToken;
                 }
             */

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                }
            }

            return http.post("/bs/faculty/save", formData, config);
  }



createFacultyUser(data, files) {

            let formData = new FormData();

            formData.append("userName", data.userName );
            formData.append("firstName",data.firstName );
            formData.append("middleName",data.middleName);
            formData.append("lastName",data.lastName );
            formData.append("password",data.password );
            formData.append("dob","Feb 27 2023")
            // formData.append("dob",data.dob );
            formData.append("email",data.email );
            formData.append("phoneNo",data.phoneNo );
            formData.append("address1",data.address1 );
            formData.append("address2",data.address2 );
            formData.append("city",data.city );// TODO backend
            formData.append("stateInCountry",data.stateInCountry ); // TODO backend
            formData.append("country",data.country );// TODO backend
            formData.append("introText",data.introText );
            formData.append("profileText",data.profileText);
            formData.append("profilePhotoFilePath",data.profilePhotoFilePath);
            formData.append("resumeFilePath",data.resumeFilePath );
            formData.append("resumeCoverNote",data.resumeCoverNote );
            formData.append("certifications",data.certifications);
            formData.append("educationalLevelCd",data.educationalLevelCd);
            formData.append("realm",data.realm );
            formData.append("employmentStatusCd",data.employmentStatusCd );
      //  formData.append("gender", data.gender ); // Deal with this; just like dob
            formData.append("coursesInterestJson",data.coursesInterestJson);
            formData.append("approvedCoursesJson",data.approvedCoursesJson);
            formData.append("preferredClassScheduleJson",data.preferredClassScheduleJson );

            // alert('data.dateOfBirth: '+ data.dateOfBirth); // data.dateOfBirth: Mon Feb 27 2023 00:00:00 GMT-0600 (Central Standard Time)
            // formData.append("dob",data.dateOfBirth ); // causes issue in formData - TODO

            formData.append("photoFile", files[0]);
            formData.append("resumeFile", files[1]);

            console.log('FacultyService.createFacultyUser - JSON.stringify(data):'+ JSON.stringify(data));
            console.log('FacultyService.createFacultyUser - files:'+files);
            console.log('FacultyService.createFacultyUser - data.resumeFile:'+data.resumeFile);
            console.log('FacultyService.createFacultyUser - data.photoFile:'+data.photoFile);
            console.log('FacultyService.createFacultyUser - JSON.stringify(formData):'+JSON.stringify(formData));

            // get user token
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            let token = JSON.stringify(user);
            if(user && user.accessToken) {
                token = user.accessToken;
             }

            const config = {
                headers: {
                'content-type': 'multipart/form-data',
                'Authorization' : `Bearer ${token}`
                }
            }

            return http.post("/bs/faculty/create", formData, config);
  }

 saveFacultyClassEngagement(data) {

        console.log('Called FacultyService.saveFacultyClassEngagement() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post('/bs/faculty/faculty-class-engagement/save', data, config);
 }

getAllFaculties() {

     console.log('Called FacultyService.getAllFaculties() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.get('/bs/faculty/all-faculties', config);
 }

// Faculty Review
///bs/facultyreview/save

 saveFacultyReview(data) {

        console.log('Called FacultyService.saveFacultyReview() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post('/bs/facultyreview/save', data, config);
 }

 getAllReviewsByFacultyId(id) {

        console.log('Called FacultyService.getAllReviewsForFaculty() ...');

        return http.get('/bs/facultyreview/all-for-faculty/{id}');
 }

  getFacultyReviewById(id) {
         console.log('Called FacultyService.getFacultyReview() ...');
         return http.get('/bs/facultyreview/{id}');
}



 onChangeCode(e) {
    this.setState({
      code: e.target.value,
    });
  }

onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

onChangeCourseGroupCode(e) {
    this.setState({
      courseGroupCode: e.target.value,
    });
  }


onChangeFeatured(e) {
    this.setState({
      featured: e.target.value,
    });
  }



/*

        getAll() {
        return http.get('/collection/asset/all');
        }

        fetchAssetsByType(collectionTypeCd) {
        return http.get('/collection/asset/type/'+collectionTypeCd);
        }


        getPageableAll(params) {
           return http.get("/collection/asset/pageable/all", { params });
        }

        fetchAssetsByTag(tag) {
        return http.get('/collection/asset/tag/${tag}');
        }

        fetchAssetsBySearchString(searchString) {
        return http.get('/collection/asset/search/${searchString}');
        }

        fetchAssetsBySearchSpecification(searchSpecification) {

            return http.post("/collection/asset/specificationFetch", searchSpecification, {
                  headers: {
                    "Content-Type": "application/json"
                  }
                });
        }


        */


 }

export default new FacultyService();