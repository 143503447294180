import React from 'react'
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'
import InputField from '../../../../components/formfields/InputField';
import CheckboxField from '../../../../components/formfields/CheckboxField';
import SelectField from '../../../../components/formfields/SelectField';
import DatePickerField from '../../../../components/formfields/DatePickerField';

const UserContact = () => {


  return (
    <Container  component="main" maxWidth="xs">
      <div>
        <Typography  component="h5" variant="h5">
           Contact Info
        </Typography>
        <form>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
                <InputField name="email" label="Email" fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
                 <InputField name="phoneNumber" label="Phone Number" fullWidth />
            </Grid>


          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default UserContact
