import React from "react";
//import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
//import CheckButton from "react-validation/build/button";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik } from 'formik';

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

import  EvaluationService  from "../../../services/EvaluationService";
import Pagination from "@material-ui/lab/Pagination";
import styles from './evaluation.module.css'

class ExamResult extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

              deletedFlag: "",
              examJsonFileContent: {},
              examCode : "",
              examName : "",
              examDescription : "",
              classId : "",
              effectiveDate : "",
              id : "",
              successful: false,
              fields: {},
              errors: {},


      formValues: [{
                                         questionSN: "",
                                         questionText : "",
                                         answerOptionA: "A",
                                         answerOptionAText : "" ,
                                         answerOptionACorrect : "false" ,
                                         answerOptionB: "B",
                                         answerOptionBText : "" ,
                                         answerOptionBCorrect : "false" ,
                                         answerOptionC: "C",
                                         answerOptionCText : "" ,
                                         answerOptionCCorrect : "false" ,
                                         answerOptionD: "D",
                                         answerOptionDText : "" ,
                                         answerOptionDCorrect : "false" ,
                                         answerOptionE: "E",
                                         answerOptionEText : "" ,
                                         answerOptionECorrect : "false"
                                       }]
     };
   // this.handleSubmit = this.handleSubmit.bind(this)
  }

    handleChange(field, e) {

      console.log('handleChange(field, e) - this.state : entry');
       let fields = this.state.fields;
       if('checkbox' === e.target.type) {
             fields[field] =  e.target.checked;
             //alert('event.target.type:'+ e.target.type);
             //alert('e.target.checked:'+ e.target.checked);
         }
         else {
             fields[field] = e.target.value;
         }
      this.setState({ fields });
       console.log('handleChange(field, e) - this.state : final');
      //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
   }


 componentDidMount() {

        EvaluationService.getExamPreview("DISMLEL01").then(
                  response => {
                    this.setState({
                      formValues: response.data.questioAndAnswerOptionsList
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
         console.log('loaded getExamPreview - this.state.formValues :'+this.state.formValues);


        EvaluationService.getExamByCode("DISMLEL01").then(
                  response => {
                    this.setState({
                        examCode :  response.data.examCode,
                        examName : response.data.examName,
                        examDescription : response.data.examDescription,
                        classId : response.data.classId,
                        effectiveDate : response.data.effectiveDate,
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );

  }

generateExamResult(e) {
       e.preventDefault();
       console.log('generateExamResult called ... ');

        // "examCode" : this.state.fields["examCode"],
        // "examName" : this.state.fields["examName"],

        let examRequest = {
         "examCode" : "DISMLEL01",
          "userId" :  "5"
        }

     console.log('generateExamResult called ..: JSON.stringify(examRequest) - from UI : ' +JSON.stringify(examRequest));

     EvaluationService.generateExamResult(examRequest)
              .then(response => {
                //this.setState({
                //  successful: true
                //});
               toast.success('🦄 Exam Result generated successfully', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  console.log('examRequest: JSON.stringify(response.data): ' +JSON.stringify(response.data));
              })
              .catch(e => {
                console.log(e);
                 this.setState({
                      successful: false,
                    });

                   toast.error('🦄 Error while generating Exam result ...'+ e.message, {
                                     position: "top-center",
                                     autoClose: 5000,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                     pauseOnHover: true,
                                     draggable: true,
                                     progress: undefined,
                                     });
              });

}

  render() {

      return (
          <Container  component="main" >
             <div>
               <Typography  component="h1" variant="h5">
                  Generate Result
               </Typography>

                 <Formik
                   initialValues={formInitialValues}
                   onSubmit={this.handleNext} >

                              <form>

                                       <div>
                                                <div>
                                                          <div>
                                                                <label htmlFor="examCode">Exam Code</label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                               onChange={this.handleChange.bind(this, "examCode")}
                                                               value={this.state.examCode}
                                                            />
                                                       </div>
                                                      <div className="form-group">
                                                        <label htmlFor="examName">Exam Name</label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          name="examName"
                                                          onChange={this.handleChange.bind(this, "examName")}
                                                          value={this.state.examName}
                                                        />
                                                      </div>

                                                      <div className="form-group">
                                                        <label htmlFor="examDescription">Exam Description</label>
                                                        <input
                                                          type="text"
                                                          className="form-control"
                                                          name="examDescription"
                                                          onChange={this.handleChange.bind(this, "examDescription")}
                                                          value={this.state.examDescription}
                                                        />
                                                      </div>

                                                       <div className="form-group">
                                                            <label htmlFor="classId">Class ID</label>
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              name="classId"
                                                              onChange={this.handleChange.bind(this, "classId")}
                                                              value={this.state.classId}
                                                            />
                                                       </div>
                                                       <div className="field-submit">
                                                          <span style={{margin: "10px"}} className="submit-span">
                                                              <button
                                                                 onClick={this.generateExamResult.bind(this)}
                                                              >Generate Exam Result</button>
                                                         </span>
                                                         <span style={{margin: "10px"}} className="submit-span">
                                                              <button>Cancel</button>
                                                          </span>
                                                       </div>
                                                  </div>

                                                  <div>
                                                       <br />
                                                  </div>
                                </div>


                              <div>
                                  <label>Questions</label>
                                  <hr style={{color: "gray",backgroundColor: "gray",height: "5px"}} />
                              </div>

                              <div className="form-group">
                                   <ToastContainer />
                              </div>

                              <br />
                              <br />


                                <div>



                                </div>

                                <div className="button-section">
                                    <button className="button add" type="button" >Add</button>
                                    <button className="button submit" type="button"  >Submit</button>
                                </div>

                                <Grid item xs={12} sm={6}>
                                      <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                          Cancel
                                      </Button>
                                      <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                         Reset
                                      </Button>
                                      <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                          Save
                                      </Button>
                                </Grid>

                            </form>
                </Formik>
              </div>
         </Container>

      );
    }
  }
  export default ExamResult;