
import React from "react";
import "./StopWatch.css";

export default function Timer(props) {
    return (
        <div className="timer">
            <span className="digits">
                  {("0" + Math.floor((props.time / 3600000) % 60)).slice(-2)}:
            </span>
            <span className="digits">
                {("0" + Math.floor((props.time / 60000) % 60)).slice(-2)}:
            </span>
            <span className="digits">
                {("0" + Math.floor((props.time / 1000) % 60)).slice(-2)}.
            </span>
            <span className="digits mili-sec">
                {("0" + ((props.time / 10) % 100)).slice(-2)}
            </span>
        </div>
    );
}

/*
  // Hours calculation
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  const milliseconds = time % 100;
*/