import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

import TutorialsAdminList from "./tutorial-admin-list.component"; // me
import SessionTimeout from "./SessionTimeout";
import BuggyCounter from "../common/BuggyCounter";
//import CollectionsCommonRenderer from "../components/africancollection/CollectionsCommonRenderer.component";
import EventBus from "../common/EventBus";  //

import ManagePosts from "../admin/components/ManagePosts.component";
import ManageUsers from "../admin/components/ManageUsers.component";
import ManageBlogs from "../admin/components/ManageBlogs.component";

import ManagePostDetail from "../admin/components/ManagePostDetail.component";
import Message from "../admin/components/Message.component";
import PopupWindow from "../admin/components/PopupWindow.component";

//import CreateCourse from "../admin/components/CreateCourse.component";
import CourseCreationForm from "../admin/components/dashboard/CourseCreationForm";
import TrainingOfferForm from "../admin/components/dashboard/TrainingOfferForm";
//import CreateCourseSyllabi from "../admin/components/CreateCourseSyllabi.component";
import CourseSyllabusCreationForm from "../admin/components/dashboard/CourseSyllabusCreationForm";
//import CreateProgram from "../admin/components/CreateProgram.component";
import ProgramCreationForm from "../admin/components/dashboard/ProgramCreationForm";
//import CreateTrainingClass from "../admin/components/CreateTrainingClass.component";
import ClassCreationForm from "../admin/components/dashboard/ClassCreationForm";
//import FacultyTrainingAssignment from "../admin/components/FacultyTrainingAssignment.component";
import FacultyClassAssignmentForm from "../admin/components/dashboard/FacultyClassAssignmentForm";
import StudentClassAssignment from "../admin/components/dashboard/StudentClassAssignment";
import StudentEnrollment from '../components/training/StudentEnrollment';
import ClassRollCall from "../admin/components/ClassRollCall";
//import RollCall from "../admin/components/RollCall.component";
//import RecordAttendance from "../admin/components/RecordAttendance.component";
//import RecordedAttendanceMgt from "../admin/components/RecordedAttendanceMgt.component";
import PublicationCreation from '../blog/PublicationCreation';
import BlogHome from "../blog/BlogHome";
import ExamsAdminDashboard from "../admin/components/exams/ExamsAdminDashboard.component";

import FormDialog from "../admin/components/FormDialog";
import UserProfile from "../components/UserProfile.component";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class AdminDashboard extends Component {

  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 3,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveTutorials() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    TutorialDataService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        this.setState({
          tutorials: tutorials,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
                // if for example token has expired as indicated by the backend
                // console.log('Token is expired ..');
                //  EventBus.dispatch("logout");
                //  this.props.history.push("/profile");
                //  window.location.reload();
                console.log("AdminDashboard throw 401 ...");
          }
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
        try {
         console.log('tutorial : '+tutorial.title);
         this.setState({
          currentTutorial: tutorial,
          currentIndex: index,
        });
        }
        catch( e) {
        console.log('tutorial error catch: '+ e);
        }
  }

  removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
            const {
              searchTitle,
              tutorials,
              currentTutorial,
              currentIndex,
              page,
              count,
              pageSize,
            } = this.state;

    return (

      <div className="list row">

        <SessionTimeout />

        <div className="col-md-12">

        <hr />

        <Tabs>
            <TabList>
                <Tab>User Profile</Tab>
                <Tab>Publication</Tab>
                <Tab>Manage Blogs</Tab>
                <Tab>Manage Trainings</Tab>
                <Tab>Manage Users</Tab>
                <Tab>Manage Courses</Tab>
                <Tab>Manage Projects</Tab>
                <Tab>Communications</Tab>
                <Tab>Messaging</Tab>
                <Tab>Accounting</Tab>

                <Tab>Create Course</Tab>
                <Tab>Create Course Syllabi</Tab>
                <Tab>Training Offer</Tab>
                <Tab>Create Program</Tab>
                <Tab>Create Class</Tab>
                <Tab>Faculty - Class Assignment</Tab>
                <Tab>Student - Class Assignment</Tab>
                <Tab>Student Enrollment</Tab>
                 <Tab>Classes</Tab>
                <Tab>Class Attendance</Tab>
                <Tab>Exams/Evaluation</Tab>

            </TabList>

                <TabPanel>
                        <UserProfile />
                </TabPanel>
                <TabPanel>
                    <PublicationCreation />
                </TabPanel>

                <TabPanel>
                    <BlogHome />
                </TabPanel>

                <TabPanel>
                   <div>
                     <FormDialog classId={135} />
                       Trainings
                   </div>
                </TabPanel>

                <TabPanel>
                    <div>
                    ManageUsers
                    </div>
                </TabPanel>

                <TabPanel>
                    <div>
                      Courses
                    </div>
                </TabPanel>

                <TabPanel>
                   <div>
                       Projects
                   </div>
                </TabPanel>

                <TabPanel>
                    <div>
                      Communications
                    </div>
                </TabPanel>

                <TabPanel>
                    <div>
                      Messaging - coming soon
                    </div>
                </TabPanel>
                <TabPanel>
                      Accounting
                </TabPanel>

                <TabPanel>
                     <CourseCreationForm />
                </TabPanel>
                 <TabPanel>
                     <CourseSyllabusCreationForm />
                </TabPanel>
                <TabPanel>
                      <TrainingOfferForm />
                </TabPanel>
                 <TabPanel>
                    <ProgramCreationForm />
                </TabPanel>
                <TabPanel>
                    <ClassCreationForm />
                </TabPanel>

                <TabPanel>
                    <FacultyClassAssignmentForm />
                </TabPanel>
                <TabPanel>
                    <StudentClassAssignment />
                </TabPanel>
                <TabPanel>
                    <StudentEnrollment />
                </TabPanel>

               <TabPanel>
                    Classes
               </TabPanel>


                <TabPanel>
                    <ClassRollCall />
                </TabPanel>
                <TabPanel>
                    <ExamsAdminDashboard />
                </TabPanel>

        </Tabs>

        </div>


        <hr />

      </div>
    );
  }
}
