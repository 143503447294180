import React, { useState } from 'react';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import  EvaluationService  from "../../../services/EvaluationService";
import Pagination from "@material-ui/lab/Pagination";
import styles from './evaluation.module.css'

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';

import useStyles from './styles';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form, Field, FieldArray, ErrorMessage  } from 'formik';

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';


const ExamSetupQuestions = () => {

        const classes = useStyles();
        const [activeStep, setActiveStep] = useState(0);
        //const currentValidationSchema = UserSchema[activeStep];
       // const isLastStep = activeStep === steps.length - 1;

        const [values, setValues] = useState("");

const [questionsList, setQuestionsList] = useState([{
                                                 questionSN: "",
                                                 questionText : "",

                                                 answerOptionA: "A",
                                                 answerOptionAText : "" ,
                                                 answerOptionACorrect : "false" ,

                                                 answerOptionB: "B",
                                                 answerOptionBText : "" ,
                                                 answerOptionBCorrect : "false" ,

                                                 answerOptionC: "C",
                                                 answerOptionCText : "" ,
                                                 answerOptionCCorrect : "false" ,

                                                 answerOptionD: "D",
                                                 answerOptionDText : "" ,
                                                 answerOptionDCorrect : "false" ,

                                                 answerOptionE: "E",
                                                 answerOptionEText : "" ,
                                                 answerOptionECorrect : "false"
                                               }]);

        function _sleep(ms) {
          return new Promise(resolve => setTimeout(resolve, ms));
        }

          async function _submitForm(values, actions) {
          await _sleep(1000);
          values.userRoleCode = "ROLE_USER";
          console.log(JSON.stringify(values, null, 2));

        /*   AuthService.register(values.userName, values.firstName, values.lastName, values.email, values.password, 'REALM_BYTES_STEROID')
              .then(response => {
                      console.log("response data after user creation: "+  JSON.stringify(response.data));
                   })
                   .catch(e => {
                     console.log(e);
                   });*/

          actions.setSubmitting(false);
          setActiveStep(activeStep + 1);

        }

        function _handleSubmit(values, actions) {
          /*if (isLastStep) {
            _submitForm(values, actions);
          } else {
            setActiveStep(activeStep + 1);
            actions.setTouched({});
            actions.setSubmitting(false);
          }*/

            setValues(values);
            console.log("values in _handleSubmit function: "+ JSON.stringify(values, null, 2));
        }

        function _handlePrevious() {
          setActiveStep(activeStep - 1);
        }

// call  { generateQuestionComponent() }
function generateQuestionComponent() {
    return (

    <div>

                                            {questionsList.map((element, index) => (

                                             <div className={styles.tableDisplay}  key={index} >
                                                    <div className={styles.tableDisplay}>
                                                        <div className={styles.tableRowDisplay}>
                                                           <div className={styles.tableCellDisplay} >
                                                                <label htmlFor="questionSN">Question No</label>
                                                                 <span style={{margin: "3px"}}></span>
                                                                <label name="questionSN"  value={ index + 1}  >{index + 1}</label>
                                                                <div> {element.questionSN = index + 1}</div>
                                                            </div>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                        </div>
                                                     </div>

                                                    <div className={styles.tableDisplay}>
                                                         <div className={styles.tableRowDisplay}>
                                                             <span className={styles.tableCellDisplay} >
                                                                Question Text
                                                             </span>
                                                              <span className={styles.tableCellDisplay} >
                                                                  <textarea
                                                                    name="questionText"
                                                                    className={styles.inPutBox}
                                                                    value={element.questionText || ""}
                                                                     onChange={e => this.dynaFormHandleChange(index, e)}
                                                                  />
                                                              </span>

                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                         </div>
                                                     </div>

                                                    <div className={styles.tableDisplay}>
                                                        <div className={styles.tableRowDisplay}>
                                                            <span className={styles.tableCellDisplay} >
                                                                Answer Options
                                                            </span>
                                                            <span className={styles.tableCellDisplay} >
                                                            </span>
                                                           <span className={styles.tableCellDisplay} >
                                                           </span>

                                                            <span className={styles.tableCellDisplay}  >
                                                                <label >Is correct option ?</label>
                                                            </span>

                                                       </div>
                                                    </div>
                                                     <div className={styles.tableDisplay}>

                                                       <div className={styles.tableRowDisplay}>
                                                            <div className={styles.tableCellDisplay} >
                                                                <label name = "answerOptionA" value={element.answerOptionA || ""} > (A) </label>
                                                            </div>

                                                           <div className={styles.tableCellDisplay} >
                                                                <textarea
                                                                    name ="answerOptionAText"
                                                                     className={styles.inPutBox}
                                                                     value={element.answerOptionAText || ""}
                                                                      onChange={e => this.dynaFormHandleChange(index, e)}
                                                                />
                                                           </div>
                                                           <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                          </div>
                                                           <div className={styles.tableCellDisplay} >
                                                                <input
                                                                    name ="answerOptionACorrect"
                                                                    type="checkbox"
                                                                    defaultChecked={false}
                                                                    value={element.answerOptionACorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                 />
                                                           </div>
                                                      </div>
                                                      <div className={styles.tableRowDisplay}>
                                                                     <div className={styles.tableCellDisplay} >
                                                                          <label name = "answerOptionB"> (B) </label>
                                                                     </div>

                                                                    <div className={styles.tableCellDisplay} >
                                                                         <textarea
                                                                              name ="answerOptionBText"
                                                                              className={styles.inPutBox}
                                                                              value={element.answerOptionBText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                         />
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} >
                                                                         <input
                                                                              name ="answerOptionBCorrect"
                                                                              type="checkbox"
                                                                               defaultChecked={false}
                                                                               value={element.answerOptionBCorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                          />
                                                                    </div>
                                                      </div>
                                                     <div className={styles.tableRowDisplay}>
                                                               <div className={styles.tableCellDisplay} >
                                                                   <label name = "answerOptionC"> (C) </label>
                                                               </div>

                                                              <div className={styles.tableCellDisplay} >
                                                                   <textarea
                                                                        name ="answerOptionCText"
                                                                        className={styles.inPutBox}
                                                                        value={element.answerOptionCText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                   />
                                                              </div>
                                                              <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                               </div>
                                                              <div className={styles.tableCellDisplay} >
                                                                   <input
                                                                        name ="answerOptionCCorrect"
                                                                        type="checkbox"
                                                                        defaultChecked={false}
                                                                        value={element.answerOptionCCorrect || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                    />
                                                              </div>
                                                    </div>
                                                    <div className={styles.tableRowDisplay}>
                                                                     <div className={styles.tableCellDisplay} >
                                                                        <label name = "answerOptionD"> (D) </label>
                                                                     </div>

                                                                    <div className={styles.tableCellDisplay} >
                                                                         <textarea
                                                                                name ="answerOptionDText"
                                                                              className={styles.inPutBox}
                                                                              value={element.answerOptionDText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                         />
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                     </div>
                                                                    <div className={styles.tableCellDisplay} >
                                                                         <input
                                                                               name ="answerOptionDCorrect"
                                                                              type="checkbox"
                                                                               defaultChecked={false}
                                                                                value={element.answerOptionDCorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                          />
                                                                    </div>
                                                    </div>
                                                     <div className={styles.tableRowDisplay}>
                                                                   <div className={styles.tableCellDisplay} >
                                                                         <label name = "answerOptionE"> (E) </label>
                                                                   </div>

                                                                  <div className={styles.tableCellDisplay} >
                                                                       <textarea
                                                                             name ="answerOptionEText"
                                                                            className={styles.inPutBox}
                                                                             value={element.answerOptionEText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                       />
                                                                  </div>
                                                                  <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                  </div>
                                                                  <div className={styles.tableCellDisplay} >
                                                                       <input
                                                                             name ="answerOptionECorrect"
                                                                            type="checkbox"
                                                                             defaultChecked={false}
                                                                             value={element.answerOptionECorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                        />
                                                                  </div>
                                                     </div>
                                                     <div className={styles.tableRowDisplay}>
                                                          <button type="button"  className="button remove" onClick={() => this.removeFormFields(index)}>Remove</button>
                                                     </div>

                                                 </div>

                                         </div>

                                         ))}

               </div>

    );
  }

  return (


    <Container  component="main" maxWidth="xs">
      <div>
        <Typography  component="h5" variant="h5">
           Exam Questions
        </Typography>

               <React.Fragment>


                                  <Grid container spacing={2}>

                                    <Grid item xs={12} sm={6}>
                                        <InputField name="email" label="Email" fullWidth />
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                         <InputField name="country" label="Country" fullWidth />
                                    </Grid>

                                  </Grid>




              </React.Fragment>

      </div>
    </Container>
  )
}

export default ExamSetupQuestions
