import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class EvaluationService {


 saveExamSetup(data) {
        console.log('Called EvaluationService.saveExamSetup() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/examsetup-save', data, config);
 }


 examFetchQuestions(data) {
        console.log('Called EvaluationService.fetchQuestions() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/exam-fetch-questions', data, config);
 }

 getExamQuestionsByExamCode(examReqData, params) {

        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
          console.log('user token: '+ JSON.stringify(user));
         // let token = JSON.stringify(user);
         let token;
          if(user && user.accessToken) {
              token = user.accessToken;
           }

          // headers
          const config = {
              headers: {
              'Authorization' : `Bearer ${token}`,
              },
              params : params
          }
    return http.post("/bs/evaluation/exam-questions-map", examReqData, config);
  }

 // https://stackoverflow.com/questions/53501185/how-to-post-query-parameters-with-axios
/*  sendAllData (data) {
   return axios
        .post(API_URL + "receiveData", JSON.stringify(data), {
          headers: { "Content-Type": "application/json; charset=UTF-8" },
          params: { mail: xyx@example.col }, //Add mail as a param
        })
        .then((response) => console.log("repsonse", response.status));
  }*/



 lookupExamSetup(data) {
        console.log('Called EvaluationService.lookupExamSetup() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/examsetup-lookup', data, config);
 }


 saveExamTake(data) {
        console.log('Called EvaluationService.saveExamTake() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/exam-take', data, config);
 }
//================================

 saveExamBasicInfo(data) {
        console.log('Called EvaluationService.saveExamBasicInfo() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/examsetup-basicinfo-save', data, config);
 }

 handleSaveQuestionsWithAnswerOptions(data) {
        console.log('Called EvaluationService.handleSaveQuestionsWithAnswerOptions() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/examsetup-questions-answer-options-save', data, config);
 }


  saveExam(data) {
        console.log('Called EvaluationService.saveExam() ...[ data ]  =>: '+  JSON.stringify(data));

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }

        return http.post('/bs/evaluation/exam-save', data, config);
     }

saveExamAttemptAnswer(data) {
            console.log('Called EvaluationService.saveExam() ...[ data ]  =>: '+  JSON.stringify(data));

            // get user token
            // TODO - do this in util
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            // let token = JSON.stringify(user);
            let token;
            if(user && user.accessToken) {
              token = user.accessToken;
            }

            // headers
            const config = {
              headers: {
              'Authorization' : `Bearer ${token}`
              }
            }

            return http.post('/bs/evaluation/save-exam-attempt', data, config);
 }

generateExamResult(data) {
            console.log('Called EvaluationService.generateExamResult() ...[ data ]  =>: '+  JSON.stringify(data));

            // get user token
            // TODO - do this in util
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
            // let token = JSON.stringify(user);
            let token;
            if(user && user.accessToken) {
              token = user.accessToken;
            }

            // headers
            const config = {
              headers: {
              'Authorization' : `Bearer ${token}`
              }
            }

            return http.post('/bs/evaluation/result-generation', data, config);
 }


 retrieveClassExam(data) {

         console.log('Called EvaluationService.retrieveClassExam() ...');

            // get user token
            // TODO - do this in util
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
           // let token = JSON.stringify(user);
           let token;
            if(user && user.accessToken) {
                token = user.accessToken;
             }

            // headers
            const config = {
                headers: {
                'Authorization' : `Bearer ${token}`
                }
            }
            return http.get('/bs/evaluation/exam/fetch', config);
     }

     getExamPreview(examCode) {
        return http.get('/bs/evaluation/exampreview/'+examCode);
     }

     getExamByCode(examCode) {
            return http.get('/bs/evaluation/exam/'+examCode);
         }

 }


export default new EvaluationService();