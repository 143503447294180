import React, { useState } from "react";
import { Button, Form, Input } from "reactstrap";
import ReactStars from "react-rating-stars-component";

import StarRating from "../trials/StarRating";

export default function ReviewForm() {

  const [reviews, setReviews] = useState("");
  const onChange = (e: any) => {
    setReviews(e.target.value);
  };

  const onSubmit = (e: any) => {
    console.log("Form Submitted");
  };

const ratingChanged = (newRating) => {
  console.log(newRating);
};

function log(value) {
  console.log(value);
}

  return (
    <div className="form-container">
      <Form onSubmit={onSubmit}>
      <div>
        <span>
            <label>Rating </label>
            <ReactStars
                count={5}
                onChange={ratingChanged}
                size={24}
                isHalf={true}
                emptyIcon={<i className="far fa-star"></i>}
                halfIcon={<i className="fa fa-star-half-alt"></i>}
                fullIcon={<i className="fa fa-star"></i>}
                activeColor="#ffd700"
              />
          </span>
       </div>

       <div>
            <span>
               <label>Rating 2 </label>
               <StarRating onChange={log} />
             </span>
       </div>

      <div>
           <span>
              <label>Rating 3 </label>
              <ReactStars
                        count={5}
                        onChange={ratingChanged}
                        size={24}
                        half={true}
                        color2={'#ffd700'} />
            </span>
      </div>



      <div>
            <span>
             <label>Comment </label>
            <Input
              className="reviews-form"
              type="text"
              placeholder="enter you reviews"
              value={reviews}
              onChange={onChange}
            />
            </span>
       </div>

        <Button type="submit" style={{ background: "Green" }}>
          Submit
        </Button>
      </Form>
    </div>
  );
}