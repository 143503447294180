import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../../actions/auth";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CourseService  from "../../services/CourseService";
import FacultyService  from "../../services/FacultyService";


const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vcourseName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The Course Name must be between 3 and 20 characters.
      </div>
    );
  }
};

const vcourseGroup = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The Course Group must be between 6 and 40 characters.
      </div>
    );
  }
};

/*const RenderDatePicker = ({ input: { onChange, value }, ...rest }) => (
  <DatePicker selected={value} onChange={date => onChange(date)} {...rest} />
);*/

class FacultyCourseAssignment extends Component {

  constructor(props) {
    super(props);
    this.handleEngageFacultyWithCourse = this.handleEngageFacultyWithCourse.bind(this);
    this.onChangeCourseName = this.onChangeCourseName.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
    this.onChangeDeletedFlag = this.onChangeDeletedFlag.bind(this);
    this.onChangeCourseDescription = this.onChangeCourseDescription.bind(this);
    this.onChangeCourseGroup = this.onChangeCourseGroup.bind(this);
    this.setCoursePlannedStartDate = this.setCoursePlannedStartDate.bind(this);

    this.state = {
      courseName: "",
      email: "",
      courseGroup: "",
      duration: "",
      deletedFlag: "",
      coursePlannedStartDate: null,
      successful: false,
      courses : [],
      faculties : [],

       fields: {},
       errors: {}

    };


  }

//let courseGroupSelectInput = React.createRef();

  onChangeCourseName(e) {
    this.setState({
      courseName: e.target.value,
    });
  }


 onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

   onChangeDeletedFlag(e) {
    this.setState({
      deletedFlag: e.target.value,
    });
  }
  onChangeCourseDescription(e) {
    this.setState({
      courseDescription: e.target.value,
    });
  }

  onChangeCourseGroup(e) {
    this.setState({
      courseGroup: e.target.value,
    });
  }

  handleEngageFacultyWithCourse(e) {
     e.preventDefault();

          // if (!this.handleValidation()) {
          //     console.log('handleEngageFacultyWithCourse invoked; invalid form ...');
         //       return;
         //  }

           console.log('handleEngageFacultyWithCourse invoked ...');

           let data = {
             courseId: this.state.fields["courseId"],
             facultyId: this.state.fields["facultyId"],
             coursePlannedStartDate: this.state.coursePlannedStartDate
           };


              console.log('handleEngageFacultyWithCourse() JSON.stringify(data): ' + data);
             // console.log('saveCourse(field, e) - this.state :'+ JSON.stringify(this.state));

           FacultyService.saveFacultyCourseEngagement(data)
             .then(response => {
               //this.setState({
               //  successful: true
               //});
              toast.success('🦄 Faculty-Course engagement saved successfully', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        });

                 console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
             })
             .catch(e => {
               console.log(e);
                this.setState({
                     successful: false,
                   });

                  toast.error('🦄 Error while saving faculty-course engagement ...'+ e.message, {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    });
             });
  }


setCoursePlannedStartDate(date)  {
        this.setState({
                coursePlannedStartDate: date
              });
 }

handleChange(field, e) {

   console.log('handleChange(field, e) - this.state : entry');

      let fields = this.state.fields;
      if('checkbox' === e.target.type) {
            fields[field] =  e.target.checked;
            //alert('event.target.type:'+ e.target.type);
            //alert('e.target.checked:'+ e.target.checked);
        }
        else {
            fields[field] = e.target.value;
        }
     this.setState({ fields });
      console.log('handleChange(field, e) - this.state : final');
     //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
 }


 componentDidMount() {

         CourseService.getAllCourses().then(
                   response => {
                     this.setState({
                       courses: response.data
                     });
                   },
                   error => {
                     this.setState({
                       content:
                         (error.response &&
                           error.response.data &&
                           error.response.data.message) ||
                         error.message ||
                         error.toString()
                     });

                   }
                   // console.log('loaded courses - getAllCourses - this.state.coursess :'+this.state.courses);
                 );

         FacultyService.getAllFaculties().then(
                            response => {
                              this.setState({
                                faculties: response.data
                              });
                            },
                            error => {
                              this.setState({
                                content:
                                  (error.response &&
                                    error.response.data &&
                                    error.response.data.message) ||
                                  error.message ||
                                  error.toString()
                              });

                            }
                         //    console.log('loaded faculties - getAllFaculties - this.state.faculties :'+this.state.faculties);
              );

}



  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">




        <div className="formContainerDiv">

         <fieldset>
                 <legend>Faculty - Course Assignment</legend>


          <Form
            onSubmit={this.handleEngageFacultyWithCourse}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="courseId">Course</label>

                  <span className = "span-criteria" >

                    <select id="courseId" name="courseId"
                    onChange={this.handleChange.bind(this, "courseId")}>
                          <option value={this.state.fields["courseId"]}>Select Course</option>
                           {this.state.courses.map(course => (
                                 <option key={course.id} value={course.id}>
                                   {course.name}
                                 </option>
                               ))}
                    </select>

                 </span>

                </div>

             <div className="form-group">
               <label htmlFor="facultyId">Faculty</label>

               <span className = "span-criteria" >
                <select id="facultyId" name="facultyId"
                onChange={this.handleChange.bind(this, "facultyId")}>
                    <option value={this.state.fields["facultyId"]}>Select Faculty</option>
                     {this.state.faculties.map(faculty => (
                           <option key={faculty.facultyId} value={faculty.facultyId}>
                             {faculty.fullName}
                           </option>
                         ))}
                </select>
              </span>
              </div>

            <div className="form-group">
              <label htmlFor="coursePlannedStartDate">Course Planned Start Date</label>
              <span>
                      <DatePicker
                        locale="es"
                        selected={this.state.coursePlannedStartDate}
                        showTimeSelect
                        dateFormat="Pp"
                        showIcon
                       onChange={(coursePlannedStartDate) => this.setCoursePlannedStartDate(coursePlannedStartDate)}
                      />
              </span>

            </div>

           <div class="form-group">
                <ToastContainer />
           </div>

          <div className="field-submit">
                    <span className="submit-span">
                        <button>Assign</button>
                   </span>

                   <span className="submit-span">
                             <button>Cancel</button>
                    </span>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(FacultyCourseAssignment);
