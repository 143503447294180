import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext, Field } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core';

export default function CheckboxField(props) {
  const { label, ...rest } = props;
  const [field, meta, helper] = useField(props);
  // const { setValue } = helper;
   const { setFieldValue } = useFormikContext();

  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return <FormHelperText>{error}</FormHelperText>;
    }
  }

  function _onChange(e) {
    //setValue(e.target.checked);
    setFieldValue(field.name, e.target.checked);
    //console.log("e.target.checked :"+ e.target.checked);
    //console.log("field.value:"+ field.value);
    //  value={field.value}
    // checked={field.value}
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel

        control={<Checkbox {...field} onChange={_onChange}  checked={field.value == true}  />}
        label={label}
      />
      {_renderHelperText()}
    </FormControl>
  );
}
