import React, { Component } from "react";

const COUNT_LIMIT = 3;
class BuggyCounter extends React.Component {

  constructor(props) {
    super(props);
    this.state = { counter: 0 };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
  // error caught only when using our react-error-boundary ErrorBoundary
  try {
          if(this.state.counter > COUNT_LIMIT) {
            throw new Error('Oops, crashed!');
          }
          else {
                this.setState(({counter}) => ({
                  counter: counter + 1
                }));
              }
   }
   catch(error) {
            console.log('Counting error: ', error);
   }
}
  render() {
    // error caught only when using our vanilla ErrorBoundary
    if (this.state.counter === COUNT_LIMIT) {
      // Simulate a JS error
      throw new Error('Oops, crashed!');
    }
    return <h1 onClick={this.handleClick}>{this.state.counter}</h1>;
  }
}
export default BuggyCounter;