




import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
//import { InputField, CheckboxField, SelectField } from '../../../components/formfields';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';



const genders = [
  {
    value: 'M',
    label: 'Males'
  },
  {
    value: 'F',
    label: 'Female'
  },
  {
    value: 'U',
    label: 'Unknown'
  }
];

export default function ClassSchedulePreference(props) {
//  coursesInterestJson,

      const {
        formField: {
                    mondayMorning ,
                    mondayAfternoon,
                    mondayEvening,
                    mondayNight,
                    tuesdayMorning,
                    tuesdayAfternoon,
                    tuesdayEvening,
                    tuesdayNight,
                    wednesdayMorning,
                    wednesdayAfternoon,
                    wednesdayEvening,
                    wednesdayNight,
                    thursdayMorning,
                    thursdayAfternoon,
                    thursdayEvening,
                    thursdayNight,
                    fridayMorning,
                    fridayAfternoon,
                    fridayEvening,
                    fridayNight,
                    saturdayMorning,
                    saturdayAfternoon,
                    saturdayEvening,
                    saturdayNight,
                    sundayMorning,
                    sundayAfternoon,
                    sundayEvening,
                    sundayNight
        }
      } = props;


  return (

<Container  component="main" >
     <div>
       <Typography  component="h1" variant="h5" align="center" >
            Availability
       </Typography>

        <form>
           <Grid  container
                   spacing={0}
                   direction="column"
                   alignItems="center"
                   justifyContent="center"
                   sx={{ minHeight: '100vh' }} >

               <Grid item xl={3} >
                                     <div>Check Your Preferred Class Times</div>
                                     <div>(Morning: 8:00 AM - 11:30 AM GMT)</div>
                                     <div>(Afternoon: 12:00 PM - 3:30 PM GMT)</div>
                                     <div>(Evening: 4:00 PM - 7:30 PM GMT)</div>
                                     <div>(Night: 8:00 PM - 11:30 PM GMT)</div>
                              <div>
                                 <table name="availabilityTimes">
                                     <tr>
                                         <th scope="col"></th>
                                         <th scope="col">Morning |</th>
                                         <th scope="col">Afternoon|</th>
                                         <th scope="col">Evening|</th>
                                         <th scope="col">Night</th>
                                     </tr>
                                   <tr>
                                     <th scope="row">Monday</th>
                                     <td><CheckboxField name="mondayMorning" /></td>
                                     <td><CheckboxField name="mondayAfternoon"  /></td>
                                     <td><CheckboxField name="mondayEvening"  /></td>
                                     <td><CheckboxField name="mondayNight"  /></td>
                                   </tr>
                                   <tr>
                                     <th scope="row">Tuesday</th>
                                     <td><CheckboxField name="tuesdayMorning" /></td>
                                     <td><CheckboxField name="tuesdayAfternoon"  /></td>
                                     <td><CheckboxField name="tuesdayEvening" /></td>
                                     <td><CheckboxField name="tuesdayNight" /></td>
                                   </tr>
                                 <tr>
                                   <th scope="row">Wednesday</th>
                                   <td><CheckboxField name="wednesdayMorning"  /></td>
                                   <td><CheckboxField name="wednesdayAfternoon"  /></td>
                                   <td><CheckboxField name="wednesdayEvening"  /></td>
                                   <td><CheckboxField name="wednesdayNight"  /></td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Thursday</th>
                                   <td><CheckboxField name="thursdayMorning" /></td>
                                   <td><CheckboxField name="thursdayAfternoon"  /></td>
                                   <td><CheckboxField name="thursdayEvening" /></td>
                                   <td><CheckboxField name="thursdayNight" /></td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Friday</th>
                                   <td><CheckboxField name="fridayMorning" /></td>
                                   <td><CheckboxField name="fridayAfternoon" /></td>
                                   <td><CheckboxField name="fridayEvening"  /></td>
                                   <td><CheckboxField name="fridayNight" /></td>
                                 </tr>
                                  <tr>
                                   <th scope="row">Saturday</th>
                                   <td><CheckboxField name="saturdayMorning" /></td>
                                   <td><CheckboxField name="saturdayAfternoon"  /></td>
                                   <td><CheckboxField name="saturdayEvening" /></td>
                                   <td><CheckboxField name="saturdayNight"  /></td>
                                 </tr>
                                 <tr>
                                   <th scope="row">Sunday</th>
                                   <td><CheckboxField name="sundayMorning" /></td>
                                   <td><CheckboxField name="sundayAfternoon"  /></td>
                                   <td><CheckboxField name="sundayEvening" /></td>
                                   <td><CheckboxField name="sundayNight" /></td>
                                 </tr>
                                 </table>
                         </div>
               </Grid>
           </Grid>
         </form>

        </div>
  </Container>
);

}



