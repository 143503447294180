import React from 'react';
//import logo from '../images/xvlogo.png';
import logo from '../logo.svg';

import upriseLogo from '../static/images/uprise_logo.png';


const Header = () => {
return (
    <div>
        <img src={upriseLogo} alt="Xcelvations Logo" height="10" />
    </div>
    );
};export default Header;