import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../../actions/auth";

import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


class CreateCourse extends Component {

  constructor(props) {
    super(props);

     // this.saveCourse = this.saveCourse.bind(this);
    //  validation:  https://www.javascripttutorial.net/javascript-dom/javascript-form-validation/

    this.state = {

      id : "",
      courseGroups : [],
      successful: false,
      fields: {},
      errors: {}
    };


  }

  handleValidation() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //alert('2...this.state.fields: '+JSON.stringify(this.state.fields));

    //courseGroupCode
   // alert('fields["courseGroupCode"]: '+ fields["courseGroupCode"]);

    if (!fields["courseGroupCode"] || (fields["courseGroupCode"] === "Select Course Group")) {
      formIsValid = false;
      errors["courseGroupCode"] = "You must selct Course Group";
        //alert('3...:'+formIsValid);
    }


    // course code
     if (!fields["code"] || fields["code"].length < 3 ) {
        formIsValid = false;
         errors["code"] = 'Course code must not be empty or less than three characters';
      }
      //  alert('4...:'+formIsValid);

    // course name
     if (!fields["name"] || fields["name"].length < 3 ) {
        formIsValid = false;
         errors["name"] = 'Course name must not be empty or less than three characters';
      }
     // alert('5...:'+ formIsValid);

    // course description
     if (!fields["description"] || fields["description"].length < 6 ) {
        formIsValid = false;
         errors["description"] = 'Course description must not be empty or less than six characters';
      }
      //alert('6...:'+formIsValid);

     // course duration
      if (!fields["duration"] ) {
         formIsValid = false;
          errors["duration"] = 'Course duration must not be empty';
     }
      //alert('7...:'+ formIsValid);

    this.setState({ errors: errors });
      //alert('8...:'+formIsValid);
     //alert('formIsValid...:'+ formIsValid);
    return formIsValid;
  }

  handleChange(field, e) {

   console.log('handleChange(field, e) - this.state : entry');

      let fields = this.state.fields;
      if('checkbox' === e.target.type) {
            fields[field] =  e.target.checked;
            //alert('event.target.type:'+ e.target.type);
            //alert('e.target.checked:'+ e.target.checked);
        }
        else {
            fields[field] = e.target.value;
        }
     this.setState({ fields });
      console.log('handleChange(field, e) - this.state : final');
     //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
  }


 componentDidMount() {

        BSCommonService.getAllCourseGroups().then(
                  response => {
                    this.setState({
                      courseGroups: response.data
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
                console.log('loaded courseGroups - this.state.courseGroups :'+this.state.courseGroups);
  }


saveCourse(e) {

        e.preventDefault();

        if (!this.handleValidation()) {
            console.log('saveCourse invoked; invalid form ...');
             return;
        }

        console.log('saveCourse invoked ...');

        let data = {
          code: this.state.fields["code"],
          name: this.state.fields["name"],
          description: this.state.fields["description"],
          courseGroupCode: this.state.fields["courseGroupCode"],
          duration: this.state.fields["duration"],
          featured: this.state.fields["featured"]
        };

         //alert('data: '+ JSON.stringify(data));
         //return;

           console.log('saveCourse() JSON.stringify(data): ' + data);
          // console.log('saveCourse(field, e) - this.state :'+ JSON.stringify(this.state));

        CourseService.saveCourse(data)
          .then(response => {
            //this.setState({
            //  successful: true
            //});
           toast.success('🦄 Course saved successfully', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });

              console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
             this.setState({
                  successful: false,
                });

               toast.error('🦄 Error while saving course ...'+ e.message, {
                                 position: "top-center",
                                 autoClose: 5000,
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                                 });
          });
}


    onChangeCourseGroups(e) {
            this.setState({
              courseGroupCode: e.target.value
            });
            console.log('onChangeCourseGroups - this.state.courseGroupCode: '+ this.state.courseGroupCode);
   }


  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">

        <div className="formContainerDiv">

         <fieldset>
                 <legend>Course Detail</legend>
          <Form
            ref={(c) => {
              this.form = c;
            }}
          >

            {!this.state.successful && (
              <div>
                <div className="form-group">
                      <label htmlFor="courseName">Course Code</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="courseCode"

                        onChange={this.handleChange.bind(this, "code")}
                        value={this.state.fields["code"]}
                      />

                      <span style={{ color: "red" }}>{this.state.errors["code"]}</span>
                      <br />
                </div>
                <div className="form-group">
                  <label htmlFor="courseName">Course Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="courseName"
                     onChange={this.handleChange.bind(this, "name")}
                     value={this.state.fields["name"]}
                  />

                   <span style={{ color: "red" }}>{this.state.errors["name"]}</span>
                   <br />
                </div>

                <div className="form-group">
                  <label htmlFor="courseDescription">Course Description</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={this.handleChange.bind(this, "description")}
                    value={this.state.fields["description"]}
                  />

                 <span style={{ color: "red" }}>{this.state.errors["description"]}</span>
                 <br />
                </div>

            <div className="form-group">
              <label htmlFor="courseGroup">Course Group</label>

              <span className = "span-criteria" >
                 <select id="courseGroup" name="courseGroup"
                 onChange={this.handleChange.bind(this, "courseGroupCode")}>
                        <option value={this.state.fields["courseGroupCode"]}>Select Course Group</option>
                         {this.state.courseGroups.map(courseGroup => (
                               <option key={courseGroup.code} value={courseGroup.code}>
                                 {courseGroup.name}
                               </option>
                             ))}
                  </select>
             </span>
             <br />
             <span style={{ color: "red" }}>{this.state.errors["courseGroupCode"]}</span>
             <br />

            </div>

             <div className="form-group">
                  <label htmlFor="duration">Duration</label>
                  <Input
                    type="duration"
                    className="form-control"
                    name="duration"
                    onChange={this.handleChange.bind(this, "duration")}
                    value={this.state.fields["duration"]}
                  />

                 <span style={{ color: "red" }}>{this.state.errors["duration"]}</span>
                 <br />
                </div>

                <div className="form-group">
                       <label htmlFor="featured">Featured</label>
                         <span className="submit-span">
                             <label>
                               <input
                                 name="featured"
                                 id="featured"
                                 type="checkbox"
                                 onChange={this.handleChange.bind(this, "featured")}
                                 value={this.state.fields["featured"]}
                               />
                             </label>
                          </span>
                 </div>

                 <div className="form-group">
                        <label htmlFor="deletedFlag">Deleted Flag</label>
                          <span className="submit-span">
                              <label>
                                <input
                                  name="deleted"
                                  id="deleted"
                                  type="checkbox"
                                  onChange={this.handleChange.bind(this, "deleted")}
                                  value={this.state.fields["deleted"]}
                                />
                              </label>
                           </span>
                  </div>


                 <div class="form-group">
                    <ToastContainer />
                 </div>

                <div className="field-submit">
                    <p>
                       <span>
                           <button id="cancelButton" name="cancelButton" className="submit-span"  type="submit">Cancel</button>
                       </span>
                       <span>
                           <button id="resetButton" name="resetButton" onClick={this.newCourse}  className="submit-span"  type="submit">Reset</button>
                       </span>
                       <span>
                           <button id="saveButton"  name="saveButton" onClick={this.saveCourse.bind(this)}  className="submit-span" type="submit">Save</button>
                       </span>

                    </p>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(CreateCourse);
