import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import { useHistory } from "react-router-dom";
import { Router, Switch, Route, Link } from "react-router-dom";

export default function RegistrationStartPage() {

  const [value, setValue] = React.useState('');
  const [error, setError] = React.useState(false);
  const [helperText, setHelperText] = React.useState('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
   // setValue((event.target as HTMLInputElement).value);
    setValue(event.target.value);
    setHelperText(' ');
    setError(false);
  };


  let history = useHistory();


  const routeChange = (newPath) =>{
    let path = newPath;
    history.push(path);
  }


//     <Route exact path="/studentRegistrationForm" component={StudentRegistrationForm} />
//                      <Route exact path="/signUpStepper" component={Signup} />
 //                     <Route exact path="/facultyRegistrationForm" component={StudentRegistrationForm} />

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if ('student' === value) {
     // setHelperText('Sorry, wrong answer, student');
      setError(false);
       routeChange('/studentRegistrationForm');
    }
   else if ( 'trainer' === value) {
        //setHelperText('Sorry, wrong answer, ordinary user');
        setError(false);
         routeChange('/facultyRegistrationForm');
     }
    else if (value === 'ordinaryuser') {
        //setHelperText('Sorry, wrong answer, ordinary user');
        setError(false);
         routeChange('/signUpStepper');
     }
     else {
      setHelperText('Please select an option.');
      setError(true);
    }
  };

  return (
      <Container  component="main" >
    <React.Fragment>
       <Typography component="h4" variant="h5" align="center">
                  User Account Creation
        </Typography>

        <form onSubmit={handleSubmit}>
          <FormControl sx={{ m: 3 }} error={error} variant="standard">
            <FormLabel id="demo-error-radios">Are you registering as a student or a trainer?</FormLabel>
            <RadioGroup
              aria-labelledby="demo-error-radios"
              name="usergroup"
              value={value}
              onChange={handleRadioChange}>

              <FormControlLabel value="student" control={<Radio />} label="Student" />
              <FormControlLabel value="trainer" control={<Radio />} label="Trainer" />
              <FormControlLabel value="ordinaryuser" control={<Radio />} label="Not trainer and not  student" />

            </RadioGroup>
            <FormHelperText>{helperText}</FormHelperText>
            <Button sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
              Begin
            </Button>

          </FormControl>
        </form>
    </React.Fragment>
  </Container>


  );
}