import React, { Component } from "react";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

//import TutorialsAdminList from "./tutorial-admin-list.component"; // me
//import SessionTimeout from "./SessionTimeout";
//import BuggyCounter from "../common/BuggyCounter";
import PreviewExam from "./PreviewExam.component";
import ExamSetup from "./ExamSetup";
import ExamSetupNew from "./ExamSetupNew";
import ExamTimer from "./ExamTimer.component";
import ExamTake from "./ExamTake";
import ExamTakeNew from "./ExamTakeNew";
import ExamResult from "./ExamResult.component";

import ExamSetupForm from "./ExamSetupForm";
import ExamTestRun from "./ExamTestRun.component";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class ExamsAdminDashboard extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 3,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }



  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
            const {
              searchTitle,
              tutorials,
              currentTutorial,
              currentIndex,
              page,
              count,
              pageSize,
            } = this.state;

    return (

      <div className="list row">

        <div className="col-md-12">

        <hr />

        <Tabs>
            <TabList>
                 <Tab>Exam Setup</Tab>
                 <Tab>Exam Setup - New</Tab>
                 <Tab>Take Exam</Tab>
                 <Tab>Take Exam - New</Tab>
                 <Tab>Generate Result</Tab>
                 <Tab>Completion/Certificate Issuance</Tab>
            </TabList>

                <TabPanel>
                   <div>
                         <ExamSetup />
                   </div>
                </TabPanel>

                 <TabPanel>
                       <div>
                             <ExamSetupNew />
                       </div>
                    </TabPanel>

                <TabPanel>
                    <div>
                     <ExamTake />
                    </div>
                </TabPanel>
                <TabPanel>
                    <div>
                     <ExamTakeNew />
                    </div>
                  </TabPanel>
                   <TabPanel>
                    <div>
                     <ExamResult />
                    </div>
                   </TabPanel>
                <TabPanel>
                    <div>
                        Completion/Certificate Issuance
                    </div>
                </TabPanel>


        </Tabs>

        </div>


        <hr />

      </div>
    );
  }
}
