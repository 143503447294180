import React, { useState } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Formik, Form } from 'formik';
import useStyles from './styles';
import formInitialValues from './formInitialValues';
import BasicInfoForTrainer from './BasicInfoForTrainer';
import ContactInfo from './ContactInfo';
import EducationInfo from './EducationInfo';
import CourseOfInterest from './CourseOfInterest';
import ClassSchedulePreference from './ClassSchedulePreference';
import PaymentInfo from './PaymentInfo';
import Confirmation from './Confirmation';
import RegistrationFormValidationForFacultySchema from './RegistrationFormValidationForFacultySchema';
import RegistrationFormModel from './RegistrationFormModel';
import RegistrationFormFormitInitValues from './RegistrationFormFormitInitValues';
import SubmissionMessage from './SubmissionMessage';

import Debug from '../../../common/Debug';
import FormitLogger from '../../../common/FormitLogger';
import FacultyService from "../../../services/FacultyService";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const steps = ['Faculty BioData', 'Contact Info', 'Education',
'Course Of Interest', 'Class Schedule Preference',  'Confirmation', 'Submission Message'];
const { formId, formField } = RegistrationFormModel;

function _renderStepContent(step, values) {

  switch (step) {

    case 0:
      return <BasicInfoForTrainer formField={formField} />;
    case 1:
      return <ContactInfo formField={formField} />;
    case 2:
      return <EducationInfo formField={formField}  />;
    case 3:
        return <CourseOfInterest formField={formField} />;
    case 4:
        return <ClassSchedulePreference formField={formField} />;
    case 5:
         return <Confirmation values={ values } />;
    case 6:
         return  <SubmissionMessage />;
    default:
      return <div>Not Found</div>;
  }
}



export default function FacultyRegistrationForm() {

      const classes = useStyles();

      const [activeStep, setActiveStep] = useState(0);
      const currentValidationSchema = RegistrationFormValidationForFacultySchema[activeStep];
      const isLastStep = activeStep === steps.length - 1;


       const [values, setValues] = useState("");

      function _sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      async function _submitForm(values, actions) {
        await _sleep(500);
       // alert(JSON.stringify(values, null, 2));

        let tutorRegistrationRequest = {};
        tutorRegistrationRequest.employmentNumber = values.employmentNumber;
        tutorRegistrationRequest.userName = values.userName; // T
        tutorRegistrationRequest.firstName = values.firstName;
        tutorRegistrationRequest.middleName = values.middleName;
        tutorRegistrationRequest.lastName = values.lastName;
        tutorRegistrationRequest.password = values.password; //"passwordConfirmation": "test",
        tutorRegistrationRequest.dateOfBirth = new Date(values.dateOfBirth);  // Error? convertion
        tutorRegistrationRequest.gender = values.gender;
        tutorRegistrationRequest.email = values.email; //
        tutorRegistrationRequest.phoneNo = values.phoneNo;
        tutorRegistrationRequest.address1 = values.address1;//
        tutorRegistrationRequest.address2 = values.address2;//
        //tutorRegistrationRequest.isFaculty = true;
        tutorRegistrationRequest.city = values.city; //
        tutorRegistrationRequest.stateInCountry = values.stateInCountry ; //
        tutorRegistrationRequest.country = values.country; //
        tutorRegistrationRequest.resumeCoverNote = values.resumeCoverNote;
        tutorRegistrationRequest.introText = values.introText;
        tutorRegistrationRequest.profileText = values.profileText;
        tutorRegistrationRequest.coursesInterestJson =  JSON.stringify(values.coursesInterestJson) || null;
        tutorRegistrationRequest.certifications =  JSON.stringify(values.certifications) || null; //educationalLevelCd
        tutorRegistrationRequest.educationalLevelCd =  values.educationalLevelCd;
        tutorRegistrationRequest.employmentStatusCd = values.employmentStatusCd;
        tutorRegistrationRequest.realm = 'REALM_BS';
        tutorRegistrationRequest.userRoleCode = 'ROLE_FACULTY';

        if(null != values.photoFile) {
          tutorRegistrationRequest.photoFile = values.photoFile[0];
          tutorRegistrationRequest.photoFilePath = values.photoFile[0].name;
        }
        else {
           tutorRegistrationRequest.profilePhotoFilePath =  null;
           tutorRegistrationRequest.photoFile = null;
        }

        if(null != values.resumeFile) {
          tutorRegistrationRequest.resumeFile = values.resumeFile[0]; // TODO
          tutorRegistrationRequest.resumeFilePath =  values.resumeFile[0].name;

        }
        else {
            tutorRegistrationRequest.resumeFilePath =  null;
            tutorRegistrationRequest.resumeFile = null;
        }

        tutorRegistrationRequest.files = [tutorRegistrationRequest.photoFile, tutorRegistrationRequest.resumeFile];


        const tutorAvailabilityTimes = {
        "mondayMorning":values.mondayMorning || false ,
        "mondayAfternoon":values.mondayAfternoon || false ,
        "mondayEvening":values.mondayEvening || false ,
        "mondayNight":values.mondayNight || false ,
        "tuesdayMorning":values.tuesdayMorning || false ,
        "tuesdayAfternoon":values.tuesdayAfternoon || false ,
        "tuesdayEvening":values.tuesdayEvening || false ,
        "tuesdayNight":values.tuesdayNight || false ,
        "wednesdayMorning":values.wednesdayMorning || false ,
        "wednesdayAfternoon":values.wednesdayAfternoon || false ,
        "wednesdayEvening":values.wednesdayEvening || false ,
        "wednesdayNight":values.wednesdayNight || false ,
        "thursdayMorning":values.thursdayMorning || false ,
        "thursdayAfternoon":values.thursdayAfternoon || false ,
        "thursdayEvening":values.thursdayEvening || false ,
        "thursdayNight":values.thursdayNight || false ,
        "fridayMorning":values.fridayMorning || false ,
        "fridayAfternoon":values.fridayAfternoon || false ,
        "fridayEvening":values.fridayEvening || false ,
        "fridayNight":values.fridayNight || false ,
        "saturdayMorning":values.saturdayMorning || false ,
        "saturdayAfternoon":values.saturdayAfternoon || false ,
        "saturdayEvening":values.saturdayEvening || false ,
        "saturdayNight":values.saturdayNight || false ,
        "sundayMorning":values.sundayMorning || false ,
        "sundayAfternoon":values.sundayAfternoon || false ,
        "sundayEvening":values.sundayEvening || false ,
        "sundayNight":values.sundayNight || false
        };

        tutorRegistrationRequest.preferredClassScheduleJson = JSON.stringify(tutorAvailabilityTimes);

     alert("tutorRegistrationRequest: "+ JSON.stringify(tutorRegistrationRequest, null, 2));

        registerFacultyUser(tutorRegistrationRequest);

        actions.setSubmitting(false);
        setActiveStep(activeStep + 1);

      }

      function _handleSubmit(values, actions) {
        if (isLastStep) {
          _submitForm(values, actions);
        } else {
          setActiveStep(activeStep + 1);
          actions.setTouched({});
          actions.setSubmitting(false);
        }

         setValues(values);
         console.log("values in _handleSubmit function: "+ JSON.stringify(values, null, 2));
      }

      function _handlePrevious() {
        setActiveStep(activeStep - 1);
      }


   function registerFacultyUser(tutorRegistrationRequest) {
      console.log("JSON.stringify(tutorRegistrationRequest in registerTutorUser(tutorRegistrationRequest) function: "+ JSON.stringify(tutorRegistrationRequest));
      FacultyService.saveFacultyUser(tutorRegistrationRequest, tutorRegistrationRequest.files)
          .then(response => {
                toast.success('Your registration was successful', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
             console.log("response data after createFacultyUser: "+  JSON.stringify(response.data));
          })
          .catch(e => {
            toast.error('Error: Registration Failed ...', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
            console.log(e);
          });
      }



  return (


    <Container  component="main" >
             <ToastContainer />
      <Typography component="h4" variant="h5" align="center">
             Faculty Registration
      </Typography>
      <Stepper activeStep={activeStep} className={classes.stepper}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <React.Fragment>
               {activeStep === steps.length ? (
                  <div>Submission Message (End)</div>
                ) : (
                  <Formik
                        initialValues={RegistrationFormFormitInitValues}
                        onSubmit={_handleSubmit}
                        validationSchema={currentValidationSchema}
                    >
                    {({ isSubmitting }) => (
                      <Form id={formId} >

                        <FormitLogger />

                        {_renderStepContent(activeStep, values)}

                        <div className={classes.buttons}>
                          {activeStep !== 0 && (
                            <Button onClick={_handlePrevious} className={classes.button}>
                              Back
                            </Button>
                          )}
                          <div className={classes.wrapper}>
                            <Button
                              disabled={isSubmitting}
                              type="submit"
                              variant="contained"
                              color="primary"
                              className={classes.button}
                            >
                              {isLastStep ? 'Save' : 'Next'}
                            </Button>
                            {isSubmitting && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>

                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
     </React.Fragment>
    </Container>
  );

}