import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

//import UserService from "../services/user.service";
//import TutorialDataService from "../services/tutorial.service";
//import TutorialsList from "./tutorials-list.component";
//import Search from "./Search.component";

import AdminDashboard from "./AdminDashboard.component";
//import ModeratorDashboard from "./ModeratorDashboard.component";
import UserDashboard from "./UserDashboard.component";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

class Profile extends Component {

 constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

   componentDidMount() {
      /*UserService.getPublicContent().then(
        response => {
          this.setState({
            content: response.data
          });
        },
        error => {
          this.setState({
            content:
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          });
        }
      );*/

    }

  render() {

    const { user: currentUser } = this.props;

     //alert('JSON.stringify(this.props) in Profile page is:'+ JSON.stringify(this.props));
     //alert('JSON.stringify(currentUser) in Profile page is:'+ JSON.stringify(currentUser));

    let showAdminBoard = false;
    let showModeratorBoard = false;
    let showUserBoard = false;

    if (!currentUser) {
      console.log('Attempting to get to Profile page with no currentUser. Redirecting to /login...');
      return <Redirect to="/signin" />;
    }
    else {
        if(currentUser.roles) {
            showAdminBoard = currentUser.roles.includes("ROLE_ADMIN");
            showModeratorBoard = currentUser.roles.includes("ROLE_MODERATOR");
            showUserBoard = currentUser.roles.includes("ROLE_USER");
            // pick superior role for display
            if(showAdminBoard) {
             showModeratorBoard = null;
             showUserBoard = null;
            }
            if(showModeratorBoard) {
                 showUserBoard = null;
             }
        }
         console.log('JSON.stringify(currentUser) in Profile page is:'+ JSON.stringify(currentUser));
         console.log('currentUser.username in Profile page is:'+currentUser.username);
         console.log('currentUser.accessToken in Profile page is:'+currentUser.accessToken);
         console.log('>> showAdminBoardin Profile page is:'+ showAdminBoard);
         console.log('>> showModeratorBoard Profile page is:'+ showModeratorBoard);
         console.log('>> showUserBoard Profile page is:'+ showUserBoard);
    }

    // TODO - user showAdminBoard to show admin whenroles are set up properly
        /*<div>

             <h6>{this.state.content} </h6>
              <p />

             <header className="jumbotron">
             <h3>
               <strong>{currentUser.username}</strong> Profile
             </h3>
               </header>
               <p>
                    <strong> =>>>  JSON.stringify(currentUser): {JSON.stringify(currentUser)})</strong>
               </p>
               <p>
                <strong> Token currentUser.accessToken: {currentUser.accessToken}</strong>
               </p>
               <p>
                 <strong>Id:</strong> {currentUser.id}
               </p>
               <p>
                 <strong>Email:</strong> {currentUser.email}
               </p>
               <strong>Authorities:</strong>
               <ul>
                 {currentUser.roles &&
                   currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
               </ul>
        </div>*/

    return (
      <div className="container">

            <div>
                    {
                     showAdminBoard && (
                            <div>
                              {
                              <AdminDashboard />
                               }
                               AdminDashboard
                            </div>
                        )
                    }
                    {
                     showModeratorBoard && (
                            <div>
                               {
                              /* <ModeratorDashboard />*/
                               }
                               ModeratorDashboard
                            </div>
                        )
                    }
                    {
                     showUserBoard && (
                            <div>
                              {
                               <UserDashboard />
                              }
                              UserDashboard
                            </div>
                        )
                    }

            </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(Profile);
