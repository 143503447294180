
import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import useStyles from './styles';
//import { InputField, CheckboxField, SelectField } from '../../../components/formfields';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import { UploaderField }from '../../../components/formfields/UploaderField';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik, Form } from 'formik';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MultiSelectField from '../../../components/formfields/MultiSelectField';

import BSCommonService  from "../../../services/BSCommonService";


const genders = [
  {
    code: 'M',
    label: 'Male'
  },
  {
    code: 'F',
    label: 'Female'
  },
  {
    code: 'U',
    label: 'Unknown'
  }
];

  let employmentList = [];
  let empNumber = null;
 function validateEmploymentNumber(value) {
       let error;
       if (!value) {
         error = 'Employment number is required';
       }

       let employmentNoExists = false;
       for(var i = 0; i < employmentList.length; i++)
       {
         if(value == employmentList[i].employeeNumber)
         {
           employmentNoExists = true;
           empNumber = employmentList[i].employeeNumber;
            // this.props.values.employmentNumber = employmentList[i].employeeNumber;
         }
       }
        if (employmentNoExists == false) {
           error = 'Employment number not found';
        }
       return error;
 }

export default function BasicInfoForTrainer(props) {
       const {
         formField: {
           employmentNumber = empNumber,
           firstName,
           middleName,
           lastName,
           userName,
           password,
           passwordConfirmation,
           gender,
           dateOfBirth,
           photoFile
         }
       } = props;

  const classes = useStyles();



   const [employments, setEmployments] = useState([]);

               useEffect(() => {
                  loadEmployments();
                 }, []);

              const loadEmployments = async () => {
                   BSCommonService.getAllEmployment().then(
                 response => {
                   setEmployments(response.data);
                   employmentList = response.data;
                   console.log("employments: "+ JSON.stringify(response.data));

                 },
                 error => {
                   console.log("error.toString(): "+ error.toString());
                 }
               );
          };

  return (

        <Container  component="main" >
         <div>
          <Typography  variant="h6" className={classes.typo} >
             Basic Information
          </Typography>
                <form  >
                <Grid container spacing={3}>


                      <Grid item xs={12} sm={6}>
                        <InputField name="employmentNumber" label="Employment Number" validate={validateEmploymentNumber} fullWidth />
                      </Grid>
                       <Grid item xs={12} sm={6}>
                         <InputField name="firstName" label="First Name" fullWidth />
                       </Grid>
                       <Grid item xs={12} sm={6}>
                          <InputField name="middleName" label="Middle Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <InputField name="lastName" label="Last Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                           <SelectField
                                       name="gender"
                                       label="Gender"
                                       data={genders}
                                       fullWidth
                                     />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField name="userName" label="User Name" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputField  type="password" name="password" label="Password" fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                              <InputField  type="password" name="passwordConfirmation" label="Password Confirmation" fullWidth />
                         </Grid>

                        <Grid item xs={12} sm={6}>

                             <DatePickerField
                               name="dateOfBirth"
                               label="Date Of Birth"
                               margin="normal"
                               disableFuture
                               inputFormat="yyyy-MM-dd"
                               fullWidth
                             />

                         </Grid>

                          <Grid item xs={12} sm={6}>
                             Profile Image
                            <UploaderField name="photoFile" type="file" value={undefined}/>
                          </Grid>
                   </Grid>
                 </form>
            </div>
          </Container>
);

}
