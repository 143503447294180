export default {

        'firstName' : '',
        'middleName' : '',
        'lastName' : '',
        'userName' : '',
        'password' : '',
        'passwordConfirmation' : '',
        'gender' : '',

        'email' : '',
        'country' : ''
}