

import React from 'react';
import { Typography } from '@material-ui/core';

function SubmissionMessage() {
  return (
    <React.Fragment>
      <Typography variant="h5" gutterBottom>
        Thank you for your registration.
      </Typography>
      <Typography variant="subtitle1">
        You may now proceed to enroll for courses.
      </Typography>
    </React.Fragment>
  );
}

export default SubmissionMessage;