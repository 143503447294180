
import React, { useState , useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import useStyles from './styles';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import MultiSelect from '../../../components/formfields/MultiSelect';

import MultiSelectField from '../../../components/formfields/MultiSelectField';
import BSCommonService  from "../../../services/BSCommonService";


const courses_of_interest = [
  {
    code: 'JAVA',
    label: 'Java'
  },
  {
    code: '.NET',
    label: '.NET'
  },
  {
    code: 'DATASCI',
    label: 'Data Science'
  },
    {
      code: 'DB',
      label: 'Databases'
    }
    ,
     {
          code: 'WEBDESIGN',
          label: 'Web Design'
     }
];

const courses = [
  "Humaira Sims",
  "Santiago Solis",
  "Dawid Floyd",
  "Mateo Barlow",
  "Samia Navarro",
  "Kaden Fields",
  "Genevieve Watkins",
  "Mariah Hickman",
  "Rocco Richardson",
  "Harris Glenn"
];

//const options = [{label:'Option 1', code:"bb"}, {label:'Option 2', code:"aa"}];

export default function CourseOfInterest(props) {

             const [courses, setCourses] = useState('');

             useEffect(() => {
                loadCourses();
               }, []);

            const loadCourses = async () => {
                 BSCommonService.getAllCourses().then(
               response => {
                 setCourses(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };


        const classes = useStyles();

        const {
        formField: {
          coursesInterestJson
        }
        } = props;



return (

    <Container  component="main" >
     <div>
      <Typography  variant="h6" className={classes.typo} >
         Courses of interest
      </Typography>
       <fieldset>
            <legend></legend>
            <form>
            <Grid container spacing={3}>

                <MultiSelectField
                    name="coursesInterestJson"
                    label="Courses Of Interest"
                    data={courses}
                />

               </Grid>
             </form>
            </fieldset>
        </div>
      </Container>
);

}
