import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import session from "./session"; //

export default combineReducers({
  auth,
  message,
  session
});
