
import { useState } from "react";

export default function Grade() {
  const [data, setData] = useState([
    { subject: "subject 1", grade: 50 },
    { subject: "subject 2", grade: 40 },
    { subject: "subject 3", grade: 30 }
  ]);
  const onChangeRow = ({ type, index, value }) => {
    const newData = data.map((item, idx) => {
      if (idx === index)
        return {
          ...item,
          [type]: value
        };
      return item;
    });
    setData(newData);
  };
  const totalGrade = data.reduce((acc, cur) => {
    acc += cur.grade;
    return acc;
  }, 0);
  return (
    <div className="App">
      <table className="table-fill">
        <thead>
          <tr>
            <th className="text-left">Subjects</th>
            <th className="text-left">Grade</th>
          </tr>
        </thead>
        <tbody className="table-hover">
          {data.map((item, index) => (
            <tr key={index}>
              <td className="text-left">
                <input
                  type="text"
                  defaultValue={item.subject}
                  onChange={(e) =>
                    onChangeRow({
                      type: "subject",
                      index,
                      value: e.target.value
                    })
                  }
                />
              </td>
              <td className="text-left">
                <input
                  type="number"
                  defaultValue={item.grade}
                  onChange={(e) =>
                    onChangeRow({
                      type: "grade",
                      index,
                      value: parseInt(e.target.value || 0)
                    })
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>Total grade: {totalGrade}</div>
    </div>
  );
}
