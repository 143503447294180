import { makeStyles } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
export default makeStyles(theme => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  total: {
    fontWeight: '700'
  },
  title: {
    marginTop: theme.spacing(2)
  },
  stepper: {
      padding: theme.spacing(3, 0, 5)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1)
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },

    stepper: {
        padding: theme.spacing(3, 0, 5)
      },
      buttons: {
        display: 'flex',
        justifyContent: 'flex-end'
      },
      button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1)
      },
      wrapper: {
        margin: theme.spacing(1),
        position: 'relative'
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%'
      },

      appBar: {
          position: 'relative'
        }

}));


