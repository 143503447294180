import React from 'react';
import { at } from 'lodash';
import { TextField } from '@material-ui/core';
import { Box, Rating, Typography } from "@mui/material";
import ReactQuill from 'react-quill';
import QuillToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";
import "./style.css";

import { useField, useFormikContext, Field } from 'formik';

export default function RichTextField({ ...props }) {

  const { errorText, ...rest } = props;
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();

/*
  function _renderHelperText() {
    const [touched, error] = at(meta, 'touched', 'error');
    if (touched && error) {
      return error;
    }
  }
*/


  return (

        <div className="text-editor">
          <QuillToolbar />
          <ReactQuill

                      value={field.value}

                      onChange={(content, delta, source, editor) => {
                                    setFieldValue(field.name, editor.getHTML())
                         }}
                     theme="snow"
                     placeholder="Type in full text for publication..."
                     style={{
                        height: '200px',
                    }}

                  modules={modules}
                  formats={formats}
                />
       </div>
  );
}
