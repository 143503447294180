
import React, { useState, useEffect , Component} from 'react';
//import {useState, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
//import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";

import InputField from '../components/formfields/InputField';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import MultiSelectField from '../components/formfields/MultiSelectField';
import DatePickerField from '../components/formfields/DatePickerField';
import RichTextField from '../components/formfields/RichTextField';
import {UploaderField} from '../components/formfields/UploaderField';
import FormitLogger from '../common/FormitLogger';
import BSCommonService  from "../services/BSCommonService";
import ClassService  from "../services/ClassService";
import BlogService from './services/BlogService';
import PublicationService from '../services/PublicationService';

import { v4  } from 'uuid';



const courseGroups = [
  {
    value: 'JAVA',
    label: 'Java'
  },
  {
    value: '.NET',
    label: '.Net'
  },
  {
    value: 'DB',
    label: 'Database'
  }
];

/*


    private Long id;
    private String category;
    private String title;

    private String originatingClassCode;
    private String originatingCoursesCode;
    private String syllabusItemRef;
    private Integer publicationVersNo;

    private String  content;
    private String  attachmentFilePaths;
    private String  standardTag;
    private String  customTag;
    private Boolean isPublished;
    private Date    publishDate;
    private Boolean isFeatured;
    private Boolean isDelete;

    private MultipartFile attachments[];''
            */

const ContentCreationSchema = Yup.object().shape({
   category: Yup.string()
     .required('Required'),
    title: Yup.string()
    .required('Required'),
    content: Yup.string()
    .min(100, 'Too Short!')
    .required('Required'),
 });


const publicationSections = [
  {
    code: 'TECH',
    label: 'Technology'
  },
  {
    code: 'LIT',
    label: 'Literature'
  },
  {
    code: 'GEN',
    label: 'General'
  },
    {
      code: 'Nature',
      label: 'Nature'
    }
];


const publicationTypes = [
  {
    code: 'BLG',
    label: 'Blog'
  },
  {
    code: 'TRN',
    label: 'Teaching/Training Resources/Notes'
  },
  {
    code: 'PRP',
    label: 'Private Publication'
  }
  ,
    {
      code: 'PRN',
      label: 'Private Resources/Notes'
    },
    {
      code: 'FPL',
      label: 'For Public'
    }
];


function renderTrainingMaterialsPublicationFields(classList, courses) {

 return (
    <>
        <Grid item xs={6} sm={6}>
              <SelectField
              name="originatingClassCode"
              label="Originating Class"
              data={classList}
              fullWidth
              />
        </Grid>
         <Grid item xs={6} sm={6}>
            <SelectField
              name="originatingCoursesCode"
              label="Originating Course"
              data={courses}
              fullWidth
              />
        </Grid>

        <Grid item xs={6} sm={6}>
              <InputField
              name="syllabusItemRef"
              label="Syllabus Reference"
              fullWidth
              />
        </Grid>
         <Grid item xs={6} sm={6}>
            <InputField
              name="publicationVersNo"
              label="Material Version No"
              fullWidth
              />
        </Grid>

    </>
    );

}

export default function PublicationCreation() {

      const [classList, setClassList] = useState([]);
      const [courses, setCourses] = useState([]);

             useEffect(() => {
                loadCourses();
               }, []);

            const loadCourses = async () => {
                 BSCommonService.getAllCourses().then(
               response => {
                 setCourses(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };

         useEffect(() => {
                loadClasses();
               }, []);

         const loadClasses = async () => {
                 ClassService.getAllClasses().then(
                   response => {
                              console.log("classList Fetched: "+ JSON.stringify(response.data));
                     setClassList(response.data);
                   },
                   error => {
                     console.log("error.toString(): "+ error.toString());
                   }
             );
          };

const parentDivStyle = {
            width: "90%",
            marginLeft: "5%",
            marginRight: "10%",
    };


  return (

          <Container  component="main" >
            <ToastContainer />

            <div style={parentDivStyle}>
                <Grid  component="h5" variant="h5" item xs={12} style={{textAlign: "center"}}>
                       Content  Creation
                </Grid>


              <Formik
                    initialValues={{
                          publicationType: '',
                          category: '',
                          originatingClassCode: '',
                          originatingCoursesCode: '',
                          syllabusItemRef: '',
                          publicationVersNo: '',
                          title: '',
                          content: '',
                          attachments: null,
                          attachmentFilePaths: '',
                          isPublished: null,
                          publishDate: null,
                          isFeatured: null,
                          isDelete: null,
                          tag: '',
                          customTag: ''
                        }}


                     enableReinitialize={true}

                     validationSchema={ContentCreationSchema}

                     onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {

                           alert(JSON.stringify(values, null, 2));

                            let contentRequestRequest = {};
                            contentRequestRequest.id = values.id;
                            contentRequestRequest.category = values.category;
                            contentRequestRequest.publicationType = values.publicationType;
                            contentRequestRequest.title = values.title;
                            contentRequestRequest.originatingClassCode = values.originatingClassCode;
                            contentRequestRequest.originatingCoursesCode = values.originatingCoursesCode;
                            contentRequestRequest.syllabusItemRef = values.syllabusItemRef;
                            contentRequestRequest.publicationVersNo = values.publicationVersNo;
                            contentRequestRequest.content = values.content;
                            contentRequestRequest.attachmentFilePaths = values.attachmentFilePaths;

                            alert('values.attachments; '+ values.attachments);


                            contentRequestRequest.standardTag = values.standardTag;
                            contentRequestRequest.customTag = values.customTag;
                            contentRequestRequest.isPublished = values.isPublished;
                            contentRequestRequest.publishDate = values.publishDate;
                            contentRequestRequest.isFeatured = values.isFeatured;
                            contentRequestRequest.isDelete = values.isDelete;

                            let attachments = [];

                          //contentRequestRequest.attachments = [contentRequestRequest.attachment1,contentRequestRequest.attachment2,
                          //                                  contentRequestRequest.attachment3, contentRequestRequest.attachment4, contentRequestRequest.attachment5];
                          if(null != values.attachments) {
                          for(let i = 0; i < values.attachments.length; i++) {
                             attachments.push(values.attachments[i]);
                          }
                        }

                         alert('attachments: '+ attachments);

                        if(contentRequestRequest.publicationType === 'BLG') {
                             PublicationService.saveResourceForBlog(contentRequestRequest, attachments).then(
                                   response => {
                                     toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                     toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error Resource content [error.toString()] : "+ error.toString());
                               }
                              );
                          setSubmitting(false);
                        } else  if(contentRequestRequest.publicationType === 'TRN')  {
                            PublicationService.saveResourceForTeaching(contentRequestRequest, attachments).then(
                                   response => {
                                     toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                     toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error Resource content [error.toString()] : "+ error.toString());
                               }
                              );

                            setSubmitting(false);
                        }
                        else  if(contentRequestRequest.publicationType === 'PRP')  {
                            PublicationService.saveResourceForPersonalPublication(contentRequestRequest, attachments).then(
                                   response => {
                                     toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                     toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error Resource content [error.toString()] : "+ error.toString());
                               }
                              );

                            setSubmitting(false);
                        }
                        else  if(contentRequestRequest.publicationType === 'PRN')  {
                            PublicationService.saveResourceForPersonalNote(contentRequestRequest, attachments).then(
                                   response => {
                                     toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                     toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error Resource content [error.toString()] : "+ error.toString());
                               }
                              );

                            setSubmitting(false);
                        }
                          else  if(contentRequestRequest.publicationType === 'FPL')  {
                            PublicationService.saveResourceForPublic(contentRequestRequest, attachments).then(
                                   response => {
                                     toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                   },
                               error => {
                                     toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error Resource content [error.toString()] : "+ error.toString());
                               }
                              );

                            setSubmitting(false);
                        }



                        }, 400);
                      }}


// TRN

            render= {({ values }) => (

               <Form>
                 <Grid container spacing={3}>
                  <FormitLogger />

                            <Grid item xs={6} sm={6}>
                                <SelectField
                                          name="publicationType"
                                          label="Publication Type"
                                          data={publicationTypes}
                                          fullWidth
                                  />
                           </Grid>
                            <Grid item xs={6} sm={6}>
                                    <SelectField
                                     name="category"
                                     label="Publication Section"
                                     data={publicationSections}
                                     fullWidth
                                  />
                            </Grid>




                             { values.publicationType === "TRN" ? renderTrainingMaterialsPublicationFields(classList, courses):  null }




                            <Grid item xs={12} sm={12}>
                                <InputField
                                  name="title"
                                  label="Title"
                                  fullWidth
                                  />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                   <RichTextField
                                     name="content"
                                     label="Publication Content"
                                     fullWidth
                                     />
                              </Grid>
                            <br />
                            <br />
                            <p />
                            <p />
                              <Grid item xs={12} sm={12}>
                                 Publication Attachment
                                 <UploaderField name="attachments" type="file" multiple value={undefined}  />
                              </Grid>

                          <p />
                          <p />


                          <Grid item xs={6} sm={6} >
                                <SelectField
                                          name="standardTag"
                                          label="Select Tag"
                                          data={publicationTypes}
                                          fullWidth
                                  />
                           </Grid>
                            <Grid item xs={6} sm={6} >
                                    <InputField
                                     name="customTag"
                                     label="Enter Tag"
                                     data={publicationSections}
                                     fullWidth
                                  />
                            </Grid>

                </Grid>

                   <p />
                    <Grid item xs={4} sm={4}>
                             <Button id="cancelButton" name="cancelButton" className="submit-span"  type="button">Cancel</Button>

                             <Button id="resetButton" name="resetButton"   className="submit-span"  type="button">Reset</Button>

                             <Button id="saveButton"  name="saveButton"  className="submit-span"type="submit">Post</Button>
                     </Grid>

                     <br />
                     <br />
               </Form>

             )}


            />

         </div>
        </Container>
);

}

