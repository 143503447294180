import React from 'react'
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'
import InputField from '../../../../components/formfields/InputField';
import CheckboxField from '../../../../components/formfields/CheckboxField';
import SelectField from '../../../../components/formfields/SelectField';
import DatePickerField from '../../../../components/formfields/DatePickerField';


const UserDetail = () => {


  return (
    <Container  component="main" maxWidth="xs">
      <div>
        <Typography  component="h5" variant="h5">
          User Bio Data zzzz
        </Typography>
        <form>
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6}>
                <InputField name="firstName" label="First Name" fullWidth />
            </Grid>

            <Grid item xs={12} sm={6}>
                 <InputField name="middleName" label="Middle Name" fullWidth />
            </Grid>

            <Grid item sm={6}>
               <InputField name="lastName" label="Last Name" fullWidth />
            </Grid>

            <Grid item sm={6}>
              <InputField name="userName" label="User Name" fullWidth />
            </Grid>

            <Grid item sm={6}>
              <InputField type="password" name="password" label="Password" fullWidth />
            </Grid>

            <Grid item sm={6}>
               <InputField type="password" name="passwordConfirmation" label="Password Confirmation" fullWidth />
            </Grid>

          </Grid>
        </form>
      </div>
    </Container>
  )
}

export default UserDetail
