import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class PublicationService {

 saveResourceForBlog(data, attachments) {
    console.log('data from PublicationService:'+JSON.stringify(data));
    console.log('attachments from PublicationService: >>>'+attachments);

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("publicationType", data.publicationType);
    formData.append("category", data.category);
    /*
    formData.append("originatingClassCode", data.originatingClassCode);
    formData.append("originatingCoursesCode", data.originatingCoursesCode);
    formData.append("syllabusItemRef", data.syllabusItemRef);
    formData.append("publicationVersNo", data.publicationVersNo);
    formData.append("attachmentFilePaths", data.attachmentFilePaths);
   formData.append("isPublished", data.isPublished);
    formData.append("publishDate", data.publishDate);
    formData.append("isFeatured", data.isFeatured);
    formData.append("isDelete", data.isDelete);*/

    formData.append("standardTag", data.standardTag);
    formData.append("customTag", data.customTag);
    formData.append("title", data.title);
    formData.append("content", data.content);


    //if(data.collectionTypeCd) {
    //     formData.append("collectionTypeCd", data.collectionTypeCd);
   // }


    for(let i = 0; i < attachments.length; i++) {
       formData.append('attachments', attachments[i])
    }


   // formData.append("attachments", attachments);
    // if list of files - handle same way also if in backend
    //for(let i = 0; i< e.target.files.length; i++) {
    //            formData.append('file', e.target.files[i])
    //   }
    //console.log('data.collectionTypeCd:'+data.collectionTypeCd);
    //console.log('formData:'+formData);

    console.log('formData from PublicationService: >>>'+JSON.stringify(formData));

    // get user token
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);
    if(user && user.accessToken) {
        token = user.accessToken;
     }

   const config = {
        headers: {
        'content-type': 'multipart/form-data'
        }
    }

     // works
    return http.post("/bs/resource/blog/save", formData, config);

  }


  getAllBlogs(params) {
    return http.get("/bs/resource/blogs", { params });
  }

  fetchBlog(id) {
        console.log('PublicationService fetchBlog(id):'+id);
        return http.get('/bs/resource/blog/'+id);
    }

  getAllTeachingResource(params) {
    return http.get("/bs/resource/training-resources", { params });
  }

  getAllPublicResource(params) {
      return http.get("/bs/resource/public-resources", { params });
  }

 getAllPersonalResource(params) {
      return http.get("/bs/resource/personal-publication-resources", { params });
  }


   getAllPersonalNoteResource(params) {
        return http.get("/bs/resource/note-resources", { params });
    }

 getPublicationResource(pubType, resourceId) {
     return http.get('/bs/resource/publication/pubType/'+ pubType + '/identifier/' + resourceId );
}

saveResourceForTeaching(data, attachments) {
    console.log('data from PublicationService:'+JSON.stringify(data));
    console.log('attachments from PublicationService: >>>'+attachments);

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("tag", data.tag);
    formData.append("customTag", data.customTag);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("category", data.category);
    formData.append("originatingClassCode", data.originatingClassCode);
    formData.append("originatingCoursesCode", data.originatingCoursesCode);
    formData.append("syllabusItemRef", data.syllabusItemRef);
    formData.append("publicationVersNo", data.publicationVersNo);

    for(let i = 0; i < attachments.length; i++) {
       formData.append('attachments', attachments[i])
    }

    console.log('formData from PublicationService: >>>'+JSON.stringify(formData));

    // get user token
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);
    if(user && user.accessToken) {
        token = user.accessToken;
     }

   const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization' : `Bearer ${token}`
        }
    }

     // works
    return http.post("/bs/resource/teaching/save", formData, config);

  }

 saveResourceForPersonalPublication(data, attachments) {
    console.log('data from PublicationService:'+JSON.stringify(data));
    console.log('attachments from PublicationService: >>>'+attachments);

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("tag", data.tag);
    formData.append("customTag", data.customTag);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("category", data.category);

    for(let i = 0; i < attachments.length; i++) {
       formData.append('attachments', attachments[i])
    }

    console.log('formData from PublicationService: >>>'+JSON.stringify(formData));

    // get user token
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);
    if(user && user.accessToken) {
        token = user.accessToken;
     }

   const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization' : `Bearer ${token}`
        }
    }

     // works
    return http.post("/bs/resource/personal/publication/save", formData, config);

  }



 saveResourceForPersonalNote(data, attachments) {
    console.log('data from PublicationService:'+JSON.stringify(data));
    console.log('attachments from PublicationService: >>>'+attachments);

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("tag", data.tag);
    formData.append("customTag", data.customTag);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("category", data.category);

    for(let i = 0; i < attachments.length; i++) {
       formData.append('attachments', attachments[i])
    }

    console.log('formData from PublicationService: >>>'+JSON.stringify(formData));

    // get user token
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);
    if(user && user.accessToken) {
        token = user.accessToken;
     }

   const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization' : `Bearer ${token}`
        }
    }

     // works
    return http.post("/bs/resource/personal/note/save", formData, config);

  }


saveResourceForPublic(data, attachments) {
    console.log('data from PublicationService:'+JSON.stringify(data));
    console.log('attachments from PublicationService: >>>'+attachments);

    let formData = new FormData();

    formData.append("id", data.id);
    formData.append("tag", data.tag);
    formData.append("customTag", data.customTag);
    formData.append("title", data.title);
    formData.append("content", data.content);
    formData.append("category", data.category);

    for(let i = 0; i < attachments.length; i++) {
       formData.append('attachments', attachments[i])
    }

    console.log('formData from PublicationService: >>>'+JSON.stringify(formData));

    // get user token
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
    let token = JSON.stringify(user);
    if(user && user.accessToken) {
        token = user.accessToken;
     }

   const config = {
        headers: {
        'content-type': 'multipart/form-data',
        'Authorization' : `Bearer ${token}`
        }
    }

     // works
    return http.post("/bs/resource/public/save", formData, config);

  }



}

export default new PublicationService();