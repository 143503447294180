
export default {

  formId: 'regForm',

  formField: {

            employmentNumber: {
              name: 'employmentNumber',
              label: 'Employment Number *',
              requiredErrorMsg: 'Employment Number is required'
            },
            firstName: {
              name: 'firstName',
              label: 'First name*',
              requiredErrorMsg: 'First name is required'
            },
            middleName: {
              name: 'middleName',
              label: 'Middle name'
            },
            lastName: {
              name: 'lastName',
              label: 'Last name*',
              requiredErrorMsg: 'Last name is required'
            },
            userName: {
              name: 'userName',
              label: 'User Name*',
              requiredErrorMsg: 'User Name is required'
            },
        password: {
              name: 'password',
              label: 'Password*',
              requiredErrorMsg: 'Password is required'
            },
        passwordConfirmation: {
            name: 'passwordConfirmation',
            label: 'Password Confirmation*',
            requiredErrorMsg: 'Password Confirmation is required'
          },
        gender: {
          name: 'gender',
          label: 'Gender*',
          requiredErrorMsg: 'Gender is required'
        },
        dateOfBirth: {
          name: 'dateOfBirth',
          label: 'Date Of Birth*',
          requiredErrorMsg: 'Date Of Birth is required'
        },

    photoFile: {
          name: 'photoFile',
          label: 'Photo File'
        },

    resumeFile: {
          name: 'resumeFile',
          label: 'Photo File'
        },

        email: {
          name: 'email',
          label: 'Email Id*',
          requiredErrorMsg: 'Email Id is required',
          invalidErrorMsg: 'Email Id is not valid (e.g. abc@yahoo.com)'
        },
        phoneNo: {
          name: 'phoneNo',
          label: 'Phone No*',
          requiredErrorMsg: 'Phone No is required'
        },
        address1: {
          name: 'address1',
          label: 'Address*',
          requiredErrorMsg: 'Address 1 is required'
        },
         address2: {
              name: 'address2',
              label: 'Address2*'
            },
        city: {
          name: 'city',
          label: 'City'
        },
         stateInCountry: {
              name: 'stateInCountry',
              label: 'State or province'
            },
        country: {
          name: 'country',
          label: 'Country*',
          requiredErrorMsg: 'Country is required'
        },
        certifications: {
              name: 'certifications',
              label: 'Enter your certifications*',
              requiredErrorMsg: 'Certification is required'
            } ,
        educationalLevelCd: {
          name: 'educationalLevelCd',
          label: 'Educational Level*',
          requiredErrorMsg: 'Educational Level is required'
        } ,
        employmentStatusCd: {
              name: 'employmentStatusCd',
              label: 'Employment Status*',
              requiredErrorMsg: 'Employment Status is required'
            },
        coursesInterestJson: {
          name: 'coursesInterestJson',
          label: 'Courses Of Interest*',
          requiredErrorMsg: 'Courses Of Interest is required'
        },

        mondayMorning : {
        name: 'mondayMorning',
        label: ''
        },
        mondayAfternoon : {
        name: 'mondayAfternoon',
        label: ''
        },
        mondayEvening : {
        name: 'mondayEvening',
        label: ''
        },
        mondayNight : {
          name: 'mondayNight',
          label: ''
        },
        tuesdayMorning : {
        name: 'tuesdayMorning',
        label: ''
        },
        tuesdayAfternoon : {
        name: 'tuesdayAfternoon',
        label: ''
        },tuesdayEvening : {
        name: 'tuesdayEvening',
        label: ''
        },tuesdayNight : {
          name: 'tuesdayNight',
          label: ''
        },
        tuesdayNight : {
        name: 'wednesdayMorning',
        label: ''
        },
        wednesdayAfternoon : {
        name: 'wednesdayAfternoon',
        label: ''
        },   wednesdayEvening : {
        name: 'wednesdayEvening',
        label: ''
        },wednesdayNight : {
          name: 'wednesdayNight',
          label: ''
        } ,
        thursdayMorning : {
        name: 'thursdayMorning',
        label: ''
        },
        thursdayAfternoon : {
        name: 'thursdayAfternoon',
        label: ''
        },   thursdayEvening : {
        name: 'thursdayEvening',
        label: ''
        },thursdayNight : {
          name: 'thursdayNight',
          label: ''
        },
        fridayMorning : {
        name: 'fridayMorning',
        label: ''
        },
        fridayAfternoon : {
        name: 'fridayAfternoon',
        label: ''
        },fridayEvening : {
        name: 'fridayEvening',
        label: ''
        },fridayNight : {
          name: 'fridayNight',
          label: ''
        },
        saturdayMorning : {
            name: 'saturdayMorning',
            label: ''
        },
        saturdayAfternoon : {
        name: 'saturdayAfternoon',
        label: ''
        },   saturdayEvening : {
        name: 'saturdayEvening',
        label: ''
        },saturdayNight : {
          name: 'saturdayNight',
          label: ''
        } ,
         sundayMorning : {
         name: 'sundayMorning',
         label: ''
        },   sundayAfternoon : {
        name: 'sundayAfternoon',
        label: ''
        },sundayEvening : {
          name: 'sundayEvening',
          label: ''
        },sundayNight : {
           name: 'sundayNight',
           label: ''
         },
        resumeFile: {
           name: 'resumeFile',
           label: 'Resume',
           requiredErrorMsg: 'Resume is required'
         },
        paymentFile: {
            name: 'paymentFile',
            label: 'Upload Payment Receipt File',
            requiredErrorMsg: 'Payment Receipt is required'
          },
        paymentChannels: {
          name: 'paymentChannels',
          label: 'Payment Channel Information',
          requiredErrorMsg: 'Payment Channel is required'
        }

  }

};
