import { makeStyles } from '@material-ui/core/styles';
import { cyan } from '@material-ui/core/colors';
export default makeStyles(theme => ({
    listItem: {
    padding: theme.spacing(1, 0)
    },
    total: {
    fontWeight: '700'
    },
    title: {
    marginTop: theme.spacing(2)
    },
    stepper: {
      padding: theme.spacing(3, 0, 5)
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end'
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1)
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%'
    },
    appBar: {
      position: 'relative'
    },
     typo: {
        flexGrow: 1,
        textAlign: "center"
      },
      root: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
          width: 600,
          marginLeft: 'auto',
          marginRight: 'auto'
        },
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
      },
      paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
          marginTop: theme.spacing(6),
          marginBottom: theme.spacing(6),
          padding: theme.spacing(3)
        }
      }



}));


