import React, { Component } from "react";
//import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import { Link, Button, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
//import { FaBeer } from 'react-icons/fa';
//import styled from "styled-components";
 import { Formik, Field, Form, FieldArray, useFormikContext,ErrorMessage } from 'formik';
 import * as Yup from 'yup';
 import ClassRollCall from "./ClassRollCall";

import { connect } from "react-redux";
import { register } from "../../actions/auth";


export default function RollCall() {

        let searchTextInput = React.createRef();
        let tagSelectInput = React.createRef();

        const [isAuthenticated, setIsAuthenticated] = useState(
            () => JSON.parse(localStorage.getItem('user_byte_steroid')) || false
        );

        const setAuth = (value) => {
            setIsAuthenticated(value);
            console.log('setIsAuthenticated value: '+value);
        };

        useEffect(()=> {
        localStorage.setItem("user_byte_steroid", JSON.stringify(isAuthenticated));
        }, [isAuthenticated]);


        const history = useHistory();

      function goToRecordAttendance() {
                    if(isAuthenticated) {
                          history.push("/recordAttendance");
                     }
                     else {
                          history.push("/login");
                     }
              }

        function goToManageAttendance() {
                       if(isAuthenticated) {
                             history.push("/recordedAttendanceMgt");
                        }
                        else {
                             history.push("/login");
                        }
                 }

    return (
      <div className="col-md-12">

           <div className="container">

                         <span style={{margin: "10px"}}>
                            <button type="button" onClick={goToRecordAttendance}> Record Attendance</button>
                        </span>

                         <span style={{margin: "10px"}}>
                              <button type="button" onClick={goToManageAttendance}> Manage Attendance</button>
                         </span>
            </div>

            <div>
                 <Formik>
                        <Form>

                        </Form>
                 </Formik>
            </div>

      </div>
    );

}