import * as Yup from 'yup';
import moment from 'moment';
import UserFormModel from './UserFormModel';

const {
      formField:
          {
            firstName,
            middleName,
            lastName,
            userName,
            password,
            passwordConfirmation,
            gender,

            email,
            country

          }
    } = UserFormModel;


export default [

        Yup.object().shape({
            [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
            [middleName.name]: Yup.string().required(`${middleName.requiredErrorMsg}`),
            [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
            [userName.name]: Yup.string().required(`${userName.requiredErrorMsg}`),
            [password.name]: Yup.string().required(`${password.requiredErrorMsg}`),
            [passwordConfirmation.name]: Yup.string().required(`${passwordConfirmation.requiredErrorMsg}`),
            [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`)
        }),
        Yup.object().shape({
            [email.name]: Yup.string().required(`${email.requiredErrorMsg}`),
            [country.name]: Yup.string().required(`${country.requiredErrorMsg}`)
                         }),
         Yup.object().shape({

                           })
];
