import React, { Component } from "react";
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";

import UserService from "../services/user.service";
import TutorialDataService from "../services/tutorial.service";
import TutorialsList from "./tutorials-list.component";
import Search from "./Search.component";
import AdminDashboard from "./AdminDashboard.component";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";


class UserProfile extends Component {

 constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

   componentDidMount() {
      UserService.getPublicContent().then(
        response => {
          this.setState({
            content: response.data
          });
        },
        error => {
          this.setState({
            content:
              (error.response && error.response.data) ||
              error.message ||
              error.toString()
          });
        }
      );
    }

  render() {

    const { user: currentUser } = this.props;

 return (
      <div className="container">
            <div>
                 <h6> Hi, {currentUser.username}</h6>

                  <h6>{this.state.content} </h6>
                   <p />

                  <h3>
                    <strong>{currentUser.username}</strong> Profile
                  </h3>

                    <p>
                         <strong> =>>>  JSON.stringify(currentUser): {JSON.stringify(currentUser)})</strong>
                    </p>
                    <p>
                     <strong> Token currentUser.accessToken: {currentUser.accessToken}</strong>
                    </p>
                    <p>
                      <strong>Id:</strong> {currentUser.id}
                    </p>
                    <p>
                      <strong>Email:</strong> {currentUser.email}
                    </p>
                    <strong>Authorities:</strong>
                    <ul>
                      {currentUser.roles &&
                        currentUser.roles.map((role, index) => <li key={index}>{role}</li>)}
                    </ul>
            </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.auth;
  return {
    user,
  };
}

export default connect(mapStateToProps)(UserProfile);
