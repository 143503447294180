import http from "../http-common";

class TutorialDataService {

  getAll(params) {
    return http.get("/tutorials", { params });
  }

  get(id) {
    return http.get(`/tutorials/${id}`);
  }

  create(data) {
    return http.post("/tutorials", data);
  }

  update(id, data) {
    return http.put(`/tutorials/${id}`, data);
  }

  delete(id) {
    return http.delete(`/tutorials/${id}`);
  }

  deleteAll() {
    return http.delete("/tutorials");
  }

  //======= from admin service react table example
  remove = (id) => {
    return http.delete(`/tutorials/${id}`);
  }
}

export default new TutorialDataService();
