
import React, { useState, useEffect, useRef} from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import DatePickerField from '../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

 import { Formik, Field, Form, FieldArray, useFormikContext,ErrorMessage } from 'formik';
 import * as Yup from 'yup';
import moment from 'moment';

import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";
import StudentService  from "../../services/StudentService";

import FormitLogger  from "../../common/FormitLogger";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom'; // TODO - UPDATE TO THIS
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ClassService  from "../../services/ClassService";

// https://fkhadra.github.io/react-toastify/api/toast
// https://fkhadra.github.io/react-toastify/introduction

const toastOptions = {
    //onOpen: props => console.log(props.foo),
    //onClose: props => console.log(props.foo),
    autoClose: 500,
    closeButton: null,
    type: toast.TYPE.INFO,
    hideProgressBar: false,
    position: toast.POSITION.TOP_CENTER,
    pauseOnHover: true,
    transition: null,
    progress: 0.2
    // and so on ...
};


export default function ClassRollCall(props) {


    const ref = useRef(null);
    //const { setFieldValue } = useFormikContext();
    const history = useHistory();
    const [isLoading, SetLoading] = useState(false);
    const [courseList, setCourseList] = useState([]);
  //  const [rollCallData, setRollCallData] = useState({rollCallData:{}});
   const [rollCallData, setRollCallData] = useState({
                                                     "id":null,
                                                     "rollCallJsonText":null,
                                                     "userId":null,
                                                     "studentId":null,
                                                     "effectiveDate":"",
                                                     "classId":null,
                                                     "classCode":"",
                                                     "rollCallData":{
                                                        "effectiveDate":"",
                                                        "classCode":"",
                                                        "classList":[

                                                        ],
                                                        "forNewRecord":false
                                                     },
                                                     "forNewRecord":null
                                                  });



   /* let studentList = {
                      courseCode : "JAVA131",
                      effectiveDate : "02-22-2023",
                      classList: [
                            {
                            id: "1",
                            studentId: "1",
                            firstName: 'Johny',
                            middleName: 'Dandy',
                            lastName: 'Igwe',
                            isPresent: false
                            }
                      ]
                      };*/
    //const [classList, setClassList] = useState( studentList);

    useEffect(() => {
      loadCourseList();
     }, []);

  useEffect(() => {
    //  fetchRolls();
     }, []);

  useEffect(() => {
       fetchClasses();
     }, []);

   const [classSelectItemsList, setClassSelectItemsList] = useState([]);
    const loadCourseList = async () => {
           CourseService.getAllCourses().then(
             response => {
               setCourseList(response.data);
               console.log("courseList: "+ JSON.stringify(response.data));
             },
         error => {
           console.log("error.toString(): "+ error.toString());
         }
        );
    };

    const fetchClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched => : "+ JSON.stringify(response.data));
                 setClassSelectItemsList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };

     /*const loadClassList = async () => {
               StudentService.getAllStudents().then(
                 response => {
                      //alert('newList: '+ JSON.stringify(newList));
                     // setClassList(newList);

                     //console.log("xx newList values.classList => xx : "+ JSON.stringify(newList));
                     //console.log("xx classList xx : "+ JSON.stringify(response.data));
                 },
             error => {
               console.log("error.toString(): "+ error);
             }
            );
        };*/

/*
     validationSchema={Yup.object().shape({
                       friends: Yup.array()
                         .of(
                           Yup.object().shape({
                             name: Yup.string().min(4, 'too short').required('Required'), // these constraints take precedence
                             salary: Yup.string().min(3, 'cmon').required('Required'), // these constraints take precedence
                           })
                         )
                         .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
                         .min(3, 'Minimum of 3 friends'),
                     })}
                */


    // For fetching class students list based on class code and Date
     function fetchRolls(e, values) {

                e.preventDefault();
                alert('Hello ...');
                let rollCallRequest = {};

                rollCallRequest.classCode = values.classCode;
                rollCallRequest.effectiveDate = new Date(values.effectiveDate);

                console.log('new Date(values.effectiveDate) xxxx: '+new Date(values.effectiveDate)); //good bad bad print rep
                console.log('rollCallRequest xxxxxxxxxxxxxxxxxx: '+ JSON.stringify(rollCallRequest)); //good bad bad print rep


                alert("rollCallData.effectiveDate : ZZ ... - : "+ JSON.stringify(rollCallRequest.effectiveDate));
                // new Date("2024-01-04").yyyymmdd(); //
                console.log("rollCallRequest ... - : "+ JSON.stringify(rollCallRequest));
                alert("rollCallRequest : ZZ ... - : "+ JSON.stringify(rollCallRequest));

                ClassService.fetchRollCall(rollCallRequest).then(
                            response => {
                                let respData = response.data;
                                setRollCallData(prevRollCallData => ({
                                       ...prevRollCallData,
                                       ...respData.rollCallData
                                   }
                               ))
                               console.log("fetchRoll ... response.data - : "+ JSON.stringify(response.data));
                            },
                        error => {
                          console.log("error.toString(): "+ error.toString());
                        }
                        );
             }


            // Move to util - Date prototype is read only, properties should not be added
            Date.prototype.yyyymmdd = function() {
                    var yyyy = this.getFullYear().toString();
                    var mm = (this.getMonth()+1).toString(); // getMonth() is zero-based
                    var dd  = this.getDate().toString();
                    return yyyy + '/' + (mm[1]?mm:"0"+mm[0]) + '/' + (dd[1]?dd:"0"+dd[0]);
               };

  return (

        <Container  component="main" >
             <ToastContainer />
         <div>

               <Typography  component="h1" variant="h5">
                     Class RollCall
               </Typography>
                <br />

                <Formik

                           /* initialValues={props.classList}*
                           /* initialValues={location.state}
                            innerRef={ref}
                           */

                           enableReinitialize={true}

                           initialValues={rollCallData}

                            validationSchema={Yup.object().shape({

                                            })}

                               onSubmit={(values, { setSubmitting }) => {

                                setTimeout(() => {
                                    // alert('values: '+JSON.stringify(values, null, 2));
                                     //alert('classList: '+JSON.stringify(values.classList, null, 2));

                                    if(null == values.effectiveDate) {
                                        alert('Please, select reccord effective date');
                                        return;
                                    }

                                    let rollCallRequestRequest = {};
                                    rollCallRequestRequest.classCode = values.classCode;
                                    console.log("Before saving new Date(values.effectiveDate): "+new Date(values.effectiveDate));
                                    rollCallRequestRequest.effectiveDate =   new Date(values.effectiveDate);  // from examSetup - '2024-01-14'
                                    rollCallRequestRequest.isPresent =  values.isPresent;

                                    rollCallRequestRequest.rollCallJsonText = JSON.stringify(values, null, 2);
                                    alert('rollCallRequestRequest: '+JSON.stringify(rollCallRequestRequest, null, 2));

                                    console.log('rollCallRequestRequest before save: '+JSON.stringify(rollCallRequestRequest, null, 2));


                                    ClassService.saveRollCall(rollCallRequestRequest).then(
                                            response => {
                                             // courseList = response.data;
                                             toast.success('RollCall saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                              console.log("RollCall saved successfully ...: "+ JSON.stringify(response));
                                            },
                                        error => {
                                          toast.error('Error while saving RollCall', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                          console.log("Error saving RollCall [error.toString()] : "+ error.toString());
                                        }
                                       );

                                   setSubmitting(false);
                                 }, 400);
                               }}

                 render={({ values }) => (

                        <Form>

                                <FormitLogger />

                                <Grid container spacing={3}>
                                        <Grid item xs={6} sm={3}>
                                             <SelectField
                                               name="classCode"
                                               label="Select A Class"
                                               data={classSelectItemsList}
                                               fullWidth
                                             />
                                            <ErrorMessage name="classCode" />
                                        </Grid>
                                        <Grid item xs={6} sm={3}>
                                            <DatePickerField
                                                name="effectiveDate"
                                                label="Effective Date"
                                                margin="normal"
                                                disableFuture
                                                inputFormat="yyyy-MM-dd"
                                                fullWidth
                                              />


                                    <ErrorMessage name="effectiveDate" />
                                </Grid>
                             </Grid>
                             <br />

                            <Grid container spacing={3} >

                                 <Grid item xs={2} sm={2}>
                                       SN

                                  </Grid>

                                 <Grid item xs={2} sm={2}>
                                       Student Id

                                 </Grid>
                                 <Grid item xs={2} sm={2}>
                                     First Name

                                </Grid>
                                <Grid item xs={2} sm={2}>
                                      Middle Name

                                 </Grid>
                                 <Grid item xs={2} sm={2}>
                                     Last Name

                                 </Grid>

                                 <Grid item xs={2} sm={2}>
                                    Is Present

                               </Grid>
                            </Grid>

                               <FieldArray
                                 name="classList"
                                 render={arrayHelpers => (

                                   <Grid container spacing={2}>

                                    {values.rollCallData.classList && values.rollCallData.classList.length > 0 ? (
                                        values.rollCallData.classList.map((item, index) => (

                                       <Grid container spacing={3} key={index}>

                                          <Grid item xs={2} sm={2}>
                                              <Field
                                                 name={`rollCallData.classList[${index}].id`}
                                                 className="form-control"
                                               />
                                               <ErrorMessage name={`rollCallData.classList[${index}].id`}/>
                                           </Grid>

                                          <Grid item xs={2} sm={2}>
                                              <Field
                                              name={`rollCallData.classList[${index}].studentId`}
                                              className="form-control"
                                              />
                                              <ErrorMessage name={`rollCallData.classList[${index}].studentId`}/>
                                          </Grid>
                                          <Grid item xs={2} sm={2}>
                                            <Field
                                               name={`rollCallData.classList[${index}].firstName`}
                                               className="form-control"
                                             />
                                             <ErrorMessage name={`rollCallData.classList[${index}].firstName`}/>
                                         </Grid>
                                         <Grid item xs={2} sm={2}>
                                             <Field
                                                name={`rollCallData.classList[${index}].middleName`}
                                                className="form-control"
                                              />
                                              <ErrorMessage name={`rollCallData.classList[${index}].middleName`}/>
                                          </Grid>
                                          <Grid item xs={2} sm={2}>
                                              <Field
                                                 name={`rollCallData.classList[${index}].lastName`}
                                                 className="form-control"
                                               />
                                               <ErrorMessage name={`rollCallData.classList[${index}].lastName`}/>
                                          </Grid>

                                          <Grid item xs={2} sm={2}>
                                          <CheckboxField
                                              name={`rollCallData.classList[${index}].isPresent`}
                                              className="form-control"
                                          />
                                          <ErrorMessage name={`rollCallData.classList[${index}].isPresent`}/>
                                        </Grid>



                                       </Grid>
                                     ))
                                   ) : (
                                        <div />
                                   )}




                                   </Grid>
                                 )}


                               />

                            <div>
                            <br />
                                <br />
                                 <Grid container spacing={1}>
                                    <Grid item xs={4} sm={4}>
                                            <button type="button" onClick={(e) => {
                                                                          fetchRolls(e, values);
                                                                       }}

                                            >Fetch Class List</button>
                                     </Grid>
                                    <Grid item xs={4} sm={4}>
                                     <button type="submit">Save Attendance</button>
                                    </Grid>
                                 </Grid>
                                <br />
                                <br />
                            </div>

                    </Form>
           )}

        />

 </div>
</Container>
);

}

