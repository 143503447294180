

import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
//import logo from '../images/xvlogo.png';

const Footer = () => {
return (

           <div>
                            <div className="row">
                                 <div className="col-lg-4 col-md-4 col-xs-12">
                                     <div className="widget clearfix">
                                         <div className="widget-title">
                                             <h3>About US</h3>
                                         </div>
                                         <p> Integer rutrum ligula eu dignissim laoreet. Pellentesque venenatis nibh sed tellus faucibus bibendum. Sed fermentum est vitae rhoncus molestie. Cum sociis natoque penatibus et magnis dis montes.</p>
                                         <p>Sed fermentum est vitae rhoncus molestie. Cum sociis natoque penatibus et magnis dis montes.</p>
                                     </div>   {/*   end clearfix  */}
                                </div>{/*   end col  */}

                                <div className="col-lg-4 col-md-4 col-xs-12">
                                         <div className="widget clearfix">
                                             <div className="widget-title">
                                                 <h3>Information Link</h3>
                                             </div>
                                             <ul className="footer-links">
                                                 <li><a href="#">Home</a></li>
                                                 <li><a href="#">Blog</a></li>

                                                 <li>
                                                   <a href="/trainingReview">Training Review</a>
                                                 </li>

                                                 <li>
                                                    <a href="/facultyReview">Faculty Review</a>
                                                 </li>

                                                 <li><a href="#">About</a></li>
                                             </ul>{/*   end links  */}
                                         </div>{/*   end clearfix  */}
                                 </div>{/*   end col  */}


                                 <div className="col-lg-4 col-md-4 col-xs-12">
                                         <div className="widget clearfix">
                                             <div className="widget-title">
                                                 <h3>Contact Details</h3>
                                                 <ul className="footer-links">
                                                    <li><a href="#">Faq</a></li>
                                                    <li><a href="/getInContactWIthUs">Contact</a></li>
                                                    <li><a href="mailto:#">uprise.training.info@gmail.com</a></li>
                                                    <li>Whatsapp: +2349136556332</li>
                                                 </ul>
                                             </div>
                                         </div>{/*   end clearfix  */}
                                     </div>{/*   end col  */}
                                 </div>{/*   end row  */}

                                <div className="copyrights">
                                    <div className="container">
                                        <div className="footer-distributed">
                                            <div className="footer-left">
                                                <p className="footer-company-name">All Rights Reserved. &copy; 2018 <a href="https://uprisecomputing.com">Uprise computing</a> Design By : <a href="https://uprisecomputing.com/">Uprise computing</a></p>
                                            </div>

                                            <div className="footer-right">
                                                <ul className="footer-links-soi">
                                                    <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-github"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-dribbble"></i></a></li>
                                                    <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                           <div className="col-lg-4 col-md-4 col-xs-12">
                            </div>
         </div>
    );
};export default Footer;