import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class CourseService {

// https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios

saveCourse(data) {

    console.log('Called CourseService.saveCourse() ...');

    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/course/save', data, config);
 }

saveSyllabus(data) {

    console.log('Called TrainingService.saveSyllabus() ...');

    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/syllabus/save', data, config);
 }


getAllCourses() {

     console.log('Called CourseService.getAllCourses() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.get('/bs/course/all-courses', config);
 }





 onChangeCode(e) {
    this.setState({
      code: e.target.value,
    });
  }

onChangeName(e) {
    this.setState({
      name: e.target.value,
    });
  }

onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

onChangeCourseGroupCode(e) {
    this.setState({
      courseGroupCode: e.target.value,
    });
  }


onChangeFeatured(e) {
    this.setState({
      featured: e.target.value,
    });
  }
 }

export default new CourseService();