import React from 'react'
import { Container, Grid, List, ListItem, ListItemText, Button,FormControl,FormLabel } from '@material-ui/core'

const Confirmation = ({ values }) => {

const { firstName, middleName, lastName, email, userName, password, passwordConfirmation, gender, phoneNumber} = values;

  return (
    <Container  component="main" maxWidth="xs">
      <div>
         <div>
              <strong>Confirmation</strong>
          </div>
           <br />
                <FormControl component="fieldset">
                        <div>
                        <FormLabel >
                             User Name : {userName}
                        </FormLabel>
                        </div>
                        <p />
                        <div>
                        <FormLabel >
                             First Name : {firstName}
                        </FormLabel>
                        </div>
                        <p />
                        <div>
                        <FormLabel >
                             Last Name : {lastName}
                        </FormLabel>
                        </div>
                        <p />
                        <div>
                        <FormLabel >
                            Password : {password}
                        </FormLabel>
                        </div>
                        <p />
                      <div>
                          <FormLabel >
                            Phone Number : {phoneNumber}/
                          </FormLabel>
                      </div>
                      <p />

                </FormControl >

        <br />

      </div>
    </Container>
  )
}

export default Confirmation