import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
import { register } from "../../actions/auth";
import  ClassService  from "../../services/ClassService";
import Pagination from "@material-ui/lab/Pagination";

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik } from 'formik';

import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import DatePickerField from '../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};


class RecordAttendance extends Component {

constructor(props) {
     super(props);
     this.setEffectiveDatee = this.setEffectiveDate.bind(this);
     this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
    // this.onChangeRow = this.onChangeRow.bind(this);

    this.state = {
      effectiveDate: null,
      classId: "",
      studentsRollCall : {},

        successful: false,
        collections: [],
        page: 1,
        count: 0,
        pageSize: 10,

    };
    this.pageSizes = [10, 20, 30];

  }

    // https://stackoverflow.com/questions/72925391/get-data-from-table-and-save-into-state
   //  onChangeRow( { fieldName, index, value }) {

  updateRow = (e, { fieldName, index, value }) => {

         e.preventDefault();
         console.log('onChangeRow: 1 - before - this.state.studentsRollCall.rollCall: '+ JSON.stringify(this.state.studentsRollCall.rollCall));

         let classList = this.state.studentsRollCall.rollCall.classList.map((item, idx) => {
          if (idx === index) {
                return {
                  ...item,
                  [fieldName]: !value
                };
            }
          return item;
        });


        const rollCallCopyForUpdate = { ... this.state.studentsRollCall }
        rollCallCopyForUpdate.rollCall.classList = classList;

        //console.log('updateRow: rollCallCopyForUpdate: '+ JSON.stringify(rollCallCopyForUpdate));

        this.setState({
                  studentsRollCall: rollCallCopyForUpdate,
                },() => {
                // this.loadRollCallData(rollCallCopyForUpdate);
                console.log('updateRow: < 4 > callback - this.state.studentsRollCall.rollCall: '+ JSON.stringify(this.state.studentsRollCall));
        });

  };





componentDidMount() {

   let stdsRollCallData = {

                      "rollCall" : {

                               "attendanceDate" : "2023-06-15",
                               "classId" : "203",
                               "classList" :  [

                                                   {
                                                       "SN" : "1",
                                                       "studentId" : "576868693334",
                                                       "firstName" : "Edet",
                                                       "middleName" : "Okon",
                                                       "lastName" : "Obong",
                                                       "isPresent" : "true"
                                                   },

                                                   {
                                                       "SN" : "2",
                                                       "studentId" : "89868693311",
                                                       "firstName" : "John",
                                                       "middleName" : "Peter",
                                                       "lastName" : "Ruffai",
                                                       "isPresent" : "false"
                                                   },
                                                   {
                                                       "SN" : "3",
                                                       "studentId" : "576868693334",
                                                       "firstName" : "Ike",
                                                       "middleName" : "Ugwu",
                                                       "lastName" : "Ohia",
                                                       "isPresent" : "false"
                                                   }

                                             ]
                       }

                   }

                  this.setState({
                     studentsRollCall: stdsRollCallData
                   },() => {
                        // ensure state data stetting happens when data read from file has fully loaded
                        this.loadRollCallData(stdsRollCallData);
                    });

}

loadRollCallData(data) {

            this.setState({
                  studentsRollCall: data,
                },() => {

               });
}


setEffectiveDate(date)  {
        this.setState({
                effectiveDate: date
              });
 }


saveRollCall(e) {

       e.preventDefault();
       console.log('saveRollCall called ... ');

        let rollCall = {
          "effectiveDate" : this.state.studentsRollCall.rollCall.attendanceDate,
          "classId" : this.state.studentsRollCall.rollCall.classId,
          "rollCallJsonText": this.state.studentsRollCall //issue here
        }

     console.log('saveRollCall called ..: JSON.stringify(rollCall) - from UI : ' +JSON.stringify(rollCall));

     ClassService.saveRollCall( rollCall )
              .then(response => {
                //this.setState({
                //  successful: true
                //});
               toast.success('🦄 Rollcall saved successfully', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  console.log('saveRollCall: JSON.stringify(response.data): ' +JSON.stringify(response.data));
              })
              .catch(e => {
                console.log(e);
                 this.setState({
                      successful: false,
                    });

                   toast.error('🦄 Error while saving Rollcall ...'+ e.message, {
                                     position: "top-center",
                                     autoClose: 5000,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                     pauseOnHover: true,
                                     draggable: true,
                                     progress: undefined,
                                     });
              });


}

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveCollections();
      }
    );
  }

handleNext(values, actions) {

}


  render() {

   // const { message } = this.props;
    console.log('render - this.state.studentsRollCall: => ' +JSON.stringify(this.state.studentsRollCall));

  /* const {
          collectionTypeCd,
          collections,
          currentAsset,
          currentIndex,
          page,
          count,
          pageSize,
        } = this.state;
*/

    let page = 2;
    let count = 12;
    let pageSize = 5;


    // https://dev.to/collegewap/how-to-work-with-checkboxes-in-react-44bc

    // <tr><td><pre>{JSON.stringify(this.state.studentsRollCall.rollCall.classList, null, 2) }</pre></td></tr>
    //  <div><pre>{JSON.stringify(this.state.studentsRollCall.rollCall.classList, null, 2) }</pre></div>


    return (
    <Container  component="main" >
        <div>
          <Typography  component="h1" variant="h5">
             Manage Rollcall
          </Typography>

            <Formik
              initialValues={formInitialValues}
              onSubmit={this.handleNext} >

                        <Form
                            ref={(c) => {
                              this.form = c;
                            }}
                          >

                        <div className="form-group">
                          <label htmlFor="coursePlannedStartDate">Rollcall Date</label>
                          <span name ="effectiveDate" id="effectiveDate">
                                  <DatePicker
                                    locale="es"
                                    selected={this.state.effectiveDate}
                                    showTimeSelect
                                    dateFormat="Pp"
                                    showIcon
                                   onChange={(effectiveDate) => this.setEffectiveDate(effectiveDate)}
                                  />
                          </span>

                        </div>

                        <Grid item xs={12} md={6}>
                          <DatePickerField
                            name="effectiveDate"
                            label="Effective Date"
                            format="MM/yy"
                            views={['year', 'month']}
                            minDate={new Date()}
                            maxDate={new Date('2050/12/31')}
                            fullWidth
                          />
                        </Grid>

                        <div>
                                <span style={{margin: "10px"}}>
                                    Class Id:
                                </span>

                                <span style={{margin: "10px"}} name="classId" id="classId">
                                    {null == this.state.studentsRollCall.rollCall ? "" :  this.state.studentsRollCall.rollCall.classId}
                                </span>
                        </div>

                        <div style={{marginLeft : "35%"}}>
                            {"Items per Page: "}
                            <select onChange={this.handlePageSizeChange} value={pageSize}>
                              {this.pageSizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </select>
                            <p />
                            <Pagination
                              className="my-3"
                              count={count}
                              page={page}
                              siblingCount={1}
                              boundaryCount={1}
                              variant="outlined"
                              shape="rounded"
                              onChange={this.handlePageChange}
                            />
                          </div>

                         <div class="form-group">
                              <ToastContainer />
                        </div>

                        <div>

                        { (null != this.state.studentsRollCall.rollCall && this.state.studentsRollCall.rollCall.classList) &&

                            <table name="classAttendanceRecTab" id="classAttendanceRecTab" style={{width: "100%"}}  >
                             <thead>
                               <tr>
                                <th>SN</th>
                                <th>Student Id</th>
                                <th>First Name</th>
                                <th>Middle Name</th>
                                <th>Last Name</th>
                                <th>Is Present?</th>
                               </tr>
                             </thead>

                             <tbody>

                                    { this.state.studentsRollCall.rollCall.classList.map((val, index) => {
                                      return (
                                        <tr key={index}>
                                            <td>{val.SN}</td>
                                            <td>{val.studentId}</td>
                                            <td>{val.firstName}</td>
                                            <td>{val.middleName}</td>
                                            <td>{val.lastName}</td>
                                            <td><input
                                            type="checkbox"
                                            defaultChecked={(val.isPresent === "true")? true : false}

                                            onChange={(e) =>
                                                                this.updateRow(e, {
                                                                  fieldName: "isPresent",
                                                                  index,
                                                                  value: e.target.checked
                                                                })
                                                       }
                                                 />
                                             </td>
                                             <td>val.isPresent: {val.isPresent}</td>
                                             <td>The checkbox is {val.isPresent ? "checked" : "unchecked"}</td>
                                        </tr>
                                      )
                                    })}


                             </tbody>

                           </table>

                        }


                        </div>


                        <p/>

                         <div className="field-submit">
                                <span style={{margin: "10px"}} className="submit-span">
                                    <button onClick={this.saveRollCall.bind(this)} >Save</button>
                               </span>

                               <span style={{margin: "10px"}} className="submit-span">
                                         <button>Cancel</button>
                                </span>
                          </div>

                        <Grid item xs={12} sm={6}>
                            <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Cancel
                            </Button>

                            <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                Reset
                            </Button>

                            <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Save
                            </Button>
                        </Grid>

                       </Form>
                </Formik>

     </div>
    </Container>

    );

  }

}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(RecordAttendance);
