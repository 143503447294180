import * as Yup from 'yup';
import moment from 'moment';
import UserFormModel from './UserFormModel';

const {
      formField:
          {
            firstName,
            middleName,
            lastName,
            userName,
            password,
            passwordConfirmation,
            gender,
            email,
            phoneNumber

          }
    } = UserFormModel;

const passwordRules = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
//Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character

//const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/;
// min 5 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.

const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;


export default [

        Yup.object().shape({
            [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
            [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
            [userName.name]: Yup.string().required(`${userName.requiredErrorMsg}`),

            [password.name]: Yup
                           .string()
                           .matches(passwordRules, { message: "Please create a stronger password (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)" })
                           .required("Required"),

                        [passwordConfirmation.name]: Yup
                           .string()
                           .oneOf([Yup.ref("password"), null], "Passwords must match")
                           .required("Required"),
        }),
        Yup.object().shape({
          [email.name]: Yup.string()
                               .required(`${email.requiredErrorMsg}`)
                               .matches(emailRegEx, email.invalidErrorMsg),
            [phoneNumber.name]: Yup.string().matches(phoneRegExp, 'Phone number is not valid')
                         }),
         Yup.object().shape({

                           })
];
