
import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import MultiSelectField from '../../components/formfields/MultiSelectField';
import DatePickerField from '../../components/formfields/DatePickerField';
import RatingField from '../../components/formfields/RatingField';


import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
import ClassService  from "../../services/ClassService";
import FacultyService  from "../../services/FacultyService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";
import FormitLogger from '../../common/FormitLogger';

const FacultyReviewSchema = Yup.object().shape({
     reviewComment: Yup.string()
    .required(`Review Comment is required`),
     facultyId: Yup
     .number("Must be a number type") // Validates for numerical value
    .positive("Must be a positive value") // Validates against negative values
    .required("Faculty is required.") // Sets it as a compulsory field
 });

export default function FacultyReview() {

   const [classList, setClassList] = useState([]);
   const [facultyList, setFacultyList] = useState([]);

   const [value, setValue] = React.useState('');
   const [error, setError] = React.useState(false);
   const [helperText, setHelperText] = React.useState('');

             useEffect(() => {
                loadClasses();
               }, []);

            const loadClasses = async () => {
                 ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched: "+ JSON.stringify(response.data));
                 setClassList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
             );
        };

         useEffect(() => {
                    loadFaculties();
            }, []);

                const loadFaculties = async () => {
                     FacultyService.getAllFaculties().then(
                   response => {
                              console.log("setFacultyList Fetched: "+ JSON.stringify(response.data));
                     setFacultyList(response.data);
                   },
                   error => {
                     console.log("error.toString(): "+ error.toString());
                   }
                 );
            };

          const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
           // setValue((event.target as HTMLInputElement).value);
            setValue(event.target.value);
            setHelperText(' ');
            setError(false);
          };

  return (

         <Container component="main" maxWidth="xs">
            <ToastContainer />

              <React.Fragment>
                    <Box
                      sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography component="h5" variant="h5" >
                             Faculty Review
                      </Typography>

            <Formik

              initialValues={{
                             id: '',
                             reviewerUserId: '',
                             facultyId: '',
                             reviewComment: '',
                             starCount: '',
                             thumpsUpCount : 0,
                             thumpsDownCount : 0
                           }}

                  onSubmit={(values, { setSubmitting }) => {
                                                      setTimeout(() => {
                                 setSubmitting(true);
                                 alert(JSON.stringify(values, null, 2));

                                 let facultyReviewRequest = {};
                                 facultyReviewRequest.id = values.id;
                                 facultyReviewRequest.reviewerUserId = values.reviewerUserId;
                                 facultyReviewRequest.facultyId = values.facultyId;
                                 facultyReviewRequest.reviewComment = values.reviewComment;
                                 facultyReviewRequest.starCount = values.starCount;
                                 facultyReviewRequest.thumpsUpCount = values.thumpsUpCount;
                                 facultyReviewRequest.thumpsDownCount = values.thumpsDownCount;

                                  alert('facultyReviewRequest :'+JSON.stringify(facultyReviewRequest, null, 2));

                                 FacultyService.saveFacultyReview(facultyReviewRequest).then(
                                         response => {
                                           toast.success('Faculty Review engagement saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                           console.log("Faculty Review saved successfully ...: "+ JSON.stringify(response));
                                         },
                                     error => {
                                      toast.error('Error while saving Faculty Review', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     }
                                    );

                            setSubmitting(false);
                          }, 400);
                        }}

                 validationSchema={FacultyReviewSchema}
              >

               <Form>
                    <FormitLogger />
                    <Grid container spacing={1} item style={{width: "70%"}}   >


                            <Grid item xs={12} sm={12}>
                                  <SelectField
                                      name="facultyId"
                                      label="Select Faculty"
                                      data={facultyList}
                                      fullWidth
                                  />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                   <RatingField name="starCount" defaultValue={2} size="large" />
                            </Grid>

                            <Grid item xs={12} sm={12}>
                                <InputField
                                    name="reviewComment"
                                    label="Review Comment"
                                    fullWidth
                                />
                            </Grid>

                    </Grid>

                    <div>
                    < br/>
                      <Grid container spacing={.2}>

                        <Grid item xs={4} sm={4}>
                            <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Save
                            </Button>
                        </Grid>

                      </Grid>
                     < br/>
                    </div>
               </Form>
            </Formik>
            </Box>
            </React.Fragment>
      </Container>
);

}

