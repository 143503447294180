import React from "react";
//import Form from "react-validation/build/form";
//import Input from "react-validation/build/input";
//import CheckButton from "react-validation/build/button";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import  EvaluationService  from "../../../services/EvaluationService";
import Pagination from "@material-ui/lab/Pagination";
import styles from './evaluation.module.css'

import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik } from 'formik';

import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';

import formInitialValues from './formInitialValues';

class PreviewExam extends React.Component {

  constructor(props) {
    super(props)
    this.state = {

              deletedFlag: "",
              examJsonFileContent: {},
              examCode : "",
              examName : "",
              examDescription : "",
              classId : "",
              effectiveDate : "",
              id : "",
              successful: false,
              fields: {},
              errors: {},


      formValues: [{
                                         questionSN: "",
                                         questionText : "",
                                         answerOptionA: "A",
                                         answerOptionAText : "" ,
                                         answerOptionACorrect : "false" ,
                                         answerOptionB: "B",
                                         answerOptionBText : "" ,
                                         answerOptionBCorrect : "false" ,
                                         answerOptionC: "C",
                                         answerOptionCText : "" ,
                                         answerOptionCCorrect : "false" ,
                                         answerOptionD: "D",
                                         answerOptionDText : "" ,
                                         answerOptionDCorrect : "false" ,
                                         answerOptionE: "E",
                                         answerOptionEText : "" ,
                                         answerOptionECorrect : "false"
                                       }]
     };
   // this.handleSubmit = this.handleSubmit.bind(this)
  }

  dynaFormHandleChange(i, e) {
    let formValues = this.state.formValues;

    if('checkbox' === e.target.type) {
     formValues[i][e.target.name]  =  e.target.checked;
     //alert('event.target.type:'+ e.target.type);
     //alert('e.target.checked:'+ e.target.checked);
    }
    else {
    formValues[i][e.target.name] = e.target.value;
    }
    this.setState({ formValues });

     console.log('dynaFormHandleChange: JSON.stringify(this.state.formValues): ' +JSON.stringify(this.state.formValues));

  }

  addFormFields() {
    this.setState(({
      //formValues: [...this.state.formValues, { name: "", email: "" }]

       formValues: [...this.state.formValues, {
                                                                                       questionSN: "",
                                                                                       questionText : "",

                                                                                       answerOptionA: "A",
                                                                                       answerOptionAText : "" ,
                                                                                       answerOptionACorrect : "false" ,

                                                                                       answerOptionB: "B",
                                                                                       answerOptionBText : "" ,
                                                                                       answerOptionBCorrect : "false" ,

                                                                                       answerOptionC: "C",
                                                                                       answerOptionCText : "" ,
                                                                                       answerOptionCCorrect : "false" ,

                                                                                       answerOptionD: "D",
                                                                                       answerOptionDText : "" ,
                                                                                       answerOptionDCorrect : "false" ,

                                                                                       answerOptionE: "E",
                                                                                       answerOptionEText : "" ,
                                                                                       answerOptionECorrect : "false"
                                                                                     }]
    }))
  }

  removeFormFields(i) {
    let formValues = this.state.formValues;
    formValues.splice(i, 1);
    this.setState({ formValues });
  }


   handleChange(field, e) {

      console.log('handleChange(field, e) - this.state : entry');
       let fields = this.state.fields;
       if('checkbox' === e.target.type) {
             fields[field] =  e.target.checked;
             //alert('event.target.type:'+ e.target.type);
             //alert('e.target.checked:'+ e.target.checked);
         }
         else {
             fields[field] = e.target.value;
         }
      this.setState({ fields });
       console.log('handleChange(field, e) - this.state : final');
      //console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
   }

saveExamBasicInfo(e) {

 // saveExamBasicInfo = (e) => {

       e.preventDefault();

       console.log('saveExamBasicInfo called ... ');

        //if (!this.handleValidation()) {
        // console.log('saveExamBasicInfo invoked; invalid form ...');
        //  return;
        //}

        let examBasicInfo = {
        "examCode" : this.state.fields["examCode"],
        "examName" : this.state.fields["examName"],
        "examDescription" : this.state.fields["examDescription"],
        "classId" : this.state.fields["classId"],
        "effectiveDate" : this.state.fields["effectiveDate"],
        }

     console.log('saveExamBasicInfo called ..: JSON.stringify(examBasicInfo) - from UI : ' +JSON.stringify(examBasicInfo));

     EvaluationService.saveExamBasicInfo( examBasicInfo )
              .then(response => {
                //this.setState({
                //  successful: true
                //});
               toast.success('🦄 Exam saved successfully', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  console.log('saveExamBasicInfo: JSON.stringify(response.data): ' +JSON.stringify(response.data));
              })
              .catch(e => {
                console.log(e);
                 this.setState({
                      successful: false,
                    });

                   toast.error('🦄 Error while saving Exam basic Info ...'+ e.message, {
                                     position: "top-center",
                                     autoClose: 5000,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                     pauseOnHover: true,
                                     draggable: true,
                                     progress: undefined,
                                     });
              });


}


 // for saving questions and their answer options
  handleSaveQuestionsWithAnswerOptions(event) {

    event.preventDefault();
    console.log('handleSaveQuestionsWithAnswerOptions called ..: JSON.stringify(this.state.formValues) - from UI : ' +JSON.stringify(this.state.formValues));
    //alert(JSON.stringify(this.state.formValues));

    let questionAndAnswerOptionsList = [];

    for (let i = 0; i < this.state.formValues.length; i++) {

         let questionsWithAnsOptions = {
                "questionSN" : this.state.formValues[i].questionSN,
                "questionText" : this.state.formValues[i].questionText,

                "answerOptionA" : this.state.formValues[i].answerOptionA,
                "answerOptionAText" : this.state.formValues[i].answerOptionAText,
                "answerOptionACorrect" : this.state.formValues[i].answerOptionACorrect,

                "answerOptionB" : this.state.formValues[i].answerOptionB,
                "answerOptionBText" : this.state.formValues[i].answerOptionBText,
                "answerOptionBCorrect" : this.state.formValues[i].answerOptionBCorrect,

                "answerOptionC" : this.state.formValues[i].answerOptionC,
                "answerOptionCText" : this.state.formValues[i].answerOptionCText,
                "answerOptionCCorrect" : this.state.formValues[i].answerOptionCCorrect,

                "answerOptionD" : this.state.formValues[i].answerOptionD,
                "answerOptionDText" : this.state.formValues[i].answerOptionDText,
                "answerOptionDCorrect" : this.state.formValues[i].answerOptionDCorrect,

                "answerOptionE" : this.state.formValues[i].answerOptionE,
                "answerOptionEText" : this.state.formValues[i].answerOptionEText,
                "answerOptionECorrect" : this.state.formValues[i].answerOptionECorrect
                }

        questionAndAnswerOptionsList.push(questionsWithAnsOptions);

    }

 let questionsWithAnsOptionsRequest = {
        "examCode" : this.state.fields["examCode"],
        "examName" : this.state.fields["examName"],
        "examDescription" : this.state.fields["examDescription"],
        "classId" : this.state.fields["classId"],
        "effectiveDate" : this.state.fields["effectiveDate"],
        "questionAndAnswerOptionsList" : questionAndAnswerOptionsList
        }


    console.log('XXX handleSaveQuestionsWithAnswerOptions(questionsWithAnsOptionsRequest) called ..: JSON.stringify(questionsWithAnsOptionsRequest) - from UI : ' +JSON.stringify(questionsWithAnsOptionsRequest));

     EvaluationService.handleSaveQuestionsWithAnswerOptions( questionsWithAnsOptionsRequest )
              .then(response => {
               toast.success('🦄 QuestionsWithAnswerOptions saved successfully', {
                         position: "top-center",
                         autoClose: 5000,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         draggable: true,
                         progress: undefined,
                         });
                  console.log('handleSaveQuestionsWithAnswerOptions: JSON.stringify(response.data): ' +JSON.stringify(response.data));
              })
              .catch(e => {
                console.log(e);
                 this.setState({
                      successful: false,
                    });

                   toast.error('🦄 Error while saving Exam QuestionsWithAnswerOptions ...'+ e.message, {
                                     position: "top-center",
                                     autoClose: 5000,
                                     hideProgressBar: false,
                                     closeOnClick: true,
                                     pauseOnHover: true,
                                     draggable: true,
                                     progress: undefined,
                                     });
              });

 }


 componentDidMount() {

        EvaluationService.getExamPreview("DISMLEL01").then(
                  response => {
                    this.setState({
                      formValues: response.data.questioAndAnswerOptionsList
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
         console.log('loaded getExamPreview - this.state.formValues :'+this.state.formValues);


        EvaluationService.getExamByCode("DISMLEL01").then(
                  response => {
                    this.setState({
                        examCode :  response.data.examCode,
                        examName : response.data.examName,
                        examDescription : response.data.examDescription,
                        classId : response.data.classId,
                        effectiveDate : response.data.effectiveDate,
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );

  }

handleNext(values, actions) {

}


  render() {

    return (

            <Container  component="main" >
                <div>
                  <Typography  component="h1" variant="h5">
                     Exam Preview
                  </Typography>

                    <Formik
                      initialValues={formInitialValues}
                      onSubmit={this.handleNext} >


                                        <form>

                                                 <div>
                                                          <div>
                                                                    <div>
                                                                          <label htmlFor="examCode">Exam Code</label>
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                         onChange={this.handleChange.bind(this, "examCode")}
                                                                         value={this.state.examCode}
                                                                      />
                                                                 </div>
                                                                <div className="form-group">
                                                                  <label htmlFor="examName">Exam Name</label>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="examName"
                                                                    onChange={this.handleChange.bind(this, "examName")}
                                                                    value={this.state.examName}
                                                                  />
                                                                </div>

                                                                <div className="form-group">
                                                                  <label htmlFor="examDescription">Exam Description</label>
                                                                  <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    name="examDescription"
                                                                    onChange={this.handleChange.bind(this, "examDescription")}
                                                                    value={this.state.examDescription}
                                                                  />
                                                                </div>

                                                                 <div className="form-group">
                                                                      <label htmlFor="classId">Class ID</label>
                                                                      <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name="classId"
                                                                        onChange={this.handleChange.bind(this, "classId")}
                                                                        value={this.state.classId}
                                                                      />
                                                                 </div>
                                                                 <div className="field-submit">
                                                                    <span style={{margin: "10px"}} className="submit-span">
                                                                        <button
                                                                            onClick={this.saveExamBasicInfo.bind(this)}

                                                                        >Save Exam Base Info</button>
                                                                   </span>
                                                                   <span style={{margin: "10px"}} className="submit-span">
                                                                        <button>Cancel</button>
                                                                    </span>
                                                                 </div>


                                                                <Grid item xs={12} sm={6}>
                                                                        <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                                            Cancel
                                                                        </Button>
                                                                        <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                                           Reset
                                                                        </Button>
                                                                        <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                                            Save
                                                                        </Button>
                                                                </Grid>

                                                            </div>

                                                            <div>
                                                                 <br />
                                                            </div>
                                          </div>


                                        <div>
                                            <label>Questions</label>
                                            <hr style={{color: "gray",backgroundColor: "gray",height: "5px"}} />
                                        </div>

                                        <div className="form-group">
                                             <ToastContainer />
                                        </div>

                                        <br />
                                        <br />


                                          <div>

                                              {this.state.formValues.map((element, index) => (

                                               <div className={styles.tableDisplay}  key={index} >
                                                      <div className={styles.tableDisplay}>
                                                          <div className={styles.tableRowDisplay}>
                                                             <div className={styles.tableCellDisplay} >
                                                                  <label htmlFor="questionSN">Question No</label>
                                                                   <span style={{margin: "3px"}}></span>
                                                                  <label name="questionSN"  value={ index + 1}  >{index + 1}</label>
                                                                  <div> {element.questionSN = index + 1}</div>
                                                              </div>
                                                              <span className={styles.tableCellDisplay} >
                                                              </span>
                                                          </div>
                                                       </div>

                                                      <div className={styles.tableDisplay}>
                                                           <div className={styles.tableRowDisplay}>
                                                               <span className={styles.tableCellDisplay} >
                                                                  Question Text
                                                               </span>
                                                                <span className={styles.tableCellDisplay} >
                                                                    <textarea
                                                                      name="questionText"
                                                                      className={styles.inPutBox}
                                                                      value={element.questionText || ""}
                                                                       onChange={e => this.dynaFormHandleChange(index, e)}
                                                                    />
                                                                </span>

                                                              <span className={styles.tableCellDisplay} >
                                                              </span>
                                                              <span className={styles.tableCellDisplay} >
                                                              </span>
                                                           </div>
                                                       </div>

                                                      <div className={styles.tableDisplay}>
                                                          <div className={styles.tableRowDisplay}>
                                                              <span className={styles.tableCellDisplay} >
                                                                  Answer Options
                                                              </span>
                                                              <span className={styles.tableCellDisplay} >
                                                              </span>
                                                             <span className={styles.tableCellDisplay} >
                                                             </span>

                                                              <span className={styles.tableCellDisplay}  >
                                                                  <label >Is correct option ?</label>
                                                              </span>

                                                         </div>
                                                      </div>
                                                       <div className={styles.tableDisplay}>

                                                         <div className={styles.tableRowDisplay}>
                                                              <div className={styles.tableCellDisplay} >
                                                                  <label name = "answerOptionA" value={element.answerOptionA || ""} > (A) </label>
                                                              </div>

                                                             <div className={styles.tableCellDisplay} >
                                                                  <textarea
                                                                      name ="answerOptionAText"
                                                                       className={styles.inPutBox}
                                                                       value={element.answerOptionAText || ""}
                                                                        onChange={e => this.dynaFormHandleChange(index, e)}
                                                                  />
                                                             </div>
                                                             <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                            </div>
                                                             <div className={styles.tableCellDisplay} >
                                                                  <input
                                                                      name ="answerOptionACorrect"
                                                                      type="checkbox"
                                                                      defaultChecked={(element.answerOptionACorrect === true)? true : false}
                                                                      value={element.answerOptionACorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                   />
                                                             </div>
                                                        </div>
                                                        <div className={styles.tableRowDisplay}>
                                                                       <div className={styles.tableCellDisplay} >
                                                                            <label name = "answerOptionB"> (B) </label>
                                                                       </div>

                                                                      <div className={styles.tableCellDisplay} >
                                                                           <textarea
                                                                                name ="answerOptionBText"
                                                                                className={styles.inPutBox}
                                                                                value={element.answerOptionBText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                           />
                                                                      </div>
                                                                      <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                      </div>
                                                                      <div className={styles.tableCellDisplay} >
                                                                           <input
                                                                                name ="answerOptionBCorrect"
                                                                                type="checkbox"
                                                                                 defaultChecked={(element.answerOptionBCorrect === true)? true : false}
                                                                                 value={element.answerOptionBCorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                            />
                                                                      </div>
                                                        </div>
                                                       <div className={styles.tableRowDisplay}>
                                                                 <div className={styles.tableCellDisplay} >
                                                                     <label name = "answerOptionC"> (C) </label>
                                                                 </div>

                                                                <div className={styles.tableCellDisplay} >
                                                                     <textarea
                                                                          name ="answerOptionCText"
                                                                          className={styles.inPutBox}
                                                                          value={element.answerOptionCText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                     />
                                                                </div>
                                                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                 </div>
                                                                <div className={styles.tableCellDisplay} >
                                                                     <input
                                                                          name ="answerOptionCCorrect"
                                                                          type="checkbox"
                                                                          defaultChecked={(element.answerOptionCCorrect === true)? true : false}
                                                                          value={element.answerOptionCCorrect || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                      />
                                                                </div>
                                                      </div>
                                                      <div className={styles.tableRowDisplay}>
                                                                       <div className={styles.tableCellDisplay} >
                                                                          <label name = "answerOptionD"> (D) </label>
                                                                       </div>

                                                                      <div className={styles.tableCellDisplay} >
                                                                           <textarea
                                                                                  name ="answerOptionDText"
                                                                                className={styles.inPutBox}
                                                                                value={element.answerOptionDText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                           />
                                                                      </div>
                                                                      <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                       </div>
                                                                      <div className={styles.tableCellDisplay} >
                                                                           <input
                                                                                 name ="answerOptionDCorrect"
                                                                                 type="checkbox"
                                                                                 defaultChecked={(element.answerOptionDCorrect === true)? true : false}
                                                                                 value={element.answerOptionDCorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                            />
                                                                      </div>
                                                      </div>
                                                       <div className={styles.tableRowDisplay}>
                                                                     <div className={styles.tableCellDisplay} >
                                                                           <label name = "answerOptionE"> (E) </label>
                                                                     </div>

                                                                    <div className={styles.tableCellDisplay} >
                                                                         <textarea
                                                                               name ="answerOptionEText"
                                                                              className={styles.inPutBox}
                                                                               value={element.answerOptionEText || ""} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                         />
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                                                    </div>
                                                                    <div className={styles.tableCellDisplay} >
                                                                         <input
                                                                               name ="answerOptionECorrect"
                                                                              type="checkbox"
                                                                              defaultChecked={(element.answerOptionECorrect === true)? true : false}
                                                                               value={element.answerOptionECorrect || "false"} onChange={e => this.dynaFormHandleChange(index, e)}
                                                                          />
                                                                    </div>
                                                       </div>
                                                       <div className={styles.tableRowDisplay}>
                                                            <button type="button"  className="button remove" onClick={() => this.removeFormFields(index)}>Remove</button>
                                                       </div>

                                                   </div>

                                           </div>

                                           ))}

                                          </div>

                                          <div className="button-section">
                                              <button className="button add" type="button" onClick={() => this.addFormFields()}>Add</button>
                                              <button className="button submit" type="button" onClick={this.handleSaveQuestionsWithAnswerOptions.bind(this)}  >Submit</button>
                                          </div>

                                            <Grid item xs={12} sm={6}>
                                                    <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                        Cancel
                                                    </Button>
                                                    <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                       Reset
                                                    </Button>
                                                    <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                                        Save
                                                    </Button>
                                            </Grid>
                                      </form>

                                </Formik>
                            </div>
                        </Container>

    );
  }
}
export default PreviewExam;
