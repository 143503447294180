
import {
  withStyles,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container, Typography, Grid,
  CircularProgress,
  Box
} from "@material-ui/core";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import SearchIcon from '@material-ui/icons/Search';
import SaveIcon from '@mui/icons-material/Save';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PageviewIcon from '@mui/icons-material/Pageview';
// https://v4.mui.com/components/material-icons/

import React, { useState, useEffect , Component} from 'react';
//import {useState, useRef } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
//import "../../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import {Progress} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DataGrid } from '@mui/x-data-grid';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";

import InputField from '../components/formfields/InputField';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import MultiSelectField from '../components/formfields/MultiSelectField';
import DatePickerField from '../components/formfields/DatePickerField';
import RichTextField from '../components/formfields/RichTextField';
import {UploaderField} from '../components/formfields/UploaderField';
import FormitLogger from '../common/FormitLogger';
import BSCommonService  from "../services/BSCommonService";
import ClassService  from "../services/ClassService";
import BlogService from './services/BlogService';
import PublicationService from '../services/PublicationService';

import { v4  } from 'uuid';

const styles = {};
const contactFormEndpoint = process.env.REACT_APP_CONTACT_ENDPOINT;


const ContentCreationSchema = Yup.object().shape({
   category: Yup.string()
     .required('Required'),
    title: Yup.string()
    .required('Required'),
    content: Yup.string()
    .min(100, 'Too Short!')
    .required('Required'),
 });

// pubn. type
const publicationTypes = [
  {
    code: 'BLG',
    label: 'Blog'
  },
  {
    code: 'TRN',
    label: 'Teaching/Training Resources/Notes'
  },
  {
    code: 'PRP',
    label: 'Private Publication'
  }
  ,
    {
      code: 'PRN',
      label: 'Private Resources/Notes'
    },
    {
      code: 'FPL',
      label: 'For Public'
    }
];

//categories
const publicationSections = [
  {
    code: 'TECH',
    label: 'Technology'
  },
  {
    code: 'LIT',
    label: 'Literature'
  },
  {
    code: 'GEN',
    label: 'General'
  },
    {
      code: 'Nature',
      label: 'Nature'
    }
];





function renderTrainingMaterialsPublicationFields(classList, courses) {

 return (
    <>
        <Grid item xs={6} sm={6}>
              <SelectField
              name="originatingClassCode"
              label="Originating Class"
              data={classList}
              fullWidth
              />
        </Grid>
         <Grid item xs={6} sm={6}>
            <SelectField
              name="originatingCoursesCode"
              label="Originating Course"
              data={courses}
              fullWidth
              />
        </Grid>

        <Grid item xs={6} sm={6}>
              <InputField
              name="syllabusItemRef"
              label="Syllabus Reference"
              fullWidth
              />
        </Grid>
         <Grid item xs={6} sm={6}>
            <InputField
              name="publicationVersNo"
              label="Material Version No"
              fullWidth
              />
        </Grid>

    </>
    );

}

function PublicationMgt(props) {

      const [classList, setClassList] = useState([]);
      const [courses, setCourses] = useState([]);
      const [pubResource, setPubResource] = useState({});
      let resourceId = props.resourceId;
      let publicationType = props.publicationType;

      console.log('resourceId: ->xxx '+ resourceId);
      console.log('publicationType: ->xxx '+ publicationType);


     useEffect(() => {
        loadCourses();
       }, []);

    const loadCourses = async () => {
         BSCommonService.getAllCourses().then(
       response => {
         setCourses(response.data);
       },
       error => {
         console.log("error.toString(): "+ error.toString());
       }
     );
    };

    useEffect(() => {
      // TODO - pass in publicationType
       fetchResource(publicationType, resourceId);
      // fetchBlog( resourceId);
    }, []);

      const fetchResource = async (publicationType, id) => {
      //const fetchBlog = async ( id) => {
          console.log('resource id: -> '+ id);
          console.log('publicationType: -> '+ publicationType);
                 PublicationService.getPublicationResource(publicationType, resourceId).then(
                // PublicationService.fetchBlog(resourceId).then(
                   response => {
                    setPubResource(response.data);
                    console.log('resourceId response.data: -> '+ JSON.stringify(response.data));

                   },
               error => {
                 console.log("fetchResource error.toString(): "+ error.toString());
               }
             );
        };


     useEffect(() => {
            loadClasses();
           }, []);

     const loadClasses = async () => {
             ClassService.getAllClasses().then(
               response => {
                          console.log("classList Fetched: "+ JSON.stringify(response.data));
                 setClassList(response.data);
               },
               error => {
                 console.log("error.toString(): "+ error.toString());
               }
         );
      };

  const { classes } = props;
  const [open, setOpen] = useState(false);
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);

  function handleClose() {
    setOpen(false);
  }

  function handleClickOpen() {
    setSubmitionCompleted(false);
    setOpen(true);
  }

  return (
    <React.Fragment>

      <ToastContainer />

         <Button  aria-label="badge badge-warning" color="primary" onClick={handleClickOpen}>
             <PageviewIcon />
         </Button>

          <IconButton aria-label="delete" color="primary" onClick={handleClickOpen}>
             <EditIcon />
         </IconButton>

         <IconButton aria-label="delete" color="primary" onClick={handleClickOpen}>
            <DeleteIcon />
         </IconButton>

         Resource Id:    {props.resourceId}

         Publication Type:    {props.publicationType}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        {!isSubmitionCompleted && (
          <React.Fragment>
            <Box display="flex" alignItems="center">
                 <Box flexGrow={4} style={{textAlign: "center"}} component="h5" variant="h5" >Resource Editing</Box>
                  <Box>
                      <IconButton onClick={handleClose}>
                            <CloseIcon />
                      </IconButton>
                  </Box>
             </Box>

            <DialogContent>
             <DialogContentText> </DialogContentText>

             <Formik
                         initialValues={{
                               id : resourceId,
                               publicationType: publicationType,
                               category: pubResource.category,
                               originatingClassCode: pubResource.originatingClassCode,
                               originatingCoursesCode: pubResource.originatingCoursesCode,
                               syllabusItemRef: pubResource.syllabusItemRef,
                               publicationVersNo: pubResource.publicationVersNo,
                               title: pubResource.title,
                               content: pubResource.content,
                               attachments: pubResource.attachments,
                               attachmentFilePaths: pubResource.attachmentFilePaths,
                               isPublished: pubResource.isPublished,
                               publishDate: pubResource.publishDate,
                               isFeatured: pubResource.isFeatured,
                               isDelete: pubResource.isDelete,
                               standardTag: pubResource.standardTag,
                               customTag: pubResource.customTag
                             }}


                                  enableReinitialize={true}

                                  validationSchema={ContentCreationSchema}

                                  onSubmit={(values, { setSubmitting }) => {
                                                 setTimeout(() => {

                                        alert(JSON.stringify(values, null, 2));

                                         let contentRequestRequest = {};
                                         contentRequestRequest.id = resourceId;
                                         contentRequestRequest.category = values.category;
                                         contentRequestRequest.publicationType = publicationType;
                                         contentRequestRequest.title = values.title;
                                         contentRequestRequest.originatingClassCode = values.originatingClassCode;
                                         contentRequestRequest.originatingCoursesCode = values.originatingCoursesCode;
                                         contentRequestRequest.syllabusItemRef = values.syllabusItemRef;
                                         contentRequestRequest.publicationVersNo = values.publicationVersNo;
                                         contentRequestRequest.content = values.content;
                                         contentRequestRequest.attachmentFilePaths = values.attachmentFilePaths;

                                         alert('values.attachments; '+ values.attachments);

                                         contentRequestRequest.standardTag = values.standardTag;
                                         contentRequestRequest.customTag = values.customTag;
                                         contentRequestRequest.isPublished = values.isPublished;
                                         contentRequestRequest.publishDate = values.publishDate;
                                         contentRequestRequest.isFeatured = values.isFeatured;
                                         contentRequestRequest.isDelete = values.isDelete;

                                         let attachments = [];

                                       //contentRequestRequest.attachments = [contentRequestRequest.attachment1,contentRequestRequest.attachment2,
                                       //                                  contentRequestRequest.attachment3, contentRequestRequest.attachment4, contentRequestRequest.attachment5];
                                       if(null != values.attachments) {
                                       for(let i = 0; i < values.attachments.length; i++) {
                                          attachments.push(values.attachments[i]);
                                       }
                                     }

                                      alert('attachments: '+ attachments);

                                     if(contentRequestRequest.publicationType === 'BLG') {
                                          PublicationService.saveResourceForBlog(contentRequestRequest, attachments).then(
                                                response => {
                                                  toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                                },
                                            error => {
                                                  toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Error Resource content [error.toString()] : "+ error.toString());
                                            }
                                           );
                                       setSubmitting(false);
                                     } else  if(contentRequestRequest.publicationType === 'TRN')  {
                                         PublicationService.saveResourceForTeaching(contentRequestRequest, attachments).then(
                                                response => {
                                                  toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                                },
                                            error => {
                                                  toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Error Resource content [error.toString()] : "+ error.toString());
                                            }
                                           );

                                         setSubmitting(false);
                                     }
                                     else  if(contentRequestRequest.publicationType === 'PRP')  {
                                         PublicationService.saveResourceForPersonalPublication(contentRequestRequest, attachments).then(
                                                response => {
                                                  toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                                },
                                            error => {
                                                  toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Error Resource content [error.toString()] : "+ error.toString());
                                            }
                                           );

                                         setSubmitting(false);
                                     }
                                     else  if(contentRequestRequest.publicationType === 'PRN')  {
                                         PublicationService.saveResourceForPersonalNote(contentRequestRequest, attachments).then(
                                                response => {
                                                  toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                                },
                                            error => {
                                                  toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Error Resource content [error.toString()] : "+ error.toString());
                                            }
                                           );

                                         setSubmitting(false);
                                     }
                                       else  if(contentRequestRequest.publicationType === 'FPL')  {
                                         PublicationService.saveResourceForPublic(contentRequestRequest, attachments).then(
                                                response => {
                                                  toast.success('Resource content saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Resource content saved successfully ...: "+ JSON.stringify(response));
                                                },
                                            error => {
                                                  toast.error('Error while saving Resource content', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                  console.log("Error Resource content [error.toString()] : "+ error.toString());
                                            }
                                           );

                                         setSubmitting(false);
                                     }


                                     }, 400);
                                   }}


             // TRN

                         render= {({ values }) => (

                            <Form>
                              <Grid container spacing={3}>
                                        <FormitLogger />
                                         <Grid item xs={6} sm={6}>
                                             <SelectField
                                                       value={pubResource.publicationType}
                                                       name="publicationType"
                                                       label="Publication Type"
                                                       data={publicationTypes}
                                                       fullWidth
                                               />
                                        </Grid>
                                         <Grid item xs={6} sm={6}>
                                                 <SelectField
                                                  name="category"
                                                  label="Publication Section"
                                                  data={publicationSections}
                                                  fullWidth
                                               />
                                         </Grid>

                                          { values.publicationType === "TRN" ? renderTrainingMaterialsPublicationFields(classList, courses):  null }

                                         <Grid item xs={12} sm={12}>
                                             <InputField
                                               name="title"
                                               label="Title"
                                               fullWidth
                                               />
                                         </Grid>

                                         <Grid item xs={12} sm={12}>
                                                <RichTextField
                                                  name="content"
                                                  label="Publication Content"
                                                  fullWidth
                                                  value={values.content}
                                                  />
                                           </Grid>
                                          <br />
                                          <br />
                                          <p />
                                         <p />
                                           <Grid item xs={12} sm={12}>
                                              Publication Attachment
                                              <UploaderField name="attachments" type="file" multiple value={undefined}  />
                                           </Grid>

                                       <p />
                                       <p />


                                       <Grid item xs={4} sm={4} >
                                             <SelectField
                                                       name="standardTag"
                                                       label="Select Tag"
                                                       data={publicationTypes}
                                                       fullWidth
                                               />
                                        </Grid>
                                         <Grid item xs={4} sm={4} >
                                                 <InputField
                                                  name="customTag"
                                                  label="Enter Tag"
                                                  data={publicationSections}
                                                  fullWidth
                                               />
                                         </Grid>

                             </Grid>

                            <p />
                             <Grid item xs={4} sm={4}>
                                      <Button id="cancelButton" name="cancelButton" className="submit-span"  type="button">Close</Button>

                                      <Button id="saveButton"  name="saveButton"  className="submit-span" type="submit">Post</Button>
                              </Grid>

                              <br />
                              <br />

                            </Form>

                          )}


                         />


            </DialogContent>

          </React.Fragment>
        )}
        {
      /*        isSubmitionCompleted && (
          <React.Fragment>
            <DialogTitle id="form-dialog-title">Thanks!</DialogTitle>
            <DialogContent>
              <DialogContentText>Thanks</DialogContentText>
              <DialogActions>
                <Button type="button" className="outline" onClick={handleClose}>
                  Back to app
                </Button>
              </DialogActions>
            </DialogContent>
          </React.Fragment>
        )*/

        }
      </Dialog>

    </React.Fragment>
  );
}

export default withStyles(styles)(PublicationMgt);
