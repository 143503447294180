
import React, { useState , useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,
  CircularProgress
} from '@material-ui/core';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import MultiSelect from '../../../components/formfields/MultiSelect';
import MultiSelectField from '../../../components/formfields/MultiSelectField';
import { UploaderField }from '../../../components/formfields/UploaderField';

import formInitialValues from './formInitialValues';

import { Formik, Field, Form, FieldArray, useFormikContext,ErrorMessage } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import FormitLogger  from "../../../common/FormitLogger";

import { connect } from "react-redux";
import StudentService  from "../../../services/StudentService";
import CourseService  from "../../../services/CourseService";
import BSCommonService  from "../../../services/BSCommonService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const offerTypes = [
  {
    code: 'PACKAGE',
    label: 'Package'
  },
  {
    code: 'SINGLECOURSE',
    label: 'Single Course'
  },
  {
    code: 'CORPORATE',
    label: 'Corporate'
  }
];

const courseGroups = [
    {
    code: 'CLMFOUFOU',
    label: 'Computer Literacy & MS Office Specialty'
    },
    {
    code: 'JT',
    label: 'Java Technologies'
    },
    {
    code: '.NETT',
    label: '.NET Technologies'
    },
    {
      code: 'PYTT',
      label: 'Python Technologies'
    },
    {
     code: 'MOADT',
     label: 'Mobile App Development Technologies'
    },
    {
      code: 'JST',
      label: 'Javascript Technologies'
    },
    {
       code: 'DSMLAIT',
       label: 'Data Science, ML & AI Technologies'
    },
    {
    code: 'DBT',
    label: 'Database Technologies'
    }
];




const OfferCreationSchema = Yup.object().shape({
        code: Yup.string()
        .required(`Code Required`),
        name: Yup.string()
        .required(`Name Required`),
        offerTypeCode: Yup.string()
        .required(`offerTypeCode Required`),
         courseGroupCode: Yup.string()
                .required(`Course Group Required`),
        mktDescription: Yup.string()
        .required(`Marketing Description Required`),
        imageFile: Yup.string()
        .required(`Image File Required`),
        price: Yup
        .number("Must be a number type") // Validates for numerical value
        .positive("Must be a positive value") // Validates against negative values
        .required("Please enter a duration.") // Sets it as a compulsory field
        .min(1, "Duration must be greater than or equal to 1!"),
 });


export default function TrainingOfferForm() {

    const [courses, setCourses] = useState([]);

         useEffect(() => {
            loadCourses();
           }, []);

        const loadCourses = async () => {
             BSCommonService.getAllCourses().then(
           response => {
             setCourses(response.data);
           },
           error => {
             console.log("error.toString(): "+ error.toString());
           }
         );
    };


  return (

        <Container  component="main" >
            <ToastContainer />
         <div>
              <Typography  component="h1" variant="h5">
                 Training Offer Form
              </Typography>

            <Formik
              initialValues={{
                        id: '',
                        courseListJson: '',
                        code: '',
                        name: '',
                        offerTypeCode: '',
                        mktDescription: '',
                        courseGroupCode: '',
                        price : 0.0,
                        imageFile : null,
                        imagePath: '',
                        startDate: null,
                        endDate: null,
                        resourcePath: '',
                        deletedFlag: ''
                     }}

             validationSchema={OfferCreationSchema}

             onSubmit={(values, { setSubmitting }) => {
                  setTimeout(() => {

                        let offerRequestRequest = {};
                        offerRequestRequest.id = values.id;
                        offerRequestRequest.courseListJson = values.courseListJson;
                        offerRequestRequest.code = values.code;
                        offerRequestRequest.name = values.name;
                        offerRequestRequest.offerTypeCode = values.offerTypeCode;
                        offerRequestRequest.mktDescription = values.mktDescription;
                        offerRequestRequest.price = values.price;
                        offerRequestRequest.courseGroupCode = values.courseGroupCode;

                        offerRequestRequest.imageFile = values.imageFile[0];
                        if(null != values.imageFile[0]) {
                            offerRequestRequest.imagePath = values.imageFile[0].name;
                        }
                        if(values.startDate) {
                            offerRequestRequest.startDate = new Date(values.startDate);
                        }
                        else{
                           offerRequestRequest.startDate =  new Date();
                        }
                         if(values.endDate) {
                             offerRequestRequest.endDate = new Date(values.endDate);
                        }else {
                            offerRequestRequest.endDate =  new Date('2050-12-31');
                        }

                        offerRequestRequest.resourcePath = values.resourcePath;
                        offerRequestRequest.deletedFlag = values.deletedFlag;

                       // alert('offerRequestRequest before save: '+JSON.stringify(offerRequestRequest, null, 2));
                        console.log('offerRequestRequest before save: '+JSON.stringify(offerRequestRequest, null, 2));
                        console.log("Before saving new Date(values.startDate): "+new Date(values.startDate));

                      // offerRequestRequest.startDate =   new Date(values.startDate);  // from examSetup - '2024-01-14'
                      //offerRequestRequest.rollCallJsonText = JSON.stringify(values, null, 2);

                      StudentService.saveTrainingOffer(offerRequestRequest).then(
                              response => {
                               // courseList = response.data;
                               toast.success('Training Offer saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                               console.log("Training Offer saved successfully ...: "+ JSON.stringify(response));
                              },
                          error => {
                            toast.error('Error while saving Training Offer', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                            console.log("Error saving Training Offer [error.toString()] : "+ error.toString());
                          }
                         );
                        setSubmitting(false);
                   }, 400);
             }}


          render={({ values }) => (

               <Form>

                    <FormitLogger />

                    <Grid container spacing={3}>

                           <Grid item xs={12} sm={6}>
                             <InputField name="code" label="Offer Code" fullWidth />
                           </Grid>
                           <Grid item xs={12} sm={6}>
                              <InputField name="name" label="Offer Name" fullWidth />
                           </Grid>

                            <Grid item xs={12} sm={6}>
                                <SelectField
                                   name="offerTypeCode"
                                   label="Offer Type"
                                   data={offerTypes}
                                   fullWidth
                                 />
                            </Grid>

                             <Grid item xs={12} sm={6}>
                               <InputField name="mktDescription" label="Marketing Description" fullWidth />
                            </Grid>

                              {
                                values.offerTypeCode === "PACKAGE" ||  values.offerTypeCode === "CORPORATE"?
                                        <Grid item xs={12} sm={6}>
                                          <MultiSelectField
                                               name="courseListJson"
                                               label="Course List"
                                               data={courses}
                                           />
                                        </Grid>

                                            :

                                        <Grid item xs={12} sm={6}>
                                          <SelectField
                                               name="courseListJson"
                                               label="Course List"
                                               data={courses}
                                           />
                                        </Grid>

                              }


                            <Grid item xs={12} sm={6}>
                                <SelectField
                                   name="courseGroupCode"
                                   label="Course Group"
                                   data={courseGroups}
                                   fullWidth
                                 />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <DatePickerField
                                  name="startDate"
                                  label="Offer Start Date"
                                  margin="normal"
                                  disableFuture
                                  inputFormat="yyyy-MM-dd"
                                  fullWidth
                                />
                             </Grid>
                             <Grid item xs={12} sm={6}>
                                  <DatePickerField
                                     name="endDate"
                                     label="Offer End Date"
                                     margin="normal"
                                     disableFuture
                                     inputFormat="yyyy-MM-dd"
                                     fullWidth
                                   />
                             </Grid>

                            <Grid item xs={12} sm={6}>
                                    Image  File
                                  <UploaderField name="imageFile" type="file" value={undefined}  />
                             </Grid>
                             <Grid item xs={12} sm={6}>
                                <InputField name="price" label="Price" fullWidth />
                             </Grid>

                            <br />
                            <br />

                            <Grid item xs={12} sm={6}>
                                <Button id="cancelButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Cancel
                                </Button>

                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Reset
                                </Button>

                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                     Save
                                </Button>
                            </Grid>
                    </Grid>
               </Form>
                )}
             />

         </div>
        </Container>
);

}

