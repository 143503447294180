
import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../components/formfields/InputField';
import InputTextAreaField from '../components/formfields/InputTextAreaField';
import CheckboxField from '../components/formfields/CheckboxField';
import SelectField from '../components/formfields/SelectField';
import MultiSelectField from '../components/formfields/MultiSelectField';
import DatePickerField from '../components/formfields/DatePickerField';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
//import BSCommonService  from "../../services/BSCommonService";
//import CourseService  from "../../services/CourseService";
import ClassService  from "../services/ClassService";
import BSCommonService  from "../services/BSCommonService";
import FormitLogger from '../common/FormitLogger';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const FORM_ENDPOINT = ""; // TODO - fill on the later step

const ContactUsSchema = Yup.object().shape({
        firstName: Yup.string()
        .required(`First Name Required`),
        lastName: Yup.string()
        .required(`Last Name is required`),
        email: Yup.string()
        .required(`Email is required`),
        message: Yup.string()
        .required(`Message is required`)
 });

export default function ContactUs() {

 const [classList, setClassList] = useState([]);


  return (


    <Container component="main" maxWidth="xs">
                <ToastContainer />
     <React.Fragment>
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
        <Typography component="h5" variant="h5" >

             Contact Us

        </Typography>


            <Formik

              initialValues={{
                             id: '',
                             firstName: '',
                             lastName: '',
                             email : '',
                             phoneNo : '',
                             message : '',
                           }}
                  onSubmit={(values, { setSubmitting }) => {
                                                      setTimeout(() => {

                                 setSubmitting(true);
                                 alert(JSON.stringify(values, null, 2));

                                 let contactUsRequest = {};
                                 contactUsRequest.id = values.id;
                                 contactUsRequest.firstName = values.firstName;
                                 contactUsRequest.lastName = values.lastName;
                                 contactUsRequest.email = values.email;
                                 contactUsRequest.phoneNo = values.phoneNo;
                                 contactUsRequest.message = values.message;

                                 alert('contactUsRequest :'+JSON.stringify(contactUsRequest, null, 2));

                                 BSCommonService.saveContactUs(contactUsRequest).then(
                                         response => {
                                           toast.success('Contact us message saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                           console.log("Contact us message saved successfully ...: "+ JSON.stringify(response));
                                         },
                                     error => {
                                      toast.error('Error while saving Contact us message', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     }
                                    );

                            setSubmitting(false);
                          }, 400);
                        }}

                 validationSchema={ContactUsSchema}
              >

               <Form>


                 <FormitLogger />
                   <Box sx={{ mt: 1 }}>

                    <Grid container spacing={1}>

                               <Grid item xs={6} sm={6}>
                                  <InputField
                                        name="firstName"
                                        label="First Name"
                                        fullWidth
                                      />
                                 </Grid>
                                <Grid item xs={6} sm={6}>
                                    <InputField
                                        name="lastName"
                                        label="Last Name"
                                        fullWidth
                                      />
                                </Grid>
                                 <Grid item xs={6} sm={6}>
                                  <InputField
                                        name="email"
                                        label="Email"
                                        fullWidth
                                      />
                                 </Grid>
                                <Grid item xs={6} sm={6}>
                                    <InputField
                                        name="phoneNo"
                                        label="phoneNo"
                                        fullWidth
                                      />
                                </Grid>

                     </Grid>

                    <Grid item xs={12} sm={12}>
                         <InputTextAreaField
                              name="message"
                              label="Message"
                              fullWidth
                            />
                    </Grid>

                 </Box>

                    <div>
                    < br/>
                      <Grid container spacing={.2}>
                        <Grid item xs={4} sm={4}>
                            <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                 Save
                            </Button>
                        </Grid>

                      </Grid>
                     < br/>
                    </div>

               </Form>
            </Formik>

            </Box>
         </React.Fragment>
          <br />
        </Container>
);

}
