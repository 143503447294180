import http from "../http-common";
import axios from "axios";

//const API_URL = "http://localhost:8080/api";

class ClassService {

// https://stackoverflow.com/questions/45578844/how-to-set-header-and-options-in-axios

saveClass(data) {

    console.log('Called ClassService.saveClass() ...');

    // get user token
    // TODO - do this in util
    let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
    console.log('user token: '+ JSON.stringify(user));
   // let token = JSON.stringify(user);
   let token;
    if(user && user.accessToken) {
        token = user.accessToken;
     }

    // headers
    const config = {
        headers: {
        'Authorization' : `Bearer ${token}`
        }
    }
    return http.post('/bs/training-class/save', data, config);
 }


getAllClasses() {

     console.log('Called ClassService.getAllClasses() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.get('/bs/training-class/all-classes', config);
 }

  // For fetching class students list based on class code and Date
 fetchRollCall(data) {

      console.log('Called ClassService.getAllClasses() ...');

         // get user token
         // TODO - do this in util
         let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
         console.log('user token: '+ JSON.stringify(user));
        // let token = JSON.stringify(user);
        let token;
         if(user && user.accessToken) {
             token = user.accessToken;
          }

         // headers
         const config = {
             headers: {
             'Authorization' : `Bearer ${token}`
             }
         }
         return http.post('/bs/rollcall/fetch', data, config);
  }

  saveRollCall(data) {

        console.log('Called ClassService.getAllClasses() ...');

           // get user token
           // TODO - do this in util
           let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
           console.log('user token: '+ JSON.stringify(user));
          let token;
           if(user && user.accessToken) {
               token = user.accessToken;
            }

           // headers
           const config = {
               headers: {
               'Authorization' : `Bearer ${token}`
               }
           }
           return http.post('/bs/rollcall/save', data, config);
    }


retrieveClassRollCall(data) {

         console.log('Called RollCallService.getClassRollCall() ...');

            // get user token
            // TODO - do this in util
            let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
            console.log('user token: '+ JSON.stringify(user));
           // let token = JSON.stringify(user);
           let token;
            if(user && user.accessToken) {
                token = user.accessToken;
             }

            // headers
            const config = {
                headers: {
                'Authorization' : `Bearer ${token}`
                }
            }
            return http.get('/bs/rollcall/retrieval/class-rollcall', config);
     }


  getClassByCode(code) {
       let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
          console.log('user token: '+ JSON.stringify(user));
         // let token = JSON.stringify(user);
         let token;
          if(user && user.accessToken) {
              token = user.accessToken;
           }

          // headers
          const config = {
              headers: {
              'Authorization' : `Bearer ${token}`
              }
          }
        return http.get('/bs/training-class/'+code, config);
}

 // class review

 saveClassReview(data) {

        console.log('Called ClassService.saveClassReview() ...');

        // get user token
        // TODO - do this in util
        let user = JSON.parse(localStorage.getItem('user_byte_steroid'));
        console.log('user token: '+ JSON.stringify(user));
       // let token = JSON.stringify(user);
       let token;
        if(user && user.accessToken) {
            token = user.accessToken;
         }

        // headers
        const config = {
            headers: {
            'Authorization' : `Bearer ${token}`
            }
        }
        return http.post('/bs/classreview/save', data, config);
 }

 getAllReviewsByClassCode(classCode) {

        console.log('Called ClassService.getAllReviewsByClassCode() ...');

        return http.get('/bs/classreview/all/{classCode}');
 }

  getClassReviewById(id) {
         console.log('Called ClassService.getClassReviewById() ...');
         return http.get('/bs/classreview/{id}');
}


}

export default new ClassService();