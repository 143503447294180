

import React from 'react';
import { at } from 'lodash';
import { useField, useFormikContext, Field } from 'formik';

export const UploaderField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);

    return (
        <Field
            {...field}
            {...props}
            onChange={(event) => {
                setFieldValue(field.name, event.currentTarget.files)
            }}
        />
    )
}