
export default {

        'employmentNumber' : '',
        'firstName' : '',
        'middleName' : '',
        'lastName' : '',
        'userName' : '',
        'password' : '',
        'passwordConfirmation' : '',
        'gender' : '',
        'dateOfBirth' : '',

        'photoFile': null,
        'resumeFile': null,

        'email' : '',
        'phoneNo' : '',
        'address1' : '',
        'address2' : '',
        'city' : '',
        'stateInCountry' : '',
        'country' : '',

        'certifications' : '',
        'educationalLevelCd' : '',
        'employmentStatusCd' : '',

        'coursesInterestJson' : '',

        'mondayMorning' : 'false',
        'mondayAfternoon' : 'false',
        'mondayEvening' : 'false',
        'mondayNight' : 'false',

        'tuesdayMorning' : 'false',
        'tuesdayAfternoon' : 'false',
        'tuesdayEvening' : 'false',
        'tuesdayNight' : 'false',

        'wednesdayMorning' : 'false',
        'wednesdayAfternoon' : 'false',
        'wednesdayEvening' : 'false',
        'wednesdayNight' : 'false',

        'thursdayMorning' : 'false',
        'thursdayAfternoon' : 'false',
        'thursdayEvening' : 'false',
        'thursdayNight' : 'false',

        'fridayMorning' : 'false',
        'fridayAfternoon' : 'false',
        'fridayEvening' : 'false',
        'fridayNight' : 'false',

        'saturdayMorning' : 'false',
        'saturdayAfternoon' : 'false',
        'saturdayEvening' : 'false',
        'saturdayNight' : 'false',

        'sundayMorning' : 'false',
        'sundayAfternoon' : 'false',
        'sundayEvening' : 'false',
        'sundayNight' : 'false',
        'paymentFile' : null,
        'paymentChannels' : ''

}