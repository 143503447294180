
export default {

  formId: 'regForm',

  formField: {
            firstName: {
              name: 'firstName',
              label: 'First name*',
              requiredErrorMsg: 'First name is required'
            },
            middleName: {
              name: 'middleName',
              label: 'Middle name'
            },
            lastName: {
              name: 'lastName',
              label: 'Last name*',
              requiredErrorMsg: 'Last name is required'
            },
            userName: {
              name: 'userName',
              label: 'User Name*',
              requiredErrorMsg: 'User Name is required'
            },
            password: {
                  name: 'password',
                  label: 'Password*',
                  requiredErrorMsg: 'Password is required'
                },
            passwordConfirmation: {
                name: 'passwordConfirmation',
                label: 'Password Confirmation*',
                requiredErrorMsg: 'Password Confirmation is required'
              },
            gender: {
              name: 'gender',
              label: 'Gender*',
              requiredErrorMsg: 'Gender is required'
            },
            email: {
              name: 'email',
              label: 'Email Id*',
              requiredErrorMsg: 'Email Id is required',
              invalidErrorMsg: 'Email Id is not valid (e.g. abc@yahoo.com)'
            },
            phoneNumber: {
              name: 'phoneNumber',
              label: 'Phone Number*',
              requiredErrorMsg: 'Phone Number is required'
            }
  }

};
