import { TIME_OUT, SET_IS_TIMED_OUT } from "./types";
import AuthService from "../services/auth.service";

export const timeOut = () => (dispatch) => {
  AuthService.logout();
  dispatch({
    type: TIME_OUT
  });
};

/*
export const setIsTimedOut = (isTimedOut) => ({
  type: SET_IS_TIMED_OUT,
  payload: isTimedOut,
});*/
