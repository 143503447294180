import React from 'react'
import ReviewBody from './ReviewBody.component'
import ReviewForm from './ReviewForm.component'
//import NavbarComponent from './Navbar'
import './review.css'

export default function Review() {
  return (
    <>
    <ReviewBody />
    <ReviewForm/>
    </>
  )
}