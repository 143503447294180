import React, { useContext, useRef, useState } from "react";
import IdleTimer from "react-idle-timer";
import { navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { AppStateContext } from "./contexts/AppStateContext";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";
import { timeOut } from "../actions/session";

let countdownInterval;
let timeout;

export default function SessionTimeout() {
  const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
  const [timeoutCountdown, setTimeoutCountdown] = useState(0);
  const idleTimer = useRef(null);
  const dispatch = useDispatch();

  const clearSessionTimeout = () => {
   console.log('clearSessionTimeout - mat-ui');
    clearTimeout(timeout);
  };

  const clearSessionInterval = () => {
  console.log('clearSessionInterval - mat-ui');
    clearInterval(countdownInterval);
  };

/*   const handleLogout = (isTimedOut = false) => {
    console.log('handleLogout - mat-ui');
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
    handleLogoutUser(isTimedOut);
  }; */

/// TODO - wip
const handleLogout = (isTimedOut = false) => {

  //const { dispatch, history } = this.props;


    console.log('handleLogout - mat-ui yyyy');
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();

    dispatch(timeOut())
    //dispatch({ type: 'TIME_OUT', isTimedOut });
    //EventBus.dispatch("timeOut", true);

    //navigate("/");
    navigate("/login");
    //window.history.push('/login'); //
     window.location.reload();

   console.log('handleLogout - mat-ui zzzz');
   AuthService.logout();

  dispatch({
    type: 'LOGOUT',
  });
};

  const handleContinue = () => {
    console.log('handleContinue - mat-ui zzzzß');
    setTimeoutModalOpen(false);
    clearSessionInterval();
    clearSessionTimeout();
  };

  const onActive = () => {
    console.log('onActive - mat-ui');
    if (!timeoutModalOpen) {
      clearSessionInterval();
      clearSessionTimeout();
    }
  };

    const onIdle = () => {
    // timeout={1000 * 60 * 14} //live
    // timeout={5000} //testing

    //let countDown = 60; //live
    //let countDown = 10; // testing

      const user = JSON.parse(localStorage.getItem("user"));

      const initialState = user
      ? { isLoggedIn: true, isTimedOut: false, user }
      : { isLoggedIn: false, isTimedOut: false, user: null };

        console.log('onIdle - mat-ui');
        const delay = 1000 * 5 * 1;

        console.log('onIdle - 1');
        if (initialState.isLoggedIn && !timeoutModalOpen) {
           console.log('onIdle - 2');
          timeout = setTimeout(() => {
            //let countDown = 10;
             let countDown = 60; // live
            setTimeoutModalOpen(true);
            setTimeoutCountdown(countDown);
            countdownInterval = setInterval(() => {
              if (countDown > 0) {
                 console.log('onIdle - 3');
                setTimeoutCountdown(--countDown);
              } else {
                 console.log('onIdle - 4');
                handleLogout(true);
              }
            }, 1000);
          }, delay);
        }
      };

  /*
  timeout={1000 * 60 * 14}
  timeout={1000 * 5 * 1}
  const onIdle = () => {
    console.log('onIdle - mat-ui');
    const delay = 1000 * 5 * 1;
    if (isAuthenticated && !timeoutModalOpen) {
      timeout = setTimeout(() => {
        let countDown = 10;
        setTimeoutModalOpen(true);
        setTimeoutCountdown(countDown);
        countdownInterval = setInterval(() => {
          if (countDown > 0) {
            setTimeoutCountdown(--countDown);
          } else {
            handleLogout(true);
          }
        }, 1000);
      }, delay);
    }
  }; */




  return (
    <>
      <IdleTimer
        ref={idleTimer}
        onActive={onActive}
        onIdle={onIdle}
        debounce={250}
        timeout={1000 *60 * 14}
      />
      <SessionTimeoutDialog
        countdown={timeoutCountdown}
        onContinue={handleContinue}
        onLogout={() => handleLogout(false)}
        open={timeoutModalOpen}
      />
    </>

  );
}
