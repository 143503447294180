import React, { Component } from "react";
import TutorialDataService from "../services/tutorial.service";
import { Link } from "react-router-dom";
import Pagination from "@material-ui/lab/Pagination";

import TutorialsAdminList from "./tutorial-admin-list.component"; // me
import SessionTimeout from "./SessionTimeout";
import BuggyCounter from "../common/BuggyCounter";
//import CollectionsCommonRenderer from "../components/africancollection/CollectionsCommonRenderer.component";
import UserProfile from "../components/UserProfile.component";
import ManagePosts from "../admin/components/ManagePosts.component";
import ManageUsers from "../admin/components/ManageUsers.component";
import ManageBlogs from "../admin/components/ManageBlogs.component";

import ManagePostDetail from "../admin/components/ManagePostDetail.component";
import Message from "../admin/components/Message.component";
import PopupWindow from "../admin/components/PopupWindow.component";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class UserDashboard extends Component {
  constructor(props) {
    super(props);
    this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
    this.retrieveTutorials = this.retrieveTutorials.bind(this);
    this.refreshList = this.refreshList.bind(this);
    this.setActiveTutorial = this.setActiveTutorial.bind(this);
    this.removeAllTutorials = this.removeAllTutorials.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlePageSizeChange = this.handlePageSizeChange.bind(this);

    this.state = {
      tutorials: [],
      currentTutorial: null,
      currentIndex: -1,
      searchTitle: "",

      page: 1,
      count: 0,
      pageSize: 3,
    };

    this.pageSizes = [3, 6, 9];
  }

  componentDidMount() {
    this.retrieveTutorials();
  }

  onChangeSearchTitle(e) {
    const searchTitle = e.target.value;

    this.setState({
      searchTitle: searchTitle,
    });
  }

  getRequestParams(searchTitle, page, pageSize) {
    let params = {};

    if (searchTitle) {
      params["title"] = searchTitle;
    }

    if (page) {
      params["page"] = page - 1;
    }

    if (pageSize) {
      params["size"] = pageSize;
    }

    return params;
  }

  retrieveTutorials() {
    const { searchTitle, page, pageSize } = this.state;
    const params = this.getRequestParams(searchTitle, page, pageSize);

    TutorialDataService.getAll(params)
      .then((response) => {
        const { tutorials, totalPages } = response.data;

        this.setState({
          tutorials: tutorials,
          count: totalPages,
        });
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  refreshList() {
    this.retrieveTutorials();
    this.setState({
      currentTutorial: null,
      currentIndex: -1,
    });
  }

  setActiveTutorial(tutorial, index) {
 try {
         console.log('tutorial : '+tutorial.title);
         this.setState({
          currentTutorial: tutorial,
          currentIndex: index,
        });
     }
     catch( e) {
       console.log('tutorial error catch: '+ e);
     }
  }

  removeAllTutorials() {
    TutorialDataService.deleteAll()
      .then((response) => {
        console.log(response.data);
        this.refreshList();
      })
      .catch((e) => {
        console.log(e);
      });
  }

  handlePageChange(event, value) {
    this.setState(
      {
        page: value,
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  handlePageSizeChange(event) {
    this.setState(
      {
        pageSize: event.target.value,
        page: 1
      },
      () => {
        this.retrieveTutorials();
      }
    );
  }

  render() {
            const {
              searchTitle,
              tutorials,
              currentTutorial,
              currentIndex,
              page,
              count,
              pageSize,
            } = this.state;

    return (

      <div className="list row">

        <SessionTimeout />

        <div className="col-md-12">

        <hr />

        <Tabs>
            <TabList>

                <Tab>User Profile</Tab>
            </TabList>

                <TabPanel>
                   <div>
                         User profile
                       <UserProfile />

                   </div>
                </TabPanel>


        </Tabs>

        </div>


        <hr />

      </div>
    );
  }
}
