import * as Yup from 'yup';
import moment from 'moment';
import RegistrationFormModel from './RegistrationFormModel';

const {
      formField:
          {
            firstName,
            middleName,
            lastName,
            userName,
            password,
            passwordConfirmation,
            gender,
            dateOfBirth,
            photoFile,

            email,
            phoneNo,
            address1,
            address2,
            city,
            stateInCountry,
            country,

            certifications,
            educationalLevelCd,
            employmentStatusCd,

            coursesInterestJson,

            resumeFile,

            paymentFile
          }
    } = RegistrationFormModel;


const passwordRules = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
//Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character

const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
//const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;


export default [

        Yup.object().shape({
            [firstName.name]: Yup.string().required(`${firstName.requiredErrorMsg}`),
            [middleName.name]: Yup.string().required(`${middleName.requiredErrorMsg}`),
            [lastName.name]: Yup.string().required(`${lastName.requiredErrorMsg}`),
            [userName.name]: Yup.string().required(`${userName.requiredErrorMsg}`),
            [password.name]: Yup
            .string()
            .matches(passwordRules, { message: "Please create a stronger password (Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character)" })
            .required("Required"),

            [passwordConfirmation.name]: Yup
            .string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("Required"),

            [gender.name]: Yup.string().required(`${gender.requiredErrorMsg}`),
            [dateOfBirth.name]: Yup.string().required(`${dateOfBirth.requiredErrorMsg}`),
            [photoFile.name]: Yup.string().required(`${photoFile.requiredErrorMsg}`)
        }),
        Yup.object().shape({
                    [email.name]: Yup.string()
                         .required(`${email.requiredErrorMsg}`)
                         .matches(emailRegEx, email.invalidErrorMsg),
            [address1.name]: Yup.string().required(`${address1.requiredErrorMsg}`),
            [phoneNo.name]: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
            [country.name]: Yup.string().required(`${country.requiredErrorMsg}`)
                         }),
         Yup.object().shape({
            [educationalLevelCd.name]: Yup.string().required(`${educationalLevelCd.requiredErrorMsg}`),
            [employmentStatusCd.name]: Yup.string().required(`${employmentStatusCd.requiredErrorMsg}`),
             [resumeFile.name]: Yup.mixed()
                                            // .notRequired()
                                            // .nullable()
                                            .test(
                                              "resumeFileTest",
                                              "Profile file is required and must not be more than 1MB",
                                              (file) => {
                                                console.log("file check : ", file[0]);
                                                if (file) {
                                                    return (file[0].size > 0  && file[0].size <= 1100000);
                                                } else {
                                                  return false;
                                                }
                                              }
                                            )
                        }),
         Yup.object().shape({
          [coursesInterestJson.name]: Yup.string().required(`${coursesInterestJson.requiredErrorMsg}`)
                      }),
          Yup.object().shape({
                                }),
         Yup.object().shape({

                         }),
         Yup.object().shape({

                           })
];
