import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { connect } from "react-redux";
import { register } from "../../actions/auth";
import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";
import StudentService  from "../../services/StudentService";

//import StudentRegistrationForm  from "../../components/training/user/StudentRegistrationForm";



//import CustomerForm  from "./stepperform/CustomerForm";

export default function FormDialog(props) {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

let styles = {
        marginRight: '20px',
        marginTop: '20px',
};

  return (

    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Enroll
      </Button>

      <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                        Enrollment
                        <DialogActions>
                            <button type="button"classNameName="close" data-dismiss="modal" aria-hidden="true" onClick={handleClose}>&times;</button>
                        </DialogActions>

                </DialogTitle>

        <DialogContent>



        <div classNameName="modal-header tit-up">
            <h4 classNameName="modal-title">Student-Course Enrollment</h4>
         </div>
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          />

         <div> Class Id: {props.classId} </div>


         <div >

                  <fieldset>
                          <legend>Student - Course Enrollment</legend>
                   <Form
                     ref={(c) => {
                     }}
                   >



                   <div>
                     <div className="form-group">
                       <label htmlFor="courseGroup">Course</label>

                       <span className = "span-criteria" >
                         <select id="courseCode" name="courseCode">

                            <option >Select Course</option>

                          </select>
                      </span>
                      <span style={{ color: "red" }}></span>
                       <br />
                     </div>
                     <div className="form-group">
                       <label htmlFor="duration">Total cost: </label>

                         <Input
                               type="text"
                               className="form-control"
                               name="registrationAmt"
                             />
                    </div>


                      <div className="form-group">
                           <label htmlFor="paymentReceiptFile">Payment Receipt</label>
                            <input type="file" name="paymentReceiptFile" class="form-control" single />
                      </div>

                     <div className="field-submit">
                         <span className="submit-span">
                             <button >Apply for Enrollment</button>
                        </span>

                        <span className="submit-span">
                              <button>Cancel</button>
                         </span>
                     </div>
                       </div>

                   </Form>

                 </fieldset>
         </div>



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Enroll</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}