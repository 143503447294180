
import React, { useState, useEffect } from 'react';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Container, Typography, Grid, TextField,

  CircularProgress
} from '@material-ui/core';
import InputField from '../../components/formfields/InputField';
import CheckboxField from '../../components/formfields/CheckboxField';
import SelectField from '../../components/formfields/SelectField';
import MultiSelectField from '../../components/formfields/MultiSelectField';
import DatePickerField from '../../components/formfields/DatePickerField';
import RatingField from '../../components/formfields/RatingField';

import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Box from "@mui/material/Box";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { Formik, Form,Field } from 'formik';
import * as Yup from 'yup';
import { connect } from "react-redux";
import ClassService  from "../../services/ClassService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";
import FormitLogger from '../../common/FormitLogger';


const ClassReviewSchema = Yup.object().shape({
    classCode: Yup.string()
    .required(`Class Required`),
         reviewComment: Yup.string()
    .required(`Comment is required`),
         starCount: Yup.string()
         .required(`Star Rating Required`)
 });

export default function ClassReview() {

   const [classList, setClassList] = useState([]);
   const [value, setValue] = React.useState('');
   const [error, setError] = React.useState(false);
   const [starCount, setStarCount] = useState(0);

   useEffect(() => {
        loadClasses();
       }, []);

    const loadClasses = async () => {
         ClassService.getAllClasses().then(
           response => {
                      console.log("classList Fetched: "+ JSON.stringify(response.data));
             setClassList(response.data);
           },
           error => {
             console.log("error.toString(): "+ error.toString());
           }
     );
    };


const updateStarCount= (newRating) => {
    setStarCount(newRating);
    console.log(starCount);
}

  return (

               <Container component="main" maxWidth="xs">
                 <ToastContainer />

                  <React.Fragment>
                         <Box
                           sx={{
                             marginTop: 8,
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "center",
                           }}
                         >
                           <Typography component="h5" variant="h5" >
                                  Training class  Review
                           </Typography>

                    <Formik

                                 initialValues={{
                                     id: '',
                                     reviewerUserId: '',
                                     classCode: '',
                                     reviewComment: '',
                                     starCount: 0,
                                     thumpsUpCount : '',
                                     thumpsDownCount : ''
                                   }}

                                onSubmit={(values, { setSubmitting }) => {

                                setTimeout(() => {
                                         setSubmitting(true);

                                         alert(JSON.stringify(values, null, 2));
                                         let classReviewRequest = {};
                                         classReviewRequest.classCode = values.classCode;
                                         classReviewRequest.reviewComment = values.reviewComment;
                                         classReviewRequest.starCount = values.starCount;

                                         alert('classReviewRequest :'+JSON.stringify(classReviewRequest, null, 2));

                                         ClassService.saveClassReview(classReviewRequest).then(
                                                 response => {
                                                   toast.success('Class Review saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                                   console.log("Class Review saved successfully ...: "+ JSON.stringify(response));
                                                 },
                                             error => {
                                              toast.error('Error while saving Class Review', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                             }
                                            );

                                    setSubmitting(false);
                                  }, 400);
                                }}

                             validationSchema={ClassReviewSchema}
                      >

                       <Form>
                            <FormitLogger />
                            <Grid container spacing={1} item style={{width: "70%"}}   >

                                    <Grid item xs={12} sm={12}>
                                          <SelectField
                                          name="classCode"
                                          label="Select Class"
                                          data={classList}
                                          fullWidth
                                          />
                                    </Grid>
                                    <br />
                                    <br />
                                    <Grid item xs={12} sm={12}>
                                           Rating
                                       <RatingField name="starCount" defaultValue={2} size="large" />

                                    </Grid>
                                    <br />
                                    <Grid item xs={12} sm={12}>

                                       <InputField
                                        name="reviewComment"
                                        label="Review Comment"
                                        fullWidth
                                        />
                                    </Grid>
                                    <br />
                                    <br />
                                      <Grid item xs={12} sm={12}>
                                        <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                             Save
                                        </Button>
                                      <br />
                                     </Grid>
                            </Grid>

                       </Form>
                    </Formik>
                    </Box>
                   </React.Fragment>

        </Container>
);

}

