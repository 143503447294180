import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import { connect } from "react-redux";
import { register } from "../../actions/auth";
import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";
import StudentService  from "../../services/StudentService";

class StudentCourseEnrollment extends Component {

  constructor(props) {
    super(props);

   // this.handleFileChange = this.handleFileChange.bind(this);

    this.state = {
       id : "",
       courses : [],
       paymentReceiptFilePath : null,
       paymentReceiptFile : null,
       successful: false,
       MyMessage : "",
       fields: {},
       errors: {}
    };
  }

//======================TODO
 /*   const [isAuthenticated, setIsAuthenticated] = useState(
                () => JSON.parse(localStorage.getItem('user_byte_steroid')) || false
            );

            const setAuth = (value) => {
                setIsAuthenticated(value);
                console.log('setIsAuthenticated value: '+value);
            };

            useEffect(()=> {
                localStorage.setItem("user_byte_steroid", JSON.stringify(isAuthenticated));
            }, [isAuthenticated]);


            const history = useHistory();

           function goToStudentCourseSignup() {
                        if(isAuthenticated) {
                              history.push("/studentCourseEnrollment");
                         }
                         else {
                              history.push("/login");
                         }
             }*/
//=======================






componentDidMount() {

        BSCommonService.getAllCourses().then(
                  response => {
                    this.setState({
                      courses: response.data
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
                console.log('loaded courses - this.state.courses :'+this.state.courses);
 }



  handleChange(field, e) {

  console.log('handleChange(field, e) - e :'+ e);
  let fields = this.state.fields;
        if('checkbox' === e.target.type) {
              fields[field] =  e.target.checked;
              //alert('event.target.type:'+ e.target.type);
              //alert('e.target.checked:'+ e.target.checked);
          }
         // if('file' === e.target.type) {
         //    let files = event.target.files;
         //     fields['paymentReceiptFile']  : files[0],
         //     fields['paymentReceiptFilePath']  : files[0].name,
         // }
          else {
              fields[field] = e.target.value;
          }
        this.setState({ fields });
        console.log('handleChange(field, e) - this.state : final');

}


/*
onChangeFileHandler = event=> {

          let files = event.target.files;
            console.log('files : '+ files);
             this.setState({
             paymentReceiptFile: files[0],
             paymentReceiptFilePath: files[0].name
          })
       // console.log('onChangeFileHandler - this.state :'+ this.state);
       //    console.log('onChangeFileHandler - this.state :'+ JSON.stringify(this.state));
    }
*/


 /*handleFileChange = event=> {
          let files = event.target.files

          this.setState({
            paymentReceiptFile: files[0],
            paymentReceiptFilePath: files[0].name,
            MyMessage : "Hello Desmond"
          }, function () {
          });

}*/


handleFileChange = event=> {
          let files = event.target.files

          this.setState({
            paymentReceiptFile: files[0],
            paymentReceiptFilePath: files[0].name,
            MyMessage : "Hello Desmond"
          }, function () {
                this.setStateReceiptFileField(files)
          });

}

setStateReceiptFileField(files) {
        this.setState({
                paymentReceiptFile: files[0],
                paymentReceiptFilePath: files[0].name,
                MyMessage : "Hello Desmond"
              }, function () {

              });
}


componentDidUpdate() {
   // console.log('componentDidUpdate this.state :' + JSON.stringify(this.state));
}


handleValidation() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;
    //alert('2...this.state.fields: '+JSON.stringify(this.state.fields));

    // courseGroupCode
   // alert('fields["courseGroupCode"]: '+ fields["courseGroupCode"]);

    if (!fields["courseGroupCode"] || (fields["courseGroupCode"] === "Select Course Group")) {
      formIsValid = false;
      errors["courseGroupCode"] = "You must selct Course Group";
        //alert('3...:'+formIsValid);
    }

    // course code
     if (!fields["code"] || fields["code"].length < 3 ) {
        formIsValid = false;
         errors["code"] = 'Course code must not be empty or less than three characters';
      }
      //  alert('4...:'+formIsValid);

    // course name
     if (!fields["name"] || fields["name"].length < 3 ) {
        formIsValid = false;
         errors["name"] = 'Course name must not be empty or less than three characters';
      }
     // alert('5...:'+ formIsValid);

    // course description
     if (!fields["description"] || fields["description"].length < 6 ) {
        formIsValid = false;
         errors["description"] = 'Course description must not be empty or less than six characters';
      }
      //alert('6...:'+formIsValid);

     // course duration
      if (!fields["duration"] ) {
         formIsValid = false;
          errors["duration"] = 'Course duration must not be empty';
     }
      //alert('7...:'+ formIsValid);

    this.setState({ errors: errors });
      //alert('8...:'+formIsValid);
     //alert('formIsValid...:'+ formIsValid);
    return formIsValid;
  }



  saveStudentEnrollment(e) {

          e.preventDefault();

          //if (!this.handleValidation()) {
          //    console.log('saveStudentEnrollment invoked; invalid form ...');
         //      return;
         // }

            console.log('zzz - 2 - saveStudentEnrollment( e) - this.state zzz :'+ this.state.MyMessage); // not printing. why?
            //   console.log('saveStudentEnrollment invoked ...3: this.state.fields["courseCode"]: '+ this.state.fields["courseCode"]);
            //   console.log('saveStudentEnrollment invoked ...4: this.state.fields["registrationAmt"]: '+ this.state.fields["registrationAmt"]);
            console.log('saveStudentEnrollment invoked ...5: this.state.paymentReceiptFile: '+ this.state.paymentReceiptFile);

            let data = {
                       courseCode : this.state.fields["courseCode"],
                       registrationAmt : this.state.fields["registrationAmt"],
                       paymentReceiptFilePath : this.state.paymentReceiptFilePath
                     };

             console.log('saveStudentEnrollment invoked ...6');
             console.log('saveStudentEnrollment() JSON.stringify(data): 7: ' +JSON.stringify(data));


                 StudentService.saveStudentEnrollment(data, this.state.paymentReceiptFile)
                   .then(response => {
                     //this.setState({
                     //  successful: true
                     //});
                    toast.success('🦄Student Enrollment saved successfully', {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              });

                            console.log('JSON.stringify(response.data): 4 ' +JSON.stringify(response.data));
                   })
                   .catch(e => {
                     console.log(e);
                      this.setState({
                           successful: false,
                         });

                        toast.error('🦄 Error while saving Student Enrollment  ...5'+ e.message, {
                                          position: "top-center",
                                          autoClose: 5000,
                                          hideProgressBar: false,
                                          closeOnClick: true,
                                          pauseOnHover: true,
                                          draggable: true,
                                          progress: undefined,
                                          });
                   });
}


  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">

        <div className="formContainerDiv">

         <fieldset>
                 <legend>Student - Course Enrollment</legend>
          <Form
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
          <div>
            <div className="form-group">
              <label htmlFor="courseGroup">Course</label>

              <span className = "span-criteria" >
                <select id="courseCode" name="courseCode"
                 onChange={this.handleChange.bind(this, "courseCode")}>
                      <option value={this.state.fields["courseCode"]}>Select Course</option>
                       {this.state.courses.map(course => (
                             <option key={course.code} value={course.code}>
                               {course.name}
                             </option>
                           ))}
                 </select>
             </span>
             <span style={{ color: "red" }}>{this.state.errors["courseCode"]}</span>
              <br />
            </div>
            <div className="form-group">
              <label htmlFor="duration">Total cost: </label>

                <Input
                      type="text"
                      className="form-control"
                      name="registrationAmt"
                       onChange={this.handleChange.bind(this, "registrationAmt")}
                       value={this.state.fields["registrationAmt"]}
                    />
           </div>


             <div className="form-group">
                  <label htmlFor="paymentReceiptFile">Payment Receipt</label>
                   <input type="file" name="paymentReceiptFile" class="form-control" single onChange={this.handleFileChange}/>
             </div>

            <div className="field-submit">
                <span className="submit-span">
                    <button onClick={this.saveStudentEnrollment.bind(this)} >Apply for Enrollment</button>
               </span>

               <span className="submit-span">
                     <button>Cancel</button>
                </span>
            </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

//export default connect(mapStateToProps)(StudentCourseEnrollment);

export default StudentCourseEnrollment;