
import React, { useState } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  CircularProgress
} from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import { Formik, Form, useFormikContext } from "formik";


export default function FormitLogger(props) {

  const formik = useFormikContext();

  React.useEffect(() => {
    console.group("Formik State");
    console.log("values", formik.values);
    console.log("errors", formik.errors);
    console.log("touched", formik.touched);
    console.log("isSubmitting", formik.isSubmitting);
    console.log("isValidating", formik.isValidating);
    console.log("submitCount", formik.submitCount);
    console.groupEnd();
  }, [
    formik.values,
    formik.errors,
    formik.touched,
    formik.isSubmitting,
    formik.isValidating,
    formik.submitCount,
    formik.initialValues
  ]);
  return null;
};