import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../../actions/auth";

import BSCommonService  from "../../services/BSCommonService";
import CourseService  from "../../services/CourseService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class CreateCourseSyllabi extends Component {

  constructor(props) {

    super(props);
    this.state = {
     id : "",
     courses : [],
     successful: false,
     fields: {},
     errors: {}
    };

  }

handleValidation() {

    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    // Syllabus title
     if (!fields["title"] || fields["title"].length < 3 ) {
        formIsValid = false;
         errors["title"] = 'Syllabus item title must not be empty or less than ten characters';
      }

    if (!fields["courseCode"] || (fields["courseCode"] === "Select Course")) {
      formIsValid = false;
      errors["courseCode"] = "You must select Course";
    }

    // Syllabus description
     if (!fields["description"] || fields["description"].length < 6 ) {
        formIsValid = false;
         errors["description"] = 'Syllabus item description must not be empty or less than six characters';
      }

    this.setState({ errors: errors });
    return formIsValid;
  }

  handleChange(field, e) {
      let fields = this.state.fields;
      if('checkbox' === e.target.type) {
            fields[field] =  e.target.checked;
        }
        else {
            fields[field] = e.target.value;
        }
     this.setState({ fields });
     console.log('handleChange(field, e) - this.state :'+ JSON.stringify(this.state));
  }

saveSyllabus(e) {

        e.preventDefault();

        if (!this.handleValidation()) {
            console.log('saveSyllabus invoked; invalid form ...');
             return;
        }
        console.log('saveSyllabus invoked ...');

        let data = {
          title: this.state.fields["title"],
          description: this.state.fields["description"],
          courseCode: this.state.fields["courseCode"],
          deletedFlag: this.state.fields["deleted"]
        };

        console.log('saveSyllabus() JSON.stringify(data): ' +JSON.stringify(data));
        console.log('saveSyllabus(field, e) - this.state :'+ JSON.stringify(this.state));

        CourseService.saveSyllabus(data)
          .then(response => {
           toast.success('🦄 Syllabus saved successfully', {
                     position: "top-center",
                     autoClose: 5000,
                     hideProgressBar: false,
                     closeOnClick: true,
                     pauseOnHover: true,
                     draggable: true,
                     progress: undefined,
                     });

              console.log('JSON.stringify(response.data): ' +JSON.stringify(response.data));
          })
          .catch(e => {
            console.log(e);
             this.setState({
                  successful: false,
                });

               toast.error('🦄 Error while saving syllabus ...'+ e.message, {
                                 position: "top-center",
                                 autoClose: 5000,
                                 hideProgressBar: false,
                                 closeOnClick: true,
                                 pauseOnHover: true,
                                 draggable: true,
                                 progress: undefined,
                                 });
          });
}

componentDidMount() {

        BSCommonService.getAllCourses().then(
                  response => {
                    this.setState({
                      courses: response.data
                    });
                  },
                  error => {
                    this.setState({
                      content:
                        (error.response &&
                          error.response.data &&
                          error.response.data.message) ||
                        error.message ||
                        error.toString()
                    });

                  }
                );
                console.log('loaded courses - this.state.courses :'+this.state.courses);
      }

  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">
        <div className="formContainerDiv">

         <fieldset>
                 <legend>Course Syllabi Detail</legend>

          <Form
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="courseName">Syllabus Item Title</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="title"
                    onChange={this.handleChange.bind(this, "title")}
                    value={this.state.fields["title"]}
                  />
                   <span style={{ color: "red" }}>{this.state.errors["title"]}</span>
                   <br />
                </div>

                <div className="form-group">
                  <label htmlFor="courseDescription">Syllabus Item Description</label>
                  <Input
                    type="text"
                    className="form-control"
                    name="description"
                    onChange={this.handleChange.bind(this, "description")}
                    value={this.state.fields["description"]}
                  />
                  <span style={{ color: "red" }}>{this.state.errors["description"]}</span>
                  <br />
                </div>

                <div className="form-group">
                  <label htmlFor="course">Courses</label>

                  <span className = "span-criteria" >
                    <select id="course" name="course"
                    onChange={this.handleChange.bind(this, "courseCode")}>
                         <option value={this.state.fields["courseCode"]}>Select Course</option>
                          {this.state.courses.map(course => (
                                <option key={course.code} value={course.code}>
                                  {course.name}
                                </option>
                              ))}
                    </select>
                 </span>
                 <span style={{ color: "red" }}>{this.state.errors["courseCode"]}</span>
                 <br />
                </div>

                <div className="form-group">
                   <label htmlFor="deletedFlag">Deleted Flag</label>
                     <span className="submit-span">
                         <label>
                           <input
                             name="deleted"
                             id="deleted"
                             type="checkbox"
                             onChange={this.handleChange.bind(this, "deleted")}
                             value={this.state.fields["deleted"]}
                           />
                         </label>
                      </span>
               </div>


                 <div class="form-group">
                     <ToastContainer />
                 </div>

                <div className="field-submit">

                   <span className="submit-span">
                             <button>Cancel</button>
                    </span>
                    <span className="submit-span">
                       <button id="saveButton"  name="saveButton" onClick={this.saveSyllabus.bind(this)}  className="submit-span" type="submit">Save</button>
                    </span>

                </div>
              </div>
            )}


          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(CreateCourseSyllabi);
