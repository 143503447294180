import React, { Component } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import { connect } from "react-redux";
import { register } from "../../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vcourseName = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className="alert alert-danger" role="alert">
        The Course Name must be between 3 and 20 characters.
      </div>
    );
  }
};

const vcourseGroup = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className="alert alert-danger" role="alert">
        The Course Group must be between 6 and 40 characters.
      </div>
    );
  }
};

class FacultyCourseAssignment extends Component {

  constructor(props) {
    super(props);
    this.handleCreate = this.handleCreate.bind(this);
    this.onChangeCourseName = this.onChangeCourseName.bind(this);
    this.onChangeDuration = this.onChangeDuration.bind(this);
    this.onChangeDeletedFlag = this.onChangeDeletedFlag.bind(this);
    this.onChangeCourseDescription = this.onChangeCourseDescription.bind(this);
    this.onChangeCourseGroup = this.onChangeCourseGroup.bind(this);

    this.state = {
      courseName: "",
      email: "",
      courseGroup: "",
      duration: "",
      deletedFlag: "",
      successful: false,
    };


  }

//let courseGroupSelectInput = React.createRef();

  onChangeCourseName(e) {
    this.setState({
      courseName: e.target.value,
    });
  }


 onChangeDuration(e) {
    this.setState({
      duration: e.target.value,
    });
  }

   onChangeDeletedFlag(e) {
    this.setState({
      deletedFlag: e.target.value,
    });
  }
  onChangeCourseDescription(e) {
    this.setState({
      courseDescription: e.target.value,
    });
  }

  onChangeCourseGroup(e) {
    this.setState({
      courseGroup: e.target.value,
    });
  }

  handleCreate(e) {
    e.preventDefault();

    this.setState({
      successful: false,
    });

    this.form.validateAll();

    if (this.checkBtn.context._errors.length === 0) {
      this.props
        .dispatch(
          register(this.state.courseName, this.state.courseDescription, this.state.courseGroup, this.state.duration, this.state.deletedFlag, 'REALM_BYTES_STEROID')
        )
        .then(() => {
          this.setState({
            successful: true,
          });
        })
        .catch(() => {
          this.setState({
            successful: false,
          });
        });
    }
  }

  render() {
    const { message } = this.props;

    return (
      <div className="col-md-12">




        <div className="formContainerDiv">

         <fieldset>
                 <legend>Faculty - Program Assignment</legend>


          <Form
            onSubmit={this.handleCreate}
            ref={(c) => {
              this.form = c;
            }}
          >
            {!this.state.successful && (
              <div>
                <div className="form-group">
                  <label htmlFor="courseGroup">Course</label>

                  <span className = "span-criteria" >
                     <select id="courseGroup" name="courseGroup">
                     <option value="">Select Course</option>

                      ))}
                      </select>
                 </span>

                </div>

                <div className="form-group">
                <label htmlFor="facultyId">Faculty</label>

                <span className = "span-criteria" >
                   <select id="facultyId" name="facultyId">
                   <option value="">Select Faculty</option>

                    ))}
                    </select>
                </span>
                </div>

                <div className="field-submit">
                    <span className="submit-span">
                        <button>Assign</button>
                   </span>

                   <span className="submit-span">
                        <button>Cancel</button>
                    </span>
                </div>
              </div>
            )}

            {message && (
              <div className="form-group">
                <div className={ this.state.successful ? "alert alert-success" : "alert alert-danger" } role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton
              style={{ display: "none" }}
              ref={(c) => {
                this.checkBtn = c;
              }}
            />
          </Form>

        </fieldset>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  return {
    message,
  };
}

export default connect(mapStateToProps)(FacultyCourseAssignment);
