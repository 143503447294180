export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
//Me
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const TIME_OUT = "TIME_OUT";
export const SET_IS_TIMED_OUT = "SET_IS_TIMED_OUT";

