import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'
import Box from '@mui/material/Box';
import InputField from '../../../components/formfields/InputField';
import CheckboxField from '../../../components/formfields/CheckboxField';
import SelectField from '../../../components/formfields/SelectField';
import DatePickerField from '../../../components/formfields/DatePickerField';
import InputTextAreaField from '../../../components/formfields/InputTextAreaField';
import styles from './evaluation.module.css'

import Pagination from "@material-ui/lab/Pagination";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';

import EvaluationService from '../../../services/EvaluationService';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Formik, Form, Field, FieldArray, ErrorMessage  } from 'formik';
import FormitLogger  from "../../../common/FormitLogger";
import jeanLogo from'./static/jeans3.jpg'

const examTypes = [
  {
    code: 'T',
    label: 'Test'
  },
  {
    code: 'E',
    label: 'Examination'
  }
];


const questionTypes = [
  {
    code: 'TF',
    label: 'True or False'
  },
  {
    code: 'MC',
    label: 'Multiple Choice'
  },
   {
      code: 'FB',
      label: 'Fill In Blank/Exact'
    }
    ,
    {
       code: 'DD',
       label: 'Drag And Drop Matching'
    }
    ,
    {
      code: 'ES',
      label: 'Essay'
    },
    {
        code: 'MT',
        label: 'Matching Question'
    }
    ,
    {
        code: 'SE',
        label: 'Rank/Sequence'
    } ,
     {
        code: 'HS',
        label: 'Hot Spot'
    }
];


const booleanOptions = [
  {
    code: 'T',
    label: 'True'
  },
  {
    code: 'F',
    label: 'False'
  }
];

const multiChoiceAnswerOptionCount = [
      {
        code: '3',
        label: 'Three'
      },
    {
    code: '4',
    label: 'Four'
    }
    ,
    {
    code: '5',
    label: 'Five'
    }
];

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#fff"
  },
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://images.unsplash.com/photo-1558981852-426c6c22a060?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em"
    }
  },
  blogsContainer: {
    paddingTop: theme.spacing(3)
  },
  blogTitle: {
    fontWeight: 800,
    paddingBottom: theme.spacing(3)
  },
  card: {
    maxWidth: "100%",
  },
  media: {
    height: 240
  },
  cardActions: {
    display: "flex",
    margin: "0 10px",
    justifyContent: "space-between"
  },
  author: {
    display: "flex"
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "center"
  }
}));

const ExamTakeNew = () =>    {

            const classes = useStyles();
            let sn = 0;

            const [flow, setFlow] = React.useState('');
            const [error, setError] = React.useState(false);
            const [helperText, setHelperText] = React.useState('');

            const [questionsList, setQuestionsList] = useState([]);
            const [answersList, setAnswersList] = useState([]);
            const [count, setCount] = useState(0);
            let [page, setPage] = React.useState(0);

            const handleFlowRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            // setValue((event.target as HTMLInputElement).value);
            setFlow(event.target.value);
            setHelperText(' ');
            setError(false);
            };

         useEffect(() => {
                  retrieveQuestionsList();
          }, []);
    //================

function retrieveQuestionsList(e, examCode) {
       let params = {};
       // if (searchTitle) {
        //  params["id"] = searchTitle;
       // }

       //setPage(page++)

        if (page) {
          params["page"] = page;
       }

       // if (pageSize) {
          params["size"] = 1;
       // }

       // e.preventDefault();
        console.log('retrieveQuestionsList called ... ');
        // alert('examCode: '+examCode)
        let examRequest = {
            "examCode": examCode
        }

        console.log('examRequest called ..: JSON.stringify(examRequest) - from UI : ' +JSON.stringify(examRequest));
        EvaluationService.getExamQuestionsByExamCode(examRequest, params)
                 .then((response) => {
                    const { questionsList, answersList, currentPage, totalItems, totalPages } = response.data;
                    setQuestionsList(questionsList);
                    setAnswersList(answersList);
                    setCount(totalPages);
                    console.log(response.data);
                 })
                 .catch((e) => {
                   console.log(e);
                 });

}

 const handlePageChange = (event, page, examCode) => {
        setPage(page);
        retrieveQuestionsList(event, examCode)
 };

function lookupExam(e, examCode) {

           e.preventDefault();
           console.log('lookupExam called ... ');
          // alert('examCode: '+examCode)
           let examRequest = {
                                "examCode": examCode
                             }

        // alert('examSetupRequest : '+JSON.stringify(examSetupRequest, null, 2));
        // examCodeForSearch
         console.log('examRequest called ..: JSON.stringify(examRequest) - from UI : ' +JSON.stringify(examRequest));

         EvaluationService.examFetchQuestions(examRequest)
                  .then(response => {

                    // alert('response.data : '+JSON.stringify(response.data, null, 2));
                    // exam, questionAndAnswerList
                    //   setQuestionsList(response.data.questionAndAnswerList);

                    // setQuestionsList(questionsList => ([...questionsList, ...response.data.questionAndAnswerList]));

                    // alert('questionsList : '+JSON.stringify(questionsList, null, 2));

                    // setExam(exam => ([...exam, ...response.data.exam]));

                   toast.success('🦄 Exam setup fetched successfully', {
                             position: "top-center",
                             autoClose: 5000,
                             hideProgressBar: false,
                             closeOnClick: true,
                             pauseOnHover: true,
                             draggable: true,
                             progress: undefined,
                             });
                      console.log('Exam setup fetched response: JSON.stringify(response.data): ' +JSON.stringify(response.data));
                      alert('Exam setup fetched response: JSON.stringify(response.data): ' +JSON.stringify(response.data));
                  })
                  .catch(e => {
                    console.log(e);
                    toast.error('🦄 Error while fetching Exam setup ...'+ e.message, {
                                         position: "top-center",
                                         autoClose: 5000,
                                         hideProgressBar: false,
                                         closeOnClick: true,
                                         pauseOnHover: true,
                                         draggable: true,
                                         progress: undefined,
                                         });
                  });

                // alert('questionsList 2 : '+JSON.stringify(questionsList, null, 2));
    }

// Varying question type question rendering
function renderTrueOrFalseQuestionSetup(question, index) {

 return (
       <Grid item xs={12} sm={12}>

             <Grid item xs={12} sm={12}>
                 <span>SN</span> <span>{sn++}</span>
                 <InputTextAreaField
                      name="trueOrFalseQtnText"
                      value={question.questionText}
                      fullWidth
                    />
            </Grid>

           <Grid item xs={12} sm={12}>
                <RadioGroup
                  aria-labelledby="demo-error-radios"
                  name="trueOrFalseQtnGrp"
                  value={flow}
                  onChange={handleFlowRadioChange}>

                 {
                /*<FormControlLabel value="True" control={<Radio />} label="True" />
                <FormControlLabel value="False" control={<Radio />} label="False" />*/

                 }
                </RadioGroup>
             </Grid>
             <Grid item xs={12} sm={6}>
                <SelectField
                    name="correctTrueOrFalseAnswer"
                    label="Select your answer"
                    data={booleanOptions}
                    fullWidth
                  />
            </Grid>

        <br />
        <br />

        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
        </Grid>

        </Grid>
  );

}

function renderMultiChoiceQuestionSetup(values, question, index) {

 return (
    <>
        <Grid item xs={6} sm={6}>

                    <div className={styles.tableDisplay}>
                         <div className={styles.tableRowDisplay}>
                              <span>SN</span> <span>{index}</span>
                              <span className={styles.tableCellDisplay} >

                                  <InputField
                                    name="questionText"
                                    value={question.questionText}
                                    className={styles.inPutBox}
                                  />
                              </span>

                            <span className={styles.tableCellDisplay} >
                            </span>
                            <span className={styles.tableCellDisplay} >
                            </span>
                         </div>
                     </div>
                    <div>

                    </div>

                    <div className={styles.tableDisplay}>
                        <div className={styles.tableRowDisplay}>
                            <span className={styles.tableCellDisplay} >
                                Check the correct options
                            </span>
                       </div>
                    </div>

                    <div>
                        { question.multiChoiceAnswerOptionCount === 3 ? renderThreeMultiChoice(question):  null }
                        { question.multiChoiceAnswerOptionCount === 4 ? renderFourMultiChoice(question):  null }
                        { question.multiChoiceAnswerOptionCount === 5 ? renderFiveMultiChoice(question):  null }
                    </div>

        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
        </Grid>
       </>
  );

}

function renderThreeMultiChoice(question) {


  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>

                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                 value= {question.multiChoiceQuestionTextsStr.answerAText}
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>

                         <div className={styles.tableCellDisplay} >
                              <label  name="answerAlphabetBLabel" > (B) </label>
                         </div>

                        <div className={styles.tableCellDisplay} >
                             <InputField
                                  name="answerBText"
                                     value= {question.multiChoiceQuestionTextsStr.answerBText}
                                  className={styles.inPutBox}
                             />
                        </div>
                        <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                        </div>
                        <div className={styles.tableCellDisplay} >
                             <CheckboxField
                                  name="isBCorrectChoice"
                              />
                        </div>
                  </div>


                 <div className={styles.tableRowDisplay}>

                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                       value= {question.multiChoiceQuestionTextsStr.answerCText}
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

             </div>
      </>

  );

}

function renderFourMultiChoice(question) {

  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>

                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                   value= {question.multiChoiceQuestionTextsStr.answerAText}
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                      <label  name="answerAlphabetBLabel" > (B) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                          name="answerBText"
                                          value= {question.multiChoiceQuestionTextsStr.answerBText}
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                          name="isBCorrectChoice"
                                      />
                                </div>
                  </div>


                 <div className={styles.tableRowDisplay}>
                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                     value= {question.multiChoiceQuestionTextsStr.answerCText}
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

                <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                    <label  name="answerAlphabetDLabel" > (D) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                             name="answerDText"
                                             value= {question.multiChoiceQuestionTextsStr.answerDText}
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                 </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                            name="isDCorrectChoice"
                                      />
                                </div>
                </div>

             </div>
      </>

  );
}


function renderFiveMultiChoice(question) {

  return (
      <>
    <div className={styles.tableDisplay}>

                   <div className={styles.tableRowDisplay}>
                        <div className={styles.tableCellDisplay} >
                            <label  name="answerAlphabetALabel"  > (A) </label>
                        </div>

                       <div className={styles.tableCellDisplay} >
                            <InputField
                                name="answerAText"
                                   value= {question.multiChoiceQuestionTextsStr.answerAText}
                                 className={styles.inPutBox}
                            />
                       </div>
                       <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                      </div>
                       <div className={styles.tableCellDisplay} >
                            <CheckboxField
                                name="isACorrectChoice"
                             />
                       </div>
                  </div>

                  <div className={styles.tableRowDisplay}>
                                 <div>
                                    {question.multiChoiceQuestionTextsStr.answerBText}
                                 </div>
                                 <div className={styles.tableCellDisplay} >
                                      <label  name="answerAlphabetBLabel" > (B) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                          name="answerBText"
                                          value= {question.multiChoiceQuestionTextsStr.answerBText}
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                          name="isBCorrectChoice"
                                      />
                                </div>
                  </div>


                 <div className={styles.tableRowDisplay}>
                           <div>
                             {question.multiChoiceQuestionTextsStr.answerCText}
                          </div>
                           <div className={styles.tableCellDisplay} >
                               <label  name="answerAlphabetCLabel"> (C) </label>
                           </div>

                          <div className={styles.tableCellDisplay} >
                               <InputField
                                    name="answerCText"
                                    value= {question.multiChoiceQuestionTextsStr.answerCText}
                                    className={styles.inPutBox}
                               />
                          </div>
                          <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                           </div>
                          <div className={styles.tableCellDisplay} >
                               <CheckboxField
                                    name="isCCorrectChoice"
                                />
                          </div>
                </div>

                <div className={styles.tableRowDisplay}>
                                 <div className={styles.tableCellDisplay} >
                                    <label  name="answerAlphabetDLabel" > (D) </label>
                                 </div>

                                <div className={styles.tableCellDisplay} >
                                     <InputField
                                             name="answerDText"
                                             value= {question.multiChoiceQuestionTextsStr.answerDText}
                                          className={styles.inPutBox}
                                     />
                                </div>
                                <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                                 </div>
                                <div className={styles.tableCellDisplay} >
                                     <CheckboxField
                                            name="isDCorrectChoice"
                                      />
                                </div>
                </div>

                 <div className={styles.tableRowDisplay}>
                               <div className={styles.tableCellDisplay} >
                                     <label name="answerAlphabetELabel" > (E) </label>
                               </div>

                              <div className={styles.tableCellDisplay} >
                                   <InputField
                                         name="answerEText"
                                         value= {question.multiChoiceQuestionTextsStr.answerEText}
                                        className={styles.inPutBox}
                                   />
                              </div>
                              <div className={styles.tableCellDisplay} style={{marginRight: "1px"}}  >
                              </div>
                              <div className={styles.tableCellDisplay} >
                                   <CheckboxField
                                         name="isECorrectChoice"
                                    />
                              </div>
                 </div>

             </div>
      </>

  );

}

function renderFillBlankExactMatchQuestionSetup(question, index) {

 return (
    <>
          <Grid>
             </Grid >
             <Grid item xs={12} sm={12}>
                 <span>SN</span> <span>{index}</span>

                 <InputField
                      name="fillInBlankQuestionText"
                       value={question.questionText}
                      fullWidth
                    />
            </Grid>
             <Grid item xs={12} sm={12}>
                 <InputField
                      name="fillInBlankQuestionAnswer"
                      label="Enter your answer"
                      fullWidth
                    />
            </Grid>

            <br />
            <br />

        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
        </Grid>
       </>
  );

}

function renderMatchingQuestionSetup(index) {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <span>SN</span> <span>{index}</span>
             <div>
                 <h6> Question type not supported. Please, choose another question type. </h6 >
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
            <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                Add
            </Button>
        </Grid>
       </>
  );

}



function renderSequenceQuestionSetup() {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <div>
                  <h6> Question type not supported. Please, choose another question type. </h6 >
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
        </Grid>
       </>
  );

}

function renderHotSpotQuestionSetup() {

 return (
    <>
        <Grid item xs={6} sm={6}>
             <div>
                <h6>  Question type not supported. Please, choose another question type. </h6>
             </div >
        </Grid>
        <Grid item xs={12} sm={6}>
                <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                    Add
                </Button>
        </Grid>
       </>
  );

}

function renderEssayQuestionSetup(question, index) {
 console.log('Called renderEssayQuestionSetup...');

 return (
    <>

             <Grid item xs={12} sm={12}>
                 <span>SN</span> <span>{index}</span>
                 <InputTextAreaField
                      name="essayQuestionText"
                       value={question.questionText}
                      fullWidth
                    />
            </Grid>

             <Grid item xs={12} sm={12}>
                 <InputTextAreaField
                      name="essayQuestionPossibleAnswer"
                      label= "Enter your answer"
                      fullWidth
                    />
            </Grid>

            <br />
            <br />

            <Grid item xs={12} sm={12}>
                    <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                        Add
                    </Button>
            </Grid>

       </>
  );

}



 function renderDragAndDropMatchingQuestionSetup() {

  console.log('Called renderDragAndDropMatchingQuestionSetup...');

/*
        // UI : https://knowledgebase.xjtlu.edu.cn/article/drag-and-drop-matching-31.html
        // Drag & Drop

        https://www.w3schools.com/html/html5_draganddrop.asp //
        https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer //
        https://codepen.io/noyiri/pen/zeoRwB //

        https://www.digitalocean.com/community/tutorials/js-drag-and-drop-vanilla-js //



        https://www.npmjs.com/package/react-draggable
        https://www.freakyjolly.com/draggable-react-components-using-react-draggable-example-application/#:%7E:text=Adding%20a%20drag%20feature%20to%20a%20component%20is,component%20to%20make%20them%20draggable%20on%20the%20page.
        https://blog.logrocket.com/drag-and-drop-react-dnd/
    */

  return (
     <>

              <Grid item xs={12} sm={12}>

                  <p>Drag the image back and forth between the two div elements.</p>

                  <div id="ddDiv1" className={styles.ddDiv}  onDrop={drop} onDragOver={allowDrop}>
                    <img src={jeanLogo} draggable="true" onDragStart={drag} id="drag1" width="88" height="31" />
                  </div>

                  <div id="ddDiv2"   className={styles.ddDiv}  onDrop={drop} onDragOver={allowDrop} ></div>
             </Grid>

             <br />
             <br />

             <Grid item xs={12} sm={6}>
                     <Button id="addButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                         Add
                     </Button>
             </Grid>

        </>
   );

 }

function allowDrop(ev) {
  ev.preventDefault();
}

function drag(ev) {
  ev.dataTransfer.setData("text", ev.target.id);
}

function drop(ev) {
  ev.preventDefault();
  var data = ev.dataTransfer.getData("text");
  ev.target.appendChild(document.getElementById(data));
}

// End Varying question type question rendering

function renderNewExamBaseInfo() {

 return (
    <div>
        <Grid container spacing={2}>
           <Grid item sm={12}>
                <label>Exam Meta Data</label>
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examCode" label="Exam Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="examDescription" label="Exam Description" fullWidth />
            </Grid>
            <Grid item sm={3}>
                 <InputField name="classCode" label="Class Code" fullWidth />
            </Grid>
            <Grid item sm={3}>
                     <SelectField
                        name="type"
                        label="Type"
                        data={examTypes}
                        fullWidth
                      />

            </Grid>
            <Grid item sm={3}>
                  <InputField name="score" label="Maximum Score" fullWidth />
             </Grid>
            <Grid item sm={3}>
                    <InputField name="timeLimitSec" label="Time Limit(Min)" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <InputField name="attemptsAllowed" label="Attempts Allowed" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="startsDate" label="Start Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
            <Grid item sm={3}>
                    <DatePickerField name="endsDate" label="End Date"  inputFormat="yyyy-MM-dd" fullWidth />
            </Grid>
          </Grid>

    </div>
    );

}


function renderExamSearchView(values) {

     return (
        <div>
             <Grid container>
                <Grid item sm={12}>
                      <label>Search Term</label>
                </Grid>
                <Grid item sm={12}>
                        <InputField name="examCodeForSearch" label="Exam Code" />
                </Grid>


                    <Grid item xs={12} sm={6}>
                            <Button id="lookupExamButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined"

                            onClick={(e) => {
                                  retrieveQuestionsList(e, values.examCodeForSearch);
                               }}
                            >
                                Lookup Exam Setup
                            </Button>

                    </Grid>

                <br />
                <br />
              </Grid>

        </div>
        );

}

  return (
    <Container maxWidth="sm">
      <ToastContainer />
      <div>

            <Typography  component="h3"  gutterBottom variant="h3" component="div">
              <Grid item sm={12}>
                   Examination Setup xxx
              </Grid>
            </Typography>

                <Formik

                       initialValues={{
                                       questionTypes: questionTypes,
                                       questionType: "",
                                        "createdDate" : "",
                                         "lastModifiedDate" : "",
                                         "createdById" : null,
                                         "lastModifiedById" : null,
                                         "isDelete":  null,
                                         "id": "",
                                         "sn": null,
                                         "questionNo": null,
                                         "examCode": "",
                                         "questionAnswerOptionsStr": null,
                                         "correctAnswerStr": null,
                                         "allowsMultipleAnswers": null,
                                         "maxAnswersAllowed": null,
                                         "order": null,
                                         "examId": 36,
                                         "isActive": null,
                                         "questionText": "",
                                         "questionStem": null,
                                         "preQuestionStemText": null,
                                         "preQuestionStemSpan": null,
                                         "multiChoiceAnswerOptionCount": null,
                                         "multiChoiceQuestionTextsStr": null,
                                         "multiChoiceQuestionTexts": null,
                                         "multiChoiceAnswerTexts": null,
                                         "multiChoiceAnswerTextsStr": null,
                                         "questionScore": null,
                                         "level": null,
                                         "content": null,

                                        "questionId" : "",
                                        "sn" : "",
                                        "questionNo" : "",
                                        "examCode" : "",

                                        "questionText" : "",
                                        "questionAnswerOptionsStr" : "",
                                        "correctAnswerStr" : "",
                                        "questionScore" : "",

                                        "questionType" : "",
                                        "sn" : "",
                                        "examCode" :  "",
                                        "questionAnswerOptionsStr" : "",
                                        "correctAnswerStr" : "",
                                        "allowsMultipleAnswers" : "",
                                        "maxAnswersAllowed" : "",
                                        "score" :  "",
                                        "questionText" : "",
                                        "answerScore" : ""

                                      }}

                        enableReinitialize={true}

                        onSubmit={(values, { setSubmitting }) => {

                              setTimeout(() => {
                                     alert(JSON.stringify(values, null, 2));
                                     let questionAndAnswerList = [];


                /*
                questionType: "",
                // Question
                questionId: "",
                sn: "",
                questionNo: "",
                examCode: "",
                questionAnswerOptionsStr: "",
                correctAnswerStr: "",
                allowsMultipleAnswers: "",
                maxAnswersAllowed: "",
                score: "",
                questionText: "",

                // Answer
                order: "",
                sn: "",
                answerScore: "",
                examCode: "",
                questionAnswerOptionsStr: "",
                correctAnswerStr: "",
                allowsMultipleAnswers: "",
                maxAnswersAllowed: "",
                score: "",
                questionText: "",

                */
                     let question = {};
                     let answer = {};

                                if(values.questionType ===  "TF") {
                                     // build True or False Qestion and Answer Request
                                     // questionText, questionNo, questionType,

                                    // trueOrFalseQtnText
                                    // trueOrFalseQtnGrp
                                    // correctTrueOrFalseAnswer
                                     let qtn = {
                                         "questionType" : values.questionType,
                                         "questionId" : values.questionId,
                                         "sn" : values.questionNo,
                                         "questionNo" : values.questionNo,
                                         "examCode" : values.examCode,

                                         "questionText" : values.trueOrFalseQtnText,
                                         "questionAnswerOptionsStr" : values.trueOrFalseQtnGrp,
                                         "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                         "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                         "questionType" : values.questionType,
                                         "sn" : values.questionNo,
                                         "examCode" :  values.examCode,
                                         "questionAnswerOptionsStr" : values.trueOrFalseQtnGrp,
                                         "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                         "allowsMultipleAnswers" : "",
                                         "maxAnswersAllowed" : "",
                                         "score" :  values.score,
                                         "questionText" : values.trueOrFalseQtnText,
                                         "answerScore" : values.questionScore
                                    }
                                    answer = ans;
                                 }

                                if(values.questionType ===  "MC") {

                                // { values.multiChoiceAnswerOptionCount === "3" ? renderThreeMultiChoice():  null }
                                // { values.multiChoiceAnswerOptionCount === "4" ? renderFourMultiChoice():  null }
                                // { values.multiChoiceAnswerOptionCount === "5" ? renderFiveMultiChoice():  null }


                                let qtn = {
                                 "questionType" : values.questionType,
                                 "questionId" : values.questionId,
                                 "sn" : values.questionNo,
                                 "questionNo" : values.questionNo,
                                 "examCode" : values.examCode,
                                // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                 "questionAnswerOptionsStr" : "",
                                 "correctAnswerStr" : values.correctTrueOrFalseAnswer,
                                  "questionScore" : values.questionScore,
                                 "questionText" : values.multiChoiceQuestionText,
                                  "multiChoiceQuestionTexts" : { "answerAText" : values.answerAText, "answerBText" : values.answerBText, "answerCText" : values.answerCText,  "answerDText" : values.answerDText, "answerEText" : values.answerEText } ,
                                  "multiChoiceAnswerTexts" : {"isACorrectChoice" : values.isACorrectChoice, "isBCorrectChoice" : values.isBCorrectChoice, "isCCorrectChoice" : values.isCCorrectChoice,  "isDCorrectChoice" : values.isDCorrectChoice, "isECorrectChoice" : values.isECorrectChoice },
                                  "multiChoiceAnswerOptionCount" : values.multiChoiceAnswerOptionCount,
                                }


                                question = qtn;

                                let ans = {
                                 "questionType" : values.questionType,
                                 "sn" : values.questionNo,
                                 "examCode" :  values.examCode,
                                 "questionAnswerOptionsStr" : "",
                                 "correctAnswerStr" : "",
                                 "allowsMultipleAnswers" : "",
                                 "maxAnswersAllowed" : "",
                                 "answerScore" : values.questionScore,
                                 "questionText" : values.multiChoiceQuestionText,
                                  "multiChoiceQuestionTexts" : { "answerAText" : values.answerAText, "answerBText" : values.answerBText, "answerCText" : values.answerCText,  "answerDText" : values.answerDText, "answerEText" : values.answerEText }  ,
                                  "multiChoiceAnswerTexts" : {"isACorrectChoice" : values.isACorrectChoice, "isBCorrectChoice" : values.isBCorrectChoice, "isCCorrectChoice" : values.isCCorrectChoice,  "isDCorrectChoice" : values.isDCorrectChoice, "isECorrectChoice" : values.isECorrectChoice },
                                 "multiChoiceAnswerOptionCount" : values.multiChoiceAnswerOptionCount
                                }
                                answer = ans;

                                }
                                if(values.questionType ===  "FB") {
                                   // renderFillBlankExactMatchQuestionSetup():
                                   // fillInBlankQuestionText
                                   // fillInBlankQuestionAnswer

                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,

                                    "questionText" : values.fillInBlankQuestionText,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.fillInBlankQuestionAnswer,
                                    "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.fillInBlankQuestionAnswer,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "answerScore" : values.questionScore,
                                    "questionText" : values.fillInBlankQuestionText,
                                    }
                                    answer = ans;
                                }
                                if(values.questionType ===  "MT") {
                                   // renderMatchingQuestionSetup()
                                   // No Support
                                }
                                if(values.questionType ===  "SE") {
                                    // renderSequenceQuestionSetup()
                                    // No Support
                                }
                                if(values.questionType ===  "DD") {

                                   // renderDragAndDropMatchingQuestionSetup()
                                   // ddDiv1
                                   // ddDiv2
                                   // img: drag1

                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,

                                    "questionText" : values.ddDiv1,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.ddDiv2,
                                     "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.ddDiv2,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "score" :  values.score,
                                    "questionText" : values.ddDiv1,
                                    "answerScore" : values.questionScore
                                    }
                                    answer = ans;
                                }
                                if(values.questionType ===  "HS") {
                                   // renderHotSpotQuestionSetup():
                                   // No Support
                                }
                                if(values.questionType ===  "ES") {
                                   // renderEssayQuestionSetup()
                                   // essayQuestionText
                                   // essayQuestionPossibleAnswer
                                    let qtn = {
                                    "questionType" : values.questionType,
                                    "questionId" : values.questionId,
                                    "sn" : values.questionNo,
                                    "questionNo" : values.questionNo,
                                    "examCode" : values.examCode,
                                    "questionText" : values.essayQuestionText,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.essayQuestionPossibleAnswer,
                                     "questionScore" : values.questionScore
                                    }
                                    question = qtn;

                                    let ans = {
                                    "questionType" : values.questionType,
                                    "sn" : values.questionNo,
                                    "examCode" :  values.examCode,
                                    "questionAnswerOptionsStr" : "",
                                    "correctAnswerStr" : values.essayQuestionPossibleAnswer,
                                    "allowsMultipleAnswers" : "",
                                    "maxAnswersAllowed" : "",
                                    "answerScore" : values.questionScore,
                                    "questionText" : values.essayQuestionText,
                                    }
                                    answer = ans;

                                }

                                // question-answer structure as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                // let question = {

                                /*     private Long id;
                                     private  Integer sn; // keep a counter per exam
                                     private Integer questionNo;
                                     private String questionType;
                                     private String examCode;
                                     private String  questionAnswerOptionsStr;
                                     private String  correctAnswerStr;
                                     private Boolean allowsMultipleAnswers;
                                     private Integer maxAnswersAllowed;
                                     private Integer score;
                                     private String content;
                                     private String questionText*/
                               //  }


                                    //let answer = {
                                      /*  private Long id;
                                        private Long userId;
                                        private Integer sn;
                                        private Integer order;
                                        private Integer answerScore;
                                        //private  Integer answerTakeAnswerSn; // keep a counter per exam
                                        private String questionText;  // json of questionText, answerOptions []
                                        private String questionType;
                                        private String examCode;
                                        private String  questionAnswerOptionsStr; // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                        private String  correctAnswerStr;  // calling as: "AB"(single), "AB:BC:CD:AE"(multiple), "A->B:C->D" (matching);
                                        private Long examId;
                                        private Long questionId;*/
                                  //}


                               let examSetupRequest = {
                                       "exam" : {
                                            "examCodeForSearch": values.examCodeForSearch,
                                            "examCode": values.examCode,
                                            "examDescription": values.examDescription,
                                            "classCode": values.classCode,
                                            "type": values.type,
                                            "score": values.score,
                                            "timeLimitSec": values.timeLimitSec,
                                            "attemptsAllowed": values.attemptsAllowed,
                                            "startsDate": values.startsDate,
                                            "endsDate": values.endsDate
                                       },
                                       "question" : question,
                                       "answer" : answer,
                               }

                                alert('ZZZ examSetupRequest ZZZ : '+ JSON.stringify(examSetupRequest, null, 2));

                                 EvaluationService.saveExamSetup(examSetupRequest).then(
                                       response => {
                                        // courseList = response.data;
                                         toast.success('Exam Setup saved successfully', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                         console.log("Exam Setup saved successfully ...: "+ JSON.stringify(response));
                                       },
                                   error => {
                                    toast.error('Error while saving Exam Setup', {position: toast.POSITION.TOP_CENTER, autoClose: 3000});
                                     console.log("Error saving Exam Setup [error.toString()] : "+ error.toString());
                                   }
                                  );

                              setSubmitting(false);
                            }, 400);
                          }}



               render={({ values }) => (

                 <Form>

                 <Box sx={{width: 600, maxWidth: '100%',}}>
                    <Grid container spacing={3}>
                      <FormitLogger />

                        <FormControl sx={{ m: 3 }} error={error} variant="standard">
                          <FormLabel id="demo-error-radios">How do you want to proceed?</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-error-radios"
                            name="flowgroup"
                            value={flow}
                            onChange={handleFlowRadioChange}>

                            <FormControlLabel value="SearchExam" control={<Radio />} label="Lookup Existing Exam" />
                            <FormControlLabel value="GenerateResult" control={<Radio />} label="Generate Result" />

                          </RadioGroup>
                          <FormHelperText>{helperText}</FormHelperText>


                        </FormControl>

                        <Grid item xs={12} sm={12}>
                                       <hr style={{color: "gray",backgroundColor: "gray",height: "3px", width: "100%"}} />
                         </Grid>

                        <div>
                            { flow === "SearchExam" ? renderExamSearchView(values):  null }
                        </div>
                        <div>
                            { flow === "GenerateResult" ? renderNewExamBaseInfo():  null }
                        </div>

                        <Grid item xs={12} sm={12}>
                            <hr style={{color: "gray",backgroundColor: "gray",height: "3px", width: "100%"}} />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                                <Button id="submitButton" sx={{ mt: 1, mr: 1 }} type="submit" variant="outlined">
                                    Save Exam Setup
                                </Button>
                                <Button  id="resetButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                                   Save Exam Take
                                </Button>
                                <Button id="saveButton" sx={{ mt: 1, mr: 1 }} type="button" variant="outlined">
                                    Generate Result
                                </Button>
                        </Grid>
                            <br />
                            <br />
                        </Grid>
                     </Box>


                      {
                        questionsList &&
                          questionsList.map((question, index) => (
                                    <Grid container spacing={3} id="lowerContentDiv">
                                     <Grid item xs={12} sm={6} md={4}>
                                       <Card className={classes.card}>

                                                 <pre>{JSON.stringify(question, null, 2)}</pre>
                                       </Card>

                                        <Grid item xs={12} sm={12}>

                                        {
                                            question.questionType ===  "TF" ? renderTrueOrFalseQuestionSetup(question, index):  null
                                        }

                                        {
                                           question.questionType ===  "MC" ? renderMultiChoiceQuestionSetup(values,question, index):  null
                                        }

                                        {
                                           question.questionType ===  "FB" ? renderFillBlankExactMatchQuestionSetup(question, index):  null
                                        }

                                        {
                                           question.questionType ===  "MT" ? renderMatchingQuestionSetup(index):  null
                                        }

                                        {
                                           question.questionType ===  "SE" ? renderSequenceQuestionSetup(index):  null
                                        }

                                        {
                                           question.questionType ===  "DD" ? renderDragAndDropMatchingQuestionSetup(index):  null
                                        }

                                        {
                                           question.questionType ===  "HS" ? renderHotSpotQuestionSetup(index):  null
                                        }
                                        {
                                           question.questionType ===  "ES" ? renderEssayQuestionSetup(question, index):  null
                                        }

                                        </Grid>

                                      </Grid>

                                    </Grid>
                         ))}



                       <br />

                 <Box>
                     <Pagination
                             count={1}
                             page={page}
                             siblingCount={1}
                             boundaryCount={1}
                             variant="outlined"
                             shape="rounded"
                             onChange={handlePageChange}

                              onClick={(e) => {
                                                 handlePageChange(e, page+1, values.examCodeForSearch);
                                              }}
                          />
                </Box>


                  </Form>

                   )}

                 />

              </div>
    </Container>
  )
}

export default ExamTakeNew

