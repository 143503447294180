
import React, { useState, useEffect } from 'react';
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { connect } from "react-redux";
import { register } from "../../actions/auth";
import Pagination from "@material-ui/lab/Pagination";

export default function RecordRollCallTest() {

  const [data, setData] = useState([
    { subject: "subject 1", grade: 50 },
    { subject: "subject 2", grade: 40 },
    { subject: "subject 3", grade: 30 }
  ]);

  //  const [studentsRollCall, setStudentsRollCall] = useState([]);
  const [studentsRollCall, setStudentsRollCall] = useState({ });


      useEffect(() => {

       let stdsRollCallData = {

                               "rollCall" : {

                                        "attendanceDate" : "05/03/2023",
                                        "classId" : "203",
                                        "classList" :  [

                                                            {
                                                                "SN" : "1",
                                                                "studentId" : "576868693334",
                                                                "firstName" : "Edet",
                                                                "middleName" : "Okon",
                                                                "lastName" : "Obong",
                                                                "isPresent" : "true"
                                                            },

                                                            {
                                                                "SN" : "2",
                                                                "studentId" : "89868693311",
                                                                "firstName" : "John",
                                                                "middleName" : "Peter",
                                                                "lastName" : "Ruffai",
                                                                "isPresent" : "false"
                                                            },
                                                            {
                                                                "SN" : "3",
                                                                "studentId" : "576868693334",
                                                                "firstName" : "Ike",
                                                                "middleName" : "Ugwu",
                                                                "lastName" : "Ohia",
                                                                "isPresent" : "false"
                                                            }

                                                      ]
                                }

                            }
          setStudentsRollCall(stdsRollCallData);
       // console.log('categories: '+categories);
       // console.log('categories length: '+categories.length);
      }, []);



   const onChangeRow = ({ type, index, value }) => {
    const newData = data.map((item, idx) => {
      if (idx === index)
        return {
          ...item,
          [type]: value
        };
      return item;
    });
    setData(newData);
  };



   const updateRow = (e, { fieldName, index, value }) => {

       e.preventDefault();

      let classList = studentsRollCall.rollCall.classList.map((item, idx) => {
               if (idx === index) {
                     return {
                       ...item,
                       [fieldName]: !value
                     };
                 }
               return item;
             });


             const rollCallCopyForUpdate = { ... studentsRollCall }
             rollCallCopyForUpdate.rollCall.classList = classList;

             setStudentsRollCall(rollCallCopyForUpdate);

         // setStudentsRollCall(classList);

     // );

      console.log('updateRow: 4 - studentsRollCall.rollCall: '+ JSON.stringify(studentsRollCall));
    };


  return (
    <div className="App">
      <div className="formContainerDiv">

               <fieldset>
                       <legend>Manage Rollcall</legend>
                  <Form>


                  <div className="form-group">
                    <label htmlFor="coursePlannedStartDate">Rollcall Date</label>
                    <span>
                            <DatePicker
                              locale="es"

                              showTimeSelect
                              dateFormat="Pp"
                              showIcon
                            />
                    </span>
                  </div>

                  <div>
                          <span style={{margin: "10px"}}>
                              Class Id:
                          </span>

                          <span style={{margin: "10px"}}>
                              {null == studentsRollCall.rollCall ? "" :  studentsRollCall.rollCall.classId}
                          </span>
                  </div>

                  <div style={{marginLeft : "35%"}}>

                      <p />

                    </div>

                   <div class="form-group">
                        <ToastContainer />
                  </div>

                  <div>

                    <div> StudentsRollCall: {JSON.stringify(studentsRollCall)} </div>


                  { (null != studentsRollCall.rollCall && studentsRollCall.rollCall.classList) &&

                      <table name="classAttendanceRecTab" id="classAttendanceRecTab" style={{width: "100%"}}  >
                       <thead>
                         <tr>
                          <th>SN</th>
                          <th>Student Id</th>
                          <th>First Name</th>
                          <th>Middle Name</th>
                          <th>Last Name</th>
                          <th>Is Present?</th>
                         </tr>
                       </thead>

                       <tbody>

                              { studentsRollCall.rollCall.classList.map((val, index) => {
                                return (
                                  <tr key={index}>
                                      <td>{val.SN}</td>
                                      <td>{val.studentId}</td>
                                      <td>{val.firstName}</td>
                                      <td>{val.middleName}</td>
                                      <td>{val.lastName}</td>
                                      <td><input type="checkbox" defaultChecked={(val.isPresent === "true")? true : false}

                                      onChange={(e) => updateRow(e, {
                                                            fieldName: "isPresent",
                                                            index,
                                                            value: e.target.checked
                                                          })
                                                        }



                                           />
                                       </td>
                                  </tr>
                                )
                              })}


                       </tbody>

                     </table>

                  }


                  </div>


                  <p/>

                   <div className="field-submit">
                          <span style={{margin: "10px"}} className="submit-span">
                              <button>Save</button>
                         </span>

                         <span style={{margin: "10px"}} className="submit-span">
                                   <button>Cancel</button>
                          </span>
                    </div>


                </Form>

              </fieldset>

              </div>

    </div>
  );
}
