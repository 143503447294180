import React from 'react';
import Header from './Header';
import TopNavMenu from './TopNavMenu';
import RightSideMenu from './RightSideMenu';
import Footer from './Footer';

const Layout = ({ children }) => {
    return (
    <React.Fragment>

            <TopNavMenu />

            <main>
              {children}
            </main>

            <Footer />

    </React.Fragment>
    );
};
export default Layout;